import Tag, {
  TAG_ICON_POSITION,
  TAG_SIZE,
  TAG_VARIANT,
} from 'design-system/components/Tag';
import Icon from 'design-system/components/Icon';
import { dispatch } from 'reffects';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { DO_REQUEST_MARKET_PRICE } from '../../../events';
import {
  ErrorFeedback,
  RetryableErrorFeedback,
} from '../ErrorFeedback/ErrorFeedback';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  marketPriceErrorReasonSelector,
  secondsToRetryMarketPriceSelector,
  showRetryableErrorFeedbackSelector,
} from '../../../partials/MarketPrice/selectors';

const ErrorTagText = styled(Tag)`
  text-transform: uppercase;
`;

export function ErrorDisclaimer() {
  const secondsToRetry = useSelector(secondsToRetryMarketPriceSelector);
  const reason = useSelector(marketPriceErrorReasonSelector);
  const showRetryableErrorFeedback = useSelector(
    showRetryableErrorFeedbackSelector
  );
  return (
    <>
      <ErrorTagText
        variant={TAG_VARIANT.PRIMARY_DARK}
        size={TAG_SIZE.SMALL}
        icon={<Icon glyph="spark" />}
        iconPosition={TAG_ICON_POSITION.START}
      >
        <FormattedMessageWithValidation id="premium" />
      </ErrorTagText>
      {showRetryableErrorFeedback ? (
        <RetryableErrorFeedback
          secondsToRetry={secondsToRetry}
          onRetry={() => dispatch(DO_REQUEST_MARKET_PRICE)}
        />
      ) : (
        <ErrorFeedback>
          <Body2
            $weight={FONT_WEIGHT.NORMAL}
            $color={COLOR_PALETTE.PRIMARY_A120}
          >
            {reason === 'DataNotFound' && (
              <FormattedMessageWithValidation id="ad_form_section_market_price_error_no_data_found" />
            )}
            {reason === 'MissingAdData' && (
              <FormattedMessageWithValidation id="ad_form_section_market_price_error_missing_fields" />
            )}
          </Body2>
        </ErrorFeedback>
      )}
    </>
  );
}
