import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../coeffects/environment';

export const CHECKOUT_BILLING_SUMMARY_REQUESTED =
  'CHECKOUT_BILLING_SUMMARY_REQUESTED';
export const CHECKOUT_BILLING_SUMMARY_RETRIEVED =
  'CHECKOUT_BILLING_SUMMARY_RETRIEVED';

registerEventHandler(
  CHECKOUT_BILLING_SUMMARY_REQUESTED,
  (
    { environment: { apiUrl }, state: { summary } },
    { subscriptionChangeRequestId }
  ) => ({
    ...state.merge({
      summary: { loading: summary == null },
    }),
    ...http.get({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/summary`,
      successEvent: CHECKOUT_BILLING_SUMMARY_RETRIEVED,
    }),
  }),
  [environment(), state.get({ summary: 'summary' })]
);

registerEventHandler(CHECKOUT_BILLING_SUMMARY_RETRIEVED, (_, [{ data }]) => ({
  ...state.set({
    summary: { ...data, loading: false },
  }),
}));
