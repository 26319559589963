import NonPremiumLeadQualification from './partials/NonPremiumLeadQualification/NonPremiumLeadqualification';
import PremiumLeadQualification from './partials/PremiumLeadQualification/PremiumLeadQualification';

export default function LeadQualification({
  shouldTeasePremium,
  hasLeadQualification,
}) {
  if (shouldTeasePremium) {
    return <NonPremiumLeadQualification />;
  }

  if (hasLeadQualification) {
    return <PremiumLeadQualification />;
  }

  return null;
}
