import { useSelector } from 'reffects-store';
import { FieldGroup } from '../../partials/FieldGroup';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import AreaInformationSection from '../../partials/AreaInformationSection';
import DetailsSection from '../../partials/DetailsSection';
import OperationSection from '../../partials/OperationSection';
import { selectedPropertyTypeSelector } from '../../selectors';

export function PropertyDetailsGroup({ order }) {
  const propertyType = useSelector(selectedPropertyTypeSelector);

  if (!propertyType) return null;

  return (
    <FieldGroup
      id="property-detail"
      title={
        <FormattedMessageWithValidation id="ad_form_group_property_detail" />
      }
      order={order}
    >
      <AreaInformationSection />
      <DetailsSection />
      <OperationSection />
    </FieldGroup>
  );
}
