import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import styled from 'styled-components';
import { CSS } from '@dnd-kit/utilities';
import { cloneElement } from 'react';

export default function SortableContainer({ children, onSort }) {
  const sensors = useSensors(useSensor(PointerSensor));
  const items = children.map(({ props }) => props.id);
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={(event) => {
        const { active, over } = event;
        if (!active || !over) return;
        if (active.id !== over.id) {
          onSort({
            draggedImageId: active.id,
            destinationImageId: over.id,
          });
        }
      }}
    >
      <SortableContext items={items}>
        {children.map((child) => (
          <DraggableContainer id={child.props.id} key={child.props.id}>
            {child}
          </DraggableContainer>
        ))}
      </SortableContext>
    </DndContext>
  );
}

const Wrapper = styled.div`
  &:focus {
    outline: none;
  }

  &:hover {
    cursor: move;
  }
`;

function DraggableContainer({ id, children }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Wrapper
      ref={setNodeRef}
      style={style}
      id={id}
      {...attributes}
      {...listeners}
    >
      {cloneElement(children, { isDragging })}
    </Wrapper>
  );
}
