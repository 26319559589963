import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import Section from 'design-system/components/Section';
import InputRow from 'design-system/components/InputRow';
import { dispatch } from 'reffects';
import { FieldGroup } from '../../partials/FieldGroup';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import CheckboxField from '../../../../partials/fields/CheckboxField';
import {
  detailsOfPropertyTypeSelector,
  selectedPropertyTypeSelector,
} from '../../selectors';
import { getPublisherIsRematesSelector } from '../../../../selectors/publisher';
import OperationTypeSection from '../../partials/OperationTypeSection';
import PropertyTypeSection from '../../partials/PropertyTypeSection';
import { TOGGLE_BANK_PROPERTY } from '../../events';

const Wrapper = styled.div`
  display: none;
`;

export default function PropertyOperationGroup({ order }) {
  const propertyType = useSelector(selectedPropertyTypeSelector);
  const details = useSelector((state) =>
    detailsOfPropertyTypeSelector(state, propertyType)
  );
  const publisherHasRematesSubscription = useSelector(
    getPublisherIsRematesSelector
  );
  return (
    <FieldGroup
      id="operation"
      title={<FormattedMessageWithValidation id="ad_form_group_operation" />}
      order={order}
    >
      <OperationTypeSection />
      <PropertyTypeSection
        choiceLabel={
          <FormattedMessageWithValidation id="newprop_type_emptystate" />
        }
      />
      {details.includes('bankProperty') &&
        getRematesCheckBoxField(publisherHasRematesSubscription)}
    </FieldGroup>
  );
}

function getRematesCheckBoxField(isRemates) {
  if (isRemates) {
    return (
      <Wrapper>
        <Section
          title={<FormattedMessageWithValidation id="ad_form_section_bank" />}
        >
          <InputRow>
            <CheckboxField name="bankProperty" disabled>
              <FormattedMessageWithValidation id="adform_field_bank_property" />
            </CheckboxField>
          </InputRow>
        </Section>
      </Wrapper>
    );
  }
  return (
    <Section
      title={<FormattedMessageWithValidation id="ad_form_section_bank" />}
    >
      <InputRow>
        <CheckboxField
          name="bankProperty"
          onChange={(e) =>
            dispatch({
              id: TOGGLE_BANK_PROPERTY,
              payload: { value: e.target.checked },
            })
          }
        >
          <FormattedMessageWithValidation id="adform_field_bank_property" />
        </CheckboxField>
      </InputRow>
    </Section>
  );
}
