import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import styled from 'styled-components';
import { TABLE_ALIGNMENT } from 'design-system/components/Table';
import { useSelector } from 'reffects-store';
import Tooltip, { TOOLTIP_PLACEMENT } from 'design-system/components/Tooltip';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import { dispatch } from 'reffects';
import { DataTable, TextColumn } from '../../../../../partials/DataTable';
import LeadsStats from '../../LeadsStats';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import StageFilters from '../../StageFilters/StageFilters';
import StatusColumn from '../StatusColumn';
import buildTableRowEventsRedirectingTo from '../../../../../partials/DataTable/utils/buildTableRowEventsRedirectingTo';
import DateFilters from '../../DateFilters/DateFilters';
import ClearFiltersButton from '../../../../../partials/DataTable/components/filters/ClearFiltersButton';
import { LeadsFilter } from '../../LeadsFilter/LeadsFilter';
import PhoneColumn from '../PhoneColumn';
import { LastActivityColumn } from '../LastActivityColumn';
import { UnreadColumn } from '../UnreadColumn';
import AccumulatedPropertiesSnippet from '../AccumulatedPropertiesSnippet';
import ProjectFieldsColumn from './ProjectFieldsColumn';
import UnitFieldsColumn from './UnitFieldsColumn';
import EnquiryFieldsColumn from './EnquiryFieldsColumn';
import LeadsProjectFilter from './LeadsProjectFilter/LeadsProjectFilter';
import ProjectSnippet from './ProjectSnippet';
import LeadsTableSkeleton from './LeadsTableSkeleton';
import UnitSnippet from './UnitSnippet';
import EmailColumn from '../EmailColumn';
import { TRACK_CLICK } from '../../../../../partials/Tracking/events';
import { leadsWithQualifiedEnquiriesSelector } from '../selectors';

const Filters = styled.div`
  display: flex;
  flex-direction: row;

  ${spacing.stack(SPACING_SIZE.L)}
  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const EnquiriesCount = styled(Body1)`
  line-height: 38px;
`;

const LeadQualificationColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.XS)};
`;

const LeadQualificationText = styled.span`
  display: inline;
`;

const LeadQualificationBadge = styled.div``;

function DeveloperLeadsTable() {
  const handleHeaderClick = (columnName) => {
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: `${columnName}-column`,
        sectionName: 'enquiries-list-ordination-developer',
      },
    });
  };

  const handleFilterClick = (filterName, payload) => {
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: `filter-enquiries-by-${filterName}`,
        sectionName: 'enquiries-list-filters-developer',
        payload,
      },
    });
  };

  return (
    <>
      <Filters>
        <LeadsFilter
          onSelect={() => {
            handleFilterClick('lead');
          }}
        />
        <LeadsProjectFilter
          onSelect={() => {
            handleFilterClick('property');
          }}
        />
        <StageFilters
          onSelect={(payload) => {
            handleFilterClick('stage', payload);
          }}
        />
        <DateFilters
          onSelect={(payload) => {
            handleFilterClick('date', payload);
          }}
        />
        <ClearFiltersButton />
      </Filters>
      <LeadsStats />
      <DataTable
        allowPagination
        clickableRows
        rowEvents={buildTableRowEventsRedirectingTo(
          (itemId) => `/leads/${itemId}`
        )}
        loadingComponent={<LeadsTableSkeleton />}
        noItemsMessage={
          <FormattedMessageWithValidation id="enqpage_table_empty_results" />
        }
      >
        <TextColumn
          id="name"
          source={{
            id: 'id',
            name: 'name',
          }}
          sortable
          title={<FormattedMessageWithValidation id="enqpage_table_name" />}
          renderField={LeadQualification}
          onClick={() => handleHeaderClick('name')}
        />
        <EmailColumn
          id="email"
          source={{ email: 'email', hasVerifiedEmail: 'hasVerifiedEmail' }}
          title={<FormattedMessageWithValidation id="enqpage_table_email" />}
          onClick={() => handleHeaderClick('email')}
        />
        <PhoneColumn
          id="phone"
          source={{ phone: 'phone', hasVerifiedPhone: 'hasVerifiedPhone' }}
          title={<FormattedMessageWithValidation id="enqpage_table_phone" />}
          onClick={() => handleHeaderClick('phone')}
        />
        <StatusColumn
          id="status"
          sortable
          title={<FormattedMessageWithValidation id="enqpage_table_status" />}
          onClick={() => handleHeaderClick('status')}
        />
        <ProjectFieldsColumn
          id="projects"
          title={<FormattedMessageWithValidation id="enqpage_table_project" />}
          renderUnitProjectField={(image, { name }) => (
            <ProjectSnippet image={image} title={name} />
          )}
          renderProjectField={({ name, image }) => (
            <ProjectSnippet image={image} title={name} />
          )}
          renderAccumulatedField={({ total }) => (
            <AccumulatedPropertiesSnippet total={total} />
          )}
        />
        <UnitFieldsColumn
          id="units"
          title={<FormattedMessageWithValidation id="enqpage_table_unit" />}
          renderUnitField={({ bathrooms, bedrooms, price }) => (
            <UnitSnippet
              bedrooms={bedrooms}
              bathrooms={bathrooms}
              price={price}
            />
          )}
        />
        <EnquiryFieldsColumn
          id="enquiryCount"
          title={<FormattedMessageWithValidation id="enqpage_table_enquiry" />}
          renderProjectField={renderEnquiryCount}
          renderUnitField={renderEnquiryCount}
          renderAccumulatedField={renderEnquiryCount}
          alignment={TABLE_ALIGNMENT.CENTER}
          onClick={() => handleHeaderClick('enquiries-count')}
        />
        <LastActivityColumn
          id="lastActivity"
          source={{
            date: 'lastActivity',
            type: 'lastActivityType',
            callStatus: 'lastActivityCallStatus',
          }}
          sortable
          title={<FormattedMessageWithValidation id="enqpage_table_date" />}
          onClick={() => handleHeaderClick('last-activity')}
        />
        <UnreadColumn
          id="unread"
          source={{
            isUnread: 'isUnread',
          }}
        />
      </DataTable>
    </>
  );
}

function renderEnquiryCount({ enquiriesCount }) {
  return <EnquiriesCount>{enquiriesCount}</EnquiriesCount>;
}

function LeadQualification({ id, name }) {
  const leadsWithQualifiedEnquiries = useSelector(
    leadsWithQualifiedEnquiriesSelector
  );

  return (
    <LeadQualificationColumn>
      <LeadQualificationText>{name}</LeadQualificationText>
      <LeadQualificationBadge hidden={!leadsWithQualifiedEnquiries[id]}>
        <Tooltip
          title={
            <FormattedMessageWithValidation id="lead_qualification_badge_tooltip" />
          }
          $placement={TOOLTIP_PLACEMENT.RIGHT}
        >
          <Tag variant={TAG_VARIANT.GOLD}>
            <FormattedMessageWithValidation id="lead_qualification_badge" />
          </Tag>
        </Tooltip>
      </LeadQualificationBadge>
    </LeadQualificationColumn>
  );
}

export default DeveloperLeadsTable;
