import { subscribe } from 'reffects-store';
import InputRow from 'design-system/components/InputRow';
import { dispatch } from 'reffects';
import SingleChoiceField from '../../../partials/fields/SingleChoiceField';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { operationTypeOptionsSelector } from '../selectors';
import { FIELD_VALUE_CHANGED } from '../events';

function OperationTypeSection({ operationTypeOptions }) {
  return (
    <InputRow>
      <SingleChoiceField
        required
        label={<FormattedMessageWithValidation id="newprop_operation_type" />}
        name="operationType"
        choices={operationTypeOptions.map(
          ({ operationType: value, caption }) => ({
            label: <FormattedMessageWithValidation id={caption} />,
            value,
          })
        )}
        onChange={({ target }) =>
          dispatch({
            id: FIELD_VALUE_CHANGED,
            payload: { field: 'operationType', value: target.value },
          })
        }
      />
    </InputRow>
  );
}

export default subscribe(OperationTypeSection, (state) => ({
  operationTypeOptions: operationTypeOptionsSelector(state),
}));
