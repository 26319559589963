import Table from 'design-system/components/Table';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';

const statusTranslationTagMap = new Map([
  ['about to expire', 'promoted_project_about_expire'],
  ['grace period', 'promoted_project_expired'],
]);

const statusTagColorMap = new Map([
  ['about to expire', TAG_VARIANT.PUMPKIN],
  ['grace period', TAG_VARIANT.COMPLEMENTARY_STRAWBERRY],
]);

export default function StatusColumn(props) {
  const { value: status } = useColumn(props);

  return (
    <Table.Cell>
      {statusTranslationTagMap.has(status) && (
        <Tag variant={statusTagColorMap.get(status)}>
          <FormattedMessageWithValidation
            id={statusTranslationTagMap.get(status)}
          />
        </Tag>
      )}
    </Table.Cell>
  );
}
