import { ACTIVITY_PERIOD_LAST_3_MONTHS } from './partials/LeadInformation/partials/LeadPlus/constants';
import { isPremiumSubscriptionSelector } from '../../selectors/publisher';

export function leadSelector(state) {
  return state.lead;
}

export function hasLeadPlusSelector(state) {
  return Boolean(leadSelector(state)?.hasLeadPlus);
}

export function hasEnquiryQualificationSelector(state) {
  return Boolean(leadSelector(state)?.hasEnquiryQualification);
}

export function hasLeadQualificationSelector(state) {
  const hasPremiumSubscription = isPremiumSubscriptionSelector(state);

  return (
    hasPremiumSubscription && Boolean(leadSelector(state)?.hasLeadQualification)
  );
}

export function leadIsSuperHotSelector(state) {
  return Boolean(state.leadQualification?.isSuperHot);
}

export function leadIsHotSelector(state) {
  return Boolean(state.leadQualification?.isHot);
}

export function isLoadingSelector(state) {
  return leadSelector(state) == null;
}

export function distinctListingEnquiriesSelector(state) {
  return state.leadPlus?.distinctListingEnquiriesCount ?? 0;
}

export function activityPeriodSelector(state) {
  return state['lead:activityPeriod'] ?? ACTIVITY_PERIOD_LAST_3_MONTHS;
}

export function searchingSinceDateSelector(state) {
  const date = leadSelector(state)?.firstVisitDate;
  return date ? new Date(date) : undefined;
}
