import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Table from 'design-system/components/Table';
import Dialog from 'design-system/components/Dialog/Dialog';
import InputBase from 'design-system/components/InputBase/InputBase';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import InputNumber from 'design-system/components/InputNumber';
import ScrollableArea from 'design-system/components/ScrollableArea/ScrollableArea';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { useDialog } from '../../hooks/useDialog';
import {
  BOOSTING_SLOTS_MODIFIED,
  EDIT_BOOSTING_MODAL,
  SAVE_BOOSTING_SLOTS,
} from './events';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { DataTable, TextColumn } from '../../partials/DataTable';
import { useColumn } from '../../partials/DataTable/hooks/useColumn';
import {
  availableMaxBoostableSlotsSelector,
  availableMaxSuperboostableSlotsSelector,
  canSaveMaxBoostablePropertiesSelector,
  canSaveMaxSuperboostablePropertiesSelector,
  usedMaxBoostableSlotsSelector,
  usedMaxSuperboostableSlotsSelector,
} from './selectors';
import { superboostEnabledSelector } from '../../selectors/config';

const Info = styled(Body1)`
  display: block;
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

const BoostingSlotsInput = styled(InputNumber)`
  height: 33px;
  ${InputBase.Box} {
    padding: 8px;
  }
`;

const TotalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${spacing.value(SPACING_SIZE.M)};

  > ${Body1} {
    width: 346px;
  }
`;

const MaxBoostableTotal = styled.div`
  ${({ $hasSuperboost }) => `width: ${$hasSuperboost ? '105px' : '62px'}`};
`;

const MaxSuperboostableTotal = styled.div`
  width: 66px;
`;

const ErrorMessage = styled(Body2).attrs({
  $color: COLOR_PALETTE.STRAWBERRY_BASE,
})`
  ${({ $visible }) => `visibility: ${$visible ? 'visible' : 'hidden'}`}
`;

export default function EditBoostingModal({ onSuccessEvent }) {
  const { open, closeDialog } = useDialog(EDIT_BOOSTING_MODAL);
  const hasSuperboostEnabled = useSelector(superboostEnabledSelector);
  const usedMaxBoostableSlots = useSelector(usedMaxBoostableSlotsSelector);
  const availableMaxBoostableSlots = useSelector(
    availableMaxBoostableSlotsSelector
  );
  const usedMaxSuperboostableSlots = useSelector(
    usedMaxSuperboostableSlotsSelector
  );
  const availableMaxSuperboostableSlots = useSelector(
    availableMaxSuperboostableSlotsSelector
  );
  const canSaveMaxBoostableProperties = useSelector(
    canSaveMaxBoostablePropertiesSelector
  );
  const canSaveMaxSuperboostableProperties = useSelector(
    canSaveMaxSuperboostablePropertiesSelector
  );
  const submitEnabled =
    canSaveMaxBoostableProperties && canSaveMaxSuperboostableProperties;
  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id="managed_publishers_edit_boosting_modal_header" />
      </Dialog.Header>
      <Dialog.Content>
        <Info>
          <FormattedMessageWithValidation id="managed_publishers_edit_boosting_modal_info" />
        </Info>
        <ScrollableArea maxHeight="553">
          <DataTable>
            <TextColumn
              id="name"
              title={
                <FormattedMessageWithValidation id="managed_publishers_table_headers_name" />
              }
              fullWidth
            />
            <InputColumn
              id="maxBoostableProperties"
              field="maxBoostableProperties"
              source={{
                publisherId: 'id',
                boostableProperties: 'maxBoostableProperties',
              }}
              maxWidth={95}
              title={
                <FormattedMessageWithValidation id="managed_publishers_table_headers_boosting" />
              }
            />
            {hasSuperboostEnabled && (
              <InputColumn
                id="maxSuperboostableProperties"
                field="maxSuperboostableProperties"
                source={{
                  publisherId: 'id',
                  boostableProperties: 'maxSuperboostableProperties',
                }}
                maxWidth={95}
                title={
                  <FormattedMessageWithValidation id="managed_publishers_table_headers_superboosting" />
                }
              />
            )}
          </DataTable>
        </ScrollableArea>
        <TotalRow>
          <Body1 $weight={FONT_WEIGHT.BOLD}>
            <FormattedMessageWithValidation id="managed_publishers_edit_boosting_modal_total" />
          </Body1>
          <MaxBoostableTotal $hasSuperboost={hasSuperboostEnabled}>
            <Body1
              $weight={FONT_WEIGHT.LIGHT}
              $color={COLOR_PALETTE.NEUTRAL_BASE}
            >
              {usedMaxBoostableSlots}
            </Body1>
            <Body1
              $weight={FONT_WEIGHT.LIGHT}
              $color={COLOR_PALETTE.NEUTRAL_A40}
            >
              {' '}
              / {availableMaxBoostableSlots}
            </Body1>
          </MaxBoostableTotal>
          {hasSuperboostEnabled && (
            <MaxSuperboostableTotal>
              <Body1
                $weight={FONT_WEIGHT.LIGHT}
                $color={COLOR_PALETTE.NEUTRAL_BASE}
              >
                {usedMaxSuperboostableSlots}
              </Body1>
              <Body1
                $weight={FONT_WEIGHT.LIGHT}
                $color={COLOR_PALETTE.NEUTRAL_A40}
              >
                {' '}
                / {availableMaxSuperboostableSlots}
              </Body1>
            </MaxSuperboostableTotal>
          )}
        </TotalRow>
        <ErrorMessage $visible={!submitEnabled}>
          {!canSaveMaxBoostableProperties && (
            <FormattedMessageWithValidation id="managed_publishers_edit_boosting_modal_error" />
          )}
          {canSaveMaxBoostableProperties &&
            !canSaveMaxSuperboostableProperties && (
              <FormattedMessageWithValidation id="managed_publishers_edit_superboosting_modal_error" />
            )}
        </ErrorMessage>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton $size={BUTTON_SIZE.SMALL} onClick={closeDialog}>
          <FormattedMessageWithValidation id="generic_cancel" />
        </FlatButton>
        <SecondaryButton
          $size={BUTTON_SIZE.SMALL}
          onClick={() =>
            dispatch({ id: SAVE_BOOSTING_SLOTS, payload: { onSuccessEvent } })
          }
          disabled={!submitEnabled}
        >
          <FormattedMessageWithValidation id="generic_confirm" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

function InputColumn({ field, ...props }) {
  const {
    value: { publisherId, boostableProperties },
  } = useColumn(props);
  const onChange = (boostingSlots) =>
    dispatch({
      id: BOOSTING_SLOTS_MODIFIED,
      payload: { publisherId, boostingSlots, field },
    });
  const handleChange = (e) => {
    if (!e.target.rawValue) {
      onChange(undefined);
    } else {
      const number = parseInt(e.target.rawValue, 10);
      if (Number.isNaN(number)) {
        onChange(boostableProperties);
      } else {
        onChange(number);
      }
    }
  };
  return (
    <Table.Cell {...props}>
      <BoostingSlotsInput
        options={{
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
          numeralDecimalScale: 0,
        }}
        value={boostableProperties}
        onChange={handleChange}
      />
    </Table.Cell>
  );
}
