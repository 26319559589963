import { useController, useFormContext } from 'react-hook-form';

import InputSelect from 'design-system/components/InputSelect';
import { useRegisterFieldErrors } from '../../pages/publication/partials/FieldGroup';

function SelectField({
  name,
  tip,
  hideErrors,
  defaultValue,
  onSelect,
  ...rest
}) {
  const { control } = useFormContext();
  const {
    fieldState: { error },
    field: { value, onChange, ref },
  } = useController({
    name,
    control,
    defaultValue,
  });

  useRegisterFieldErrors({ name, errors: error });

  const handleSelection = (selection) => {
    onChange(selection.value);
    onSelect?.(selection);
  };

  const errorMessage = !hideErrors && error?.message;

  return (
    <InputSelect
      ref={ref}
      value={value}
      tip={errorMessage ?? tip}
      error={Boolean(error)}
      onSelect={handleSelection}
      fullWidth
      {...rest}
    />
  );
}

export default SelectField;
