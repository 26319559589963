import styled from 'styled-components';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { OutlinePrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import Icon from 'design-system/components/Icon';
import { COLOR_PALETTE } from 'design-system/styles/color';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const DisclaimerText = styled.span`
  display: flex;
  width: 100%;
  color: ${COLOR_PALETTE.PRIMARY_BASE};
  ${typography.size(FONT_SIZE.S)};
  ${typography.font(FONT_FAMILY.PRIMARY)};
  ${typography.weight(FONT_WEIGHT.NORMAL)};
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.M)};
  line-height: normal;
  align-items: center;
  justify-content: space-between;
`;

export function RetryableErrorFeedback({ secondsToRetry, onRetry }) {
  const retryDisabled = secondsToRetry > 0;
  return (
    <DisclaimerText>
      <FormattedMessageWithValidation id="upload_progress_bar_error_retry" />
      <OutlinePrimaryButton
        size={BUTTON_SIZE.SMALL}
        disabled={retryDisabled}
        onClick={onRetry}
        icon={retryDisabled ? <Icon glyph="refresh" /> : null}
      >
        <FormattedMessageWithValidation id="upload_progress_bar_error_retry_cta" />{' '}
        {retryDisabled && <>{secondsToRetry}s</>}
      </OutlinePrimaryButton>
    </DisclaimerText>
  );
}

export function ErrorFeedback({ children }) {
  return <DisclaimerText>{children}</DisclaimerText>;
}
