import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { TRACK } from '../Tracking/events';

export const SUBMIT_PREMIUM_FEEDBACK = 'SUBMIT_PREMIUM_FEEDBACK';

registerEventHandler(
  SUBMIT_PREMIUM_FEEDBACK,
  (_, { isPositive, answer, section }) => ({
    ...state.set({ [`premiumFeedbackSubmitted.${section}`]: true }),
    ...(answer.length === 0
      ? {}
      : effects.dispatch({
          id: TRACK,
          payload: {
            event: 'QualitativeFeedbackForPremiumGiven',
            section,
            payload: { isPositive, answer },
          },
        })),
  })
);
