import styled, { css, keyframes } from 'styled-components';
import Loader from 'design-system/components/Loader';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Tag, {
  TAG_ICON_POSITION,
  TAG_SIZE,
  TAG_VARIANT,
} from 'design-system/components/Tag';
import Icon from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { UPLOAD_ANIMATION_STATUS } from './constants';
import {
  getImageUploaderStatusSelector,
  sortingErrorRetryAtSelector,
} from '../../../selectors';
import { RetryableErrorFeedback } from '../ErrorFeedback/ErrorFeedback';
import { RETRY_SORTING_WITH_AI } from '../../../partials/ImageUploader/events';
import { showThinkingSelector } from '../../../partials/ImageUploader/selectors';
import AnimatedThinkingBall from './AnimatedThinkingBall';

const moveLeft = keyframes`
    0% {
        right: 24px;
    }
    100% {
        right: calc(100% - 60px);
    }
`;

const fillBackground = keyframes`
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
`;

const dots = keyframes`
    0% {
        content: '';
    }
    25% {
        content: '.';
    }
    50% {
        content: '..';
    }
    75% {
        content: '...';
    }
    100% {
        content: '....';
    }
`;

const openTransition = keyframes`
    0%{
        height: 0;
        padding: 0 ${SPACING_SIZE.M}px;
    }
    100% {
        height: 81px;
        padding: ${SPACING_SIZE.M}px;
    }
`;

const reOpenTransition = keyframes`
  from {
    height: 21px;
    padding: 0 ${SPACING_SIZE.M}px;
  }
  to {
    height: 81px;
    padding: ${SPACING_SIZE.M}px;
  }
`;

const closeTransition = keyframes`
    0% {
        height: 81px;
        padding: ${SPACING_SIZE.M}px;
    }
    100% {
        height: 0;
        padding: 0 ${SPACING_SIZE.M}px;
    }
`;

const fadeIn = keyframes`
    0%, 79% {
        opacity: 0;
    }
    80% {
        opacity: 0.5;
    }
    95% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }

`;

const ProgressContainer = styled.div`
  width: 100%;
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)},
  ${typography.size(FONT_SIZE.M)};
  font-weight: bold;
  text-align: left;
  overflow: hidden;
  height: 0;
  padding: 0;

  border-radius: 8px;
  border: 0;
  display: flex;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  position: relative;
  transition: height 0.3s ease-out, padding 0s ease-out;
  animation: ${openTransition} 1s forwards;

  ${({ $status }) =>
    $status !== UPLOAD_ANIMATION_STATUS.LOADING &&
    css`
      ${color.background(COLOR_PALETTE.PRIMARY_A05)}
    `}

  ${({ $status }) =>
    $status !== UPLOAD_ANIMATION_STATUS.THINKING &&
    $status !== UPLOAD_ANIMATION_STATUS.ONLY_THINKING &&
    css`
      justify-content: space-between;
    `}

    ${({ $status }) =>
    ($status === UPLOAD_ANIMATION_STATUS.THINKING ||
      $status === UPLOAD_ANIMATION_STATUS.ONLY_THINKING) &&
    css`
      height: 81px;
      padding: 24px;
    `}

    ${({ $status }) =>
    $status === UPLOAD_ANIMATION_STATUS.THINKING_FINISH &&
    css`
      animation: ${reOpenTransition} 1s forwards;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      $(Tag) {
        display: flex;
        padding: 2px 4px 2px 6px;
        justify-content: center;
        align-items: center;
        gap: ${SPACING_SIZE.XS}px;
        text-transform: uppercase;
      }
    `}

    ${({ $status }) =>
    $status === UPLOAD_ANIMATION_STATUS.ERROR &&
    css`
      height: auto;
      padding: ${SPACING_SIZE.M}px;
    `}

    ${({ $status }) =>
    $status === UPLOAD_ANIMATION_STATUS.FINISH &&
    css`
      height: 81px;
      padding: ${SPACING_SIZE.M}px;
      animation: ${closeTransition} 1s forwards;
      animation-delay: 1s;
    `}
`;

const TagText = styled(Tag)`
  text-transform: uppercase;
  opacity: 0;
  animation: ${fadeIn} 0.8s forwards;
`;

const Disclaimer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${SPACING_SIZE.S}px;
  flex: 1 0 0;
  padding: 2px 4px 2px 6px;
`;

const UploadProgressLoader = styled(Loader)`
  width: 36px;
  height: 36px;
  border-color: ${COLOR_PALETTE.PRIMARY_A20};
  border-top-color: transparent;
  flex-shrink: 0;
`;

const GreenBall = styled.div`
  width: 36px;
  height: 36px;
  ${color.background(COLOR_PALETTE.PRIMARY_A20)}
  border-radius: 50%;
  flex-shrink: 0;
  position: absolute;
  right: ${SPACING_SIZE.L}px;
`;
const GreenBallAnimation = styled(GreenBall)`
  animation: ${moveLeft} 0.5s forwards;
  animation-delay: 0.6s;
`;

const BackgroundFiller = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  ${color.background(COLOR_PALETTE.PRIMARY_A05)}
  z-index: -1;
  animation: ${fillBackground} 2.5s forwards;
`;

const Text = styled.span`
  margin-left: 0;
`;

const TextWithDots = styled(Text)`
  ::after {
    content: '';
    animation: ${dots} 1.8s steps(1, end) infinite;
  }
`;
const DisclaimerText = styled.span`
  color: ${COLOR_PALETTE.PRIMARY_BASE};
  ${typography.font(FONT_FAMILY.PRIMARY)}
  ${typography.size(FONT_SIZE.S)}
    ${typography.weight(FONT_WEIGHT.NORMAL)}
    line-height: normal;
  opacity: 0;
  animation: ${fadeIn} 0.8s forwards;
`;

export default function UploadProgress() {
  const status = useSelector(getImageUploaderStatusSelector);
  const sortingErrorRetryDelay = useSelector(sortingErrorRetryAtSelector);
  const showThinking = useSelector(showThinkingSelector);

  return (
    <ProgressContainer $status={status}>
      {status === UPLOAD_ANIMATION_STATUS.LOADING && (
        <>
          <BackgroundFiller />
          <TextWithDots>
            <FormattedMessageWithValidation id="upload_progress_bar_uploading_images" />
          </TextWithDots>
          <UploadProgressLoader />
        </>
      )}
      {status === UPLOAD_ANIMATION_STATUS.FINISH && (
        <>
          <GreenBall />
          <Text>
            <FormattedMessageWithValidation id="upload_progress_bar_uploading_images_complete" />
          </Text>
        </>
      )}

      {status === UPLOAD_ANIMATION_STATUS.ONLY_THINKING && (
        <AnimatedThinkingBall />
      )}

      {status === UPLOAD_ANIMATION_STATUS.THINKING && (
        <>
          {!showThinking && (
            <>
              <GreenBallAnimation />
              <Text>
                <FormattedMessageWithValidation id="upload_progress_bar_uploading_images_complete" />
              </Text>
            </>
          )}

          {showThinking && <AnimatedThinkingBall />}
        </>
      )}

      {status === UPLOAD_ANIMATION_STATUS.THINKING_FINISH && (
        <Disclaimer>
          <TagText
            variant={TAG_VARIANT.PRIMARY_DARK}
            size={TAG_SIZE.SMALL}
            icon={<Icon glyph="spark" />}
            iconPosition={TAG_ICON_POSITION.START}
          >
            <FormattedMessageWithValidation id="premium" />
          </TagText>
          <DisclaimerText>
            <FormattedMessageWithValidation
              id="ai_thinker_disclaimer_images"
              values={{
                ai_thinker_disclaimer_images_bold_1: (
                  <strong>
                    <FormattedMessageWithValidation id="ai_thinker_disclaimer_images_bold_1" />
                  </strong>
                ),
                ai_thinker_disclaimer_images_bold_2: (
                  <strong>
                    <FormattedMessageWithValidation id="ai_thinker_disclaimer_images_bold_2" />
                  </strong>
                ),
              }}
            />
          </DisclaimerText>
        </Disclaimer>
      )}

      {status === UPLOAD_ANIMATION_STATUS.ERROR && (
        <Disclaimer>
          <TagText
            variant={TAG_VARIANT.PRIMARY_DARK}
            size={TAG_SIZE.SMALL}
            icon={<Icon glyph="spark" />}
            iconPosition={TAG_ICON_POSITION.START}
          >
            <FormattedMessageWithValidation id="premium" />
          </TagText>
          <RetryableErrorFeedback
            secondsToRetry={sortingErrorRetryDelay}
            onRetry={() => dispatch(RETRY_SORTING_WITH_AI)}
          />
        </Disclaimer>
      )}
    </ProgressContainer>
  );
}
