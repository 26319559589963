import styled from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import TooltipModal from 'design-system/components/TooltipModal/TooltipModal';
import FormattedMessageWithValidation from './FormattedMessageWithValidation/FormattedMessageWithValidation';
import UnlockPremiumTooltipContent from './UnlockPremiumTooltipContent';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.XS)};

  ${Subtitle3} {
    ${color.text(COLOR_PALETTE.PRIMARY_A120)}
  }
`;

export default function UnlockPremium({
  labelTag,
  tooltipTag = 'unlock_premium_feature',
  tooltipPlacement,
}) {
  return (
    <TooltipModal
      popoverComponent={<UnlockPremiumTooltipContent labelTag={tooltipTag} />}
      $placement={tooltipPlacement}
    >
      <Wrapper>
        <Icon
          glyph="lock"
          $size={ICON_SIZE.XSMALL}
          $color={COLOR_PALETTE.PRIMARY_A120}
        />
        <Subtitle3>
          <FormattedMessageWithValidation id={labelTag} />
        </Subtitle3>
      </Wrapper>
    </TooltipModal>
  );
}
