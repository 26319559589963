import { effects, registerEventHandler } from 'reffects';
import { http, queryParams } from 'reffects-batteries';
import { state } from 'reffects-store';
import { calendar } from '../../../coeffects/calendar';
import { environment } from '../../../coeffects/environment';
import { uuid } from '../../../coeffects/uuid';
import { postMultipart } from '../../../effects/http';
import { navigateTo } from '../../../effects/routing';
import { toast } from '../../../effects/toast';
import { UNITS } from '../../../constants/routes';
import { prepareDataToSubmit } from '../prepareDataToSubmit';
import { prepareDataFromResponse } from '../prepareDataFromResponse';
import { analytics } from '../../../effects/analytics';
import {
  assignMainImageWhenUndefined,
  mergeAndReindexImages,
  prepareProjectImages,
  removeProjectImages,
} from '../partials/ImageUploader/adImages';

export const PROJECTS_REQUESTED = 'PROJECTS_REQUESTED';
export const PROJECTS_OBTAINED = 'PROJECTS_OBTAINED';
export const PROJECTS_OBTAINED_FAILED = 'PROJECTS_OBTAINED_FAILED';
export const UNIT_FORM_PROJECT_SELECTED = 'UNIT_FORM_PROJECT_SELECTED';
export const CREATE_UNIT_FORM_MOUNTED = 'CREATE_UNIT_FORM_MOUNTED';
export const UNIT_SUBMITTED = 'UNIT_SUBMITTED';
export const UNIT_FORM_SAVED = 'UNIT_FORM_SAVED';
export const UNIT_FORM_FAILED = 'UNIT_FORM_FAILED';
export const EDIT_UNIT_DATA_REQUESTED = 'EDIT_UNIT_DATA_REQUESTED';
export const EDIT_UNIT_FORM_LOADED = 'EDIT_UNIT_FORM_LOADED';
export const EDIT_UNIT_FORM_LOAD_FAILED = 'EDIT_UNIT_FORM_LOAD_FAILED';
export const RESET_UNIT_FORM_VALUES = 'RESET_UNIT_FORM_VALUES';

registerEventHandler(
  PROJECTS_REQUESTED,
  ({ environment: { apiUrl } }) => ({
    ...http.get({
      url: `${apiUrl}/developers/me/projects`,
      successEvent: PROJECTS_OBTAINED,
      errorEvent: PROJECTS_OBTAINED_FAILED,
    }),
  }),
  [environment()]
);

function mapProjectSuggestionsFromResponse(data) {
  return (data || []).map((project) => ({
    ...project,
    images: prepareProjectImages(project.images),
  }));
}

registerEventHandler(PROJECTS_OBTAINED, (_, [{ data }]) => {
  const projectSuggestions = mapProjectSuggestionsFromResponse(data);

  return state.set({
    'adForm:projectNameSuggestions': projectSuggestions,
  });
});

registerEventHandler(PROJECTS_OBTAINED_FAILED, () =>
  state.set({
    'adForm:projectNameSuggestions': [],
  })
);

registerEventHandler(
  UNIT_FORM_PROJECT_SELECTED,
  (
    { state: { currentUnitImages, currentProjectId } },
    { selectedProject: { id, amenities, images } }
  ) => {
    if (id === currentProjectId) {
      return {};
    }

    const unitImages = removeProjectImages(currentUnitImages);
    const mergedImages = assignMainImageWhenUndefined(
      mergeAndReindexImages(unitImages, images)
    );

    return state.set({
      'adForm:projectAmenities': amenities,
      'adForm:ad.projectId': id,
      'adForm:ad.propertyImages': mergedImages,
    });
  },
  [
    state.get({
      currentUnitImages: 'adForm:ad.propertyImages',
      currentProjectId: 'adForm:ad.projectId',
    }),
  ]
);

registerEventHandler(
  CREATE_UNIT_FORM_MOUNTED,
  ({
    calendar: { timestamp },
    queryParams: { projectId },
    uuid: { newUuid },
  }) =>
    state.set({
      'adForm:ad': { id: newUuid },
      imageUploader: undefined,
      'adForm:projectAmenities': undefined,
      'adForm:initialProjectId': projectId,
      'adForm:begunAt': timestamp,
    }),
  [calendar(), queryParams.get(['projectId']), uuid()]
);

registerEventHandler(
  UNIT_SUBMITTED,
  (
    {
      calendar: { now, timestamp },
      environment: { apiUrl },
      state: { begunAt },
    },
    { published = true, isNew, ...formData }
  ) => {
    const { ad, imagesToUpload, defaultContact } = prepareDataToSubmit({
      now,
      formData,
      published,
    });

    return {
      ...postMultipart({
        url: `${apiUrl}/properties/${ad.id}`,
        body: {
          ad,
        },
        files: imagesToUpload,
        successEvent: {
          id: UNIT_FORM_SAVED,
          payload: { published, defaultContact, isNew },
        },
        errorEvent: [UNIT_FORM_FAILED],
      }),
      ...state.set({ 'adForm:submitting': true }),
      ...trackSubmitFormClick({ now: timestamp, begunAt, published }),
    };
  },
  [calendar(), environment(), state.get({ begunAt: 'adForm:begunAt' })]
);

function trackSubmitFormClick({ now, begunAt, published }) {
  const timeToComplete = calculateElapsedTime(now, begunAt);

  return analytics.trackClick(
    published ? 'save-unit-publishing' : 'save-unit-as-draft',
    published
      ? 'unit-form-save-bottom-button'
      : 'unit-form-draft-bottom-button',
    { timeToComplete }
  );
}

function calculateElapsedTime(timestamp, begunAt) {
  return (timestamp - begunAt) / 1000;
}

registerEventHandler(
  UNIT_FORM_SAVED,
  ({ environment: { apiUrl } }, [_, eventPayload]) => {
    const { defaultContact, published, isNew } = eventPayload;

    const successToastMessage = getSuccessToastMessage(published, isNew);

    return {
      ...state.set({
        'adForm:submitting': false,
      }),
      ...toast.show({
        text: successToastMessage,
        milliseconds: 6000,
      }),
      ...http.put({
        url: `${apiUrl}/users/me/default-contact`,
        body: defaultContact,
      }),
      ...navigateTo(UNITS),
      ...effects.dispatch(RESET_UNIT_FORM_VALUES),
    };
  },
  [environment()]
);

registerEventHandler(UNIT_FORM_FAILED, () =>
  state.set({ 'adForm:submitting': false })
);

registerEventHandler(
  EDIT_UNIT_DATA_REQUESTED,
  ({ environment: { apiUrl } }, { adId }) => ({
    ...state.set({
      'adForm:isInitialized': false,
    }),
    ...http.get({
      url: `${apiUrl}/properties/${adId}`,
      successEvent: [EDIT_UNIT_FORM_LOADED],
      errorEvent: [EDIT_UNIT_FORM_LOAD_FAILED],
    }),
  }),
  [environment()]
);

registerEventHandler(
  EDIT_UNIT_FORM_LOADED,
  ({ calendar: { timestamp }, state: { whatsappCallingCode } }, [{ data }]) => {
    const adData = prepareDataFromResponse({
      data,
      whatsappCallingCode,
    });

    return state.set({
      'adForm:isInitialized': true,
      'adForm:ad': adData,
      imageUploader: undefined,
      'adForm:begunAt': timestamp,
    });
  },
  [
    calendar(),
    state.get({
      whatsappCallingCode: 'countryConfig.phoneNumber.callingCode',
    }),
  ]
);

registerEventHandler(EDIT_UNIT_FORM_LOAD_FAILED, () => navigateTo('/'));

function getSuccessToastMessage(published, isNew) {
  if (!published) {
    return 'toast_unit_saved_as_draft_developers';
  }
  if (!isNew) {
    return 'toast_unit_edited_developers';
  }
  return 'toast_unit_published_developers';
}

registerEventHandler(RESET_UNIT_FORM_VALUES, () =>
  state.set({
    'adForm:isInitialized': false,
    'adForm:ad': undefined,
    'adForm:projectNameSuggestions': undefined,
    'adForm:projectAmenities': undefined,
    'adForm:initialProjectId': undefined,
    'adForm:begunAt': undefined,
    imageUploader: undefined,
  })
);
