import {
  rentPriceSelector,
  selectedOperationTypeSelector,
  sellPriceSelector,
} from '../../selectors';
import { UPLOAD_ANIMATION_STATUS } from '../../AdForm/partials/UploadProgress/constants';

export function marketPriceLoadingStatusSelector(state) {
  return state['adForm:marketPriceLoadingStatus'];
}

export function priceIsAboveMarketPriceSelector(state) {
  const suggestedPrice = suggestedPriceSelector(state);
  const price = inputPriceSelector(state);
  if (suggestedPrice == null || price == null) {
    return false;
  }
  return price.amount > suggestedPrice.amount;
}

export function priceIsSameAsMarketPriceSelector(state) {
  const suggestedPrice = suggestedPriceSelector(state);
  const price = inputPriceSelector(state);
  if (suggestedPrice == null || price == null) {
    return false;
  }
  return price.amount === suggestedPrice.amount;
}

export function priceIsBelowMarketPriceSelector(state) {
  const suggestedPrice = suggestedPriceSelector(state);
  const price = inputPriceSelector(state);
  if (suggestedPrice == null || price == null) {
    return false;
  }
  return price.amount < suggestedPrice.amount;
}

export function differencePercentageSelector(state) {
  const suggestedPricePerSquareMeter = pricePerSquareMeterSelector(state);
  const inputPricePerSquareMeter = inputPricePerSquareMeterSelector(state);
  if (
    suggestedPricePerSquareMeter == null ||
    inputPricePerSquareMeter == null
  ) {
    return undefined;
  }
  const percentage =
    ((suggestedPricePerSquareMeter.amount - inputPricePerSquareMeter.amount) /
      inputPricePerSquareMeter.amount) *
    100;
  return Math.abs(Math.round(percentage));
}

export function suggestedPriceSelector(state) {
  return marketPriceForOperationSelector(state)?.suggestedPrice ?? undefined;
}

export function pricePerSquareMeterSelector(state) {
  return (
    marketPriceForOperationSelector(state)?.pricePerSquareMeter ?? undefined
  );
}

export function inputPricePerSquareMeterSelector(state) {
  const price = inputPriceSelector(state);
  const area = marketPriceSelector(state)?.area ?? null;
  if (price == null || area == null) {
    return undefined;
  }
  return { amount: price.amount / area.value, currency: price.currency };
}

function inputPriceSelector(state) {
  if (selectedOperationTypeSelector(state) === 'sell') {
    return sellPriceSelector(state);
  }
  return rentPriceSelector(state);
}

function marketPriceForOperationSelector(state) {
  const operationType = selectedOperationTypeSelector(state);
  if (operationType == null) {
    return undefined;
  }
  return marketPriceSelector(state).operations?.[operationType];
}

function marketPriceSelector(state) {
  return state['adForm:marketPrice'] ?? {};
}

export function secondsToRetryMarketPriceSelector(state) {
  return state['adForm:marketPriceErrorRetryDelay'] ?? 0;
}

export function marketPriceErrorReasonSelector(state) {
  return state['adForm:marketPriceErrorReason'];
}

export function showRetryableErrorFeedbackSelector(state) {
  const reason = marketPriceErrorReasonSelector(state);
  return reason !== 'MissingAdData' && reason !== 'DataNotFound';
}

export function hasMarketReferenceStatusSelector(state) {
  return Boolean(marketPriceLoadingStatusSelector(state));
}

export function shouldDisplayMarketReferenceThinkerBallSelector(state) {
  return (
    marketPriceLoadingStatusSelector(state) === UPLOAD_ANIMATION_STATUS.THINKING
  );
}

export function shouldDisplayMarketReferenceErrorMessageSelector(state) {
  return (
    marketPriceLoadingStatusSelector(state) === UPLOAD_ANIMATION_STATUS.ERROR
  );
}

export function shouldDisplayMarketReferenceResultSelector(state) {
  return (
    marketPriceLoadingStatusSelector(state) ===
    UPLOAD_ANIMATION_STATUS.THINKING_FINISH
  );
}
