import { ROUTE_TYPE, RouteProvider } from './context';
import RouteWithInterstitial from './RouteWithInterstitial';

function PublicRoute({ as: Component, disableInterstitial, ...props }) {
  return (
    <RouteProvider routeType={ROUTE_TYPE.PUBLIC}>
      <RouteWithInterstitial disable={disableInterstitial}>
        <Component {...props} />
      </RouteWithInterstitial>
    </RouteProvider>
  );
}

export default PublicRoute;
