import React from 'react';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Uploader from './partials/Uploader/Uploader';
import Gallery from './partials/Gallery/Gallery';
import { propertyImagesSortedWithAiSelector } from '../../selectors';
import UploadProgress from '../../AdForm/partials/UploadProgress/UploadProgress';
import {
  IMAGE_DELETE_CANCELED,
  IMAGE_DELETE_CONFIRMED,
  IMAGE_DELETED,
  IMAGES_SORTED,
  RESTORE_SORTING_WITH_AI,
  TOGGLE_SORTING_MODE,
} from './events';
import SortingSwitch from './partials/SortingSwitch';
import {
  shouldEnableDragAndDropSelector,
  shouldEnableSortingSwitchSelector,
  shouldRequestFeedbackSelector,
  shouldShowSortingSwitchSelector,
  showDeleteImageDialogSelector,
  showUploadProgressSelector,
} from './selectors';
import PremiumFeedback from '../../../../partials/PremiumFeedback/PremiumFeedback';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

function AdImageUploader(
  {
    id,
    name,
    images,
    helperText,
    errorText,
    isDisabled,
    onImagesUploaded,
    ...rest
  },
  ref
) {
  const showDeleteImageDialog = useSelector((state) =>
    showDeleteImageDialogSelector(state, name)
  );
  const usingAISorting = useSelector(propertyImagesSortedWithAiSelector);
  const showUploadProgress = useSelector((state) =>
    showUploadProgressSelector(state, name)
  );
  const shouldShowSortingSwitch = useSelector(shouldShowSortingSwitchSelector);
  const shouldEnableSortingSwitch = useSelector(
    shouldEnableSortingSwitchSelector
  );
  const shouldRequestFeedback = useSelector(shouldRequestFeedbackSelector);
  const enabledDragAndDrop = useSelector(shouldEnableDragAndDropSelector);

  return (
    <div ref={ref} {...rest}>
      <Wrapper>
        <Uploader
          id={id}
          isDisabled={isDisabled}
          helperText={helperText}
          errorText={errorText}
          onImagesUploaded={onImagesUploaded}
        />
        {shouldShowSortingSwitch && (
          <SortingSwitch
            usingAISorting={usingAISorting}
            disabled={!shouldEnableSortingSwitch}
            onToggleSortingMode={(usingAI) =>
              dispatch({ id: TOGGLE_SORTING_MODE, payload: { usingAI } })
            }
            onRestoreSorting={() => dispatch(RESTORE_SORTING_WITH_AI)}
          />
        )}
        {showUploadProgress && (
          <>
            <UploadProgress />
            {shouldRequestFeedback && (
              <PremiumFeedback
                label="premium_feedback_question_ai_images_sorting"
                section="ImageSorting"
              />
            )}
          </>
        )}
        <Gallery
          images={images}
          onImagesSorted={(e) =>
            dispatch({
              id: IMAGES_SORTED,
              payload: { field: name, images, ...e },
            })
          }
          onDelete={(imageId) =>
            dispatch({
              id: IMAGE_DELETED,
              payload: { field: name, images, imageId },
            })
          }
          showDeleteImageDialog={showDeleteImageDialog}
          onConfirmDeleteImage={() =>
            dispatch({
              id: IMAGE_DELETE_CONFIRMED,
              payload: { field: name, images },
            })
          }
          onCancelDeleteImage={() =>
            dispatch({ id: IMAGE_DELETE_CANCELED, payload: { field: name } })
          }
          disabledDragAndDrop={!enabledDragAndDrop}
        />
      </Wrapper>
    </div>
  );
}

export default React.forwardRef(AdImageUploader);
