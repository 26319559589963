import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Dialog from 'design-system/components/Dialog/Dialog';
import InputSelect from 'design-system/components/InputSelect';
import InputText from 'design-system/components/InputText';
import InputNumber, {
  INPUT_NUMBER_TYPE,
} from 'design-system/components/InputNumber';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { useDialog } from '../../../../../../../hooks/useDialog';
import { CHANGE_SUBSCRIPTION_MODAL } from './constants';
import {
  CHANGE_SUBSCRIPTION_VALUE_CHANGED,
  SUBSCRIPTION_CHANGES_CONFIRMED,
  SUBSCRIPTION_CHANGES_MAX_BOOSTABLE_PROPERTIES_SELECTED,
  SUBSCRIPTION_CHANGES_TYPE_OF_CLIENT_SELECTED,
} from './events';
import {
  FormWrapper,
  ItemFullWidth,
  ItemTwoColumns,
} from '../../../../../../../partials/SimpleFormLayout';
import { PublishesInThailandPropertyCheckbox } from '../partials/PublishesInThailandPropertyCheckbox';
import TypeOfClientSelect from '../../../../../partials/TypeOfClientSelect/TypeOfClientSelect';
import PlanToSubscribe from '../../../../../partials/PlanToSubscribe/PlanToSubscribe';
import MaxBoostablePropertiesSelect from '../../../../../partials/MaxBoostablePropertiesSelect';
import { countryHasSuperboostEnabled } from '../../../../../../../selectors/config';
import { MaxSuperboostablePropertiesSelect } from '../RequestSubscriptionChangeModal/MaxSuperboostablePropertiesSelect';
import { billingCycleOptions } from '../../../../../../utils/billingCycleOptions';
import {
  availableTypeOfClientOptionsSelector,
  canEditBillingCycleSelector,
  canEditNextBillingDateSelector,
  canEditStartDateSelector,
  canEditSubscriptionPriceSelector,
  canSubmitFormSelector,
  maxBoostablePropertiesOptionsSelector,
  minNextBillingDateSelector,
  plansByMaxBoostablePropertiesSelector,
  reasonSelector,
  selectedBaseSubscriptionFinalPriceSelector,
  selectedBillingCycleSelector,
  selectedBoostingLimitSelector,
  selectedNextBillingDateSelector,
  selectedPlanIdSelector,
  selectedPublisherCountryCodeSelector,
  selectedPublishesInThailandPropertySelector,
  selectedStartDateSelector,
  selectedSuperboostFinalPriceSelector,
  selectedSuperboostingLimitSelector,
  selectedTypeOfClientSelector,
  showPublishesInThailandPropertyCheckboxSelector,
  showTypeOfClientSelector,
  superboostErrorMessageSelector,
} from './selectors';
import { useFeatureFlagForCountrySelector } from '../../../../../../../utils/featuresFlags/selectors';
import { removeEmojis } from '../../../../../../utils/removeEmojis';

export default function ChangeSubscriptionModal({ onSuccessEvent }) {
  const { open, closeDialog } = useDialog(CHANGE_SUBSCRIPTION_MODAL);
  const plansToSubscribe = useSelector(plansByMaxBoostablePropertiesSelector);
  const maxBoostablePropertiesOptions = useSelector(
    maxBoostablePropertiesOptionsSelector
  );

  const selectedCountryCode = useSelector(selectedPublisherCountryCodeSelector);
  const SUPERBOOST_ALL_COUNTRIES_3119 = useFeatureFlagForCountrySelector(
    'SUPERBOOST_ALL_COUNTRIES_3119',
    selectedCountryCode
  );
  const showPublishesInThailandPropertyCheckbox = useSelector(
    showPublishesInThailandPropertyCheckboxSelector
  );
  const publishesInThailandPropertyValue = useSelector(
    selectedPublishesInThailandPropertySelector
  );
  const planId = useSelector(selectedPlanIdSelector);
  const maxBoostableProperties = useSelector(selectedBoostingLimitSelector);
  const maxSuperboostableProperties = useSelector(
    selectedSuperboostingLimitSelector
  );
  const showTypeOfClient = useSelector(showTypeOfClientSelector);
  const selectedTypeOfClient = useSelector(selectedTypeOfClientSelector);
  const availableTypesOfClient = useSelector(
    availableTypeOfClientOptionsSelector
  );
  const billingCycle = useSelector(selectedBillingCycleSelector);
  const startDate = useSelector(selectedStartDateSelector);
  const nextBillingDate = useSelector(selectedNextBillingDateSelector);
  const baseSubscriptionFinalPrice = useSelector(
    selectedBaseSubscriptionFinalPriceSelector
  );
  const superboostFinalPrice = useSelector(
    selectedSuperboostFinalPriceSelector
  );
  const reason = useSelector(reasonSelector);
  const superboostErrorMessage = useSelector(superboostErrorMessageSelector);
  const canEditBillingCycle = useSelector(canEditBillingCycleSelector);
  const canEditStartDate = useSelector(canEditStartDateSelector);
  const canEditNextBillingDate = useSelector(canEditNextBillingDateSelector);
  const minNextBillingDate = useSelector(minNextBillingDateSelector);
  const canEditSubscriptionPrice = useSelector(
    canEditSubscriptionPriceSelector
  );
  const canSubmitForm = useSelector(canSubmitFormSelector);
  const superboostEnabled =
    SUPERBOOST_ALL_COUNTRIES_3119 ||
    countryHasSuperboostEnabled(selectedCountryCode);
  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        Modify current subscription
      </Dialog.Header>
      <Dialog.Content>
        <form
          id="change-subscription"
          onSubmit={(e) => {
            e.preventDefault();
            dispatch({
              id: SUBSCRIPTION_CHANGES_CONFIRMED,
              payload: {
                onSuccessEvent,
              },
            });
          }}
        >
          <FormWrapper>
            <ItemFullWidth>
              {showPublishesInThailandPropertyCheckbox && (
                <PublishesInThailandPropertyCheckbox
                  onChange={(event) =>
                    dispatch({
                      id: CHANGE_SUBSCRIPTION_VALUE_CHANGED,
                      payload: {
                        field: 'publishesInThailandProperty',
                        value: event.target.checked,
                      },
                    })
                  }
                  checked={publishesInThailandPropertyValue}
                />
              )}
            </ItemFullWidth>
            <ItemTwoColumns>
              <MaxBoostablePropertiesSelect
                options={maxBoostablePropertiesOptions}
                selectedOption={maxBoostableProperties}
                onChange={(event) =>
                  dispatch({
                    id: SUBSCRIPTION_CHANGES_MAX_BOOSTABLE_PROPERTIES_SELECTED,
                    payload: {
                      maxBoostableProperties: event.value,
                    },
                  })
                }
                required
              />
            </ItemTwoColumns>
            {showTypeOfClient && (
              <ItemTwoColumns>
                <TypeOfClientSelect
                  value={selectedTypeOfClient}
                  onChange={(event) =>
                    dispatch({
                      id: SUBSCRIPTION_CHANGES_TYPE_OF_CLIENT_SELECTED,
                      payload: {
                        typeOfClient: event.value,
                      },
                    })
                  }
                  availableTypes={availableTypesOfClient}
                />
              </ItemTwoColumns>
            )}
            {!showTypeOfClient && (
              <ItemTwoColumns>
                <PlanToSubscribe
                  label="Boosting level"
                  value={planId}
                  onChange={(event) =>
                    dispatch({
                      id: CHANGE_SUBSCRIPTION_VALUE_CHANGED,
                      payload: {
                        field: 'planId',
                        value: event.value,
                      },
                    })
                  }
                  plansByMaxBoostableProperties={plansToSubscribe}
                  countryCode={selectedCountryCode}
                  required
                />
              </ItemTwoColumns>
            )}
            {superboostEnabled && (
              <ItemTwoColumns>
                <MaxSuperboostablePropertiesSelect
                  selectedMaxSuperboostable={maxSuperboostableProperties}
                  onInputChange={(event) =>
                    dispatch({
                      id: CHANGE_SUBSCRIPTION_VALUE_CHANGED,
                      payload: {
                        field: 'superboostingLimit',
                        value: event.target.rawValue,
                      },
                    })
                  }
                  errorMessage={superboostErrorMessage}
                  hasError={superboostErrorMessage}
                />
              </ItemTwoColumns>
            )}
            <ItemTwoColumns>
              <InputSelect
                label="Billing cycle"
                value={billingCycle}
                choices={billingCycleOptions().map(
                  ([duration, durationLabel]) => ({
                    label: durationLabel,
                    value: duration,
                  })
                )}
                onSelect={(event) =>
                  dispatch({
                    id: CHANGE_SUBSCRIPTION_VALUE_CHANGED,
                    payload: { field: 'billingCycle', value: event.value },
                  })
                }
                disabled={!canEditBillingCycle}
                required
              />
            </ItemTwoColumns>
            <ItemTwoColumns>
              <InputText
                value={startDate}
                label="Start date"
                type="date"
                onChange={(event) =>
                  dispatch({
                    id: CHANGE_SUBSCRIPTION_VALUE_CHANGED,
                    payload: { field: 'startDate', value: event.target.value },
                  })
                }
                disabled={!canEditStartDate}
              />
            </ItemTwoColumns>
            <ItemTwoColumns>
              <InputText
                value={nextBillingDate}
                label="Next billing date"
                type="date"
                onChange={(event) =>
                  dispatch({
                    id: CHANGE_SUBSCRIPTION_VALUE_CHANGED,
                    payload: {
                      field: 'nextBillingDate',
                      value: event.target.value,
                    },
                  })
                }
                min={minNextBillingDate}
                disabled={!canEditNextBillingDate}
              />
            </ItemTwoColumns>
            <ItemTwoColumns>
              <InputNumber
                value={baseSubscriptionFinalPrice}
                label="Base subscription price"
                type={INPUT_NUMBER_TYPE.DECIMAL}
                maxDecimals={12}
                onChange={(event) =>
                  dispatch({
                    id: CHANGE_SUBSCRIPTION_VALUE_CHANGED,
                    payload: {
                      field: 'baseSubscriptionFinalPrice',
                      value: event.target.rawValue,
                    },
                  })
                }
                disabled={!canEditSubscriptionPrice}
              />
            </ItemTwoColumns>
            <ItemTwoColumns>
              <InputNumber
                value={superboostFinalPrice}
                label="Superboost price"
                type={INPUT_NUMBER_TYPE.DECIMAL}
                maxDecimals={12}
                onChange={(event) =>
                  dispatch({
                    id: CHANGE_SUBSCRIPTION_VALUE_CHANGED,
                    payload: {
                      field: 'superboostFinalPrice',
                      value: event.target.rawValue,
                    },
                  })
                }
                disabled={!canEditSubscriptionPrice}
              />
            </ItemTwoColumns>
            <ItemFullWidth>
              <InputText
                label="Reasoning"
                required
                placeholder="Type the reason why..."
                value={reason}
                onChange={(event) =>
                  dispatch({
                    id: CHANGE_SUBSCRIPTION_VALUE_CHANGED,
                    payload: {
                      field: 'reason',
                      value: removeEmojis(event.target.value),
                    },
                  })
                }
              />
            </ItemFullWidth>
          </FormWrapper>
        </form>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog} $size={BUTTON_SIZE.SMALL}>
          Cancel
        </FlatButton>
        <SecondaryButton
          type="submit"
          form="change-subscription"
          $size={BUTTON_SIZE.SMALL}
          disabled={!canSubmitForm}
        >
          Change subscription
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
