import styled from 'styled-components';

import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import CountryFilter from '../../../partials/Filters/CountryFilter';
import BillingCycleFilter from './Filters/BillingCycleFilter';
import PaymentMethodFilter from './Filters/PaymentMethodFilter';
import SearchFilter from './Filters/SearchFilter';
import RenewalFilter from './Filters/RenewalFilter';
import StatCards from './StatCards/StatCards';
import ClearFiltersButton from '../../../../../partials/DataTable/components/filters/ClearFiltersButton';
import StatusFilter from './Filters/StatusFilter';
import FreeFilter from './Filters/FreeFilter';
import SalesPersonFilter from './Filters/SalesPersonFilter';
import { FILTERS } from '../../constants';
import PremiumFilter from './Filters/PremiumFilter';

const Wrapper = styled.div`
  > div {
    display: flex;
    align-items: center;
    width: 100%;

    > div {
      ${spacing.inline(SPACING_SIZE.S)}
    }

    ${spacing.stack(SPACING_SIZE.M, false)}
  }

  > div:last-of-type {
    ${spacing.stack(SPACING_SIZE.L, false)}
  }
`;

export function TableHeader() {
  return (
    <Wrapper>
      <div>
        <SearchFilter />
        <CountryFilter source={FILTERS.COUNTRY} />
        <SalesPersonFilter />
        <BillingCycleFilter />
        <PremiumFilter />
        <PaymentMethodFilter />
        <RenewalFilter />
        <StatusFilter />
        <FreeFilter />
        <ClearFiltersButton text="Clear filters" />
      </div>
      <StatCards />
    </Wrapper>
  );
}
