import styled from 'styled-components';
import Illustration from 'design-system/components/Illustration/Illustration';
import Image from 'design-system/components/Image/Image';
import { radius } from 'design-system/styles/radius';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const Wrapper = styled.div`
  width: 32px;
  height: 32px;
  position: relative;

  ${Image} {
    ${radius.pill()};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  ${spacing.inset(SPACING_SIZE.XS)}
  ${radius.pill()};
  ${color.background(COLOR_PALETTE.PRIMARY_BASE)};
`;

function Avatar({ className, style, hasPremiumSubscription, ...rest }) {
  return (
    <Wrapper className={className} style={style}>
      {hasPremiumSubscription && (
        <IconWrapper>
          <Icon
            glyph="premiumCrown"
            $size={ICON_SIZE.XSMALL}
            $color={COLOR_PALETTE.NEUTRAL_A00}
          />
        </IconWrapper>
      )}
      <Image placeholder={<Illustration name="avatar" />} {...rest} />
    </Wrapper>
  );
}

export default styled(Avatar)``;
