import Layout from 'design-system/components/Layout';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useEffect } from 'react';

import SkeletonBone from 'design-system/components/SkeletonBone';
import { SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { LEAD_ACTIVITY_TIMELINE_REQUEST, LEAD_STATUS_UPDATED } from './events';
import Timeline from './Timeline';
import { TimelineEvent } from './TimelineEvent';
import ConfirmDeleteNoteDialog from './dialogs/DeleteNoteDialog';
import EditNoteDialog from './dialogs/EditNoteDialog';
import LeadStatusDropdown from './LeadStatusDropdown';
import Actions from './Actions/Actions';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

import {
  isLoadingSelector,
  leadIsHotSelector,
  leadIsSuperHotSelector,
  leadSelector,
} from '../../selectors';
import SendReplyEmailErrorDialog from './dialogs/SendReplyEmailErrorDialog';
import { isLoadingLeadTimeline, leadTimelineSelector } from './selectors';
import BaseBubble from './bubbles/BaseBubble';
import { HotBubble, SuperHotBubble } from './bubbles/HotnessBubble';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled(Layout.Padded)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: ${SPACING_SIZE.M}px;

  ${Subtitle3} {
    text-transform: uppercase;
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
`;

const handleStatusChange = (payload) => {
  dispatch({ id: LEAD_STATUS_UPDATED, payload });
};

function ActivityTimeline({ leadId }) {
  useEffect(
    () =>
      dispatch({
        id: LEAD_ACTIVITY_TIMELINE_REQUEST,
        payload: { leadId },
      }),
    [leadId]
  );

  const lead = useSelector(leadSelector);
  const isLoading = useSelector(isLoadingSelector);
  const isLoadingActivity = useSelector(isLoadingLeadTimeline);
  const enquiryTimeline = useSelector(leadTimelineSelector);
  const leadIsSuperHot = useSelector(leadIsSuperHotSelector);
  const leadIsHot = useSelector(leadIsHotSelector);

  let hotnessBubble = leadIsSuperHot ? <SuperHotBubble /> : null;
  hotnessBubble = leadIsHot ? <HotBubble /> : hotnessBubble;

  hotnessBubble = null; // Disabling hotness bubble for now because Lead Qualification is exclusive for Premium users

  if (isLoading || isLoadingActivity) {
    return (
      <Wrapper>
        <Header>
          <SkeletonBone $width="120px" $height="12px" />
          <LeadStatusDropdown.Skeleton />
        </Header>
        <Timeline>
          <BaseBubble.Skeleton direction="inbound" />
          <BaseBubble.Skeleton direction="outbound" />
        </Timeline>
        <Actions.Skeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header>
        <Subtitle3>
          <FormattedMessageWithValidation id="enquiry_detail_activity_title" />
        </Subtitle3>
        <LeadStatusDropdown lead={lead} onStatusChanged={handleStatusChange} />
      </Header>
      <Timeline>
        {enquiryTimeline.map((timelineEventProps) => (
          <TimelineEvent
            key={timelineEventProps.id}
            isEnquiryActivity={
              timelineEventProps.ad || timelineEventProps.project
            }
            leadId={leadId}
            hotnessBubble={
              timelineEventProps.isLastEnquiry ? hotnessBubble : null
            }
            {...timelineEventProps}
          />
        ))}
      </Timeline>
      <Actions />
      <ConfirmDeleteNoteDialog />
      <EditNoteDialog />
      <SendReplyEmailErrorDialog />
    </Wrapper>
  );
}

export default ActivityTimeline;
