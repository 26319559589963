import styled from 'styled-components';
import Anchor from 'design-system/components/Anchor';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import Tag, { TAG_SIZE, TAG_VARIANT } from 'design-system/components/Tag';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import PropertySnippet from './PropertySnippet';
import ProjectSnippet from './ProjectSnippet';
import { Cloud } from './BaseBubble';
import InboundBubble from './InboundBubble';

const PersonalShopperBubble = styled(InboundBubble)`
  ${Cloud} {
    ${color.background(COLOR_PALETTE.EGGPLANT_A10)}
    ${Subtitle3} {
      ${color.text(COLOR_PALETTE.EGGPLANT_BASE)}
    }
  }
`;

const PersonalShopperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.XS)};
  align-items: flex-start;
`;

const Wrapper = styled.div`
  > ${Anchor}, > ${Body1} {
    display: inline-block;
    ${spacing.stack(SPACING_SIZE.XS)}
  }
`;

const TagPremium = styled(Tag)`
  text-transform: uppercase;
`;

function PersonalShopperLead({
  linkToConversation,
  message,
  ad,
  project,
  ...rest
}) {
  return (
    <PersonalShopperWrapper>
      <PersonalShopperBubble
        title={
          <FormattedMessageWithValidation id="enquiry_detail_activity_personal_shopper" />
        }
        {...rest}
      >
        <Wrapper>
          <Anchor href={linkToConversation} target="_blank">
            <FormattedMessageWithValidation id="enquiry_detail_activity_open_whatsapp" />
          </Anchor>
          {ad ? (
            <PropertySnippet property={ad} project={project} />
          ) : (
            <ProjectSnippet project={project} />
          )}
        </Wrapper>
      </PersonalShopperBubble>
      <TagPremium size={TAG_SIZE.SMALL} variant={TAG_VARIANT.PRIMARY}>
        <FormattedMessageWithValidation id="premium" />
      </TagPremium>
    </PersonalShopperWrapper>
  );
}

export default PersonalShopperLead;
