import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Tag, { TAG_SIZE, TAG_VARIANT } from 'design-system/components/Tag';
import { OutlinePrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { dispatch } from 'reffects';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import Typography from 'design-system/components/Typography/Typography';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { OPEN_LINK_IN_A_NEW_TAB } from '../App/events';
import FormattedMessageWithValidation from './FormattedMessageWithValidation/FormattedMessageWithValidation';

const Benefit = styled(({ id, ...props }) => (
  <li {...props}>
    <Typography>
      <FormattedMessageWithValidation id={id} />
    </Typography>
  </li>
))``;

const BenefitList = styled.ul`
  margin: 0;

  ${Benefit} {
    margin: 0;
    ${spacing.stack(SPACING_SIZE.XS)}
    ${typography.size(11)};
    ${typography.lineHeight(16)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: ${spacing.value(SPACING_SIZE.S)};
  width: 100%;

  ul {
    margin: 0;
    padding-left: ${spacing.value(SPACING_SIZE.M)};
  }
`;

const Label = styled(Typography)`
  text-wrap: wrap;
  ${typography.size(12)};
  ${typography.weight(FONT_WEIGHT.BOLD)};
  ${typography.lineHeight(14)};
`;

const Info = styled(Typography)`
  text-wrap: wrap;
  ${typography.size(11)};
  ${typography.weight(FONT_WEIGHT.NORMAL)};
  ${typography.lineHeight(16)};
`;

const Button = styled(OutlinePrimaryButton)`
  ${border.inner({
    size: BORDER_SIZE.NONE,
    color: COLOR_PALETTE.NONE,
  })}
`;

export default function UnlockPremiumTooltipContent({ labelTag }) {
  return (
    <Wrapper>
      <Tag variant={TAG_VARIANT.PRIMARY} size={TAG_SIZE.SMALL}>
        <FormattedMessageWithValidation id="premium" />
      </Tag>
      <Label>
        <FormattedMessageWithValidation id={labelTag} />
      </Label>
      <Info>
        <FormattedMessageWithValidation id="unlock_premium_tooltip_info" />
      </Info>
      <BenefitList>
        <Benefit id="unlock_premium_tooltip_info_ai_images_sorting" />
        <Benefit id="unlock_premium_tooltip_info_qualification" />
        <Benefit id="unlock_premium_tooltip_info_market_price" />
        <Benefit id="unlock_premium_tooltip_info_personal_shopper" />
        <Benefit id="unlock_premium_tooltip_info_call_duration" />
        <Benefit id="unlock_premium_tooltip_info_portals_premium_badge" />
      </BenefitList>
      <Button
        $size={BUTTON_SIZE.SMALL}
        onClick={() =>
          dispatch({
            id: OPEN_LINK_IN_A_NEW_TAB,
            payload: {
              url: 'https://info.proppit.com/proppit-premium?source=proppit',
              target: 'blank',
            },
          })
        }
      >
        <FormattedMessageWithValidation id="unlock_premium_more_info" />
      </Button>
    </Wrapper>
  );
}
