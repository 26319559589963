import WhatsappLeadBubble from './bubbles/WhatsappLeadBubble';
import PhoneLeadBubble from './bubbles/PhoneLeadBubble';
import CallLeadBubble from './bubbles/CallLeadBubble';
import EmailLeadBubble from './bubbles/EmailLeadBubble';
import NoteBubble from './bubbles/NoteBubble';
import StateChangeBubble from './bubbles/StateChangeBubble';
import GenericLeadBubble from './bubbles/GenericLeadBubble';
import EmailSubscriptionStatusChangeBubble from './bubbles/EmailSubscriptionStatusChangeBubble';
import PersonalShopperLeadBubble from './bubbles/PersonalShopperLeadBubble';

const LEAD_TYPE_TO_BUBBLE_COMPONENT_MAP = {
  whatsapp: WhatsappLeadBubble,
  phone: PhoneLeadBubble,
  call: CallLeadBubble,
  email: EmailLeadBubble,
  note: NoteBubble,
  'state-change': StateChangeBubble,
  'email-subscription-status-change': EmailSubscriptionStatusChangeBubble,
  line: GenericLeadBubble,
  viber: GenericLeadBubble,
  wechat: GenericLeadBubble,
  skype: GenericLeadBubble,
  messenger: GenericLeadBubble,
  chat: GenericLeadBubble,
  other: GenericLeadBubble,
  personalShopper: PersonalShopperLeadBubble,
};

export function TimelineEvent(props) {
  const { type } = props;
  const BubbleComponent = LEAD_TYPE_TO_BUBBLE_COMPONENT_MAP[type] ?? null;

  if (BubbleComponent === null) {
    return null;
  }

  return <BubbleComponent {...props} />;
}
