import { useSelector } from 'reffects-store';
import {
  defaultAreaUnitSelector,
  defaultCommunityFeesSelector,
  defaultContactDetailsSelector,
  defaultPriceCurrencySelector,
} from './selectors';
import { propertySupportedLanguagesSelector } from '../../selectors/config';
import { initialProjectIdSelector } from './UnitForm/selectors';
import { extractCountryAndNumber } from '../../partials/fields/ExtractCountryAndNumber';

export function useDefaultValues(values = {}) {
  const defaultAreaUnit = useSelector(defaultAreaUnitSelector);
  const defaultPriceCurrency = useSelector(defaultPriceCurrencySelector);
  const defaultContactDetails = useSelector(defaultContactDetailsSelector);
  const defaultCommunityFees = useSelector(defaultCommunityFeesSelector);
  const propertySupportedLanguages = useSelector(
    propertySupportedLanguagesSelector
  );
  const initialProjectId = useSelector(initialProjectIdSelector);

  return {
    propertyDescriptionLanguages: propertySupportedLanguages,
    communityFeesCurrency: defaultCommunityFees.code,
    usableAreaUnit: defaultAreaUnit.name,
    plotAreaUnit: defaultAreaUnit.name,
    floorAreaUnit: defaultAreaUnit.name,
    sellPriceCurrency: defaultPriceCurrency.code,
    rentPriceCurrency: defaultPriceCurrency.code,
    contactEmails: defaultContactDetails?.contactEmails,
    contactFacebookMessenger: defaultContactDetails?.contactFacebookMessenger,
    contactLine: defaultContactDetails?.contactLine,
    contactViber: defaultContactDetails?.contactViber,
    projectId: initialProjectId,
    ...values,
    contactPhone: extractCountryAndNumber(
      values?.contactPhone ?? defaultContactDetails?.contactPhone
    ),
    contactWhatsApp: extractCountryAndNumber(
      values?.contactWhatsApp ?? defaultContactDetails?.contactWhatsApp
    ),
  };
}
