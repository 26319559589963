import Tag, { TAG_SIZE, TAG_VARIANT } from 'design-system/components/Tag';
import Icon from 'design-system/components/Icon';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import TooltipModal from 'design-system/components/TooltipModal/TooltipModal';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import UnlockPremiumTooltipContent from '../../../../../../partials/UnlockPremiumTooltipContent';

const PremiumWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

export default function PremiumTitle({
  shouldTeasePremium,
  isPremiumSubscription,
  placement,
  tooltipFeatureTag,
  children,
}) {
  if (isPremiumSubscription) {
    return (
      <PremiumWrapper>
        {children}
        <Tag variant={TAG_VARIANT.PRIMARY} size={TAG_SIZE.SMALL}>
          <FormattedMessageWithValidation id="premium" />
        </Tag>
      </PremiumWrapper>
    );
  }

  if (shouldTeasePremium) {
    return (
      <PremiumWrapper>
        {children}
        <TooltipModal
          popoverComponent={
            <UnlockPremiumTooltipContent labelTag={tooltipFeatureTag} />
          }
          $placement={placement}
        >
          <Tag
            icon={<Icon glyph="lock" />}
            variant={TAG_VARIANT.PRIMARY}
            size={TAG_SIZE.SMALL}
          >
            <FormattedMessageWithValidation id="premium" />
          </Tag>
        </TooltipModal>
      </PremiumWrapper>
    );
  }

  return <>{children}</>;
}
