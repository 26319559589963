import styled from 'styled-components';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { useCallback, useState } from 'react';
import { dispatch } from 'reffects';
import InputTextarea from 'design-system/components/InputTextarea';
import { SecondaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { useSelector } from 'reffects-store';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { TRACK } from '../Tracking/events';
import { SUBMIT_PREMIUM_FEEDBACK } from './events';
import { feedbackWasSubmittedSelector } from './selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${spacing.inset(SPACING_SIZE.S)};
  ${radius.regular(RADIUS_SIZE.LARGE)};
  ${border.outer({
    size: BORDER_SIZE.BASE,
    color: COLOR_PALETTE.NEUTRAL_A10,
  })};
`;

const ThumbsFeedback = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${Subtitle3} {
    ${typography.weight(FONT_WEIGHT.NORMAL)}
  }
`;

const Thumbs = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

const ThumbsIcon = styled(Icon).attrs({
  $size: ICON_SIZE.XSMALL,
  $color: COLOR_PALETTE.NEUTRAL_A40,
})`
  ${({ $clickable }) => $clickable && 'cursor: pointer;'}
`;

const Answer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${spacing.inset(SPACING_SIZE.S)};
  gap: ${spacing.value(SPACING_SIZE.S)};
  ${SecondaryButton} {
    align-self: flex-end;
  }
`;

const AnswerLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.XS)};
  ${Subtitle3}:last-child {
    ${typography.weight(FONT_WEIGHT.NORMAL)};
    ${color.text(COLOR_PALETTE.NEUTRAL_A40)};
  }
`;

export default function PremiumFeedback({ label, section }) {
  const [isPositiveFeedback, setIsPositiveFeedback] = useState(null);
  const [answer, setAnswer] = useState('');
  const hasSubmittedFeedback = useSelector((state) =>
    feedbackWasSubmittedSelector(state, section)
  );
  const isNegativeFeedback = isPositiveFeedback === false;
  const hasGivenThumbsFeedback = isPositiveFeedback !== null;
  const onThumbFeedbackGiven = useCallback(
    (isPositive) => {
      if (hasGivenThumbsFeedback) {
        return;
      }
      setIsPositiveFeedback(isPositive);
      dispatch({
        id: TRACK,
        payload: {
          event: 'ThumbsFeedbackForPremiumGiven',
          section,
          payload: { isPositive },
        },
      });
    },
    [hasGivenThumbsFeedback]
  );
  if (hasSubmittedFeedback) {
    return null;
  }
  return (
    <Wrapper>
      <ThumbsFeedback>
        <Subtitle3>
          <FormattedMessageWithValidation id={label} />
        </Subtitle3>
        <Thumbs>
          <ThumbsIcon
            $clickable={!hasGivenThumbsFeedback}
            glyph={isNegativeFeedback ? 'thumbDownFilled' : 'thumbDown'}
            onClick={() => onThumbFeedbackGiven(false)}
          />
          <ThumbsIcon
            $clickable={!hasGivenThumbsFeedback}
            glyph={isPositiveFeedback ? 'thumbUpFilled' : 'thumbUp'}
            onClick={() => onThumbFeedbackGiven(true)}
          />
        </Thumbs>
      </ThumbsFeedback>
      {hasGivenThumbsFeedback && (
        <Answer>
          <AnswerLabel>
            <Subtitle3>
              <FormattedMessageWithValidation id="premium_feedback_question_qualitative_feedback" />
            </Subtitle3>
            <Subtitle3>
              <FormattedMessageWithValidation id="premium_feedback_question_qualitative_feedback_optional" />
            </Subtitle3>
          </AnswerLabel>
          <InputTextarea
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
          <SecondaryButton
            $size={BUTTON_SIZE.SMALL}
            onClick={() =>
              dispatch({
                id: SUBMIT_PREMIUM_FEEDBACK,
                payload: {
                  isPositive: isPositiveFeedback,
                  section,
                  answer,
                },
              })
            }
          >
            <FormattedMessageWithValidation id="leadpage_interests_poll_question_save_button" />
          </SecondaryButton>
        </Answer>
      )}
    </Wrapper>
  );
}
