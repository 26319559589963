import { userRoleSelector } from './user';

export function isAllowedSelector(actionId) {
  return (state) => {
    const { permissions } = state;
    const role = userRoleSelector(state);

    if (!permissions) {
      return false;
    }

    return permissions[actionId]?.includes(role) || false;
  };
}
