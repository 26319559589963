import { useSelector } from 'reffects-store';
import { isObject } from 'lodash';
import { createSelector } from 'reselect';
import { FEATURES_VALUES } from './constants';

function publisherSelector(state) {
  return state.publisher;
}

const publisherCountrySelector = createSelector(
  publisherSelector,
  (publisher) => publisher?.countryCode
);

export function getFeatureFlagSelector(state, featureName) {
  const featureFlag = state[FEATURES_VALUES]?.[featureName];
  if (isObject(featureFlag)) {
    return featureFlag.enabled || false;
  }

  return featureFlag || false;
}

export function getFeatureFlagForCountrySelector(state, featureName, country) {
  const featureFlag = state[FEATURES_VALUES]?.[featureName];

  return (
    (featureFlag?.enabled && featureFlag?.countries?.includes(country)) || false
  );
}

export function getFeatureFlagForPublisherCountrySelector(state, featureName) {
  return getFeatureFlagForCountrySelector(
    state,
    featureName,
    publisherCountrySelector(state)
  );
}

export function useFeatureFlagSelector(featureName) {
  return useSelector((state) => getFeatureFlagSelector(state, featureName));
}

export function useFeatureFlagForCountrySelector(featureName, country) {
  return useSelector((state) =>
    getFeatureFlagForCountrySelector(state, featureName, country)
  );
}

export function useFeatureFlagForPublisherCountrySelector(featureName) {
  return useSelector((state) =>
    getFeatureFlagForPublisherCountrySelector(state, featureName)
  );
}
