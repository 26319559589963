export function leadsWithLeadPlusSelector(state) {
  return state.leadsWithLeadPlus ?? {};
}

export function leadsWithQualifiedEnquiriesSelector(state) {
  return state.leadsWithQualifiedEnquiries ?? {};
}

export function leadsWithHotnessSelector(state) {
  const extractHotness = ({ isSuperHot, isHot }) => {
    if (isSuperHot) {
      return 'superHot';
    }
    return isHot ? 'hot' : null;
  };
  return mapValuesFromLeadQualifications(state, extractHotness);
}

export function leadsWithQualificationSelector(state) {
  return mapValuesFromLeadQualifications(state, (lead) =>
    Object.values(lead).some(Boolean)
  );
}

function mapValuesFromLeadQualifications(state, f) {
  const leadQualifications = state.leadQualifications ?? {};
  return Object.fromEntries(
    Object.entries(leadQualifications).map(([leadId, lead]) => [
      leadId,
      f(lead),
    ])
  );
}
