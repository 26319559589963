import styled from 'styled-components';
import { FormattedDate } from 'react-intl';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import Icon from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { useSelector } from 'reffects-store';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { TOOLTIP_MODAL_PLACEMENT } from 'design-system/components/TooltipModal/TooltipModal';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import InboundBubble from './InboundBubble';
import PropertySnippet from './PropertySnippet';
import ProjectSnippet from './ProjectSnippet';
import { CALL_STATUS } from '../../../../../constants/enquiry';
import MissedCallLeadBubble from './MissedCallLeadBubble';
import { shouldTeasePremiumSubscriptionSelector } from '../../../../../selectors/premium';
import UnlockPremium from '../../../../../partials/UnlockPremium';

const CallExplanation = styled.span`
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
    ${typography.size(FONT_SIZE.XS)}
    ${spacing.stack(SPACING_SIZE.XS, false)}
`;

function CallLeadBubble({ phone, ad, project, callStatus, duration, ...rest }) {
  if (callStatus === CALL_STATUS.MISSED) {
    return (
      <MissedCallLeadBubble
        phone={phone}
        ad={ad}
        project={project}
        callStatus={callStatus}
        duration={duration}
        {...rest}
      />
    );
  }
  return (
    <InboundBubble
      title={<FormattedMessageWithValidation id="enquiries_call_incoming" />}
      {...rest}
    >
      <IncomingCall callStatus={callStatus} duration={duration} phone={phone} />
      <CallExplanation>
        <FormattedMessageWithValidation id="enquiry_detail_call_activity" />
      </CallExplanation>
      {ad ? (
        <PropertySnippet property={ad} project={project} />
      ) : (
        <ProjectSnippet project={project} />
      )}
    </InboundBubble>
  );
}

const PremiumIncomingCall = styled.div`
  display: inline-flex;
  align-items: center;

  ${spacing.stack(SPACING_SIZE.XS, false)}
  ${Body1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    ${typography.weight(FONT_WEIGHT.LIGHT)}
  }

  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const NonPremiumIncomingCall = styled.div`
  display: inline-flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: ${spacing.value(SPACING_SIZE.XS)};

  ${spacing.stack(SPACING_SIZE.XS, false)}
  ${Body1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
    ${typography.weight(FONT_WEIGHT.LIGHT)}
  }

  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const NonPremiumLabel = styled.div`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.S)};
  align-items: center;

  ${Subtitle3} {
    margin: 0;
  }
`;

function IncomingCall({ callStatus, duration, phone }) {
  const shouldTeasePremium = useSelector(
    shouldTeasePremiumSubscriptionSelector
  );

  if (shouldTeasePremium) {
    return (
      <NonPremiumIncomingCall>
        {callStatus === CALL_STATUS.PICKED_UP && (
          <NonPremiumLabel>
            <Tag variant={TAG_VARIANT.PRIMARY_DARK}>
              <FormattedMessageWithValidation id="premium" />
            </Tag>
            <UnlockPremium
              tooltipTag="unlock_premium_tooltip_call_duration"
              labelTag="unlock_premium_call_duration"
              tooltipPlacement={TOOLTIP_MODAL_PLACEMENT.DOWN}
            />
          </NonPremiumLabel>
        )}
        <Body1>{phone}</Body1>
      </NonPremiumIncomingCall>
    );
  }

  return (
    <PremiumIncomingCall>
      {callStatus === CALL_STATUS.PICKED_UP && (
        <Tag
          variant={TAG_VARIANT.PRIMARY_DARK}
          icon={<Icon glyph="callIncoming" />}
        >
          <FormattedDate
            minute="numeric"
            second="numeric"
            value={new Date(duration * 1000)}
          />
        </Tag>
      )}
      <Body1>{phone}</Body1>
    </PremiumIncomingCall>
  );
}

export default CallLeadBubble;
