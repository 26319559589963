import { useMount, useUnmount } from 'react-use';
import { dispatch, dispatchMany } from 'reffects';
import { useSelector } from 'reffects-store';
import { DEFAULT_CONTACT_REQUESTED } from '../events';

import { CREATE_AD_FORM_MOUNTED, RESET_AD_FORM_VALUES } from './events';

import AdForm from './AdForm';
import { adDataSelector } from '../selectors';

function CreateAdForm() {
  useMount(() =>
    dispatchMany([CREATE_AD_FORM_MOUNTED, DEFAULT_CONTACT_REQUESTED])
  );
  useUnmount(() => dispatch(RESET_AD_FORM_VALUES));

  const values = useSelector(adDataSelector);
  const isAdFormInitialized = useSelector(
    (state) => state['adForm:isInitialized']
  );

  return isAdFormInitialized && <AdForm values={values} />;
}

export default CreateAdForm;
