import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch, dispatchMany } from 'reffects';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import SegmentedControl from 'design-system/components/SegmentedControl';
import DropdownMenu from 'design-system/components/DropdownMenu';
import SkeletonBone from 'design-system/components/SkeletonBone';
import Menu from 'design-system/components/Menu/Menu';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { BUTTON_ICON_POSITION } from 'design-system/components/Button';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { useRef } from 'react';
import Stats from './Stats';
import BarChart from './BarChart';
import Interests from './Interests';
import {
  availableLeadPlusOperationsSelector,
  isLoadingLeadPlusSelector,
  leadPlusLocationChartSelector,
  leadPlusPropertyTypeChartSelector,
  leadPlusSelectedOperationTypeSelector,
  leadPlusSummaryStatsSelector,
} from './selectors';
import {
  CHANGE_SELECTED_OPERATION_TYPE,
  REQUEST_LEAD_PLUS_ACTIVITY,
} from './events';
import {
  ACTIVITY_PERIOD_ALL,
  ACTIVITY_PERIOD_LAST_3_MONTHS,
} from './constants';
import LocationsMap from './LocationsMap';
import Interest from './Interest';
import {
  isPublisherFromMxSelector,
  publisherSubscriptionPlanIsFreeSelector,
} from '../../../../../../selectors/publisher';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { subscriptionRoute } from '../../../../../../utils/proppitWebRouter';
import {
  activityPeriodSelector,
  distinctListingEnquiriesSelector,
  hasLeadQualificationSelector,
  searchingSinceDateSelector,
} from '../../../../selectors';
import { useTrack } from '../../../../../../hooks/useTracking';
import CustomFormattedDate from '../../../../../../partials/FormattedDate/CustomFormattedDate';
import { propertyTypesTranslationTags } from '../../../../../../constants/ad';
import LeadFeedback from '../../../LeadFeedback';
import { LEAD_DETAIL_FEEDBACK_SUBMITTED } from '../../../../events';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  row-gap: ${spacing.value(SPACING_SIZE.XL)};
  ${({ isFree }) => isFree && `row-gap: ${spacing.value(SPACING_SIZE.L)}`};
  ${spacing.insetSandwich(SPACING_SIZE.XL)};

  ${Interests} {
    align-self: flex-start;
    width: 100%;
  }
`;

const UpgradeBanner = styled.div`
  ${spacing.inset(SPACING_SIZE.M)};
  ${radius.regular(RADIUS_SIZE.LARGE)};
  ${color.background(COLOR_PALETTE.PUMPKIN_A20)};
`;

const Padded = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 ${spacing.value(SPACING_SIZE.XL)};
  width: 100%;
`;

const ActivityInfo = styled(Padded)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: ${spacing.value(SPACING_SIZE.S)};
`;

const OPERATION_TYPE_TAG = {
  sell: 'propspage_table_operation_sale',
  rent: 'propspage_table_operation_rent',
};

const ACTIVITY_PERIOD_TAG = {
  [ACTIVITY_PERIOD_LAST_3_MONTHS]: 'lead_detail_activity_period_3_months',
  [ACTIVITY_PERIOD_ALL]: 'lead_detail_activity_period_all',
};

export default function LeadPlus() {
  const isLoading = useSelector(isLoadingLeadPlusSelector);
  const publisherSubscriptionPlanIsFree = useSelector(
    publisherSubscriptionPlanIsFreeSelector
  );

  if (publisherSubscriptionPlanIsFree) {
    return <LeadPlusFree />;
  }

  if (isLoading) {
    return <LeadPlusSkeleton />;
  }

  return <LeadPlusPremium />;
}

function LeadPlusSkeleton() {
  return (
    <Wrapper>
      <ActivityInfo>
        <SkeletonBone $width="120px" $height="12px" />
        <Interest.Skeleton />
        <Interest.Skeleton />
      </ActivityInfo>
    </Wrapper>
  );
}

function LeadPlusFree() {
  const hasViewsInfo = !useSelector(isPublisherFromMxSelector);
  return (
    <Wrapper isFree>
      <Padded>
        <UpgradeBanner>
          <Body1 $weight={FONT_WEIGHT.LIGHT}>
            <FormattedMessageWithValidation
              id="lead_detail_free_client_upgrade"
              values={{
                upgradeNow: (
                  <Link to={subscriptionRoute()}>
                    <FormattedMessageWithValidation id="lead_detail_free_client_upgrade_link_text" />
                  </Link>
                ),
              }}
            />
          </Body1>
        </UpgradeBanner>
      </Padded>
      <Padded>
        <Stats blurred hasViewsInfo={hasViewsInfo} />
      </Padded>
      <Padded>
        <BarChart
          title={
            <FormattedMessageWithValidation id="lead_detail_activity_chart_property_types" />
          }
          blurred
        />
      </Padded>
      <Interests blurred />
    </Wrapper>
  );
}

function LeadPlusPremium() {
  const intl = useIntl();
  const currentTab = useSelector(leadPlusSelectedOperationTypeSelector);
  const operations = useSelector(availableLeadPlusOperationsSelector);
  const searchingSinceDate = useSelector(searchingSinceDateSelector);
  const activityPeriod = useSelector(activityPeriodSelector);
  const stats = useSelector(leadPlusSummaryStatsSelector);
  const propertyTypes = useSelector(leadPlusPropertyTypeChartSelector);
  const locations = useSelector(leadPlusLocationChartSelector);
  const hasViewsInfo = !useSelector(isPublisherFromMxSelector);
  const listingEnquiriesCount = useSelector(distinctListingEnquiriesSelector);
  const hasLeadQualification = useSelector(hasLeadQualificationSelector);
  const refToUse = useRef(null);

  useTrack(refToUse, {
    track: {
      event: 'LeadPlusViewed',
      section: 'leads-plus-section',
      payload: {
        hasLeadQualification,
      },
    },
  });

  return (
    <>
      <Wrapper>
        <ActivityInfo>
          <Body1 $weight={FONT_WEIGHT.LIGHT} ref={refToUse}>
            <FormattedMessageWithValidation
              id="lead_detail_activity_started"
              values={{
                date: <CustomFormattedDate value={searchingSinceDate} />,
              }}
            />
          </Body1>
          <DropdownMenu
            onConfirm={({ id }) =>
              dispatchMany([
                {
                  id: REQUEST_LEAD_PLUS_ACTIVITY,
                  payload: { period: id },
                },
                {
                  id: LEAD_DETAIL_FEEDBACK_SUBMITTED,
                  payload: {
                    sectionName: 'filter-lead-plus-activity',
                    payload: {
                      hasLeadQualification,
                      activityPeriod: id,
                    },
                  },
                },
              ])
            }
            button={
              <FlatButton
                icon={<Icon glyph="chevronDown" />}
                $iconPosition={BUTTON_ICON_POSITION.END}
              >
                <FormattedMessageWithValidation
                  id={ACTIVITY_PERIOD_TAG[activityPeriod]}
                />
              </FlatButton>
            }
          >
            <Menu>
              <Menu.Item
                label={
                  <Body1 $weight={FONT_WEIGHT.LIGHT}>
                    <FormattedMessageWithValidation
                      id={ACTIVITY_PERIOD_TAG[ACTIVITY_PERIOD_LAST_3_MONTHS]}
                    />
                  </Body1>
                }
                id={ACTIVITY_PERIOD_LAST_3_MONTHS}
              />
              <Menu.Item
                label={
                  <Body1 $weight={FONT_WEIGHT.LIGHT}>
                    <FormattedMessageWithValidation
                      id={ACTIVITY_PERIOD_TAG[ACTIVITY_PERIOD_ALL]}
                    />
                  </Body1>
                }
                id={ACTIVITY_PERIOD_ALL}
              />
            </Menu>
          </DropdownMenu>
        </ActivityInfo>
        <Padded>
          <SegmentedControl
            selectedValue={currentTab}
            defaultValue={currentTab}
            onChange={({ value }) =>
              dispatch({
                id: CHANGE_SELECTED_OPERATION_TYPE,
                payload: { operationType: value },
              })
            }
          >
            {operations.map((o) => (
              <SegmentedControl.Item key={o} value={o}>
                <FormattedMessageWithValidation id={OPERATION_TYPE_TAG[o]} />
              </SegmentedControl.Item>
            ))}
          </SegmentedControl>
        </Padded>
        <Padded>
          <Stats
            hasViewsInfo={hasViewsInfo}
            views={stats.views}
            enquiries={stats.enquiries}
          />
        </Padded>
        <Padded>
          <BarChart
            title={
              <FormattedMessageWithValidation id="lead_detail_activity_chart_property_types" />
            }
            elements={propertyTypes.map((p) => ({
              ...p,
              name: intl.formatMessage({
                id: propertyTypesTranslationTags.get(p.name),
              }),
            }))}
          />
        </Padded>
        <Padded>
          <BarChart
            title={
              <FormattedMessageWithValidation id="lead_detail_activity_chart_locations" />
            }
            elements={locations}
          />
        </Padded>
        <Interests />
        <LocationsMap />
      </Wrapper>
      {!hasLeadQualification && (
        <LeadFeedback eventExtraInformation={{ listingEnquiriesCount }} />
      )}
    </>
  );
}
