export default function ArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
    >
      <path
        d="M0.000976562 6L6.00098 12L6.00098 0L0.000976562 6Z"
        fill="#08A36B"
      />
    </svg>
  );
}
