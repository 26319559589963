import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useWindowSize } from 'react-use';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography from 'design-system/components/Typography/Typography';
import { FONT_FAMILY, FONT_WEIGHT } from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { bindClassname } from '../utils';
import Portal from './Portal';
import getAbsolutePosition from '../utils/getAbsolutePosition';

export const TOOLTIP_PLACEMENT = {
  TOP: Symbol('TOOLTIP_PLACEMENT_TOP'),
  RIGHT: Symbol('TOOLTIP_PLACEMENT_RIGHT'),
};

const modPlacementTop = () => css`
  top: ${({ $wrapperPosition: { top }, $tipSize: { height: tipHeight } }) =>
    top - tipHeight - SPACING_SIZE.XS}px;
  left: ${({ $wrapperPosition, $tipSize }) =>
    verticalPlacementLeftPosition($wrapperPosition, $tipSize)}px;
`;

const modPlacementRight = () => css`
  top: ${({ $wrapperPosition, $tipSize }) =>
    horizontalPlacementTopPosition($wrapperPosition, $tipSize)}px;
  left: ${({ $wrapperPosition: { right } }) => right + SPACING_SIZE.S}px;
`;

const modPlacement = (placement) =>
  ({
    [TOOLTIP_PLACEMENT.TOP]: modPlacementTop,
    [TOOLTIP_PLACEMENT.RIGHT]: modPlacementRight,
  }[placement]);

const Tip = styled.div`
  visibility: ${({ $shown }) => ($shown ? 'visible' : 'hidden')};
  top: 0;
  left: 0;
  position: absolute;
  z-index: ${({ $zIndex = 3 }) => $zIndex};
  ${({ $wrapped }) =>
    $wrapped &&
    css`
      max-width: 350px;

      > span {
        text-wrap: auto;
      }
    `}

  ${spacing.insetSquish(SPACING_SIZE.S)}
    ${Typography.mods.noWrap()}
    ${Typography.mods.font(FONT_FAMILY.PRIMARY)}
    ${Typography.mods.weight(FONT_WEIGHT.MEDIUM)}
    ${Typography.mods.size(11)}
    ${color.background(COLOR_PALETTE.NEUTRAL_A80)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
    ${radius.regular(RADIUS_SIZE.BASE)}

    ${({ $placement }) => modPlacement($placement)}
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

function Tooltip({
  children,
  title,
  $wrapped,
  $placement = TOOLTIP_PLACEMENT.TOP,
  $zIndex,
  ...rest
}) {
  const [hovered, setHovered] = useState(false);
  const { width, height } = useWindowSize();
  const tipRef = useRef();
  const wrapperRef = useRef();
  const tipSize = useTipSize(tipRef);
  const wrapperPosition = useWrapperPosition(wrapperRef, hovered);

  function enforceElementInView(element) {
    const rect = element.getBoundingClientRect();
    let offsetX = 0;
    let offsetY = 0;

    if (rect.left < 0) {
      offsetX = -rect.left;
    } else if (rect.right > window.innerWidth) {
      offsetX = window.innerWidth - rect.right;
    }

    if (rect.top < 0) {
      offsetY = -rect.top;
    } else if (rect.bottom > window.innerHeight) {
      offsetY = window.innerHeight - rect.bottom;
    }

    if (offsetX !== 0 || offsetY !== 0) {
      element.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
    }
  }

  useEffect(() => {
    if (tipRef.current) {
      enforceElementInView(tipRef.current);
    }
  }, [tipRef]);

  useEffect(() => {
    if (hovered) {
      setHovered(false);
    }
  }, [width, height]);

  if (title == null) {
    return children;
  }

  return (
    <Wrapper
      ref={wrapperRef}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      {...rest}
    >
      {children}
      <Portal>
        <Tip
          $zIndex={$zIndex}
          $placement={$placement}
          $wrapped
          $shown={hovered && wrapperPosition != null}
          $wrapperPosition={wrapperPosition ?? {}}
          $tipSize={tipSize}
          ref={tipRef}
        >
          {title}
        </Tip>
      </Portal>
    </Wrapper>
  );
}

Tooltip.mods = {
  placement: modPlacement,
};

export default bindClassname(Tooltip, Wrapper);

function useTipSize(tipRef) {
  const [tipSize, setTipSize] = useState({});
  useEffect(() => {
    if (tipRef.current) {
      setTipSize({
        width: tipRef.current.offsetWidth,
        height: tipRef.current.offsetHeight,
      });
    }
  }, [tipRef]);
  return tipSize;
}

function useWrapperPosition(wrapperRef, hovered) {
  const [position, setPosition] = useState();

  useEffect(() => {
    if (wrapperRef.current && hovered) {
      setPosition(getAbsolutePosition(wrapperRef.current));
    }
  }, [hovered]);

  return position;
}

function horizontalPlacementTopPosition(
  { top, height },
  { height: tipHeight }
) {
  return top + height / 2 - tipHeight / 2;
}

function verticalPlacementLeftPosition({ left, width }, { width: tipWidth }) {
  return left + width / 2 - tipWidth / 2;
}
