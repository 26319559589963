import { DropdownFilter } from '../../../../../../partials/DataTable';
import { NO_FILTER_OPTION } from '../../../../partials/Filters/constants';

export default function PremiumFilter() {
  return (
    <DropdownFilter
      label="Premium"
      source="hasPremiumSubscription"
      options={[
        { id: NO_FILTER_OPTION, value: null, label: 'All' },
        { id: true, value: true, label: 'Premium' },
        { id: false, value: false, label: 'Non premium' },
      ]}
    />
  );
}
