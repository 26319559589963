import { MEXICO_TYPE_OF_CLIENT } from '../../../../CreatePublisher/constants';

export function convertPublisherToSelectedOptions({
  subscription,
  superboost,
  isThailandPropertyClient,
}) {
  return {
    billingCycle:
      subscription.productId === 'free' ? 1 : subscription.billingCycleInMonths,
    boostingLimit:
      subscription.productId === 'free'
        ? null
        : subscription.maxBoostableProperties,
    superboostingLimit:
      subscription.productId === 'free'
        ? null
        : superboost?.maxBoostableProperties ?? null,
    publishesInThailandProperty: isThailandPropertyClient,
    planId: subscription.productId === 'free' ? '' : subscription.productId,
    typeOfClient: convertedTypeOfClient(subscription),
  };
}

function convertedTypeOfClient({ isPremium, typeOfClient }) {
  if (!isPremium) {
    return typeOfClient;
  }
  if (typeOfClient === MEXICO_TYPE_OF_CLIENT.AGENCIA) {
    return MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM;
  }
  return MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM;
}
