import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import styled from 'styled-components';
import { useCallback } from 'react';
import Table, { ROWS_PER_PAGE } from 'design-system/components/Table';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Tooltip from 'design-system/components/Tooltip';
import Menu from 'design-system/components/Menu/Menu';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Layout from '../partials/Layout/Layout';
import AssignProjectModal from './AssignProject/AssignProjectDialog';
import { ASSIGN_PROJECT_TO_DEVELOPER_DIALOG } from './AssignProject/events';
import { COLLECTION_NAME } from './constants';
import ImpersonateButton from '../partials/ImpersonateButton/ImpersonateButton';
import { OPEN_DIALOG } from '../../../events/dialogs';
import CopyTextButton from '../partials/CopyTextButton/CopyTextButton';
import { createDevelopersTableAdapter } from './developersTableAdapter';
import {
  DataTable,
  DataTableProvider,
  TextColumn,
} from '../../../partials/DataTable';
import SearchFilter from '../partials/Filters/TextFilter';
import CountryFilter from '../partials/Filters/CountryFilter';
import { useColumn } from '../../../partials/DataTable/hooks/useColumn';
import { publisherCountrySelector } from '../../../selectors/publisher';
import { ProtectedComponent } from '../../ProtectedComponent';

const Content = styled.div`
  width: 80%;
  margin: 2rem auto;

  ${Body1} {
    width: 100%;
  }
`;

const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  ${SearchFilter} {
    min-width: 30%;
  }
`;

const DeveloperName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:hover {
    ${CopyTextButton} {
      visibility: visible;
    }
  }

  ${CopyTextButton} {
    visibility: hidden;
  }
`;

function ActionsColumn(props) {
  const { value } = useColumn(props);
  const onAssignProject = useCallback(
    () =>
      dispatch({
        id: OPEN_DIALOG,
        payload: {
          id: ASSIGN_PROJECT_TO_DEVELOPER_DIALOG,
          parameters: { developer: value },
        },
      }),
    [value]
  );

  return (
    <Table.Cell>
      <ImpersonateButton publisherId={value.id} />
      <ProtectedComponent action="developer/do-developer-actions">
        <DropdownMenu
          tooltip={<Tooltip title="Actions" />}
          button={<FlatButton icon={<Icon glyph="moreVert" />} />}
        >
          <Menu>
            <Menu.Item
              label="Assign project"
              onClick={withoutEventPropagation(onAssignProject)}
            />
          </Menu>
        </DropdownMenu>
      </ProtectedComponent>
    </Table.Cell>
  );
}

export default function Developers() {
  const defaultCountry = useSelector(publisherCountrySelector);
  if (defaultCountry == null) {
    return null;
  }
  const context = {
    collectionName: COLLECTION_NAME,
    defaultSorting: {},
    defaultFilters: {
      country: defaultCountry,
    },
    initialItemsPerPage: ROWS_PER_PAGE.TWENTY_FIVE,
    adapter: createDevelopersTableAdapter(),
  };
  return (
    <Layout>
      <Content>
        <DataTableProvider {...context}>
          <Filters>
            <SearchFilter
              name="developer"
              placeholder="Search by developer name or uuid"
            />
            <CountryFilter source="country" />
          </Filters>
          <DataTable>
            <TextColumn
              id="developer"
              renderField={({ id, name }) => (
                <DeveloperName>
                  {name}
                  <CopyTextButton title="Copy publisher id" text={id} />
                </DeveloperName>
              )}
              title="Developer"
            />
            <TextColumn
              id="paymentMethod"
              source="paymentMethod"
              title="Payment method"
            />
            <ActionsColumn
              id="actions"
              source={{ id: 'id', name: 'name', countryCode: 'countryCode' }}
              title="Actions"
            />
          </DataTable>
          <AssignProjectModal />
        </DataTableProvider>
      </Content>
    </Layout>
  );
}
