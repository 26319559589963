import { forwardRef, useEffect, useState } from 'react';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { OutlineButton } from 'design-system/components/Button/presets';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Tag, { TAG_SIZE, TAG_VARIANT } from 'design-system/components/Tag';
import { useSelector } from 'reffects-store';
import Tooltip, { TOOLTIP_PLACEMENT } from 'design-system/components/Tooltip';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { propertyImagesSortedWithAiSelector } from '../../../../selectors';

const Image = styled.div`
  ${OutlineButton} {
    position: absolute;
    top: ${spacing.value(SPACING_SIZE.S)};
    right: ${spacing.value(SPACING_SIZE.S)};
    display: none;
  }

  &:hover ${OutlineButton} {
    display: block;
    transform: unset;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  width: calc(100% - (${spacing.value(SPACING_SIZE.S)} * 2));

  justify-content: ${({ isMain, sortedWithAi }) =>
    !isMain && sortedWithAi ? 'center' : 'space-between'};

  position: absolute;
  bottom: ${spacing.value(SPACING_SIZE.S)};
  left: ${spacing.value(SPACING_SIZE.S)};
`;

const WrapTitle = styled.div`
  white-space: normal;
  max-width: 250px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const ImagePreview = forwardRef(
  (
    {
      id,
      file,
      isMain,
      isBadQuality,
      displayDeleteIcon,
      style,
      onDelete,
      ...props
    },
    ref
  ) => {
    const [src, setSrc] = useState();

    const sortedWithAi = useSelector(propertyImagesSortedWithAiSelector);

    useEffect(() => {
      if (isAFile(file)) {
        setSrc(URL.createObjectURL(file));
      } else {
        setSrc(file);
      }
    }, [file]);

    const inlineStyle = {
      position: 'relative',
      transformOrigin: '0 0',
      gridColumnStart: isMain ? 'span 3' : null,
      gridRowStart: isMain ? 'span 3' : null,
      width: isMain ? '100%' : '5rem',
      height: isMain ? '100%' : '5rem',
      backgroundImage: `url("${src}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: 'grey',
      borderRadius: '8px',
      ...style,
    };

    return (
      <Image ref={ref} style={inlineStyle} {...props}>
        {displayDeleteIcon && (
          <OutlineButton
            icon={<Icon glyph="bin" />}
            onMouseDown={() => onDelete(id)}
            $size={ICON_SIZE.XXSMALL}
          />
        )}
        <TagsWrapper isMain={isMain} sortedWithAi={sortedWithAi}>
          {isMain && (
            <Tag variant={TAG_VARIANT.SECONDARY_DARK} size={TAG_SIZE.SMALL}>
              <FormattedMessageWithValidation id="order_photos_main_picture" />
            </Tag>
          )}
          {sortedWithAi && isBadQuality && (
            <Tooltip
              title={
                <WrapTitle>
                  <FormattedMessageWithValidation id="image_quality_tooltip" />
                </WrapTitle>
              }
              $placement={TOOLTIP_PLACEMENT.RIGHT}
            >
              <Tag
                variant={TAG_VARIANT.COMPLEMENTARY_STRAWBERRY}
                size={TAG_SIZE.SMALL}
                icon={<Icon glyph="error" />}
              >
                <FormattedMessageWithValidation id="image_quality" />
              </Tag>
            </Tooltip>
          )}
        </TagsWrapper>
      </Image>
    );
  }
);

function isAFile(image) {
  return image instanceof Blob || image instanceof File;
}

export default ImagePreview;
