import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../coeffects/environment';
import { TRACK_PAGE_VIEW_MOUNTED } from '../../../partials/Tracking/events';
import { navigateTo } from '../../../effects/routing';
import {
  checkoutAlreadyActiveRoute,
  checkoutPaymentCancelledRoute,
  checkoutPaymentForbiddenRoute,
  loginRoute,
} from '../../../utils/proppitWebRouter';
import { isBackofficeRole } from '../../../constants/user';
import { loadCountryConfig } from '../../../effects/loadCountryConfig';
import { COUNTRY_CONFIG_STATE_REGION } from '../../../config';

export const CHECKOUT_PAGE_REQUESTED = 'CHECKOUT_PAGE_REQUESTED';
export const CHECKOUT_PAGE_LAST_PENDING_REQUEST_LOADED =
  'CHECKOUT_PAGE_LAST_PENDING_REQUEST_LOADED';
export const CHECKOUT_PAGE_LAST_PENDING_REQUEST_FAILED =
  'CHECKOUT_PAGE_LAST_PENDING_REQUEST_FAILED';
export const CHECKOUT_PUBLISHER_DATA_REQUESTED =
  'CHECKOUT_PUBLISHER_DATA_REQUESTED';
export const CHECKOUT_PUBLISHER_DATA_RETRIEVED =
  'CHECKOUT_PUBLISHER_DATA_RETRIEVED';
export const CHECKOUT_PUBLISHER_DATA_RETRIEVAL_FAILED =
  'CHECKOUT_PUBLISHER_DATA_RETRIEVAL_FAILED';
export const CHECKOUT_COUNTRY_CONFIG_RETRIEVED =
  'CHECKOUT_COUNTRY_CONFIG_RETRIEVED';
export const CHECKOUT_TRACK_PAGE_VIEW = 'CHECKOUT_TRACK_PAGE_VIEW';

registerEventHandler(
  CHECKOUT_PAGE_REQUESTED,
  ({ environment: { apiUrl } }, { subscriptionChangeRequestId }) => ({
    ...http.get({
      url: `${apiUrl}/checkout/subscription-change-requests/${subscriptionChangeRequestId}/lastPending`,
      successEvent: CHECKOUT_PAGE_LAST_PENDING_REQUEST_LOADED,
      errorEvent: {
        id: CHECKOUT_PAGE_LAST_PENDING_REQUEST_FAILED,
        payload: subscriptionChangeRequestId,
      },
    }),
    ...state.set({
      checkoutPage: { loaded: false },
    }),
  }),
  [environment()]
);

registerEventHandler(
  CHECKOUT_PAGE_LAST_PENDING_REQUEST_LOADED,
  (_, [{ data }]) => ({
    ...state.merge({
      checkoutPage: {
        subscriptionChangeRequestId: data,
      },
    }),
    ...effects.dispatch({
      id: CHECKOUT_PUBLISHER_DATA_REQUESTED,
      payload: { subscriptionChangeRequestId: data },
    }),
  }),
  []
);

registerEventHandler(
  CHECKOUT_PAGE_LAST_PENDING_REQUEST_FAILED,
  (_, [__, subscriptionChangeRequestId]) =>
    state.set({
      checkoutPage: {
        subscriptionChangeRequestId,
        loaded: true,
      },
    })
);

registerEventHandler(
  CHECKOUT_PUBLISHER_DATA_REQUESTED,
  (
    { environment: { apiUrl }, state: { billingEntity } },
    { subscriptionChangeRequestId }
  ) => ({
    ...state.merge({
      form: { loading: billingEntity == null },
    }),
    ...http.get({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/publisher`,
      successEvent: {
        id: CHECKOUT_PUBLISHER_DATA_RETRIEVED,
        payload: { subscriptionChangeRequestId },
      },
      errorEvent: CHECKOUT_PUBLISHER_DATA_RETRIEVAL_FAILED,
    }),
  }),
  [environment(), state.get({ billingEntity: 'publisher.billingEntity' })]
);

registerEventHandler(CHECKOUT_PUBLISHER_DATA_RETRIEVAL_FAILED, () =>
  navigateTo(loginRoute())
);

registerEventHandler(
  CHECKOUT_PUBLISHER_DATA_RETRIEVED,
  (
    { environment: { apiUrl }, state: { subRoute, subscriptionId, role } },
    [{ data: publisher }, { subscriptionChangeRequestId }]
  ) => {
    if (
      publisher.subscriptionChangeRequestActivated === true ||
      publisher.subscriptionChangeRequestPaid === true
    ) {
      return navigateTo(
        checkoutAlreadyActiveRoute({ subRoute, subscriptionChangeRequestId })
      );
    }
    if (publisher.subscriptionChangeRequestCancelled === true) {
      return navigateTo(
        checkoutPaymentCancelledRoute({
          subRoute,
          subscriptionChangeRequestId,
        })
      );
    }
    if (
      subscriptionId &&
      subscriptionId !== publisher.subscriptionId &&
      !isBackofficeRole(role)
    ) {
      return navigateTo(
        checkoutPaymentForbiddenRoute({
          subRoute,
          subscriptionChangeRequestId,
        })
      );
    }
    return {
      ...state.merge({
        publisher,
      }),
      ...loadCountryConfig({
        apiUrl,
        country: publisher.countryCode,
        successEvent: CHECKOUT_COUNTRY_CONFIG_RETRIEVED,
      }),
    };
  },
  [
    environment(),
    state.get({
      subRoute: 'checkoutConfig.subRoute',
      subscriptionId: 'publisher.subscriptionId',
      role: 'user.role',
    }),
  ]
);

registerEventHandler(
  CHECKOUT_COUNTRY_CONFIG_RETRIEVED,
  (_, [countryConfig]) => ({
    ...state.merge({
      [COUNTRY_CONFIG_STATE_REGION]: countryConfig,
      form: {
        loading: false,
      },
      checkoutPage: { loaded: true },
    }),
  }),
  []
);

registerEventHandler(
  CHECKOUT_TRACK_PAGE_VIEW,
  ({ state: { publisherId, country } }, { pageViewName, pageViewPayload }) => ({
    ...effects.dispatch({
      id: TRACK_PAGE_VIEW_MOUNTED,
      payload: {
        pageViewName,
        payload: { ...pageViewPayload, publisherId, country },
      },
    }),
  }),
  [
    state.get({
      publisherId: 'publisher.id',
      country: 'publisher.countryCode',
    }),
  ]
);
