export default function createImageFile(data = {}) {
  const { file = '', isMain = false, id = undefined } = data;
  return {
    file,
    isMain,
    id,
  };
}

export function createFloorPlanFiles(images, mainImage) {
  return (images || []).map((image, idx) =>
    createImage({
      file: image,
      isMain: image === mainImage,
      id: idx + 1,
    })
  );
}

export function createAdImages(images, mainImage) {
  return (images || []).map((image, idx) =>
    createImage({
      id: idx + 1,
      file: image.url,
      isMain: image.url === mainImage,
      isProjectImage: Boolean(image.isProjectImage),
    })
  );
}

export function prepareProjectImages(images) {
  return (images || []).map((image) =>
    createImage({
      id: undefined,
      file: image,
      isMain: false,
      isProjectImage: true,
    })
  );
}

export function appendNewImages(newImages, existingImages) {
  const lastUsedImageId = existingImages?.length ?? 0;
  const previousImages = existingImages ?? [];

  const propertyImages = newImages.map((image, idx) => {
    const imageId = lastUsedImageId + idx + 1;
    const isFirstImage = imageId === 1;

    return createImage({
      id: imageId,
      file: image.url,
      isMain: isFirstImage,
    });
  });

  return [...previousImages, ...propertyImages];
}

export function createImage({
  id = undefined,
  file = '',
  isMain = false,
  isProjectImage = false,
}) {
  return {
    id,
    file,
    isMain,
    isProjectImage,
  };
}

export function createAiAdImages(images) {
  return (images || []).map((image, idx) =>
    createAiImage({
      id: idx + 1,
      file: image.url,
      isMain: idx === 0,
      isProjectImage: false,
      isBadQuality: image.isBadQuality,
    })
  );
}

export function createAiImage({
  id = undefined,
  file = '',
  isMain = false,
  isProjectImage = false,
  isBadQuality = false,
}) {
  return {
    id,
    file,
    isMain,
    isProjectImage,
    isBadQuality,
  };
}

export function mergeAndReindexImages(...collections) {
  return collections.flat().map((image, idx) => ({
    ...image,
    isMain: Boolean(image.isMain),
    id: idx + 1,
  }));
}

export function removeProjectImages(images) {
  return (images ?? []).filter((image) => !image.isProjectImage);
}

function isThereAMainImage(images) {
  return images.some((image) => image.isMain === true);
}

export function assignMainImageWhenUndefined(images) {
  if (images && images.length === 0) {
    return images;
  }

  if (!isThereAMainImage(images)) {
    const [first, ...rest] = images;

    return [updateImage(first, { isMain: true })].concat(rest);
  }

  return images;
}

function updateImage(original, changes) {
  return { ...original, ...changes };
}

export function removeImage(images, imageIndexToDelete) {
  return images.filter((_, index) => index !== imageIndexToDelete);
}

export function removeImageByUrl(images, imageUrlToDelete) {
  return images.filter((image) => image.file !== imageUrlToDelete);
}
