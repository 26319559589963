import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../../../../coeffects/environment';
import { toast } from '../../../../../../../effects/toast';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../../../../events/dialogs';
import { SUBSCRIPTION_CHANGE_REQUEST_MODAL_ID } from '../constants';
import { putSubscriptionChangeRequestDetailsInStateEffect } from '../putSubscriptionChangeRequestDetailsInStateEffect';
import {
  PLANS_PER_COUNTRY_REQUESTED,
  WASI_PLANS_REQUESTED,
} from '../../../../../events';
import { COUNTRY_CODE } from '../../../../../../../constants/country';
import { SUBSCRIPTION_CHANGE_TYPE } from '../../../../../../constants/subscription';
import { REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE } from './constansts';
import { MEXICO_TYPE_OF_CLIENT } from '../../../../../CreatePublisher/constants';
import { isMexico } from '../../../../../../../utils/countries';
import { PLANS_STATE_REGION } from '../../../../../constants';
import { SUBSCRIPTION_TYPES } from '../../../../../../../constants/subscription';
import { convertPublisherToSelectedOptions } from '../convertPublisherToSelectedOptions';
import { PAYMENT_METHODS_LABELS } from '../../../../../../utils/paymentMethodOptions';
import { findMexicanPlanByTypeOfClientAndMaxBoostableProperties } from '../../../../../../utils/plansFinder';

export const REQUEST_SUBSCRIPTION_CHANGE_MODAL =
  'REQUEST_SUBSCRIPTION_CHANGE_MODAL';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_PLAN_SELECTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_PLAN_SELECTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_TYPE_OF_CLIENT_SELECTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_TYPE_OF_CLIENT_SELECTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_ABORTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_ABORTED';
export const SUBSCRIPTION_CHANGE_REQUEST_REQUESTED =
  'SUBSCRIPTION_CHANGE_REQUEST_REQUESTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_CYCLE_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_CYCLE_CHANGED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_PAYMENT_METHOD_SELECTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_PAYMENT_METHOD_SELECTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHES_IN_THAILAND_PROPERTY_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHES_IN_THAILAND_PROPERTY_CHANGED';
export const SUBSCRIPTION_CHANGE_REQUEST_ACTION_REQUESTED =
  'SUBSCRIPTION_CHANGE_REQUEST_ACTION_REQUESTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_LOADED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_LOADED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUEST_FAILED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUEST_FAILED';
export const SUBSCRIPTION_CHANGE_REQUESTED_SUCCESSFULLY =
  'SUBSCRIPTION_CHANGE_REQUESTED_SUCCESSFULLY';
export const SUBSCRIPTION_CHANGE_REQUEST_FAILED =
  'SUBSCRIPTION_CHANGE_REQUEST_FAILED';
export const SUBSCRIPTION_CHANGE_REQUEST_DETAILS_RETRIEVED_SUCCESSFULLY =
  'SUBSCRIPTION_CHANGE_REQUEST_DETAILS_RETRIEVED_SUCCESSFULLY';
export const REQUEST_SUBSCRIPTION_CHANGE_PUBLISHER_DATA_LOAD_SUCCEEDED =
  'REQUEST_SUBSCRIPTION_CHANGE_PUBLISHER_DATA_LOAD_SUCCEEDED';
export const REQUEST_SUBSCRIPTION_CHANGE_PUBLISHER_DATA_LOAD_FAILED =
  'REQUEST_SUBSCRIPTION_CHANGE_PUBLISHER_DATA_LOAD_FAILED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_MAX_BOOSTABLE_PROPERTIES_SELECTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_MAX_BOOSTABLE_PROPERTIES_SELECTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_MAX_SUPERBOOSTABLE_PROPERTIES_SELECTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_MAX_SUPERBOOSTABLE_PROPERTIES_SELECTED';
export const SUBSCRIPTION_CHANGE_REQUEST_TYPE_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_TYPE_CHANGED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_BASE_CUSTOM_DISCOUNT_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_BASE_CUSTOM_DISCOUNT_CHANGED';
export const VALIDATE_DISCOUNT_WHEN_SUBSCRIPTION_CHANGE_REQUEST_MODAL_CUSTOM_DISCOUNT_UPDATED =
  'VALIDATE_DISCOUNT_WHEN_SUBSCRIPTION_CHANGE_REQUEST_MODAL_CUSTOM_DISCOUNT_UPDATED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_BASE_FINAL_PRICE_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_BASE_FINAL_PRICE_CHANGED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_SUPERBOOST_CUSTOM_DISCOUNT_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_SUPERBOOST_CUSTOM_DISCOUNT_CHANGED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_SUPERBOOST_FINAL_PRICE_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_SUPERBOOST_FINAL_PRICE_CHANGED';
export const SUBSCRIPTION_CHANGE_REQUEST_SUBMITTED =
  'SUBSCRIPTION_CHANGE_REQUEST_SUBMITTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_ADDITIONAL_MONTH_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_ADDITIONAL_MONTH_CHANGED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_SELECTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_SELECTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_CUSTOM_DISCOUNT_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_CUSTOM_DISCOUNT_CHANGED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_FINAL_PRICE_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_FINAL_PRICE_CHANGED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_SELECTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_SELECTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHER_USERS_REQUESTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHER_USERS_REQUESTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHER_USERS_LOADED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHER_USERS_LOADED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_SELECTED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_SELECTED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_EXISTS =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_EXISTS';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_DOES_NOT_EXIST =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_DOES_NOT_EXIST';
export const VALIDATE_SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_QUANTITY_UPDATED =
  'VALIDATE_SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_QUANTITY_UPDATED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_CUSTOM_DISCOUNT_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_CUSTOM_DISCOUNT_CHANGED';
export const SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_FINAL_PRICE_CHANGED =
  'SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_FINAL_PRICE_CHANGED';

const PRODUCT_SUPERBOOST = 'superboost';
const PRODUCT_REMATES_SUPERBOOST = 'remate-superboost';
const MAX_EXTRA_USERS = 1000;
const MIN_EXTRA_USERS = 0;

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_ACTION_REQUESTED,
  ({ environment: { apiUrl } }, { publisherId, paymentMethod }) => ({
    ...state.set({
      [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
        type: '',
        selectedPublisher: {},
        selectedOptions: {
          billingCycle: '',
          boostingLimit: '',
          superboostingLimit: '',
          planId: '',
          paymentMethod,
          baseCustomDiscount: null,
          superboostCustomDiscount: null,
          baseFinalPrice: null,
          superboostFinalPrice: null,
        },
        summary: undefined,
        formValidationErrors: {},
      },
    }),
    ...http.get({
      url: `${apiUrl}/backoffice/publishers/${publisherId}`,
      successEvent: REQUEST_SUBSCRIPTION_CHANGE_PUBLISHER_DATA_LOAD_SUCCEEDED,
      errorEvent: REQUEST_SUBSCRIPTION_CHANGE_PUBLISHER_DATA_LOAD_FAILED,
    }),
  }),
  [environment()]
);

registerEventHandler(
  REQUEST_SUBSCRIPTION_CHANGE_PUBLISHER_DATA_LOAD_SUCCEEDED,
  (_, [{ data }]) => {
    const events = [
      {
        id: OPEN_DIALOG,
        payload: { id: REQUEST_SUBSCRIPTION_CHANGE_MODAL },
      },
    ];
    if (data.subscription.productId !== 'free') {
      events.push({
        id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
      });
    }
    return {
      ...state.merge({
        [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
          selectedPublisher: data,
          selectedOptions: convertPublisherToSelectedOptions(data),
        },
      }),
      ...effects.dispatchMany(events),
    };
  }
);

registerEventHandler(
  REQUEST_SUBSCRIPTION_CHANGE_PUBLISHER_DATA_LOAD_FAILED,
  () =>
    toast.show({
      text: 'An error occurred while retrieving publisher data',
    })
);

registerEventHandler(SUBSCRIPTION_CHANGE_REQUEST_MODAL_ABORTED, () => ({
  ...state.merge({
    [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
      selectedPublisher: {},
      type: undefined,
      selectedOptions: {
        billingCycle: '',
        boostingLimit: '',
        superboostingLimit: '',
        planId: '',
        paymentMethod: '',
        baseCustomDiscount: null,
        superboostCustomDiscount: null,
        baseFinalPrice: null,
        superboostFinalPrice: null,
      },
      summary: undefined,
      formValidationErrors: {},
    },
  }),
  ...effects.dispatch(CLOSE_DIALOG),
}));
registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
  ({
    environment: { apiUrl },
    state: {
      publisherId,
      currentProductId,
      currentSuperboostableProperties,
      currentPublishesInThailandProperty,
      billingCycle,
      productId,
      maxboostableProperties,
      superboostableProperties,
      requestType,
      publishesInThailandProperty,
      typeOfClient,
      baseCustomDiscount,
      superboostCustomDiscount,
      baseFinalPrice,
      superboostFinalPrice,
      selectedOptions,
    },
  }) => {
    const hasSetProductOrSuperboostableProps =
      productId || superboostableProperties;
    if (!hasSetProductOrSuperboostableProps || !requestType) {
      return {};
    }

    if (
      (requestType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE ||
        requestType === SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEW_CYCLE) &&
      !productId
    ) {
      return {};
    }

    if (
      isInvalidNumber(baseFinalPrice) ||
      isInvalidNumber(superboostFinalPrice) ||
      isInvalidNumber(baseCustomDiscount) ||
      isInvalidNumber(superboostCustomDiscount)
    ) {
      return {};
    }

    const shouldSendBaseSubscription =
      requestType !== SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE ||
      currentProductId !== productId ||
      currentPublishesInThailandProperty !== publishesInThailandProperty;

    const baseSubscription = productId &&
      shouldSendBaseSubscription && {
        [SUBSCRIPTION_TYPES.BASE_SUBSCRIPTION]: {
          productId,
          boostableProperties: maxboostableProperties,
          customDiscount: baseCustomDiscount,
          finalPrice: baseFinalPrice,
        },
      };
    const shouldSendSuperboost =
      requestType !== SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE ||
      currentSuperboostableProperties !== superboostableProperties;

    const superboost = superboostableProperties &&
      shouldSendSuperboost && {
        [SUBSCRIPTION_TYPES.SUPERBOOST]: {
          productId: getSuperboostProductIdBy(typeOfClient),
          boostableProperties: superboostableProperties,
          customDiscount: superboostCustomDiscount,
          finalPrice: superboostFinalPrice,
        },
      };

    const wasiProduct = getWasiProduct(selectedOptions);

    if (!baseSubscription && !superboost) {
      return state.set({
        'requestSubscriptionChangeModal.availablePaymentMethods':
          selectedOptions.paymentMethod
            ? [
                {
                  id: selectedOptions.paymentMethod,
                  label: PAYMENT_METHODS_LABELS[selectedOptions.paymentMethod],
                },
              ]
            : [],
      });
    }

    return http.post({
      url: `${apiUrl}/admin/calculate-subscription-change-request-billing-detail`,
      body: {
        publisherId,
        products: {
          ...baseSubscription,
          ...superboost,
        },
        wasiProduct,
        additionalMonth: selectedOptions.additionalMonth,
        billingCycleInMonths: billingCycle,
        type: requestType,
        paymentMethod: selectedOptions.paymentMethod,
        publishesInThailandProperty,
      },
      successEvent: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_LOADED,
      errorEvent:
        SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUEST_FAILED,
    });
  },
  [
    environment(),
    state.get({
      publisherId: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedPublisher.id`,
      currentProductId: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedPublisher.subscription.productId`,
      currentSuperboostableProperties: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedPublisher.superboost.maxBoostableProperties`,
      currentPublishesInThailandProperty: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedPublisher.isThailandPropertyClient`,
      billingCycle: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.billingCycle`,
      productId: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.planId`,
      maxboostableProperties: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.boostingLimit`,
      superboostableProperties: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostingLimit`,
      requestType: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.type`,
      publishesInThailandProperty: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.publishesInThailandProperty`,
      typeOfClient: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.typeOfClient`,
      baseCustomDiscount: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseCustomDiscount`,
      baseFinalPrice: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseFinalPrice`,
      superboostCustomDiscount: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostCustomDiscount`,
      superboostFinalPrice: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostFinalPrice`,
      selectedOptions: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions`,
    }),
  ]
);

function isInvalidNumber(value) {
  return value && Number.isNaN(parseFloat(value));
}

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_LOADED,
  (
    {
      state: {
        baseCustomDiscount,
        superboostCustomDiscount,
        baseFinalPrice,
        superboostFinalPrice,
        selectedPaymentMethod,
      },
    },
    [
      {
        data: {
          proppit: {
            products,
            vat,
            taxRetentions,
            nextBillingDate,
            startDate,
            isPriceLowerThanMinimum,
            isPriceHigherThanMaximum,
          },
          availablePaymentMethods,
          wasi,
          totalPrice,
          totalRenewalPrice,
          scrType,
        },
      },
    ]
  ) => {
    let shouldResetSelectedPaymentMethod = false;
    if (
      !availablePaymentMethods?.find(
        (availablePaymentMethod) =>
          availablePaymentMethod.id === selectedPaymentMethod
      )
    ) {
      shouldResetSelectedPaymentMethod = true;
    }

    const baseSubscription = products.find(
      ({ type: productType }) =>
        productType === SUBSCRIPTION_TYPES.BASE_SUBSCRIPTION
    );
    const superboost = products.find(
      ({ type: productType }) => productType === SUBSCRIPTION_TYPES.SUPERBOOST
    );
    if (
      baseSubscription?.billingDetail.total.amount < 0 &&
      (baseFinalPrice || baseCustomDiscount)
    ) {
      return state.merge({
        [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
          disableSubmitButton: true,
        },
      });
    }
    if (
      superboost?.billingDetail.total.amount < 0 &&
      (superboostFinalPrice || superboostCustomDiscount)
    ) {
      return state.merge({
        [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
          disableSubmitButton: true,
        },
      });
    }

    const subscriptions = products.map(
      ({
        billingDetail: {
          total: productTotalPrice,
          unitPrice,
          basePrice,
          billingCycleInMonths,
          discounts,
          unusedPreviousSubscriptionDays,
          unusedCurrentSubscriptionDays,
          proportionalPriceForRemainingDays,
        },
        type: productType,
        boostableProperties,
      }) => ({
        totalPrice: productTotalPrice,
        price: unitPrice,
        basePrice,
        billingCycleInMonths,
        discounts,
        unusedPreviousSubscriptionDays,
        unusedCurrentSubscriptionDays,
        productType,
        boostableProperties,
        proportionalPriceForRemainingDays,
      })
    );
    return state.merge({
      [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
        disableSubmitButton: false,
        summary: {
          subscriptions,
          ...(wasi
            ? {
                wasi: {
                  plan: wasi.planBillingDetail,
                  extraUsers: wasi.extraUsersBillingDetail,
                  vat: wasi.vat,
                  total: wasi.total,
                },
              }
            : {}),
          taxDiscounts: taxRetentions,
          taxApplied: vat,
          totalPrice,
          totalRenewalPrice,
          nextBillingDate,
          startDate,
          isPriceLowerThanMinimum,
          isPriceHigherThanMaximum,
        },
        availablePaymentMethods,
        type: scrType,
        ...(shouldResetSelectedPaymentMethod
          ? { selectedOptions: { paymentMethod: null } }
          : {}),
      },
    });
  },
  [
    state.get({
      baseCustomDiscount: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseCustomDiscount`,
      baseFinalPrice: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseFinalPrice`,
      superboostCustomDiscount: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostCustomDiscount`,
      superboostFinalPrice: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostFinalPrice`,
      selectedPaymentMethod: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.paymentMethod`,
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUEST_FAILED,
  () =>
    state.merge({
      [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
        disableSubmitButton: true,
      },
    })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_SUBMITTED,
  (
    {
      state: {
        maxboostableProperties,
        superboostableProperties,
        type,
        hasSuperboostContracted,
      },
    },
    payload
  ) => {
    const isTryingToCancelSuperboost =
      superboostableProperties === 0 &&
      hasSuperboostContracted &&
      type === SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE;
    const isTryingToContractMoreSuperboostThanAvailable =
      superboostableProperties > maxboostableProperties;
    if (
      isTryingToContractMoreSuperboostThanAvailable ||
      isTryingToCancelSuperboost
    ) {
      return {
        ...state.merge({
          [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
            formValidationErrors: {
              maxSuperboostable: true,
            },
          },
        }),
      };
    }
    return {
      ...state.merge({
        [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
          formValidationErrors: {
            maxSuperboostable: false,
          },
        },
      }),
      ...effects.dispatch(SUBSCRIPTION_CHANGE_REQUEST_REQUESTED, payload),
    };
  },
  [
    state.get({
      maxboostableProperties: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.boostingLimit`,
      superboostableProperties: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostingLimit`,
      type: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.type`,
      hasSuperboostContracted: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedPublisher.hasSuperboostContracted`,
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_REQUESTED,
  (
    {
      environment: { apiUrl },
      state: {
        publisherId,
        currentProductId,
        currentPublishesInThailandProperty,
        billingCycleInMonths,
        productId,
        type,
        publishesInThailandProperty,
        paymentMethod,
        maxboostableProperties,
        superboostableProperties,
        typeOfClient,
        baseCustomDiscount,
        superboostCustomDiscount,
        baseFinalPrice,
        superboostFinalPrice,
        additionalMonth,
        selectedOptions,
      },
    },
    payload
  ) => {
    const baseSubscription = (type !==
      SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE ||
      currentProductId !== productId ||
      currentPublishesInThailandProperty !== publishesInThailandProperty) &&
      productId && {
        [SUBSCRIPTION_TYPES.BASE_SUBSCRIPTION]: {
          productId,
          boostableProperties: maxboostableProperties,
          customDiscount: baseCustomDiscount,
          finalPrice: baseFinalPrice,
        },
      };
    const superboost = (superboostableProperties ||
      (superboostableProperties === 0 &&
        (type === SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE ||
          type === SUBSCRIPTION_CHANGE_TYPE.DOWNGRADE_NEXT_CYCLE))) && {
      [SUBSCRIPTION_TYPES.SUPERBOOST]: {
        productId: getSuperboostProductIdBy(typeOfClient),
        boostableProperties: superboostableProperties,
        customDiscount: superboostCustomDiscount,
        finalPrice: superboostFinalPrice,
      },
    };

    const wasiPlan = getWasiProduct(selectedOptions);

    if (!baseSubscription && !superboost) {
      return null;
    }

    return {
      ...state.merge({
        [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
          inProgress: true,
        },
      }),
      ...http.post({
        url: `${apiUrl}/admin/subscription-change-request`,
        body: {
          publisherId,
          billingCycleInMonths,
          products: {
            ...baseSubscription,
            ...superboost,
          },
          wasiProduct: wasiPlan,
          type,
          publishesInThailandProperty,
          paymentMethod,
          additionalMonth,
        },
        successEvent: {
          id: SUBSCRIPTION_CHANGE_REQUESTED_SUCCESSFULLY,
          payload,
        },
        errorEvent: SUBSCRIPTION_CHANGE_REQUEST_FAILED,
      }),
    };
  },
  [
    environment(),
    state.get({
      publisherId: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedPublisher.id`,
      billingCycleInMonths: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.billingCycle`,
      productId: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.planId`,
      currentPublishesInThailandProperty: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedPublisher.isThailandPropertyClient`,
      currentProductId: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedPublisher.subscription.productId`,
      maxboostableProperties: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.boostingLimit`,
      superboostableProperties: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostingLimit`,
      type: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.type`,
      publishesInThailandProperty: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.publishesInThailandProperty`,
      paymentMethod: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.paymentMethod`,
      typeOfClient: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.typeOfClient`,
      baseCustomDiscount: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseCustomDiscount`,
      superboostCustomDiscount: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostCustomDiscount`,
      baseFinalPrice: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseFinalPrice`,
      superboostFinalPrice: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostFinalPrice`,
      additionalMonth: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.additionalMonth`,
      selectedOptions: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions`,
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUESTED_SUCCESSFULLY,
  (
    { environment: { apiUrl } },
    [
      {
        data: { subscriptionChangeRequestId },
      },
      { onSuccessEvent },
    ]
  ) => ({
    ...state.merge({
      [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
        inProgress: false,
      },
    }),
    ...http.get({
      url: `${apiUrl}/admin/subscription-change-request/${subscriptionChangeRequestId}`,
      successEvent: SUBSCRIPTION_CHANGE_REQUEST_DETAILS_RETRIEVED_SUCCESSFULLY,
    }),
    ...effects.dispatchMany([CLOSE_DIALOG, onSuccessEvent]),
  }),
  [environment()]
);
registerEventHandler(SUBSCRIPTION_CHANGE_REQUEST_FAILED, () => ({
  ...state.merge({
    [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: { inProgress: false },
  }),
  ...effects.dispatch(CLOSE_DIALOG),
  ...toast.show({
    text: 'An error has occurred',
  }),
}));

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_DETAILS_RETRIEVED_SUCCESSFULLY,
  (_, [{ data }]) =>
    data.status === 'pending'
      ? {
          ...putSubscriptionChangeRequestDetailsInStateEffect(data),
          ...toast.show({
            text: 'The request has been successfully sent.',
          }),
          ...effects.dispatch({
            id: OPEN_DIALOG,
            payload: {
              id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_ID,
            },
          }),
        }
      : toast.show({
          text: 'The plan has been successfully renovated',
        })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_CYCLE_CHANGED,
  (_, { billingCycleInMonths }) =>
    state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.billingCycle`]:
        billingCycleInMonths,
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.additionalMonth`]: false,
    })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_PAYMENT_METHOD_SELECTED,
  (_, { paymentMethod }) =>
    state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.paymentMethod`]:
        paymentMethod,
    })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_BASE_CUSTOM_DISCOUNT_CHANGED,
  (_, { baseCustomDiscount }) => {
    const customDiscountNullIfEmpty = setNullIfEmpty(baseCustomDiscount);
    return {
      ...state.set({
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseCustomDiscount`]:
          customDiscountNullIfEmpty,
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseFinalPrice`]:
          null,
      }),
      ...effects.dispatchLater({
        id: VALIDATE_DISCOUNT_WHEN_SUBSCRIPTION_CHANGE_REQUEST_MODAL_CUSTOM_DISCOUNT_UPDATED,
        payload: {
          stateRegion: 'baseCustomDiscount',
          discount: customDiscountNullIfEmpty,
        },
      }),
    };
  }
);

const MAX_CUSTOM_DISCOUNT = '100';
registerEventHandler(
  VALIDATE_DISCOUNT_WHEN_SUBSCRIPTION_CHANGE_REQUEST_MODAL_CUSTOM_DISCOUNT_UPDATED,
  (_, { stateRegion, discount }) => ({
    ...state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.${stateRegion}`]:
        Number(discount) > Number(MAX_CUSTOM_DISCOUNT)
          ? MAX_CUSTOM_DISCOUNT
          : discount,
    }),
    ...effects.dispatch({
      id: SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED,
    }),
  }),
  [
    state.get({
      baseCustomDiscount: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseCustomDiscount`,
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_BASE_FINAL_PRICE_CHANGED,
  (_, { finalPrice }) =>
    state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseFinalPrice`]:
        setNullIfEmpty(finalPrice),
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseCustomDiscount`]:
        null,
    })
);

function setNullIfEmpty(param) {
  return param === '' ? null : param;
}

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_SUPERBOOST_CUSTOM_DISCOUNT_CHANGED,
  (_, { superboostCustomDiscount }) => {
    const customDiscountNullIfEmpty = setNullIfEmpty(superboostCustomDiscount);
    return {
      ...state.set({
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostCustomDiscount`]:
          customDiscountNullIfEmpty,
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostFinalPrice`]:
          null,
      }),
      ...effects.dispatchLater({
        id: VALIDATE_DISCOUNT_WHEN_SUBSCRIPTION_CHANGE_REQUEST_MODAL_CUSTOM_DISCOUNT_UPDATED,
        payload: {
          stateRegion: 'superboostCustomDiscount',
          discount: customDiscountNullIfEmpty,
        },
      }),
    };
  }
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_SUPERBOOST_FINAL_PRICE_CHANGED,
  (_, { finalPrice }) =>
    state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostFinalPrice`]:
        setNullIfEmpty(finalPrice),
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostCustomDiscount`]:
        null,
    })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_PLAN_SELECTED,
  (_, { planId }) =>
    state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.planId`]:
        planId,
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseCustomDiscount`]:
        null,
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.baseFinalPrice`]:
        null,
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostCustomDiscount`]:
        null,
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostFinalPrice`]:
        null,
    })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_TYPE_OF_CLIENT_SELECTED,
  (_, { typeOfClient }) =>
    state.merge({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}`]: {
        selectedOptions: {
          typeOfClient,
          boostingLimit: null,
          planId: '',
        },
      },
    }),
  []
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_MAX_BOOSTABLE_PROPERTIES_SELECTED,
  (
    { state: { plans, typeOfClient, countryCode } },
    { maxBoostableProperties }
  ) => {
    if (!isMexico(countryCode)) {
      return state.merge({
        [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
          selectedOptions: {
            boostingLimit: maxBoostableProperties,
            planId: '',
            baseCustomDiscount: null,
            baseFinalPrice: null,
            superboostCustomDiscount: null,
            superboostFinalPrice: null,
          },
        },
      });
    }

    const filteredPlans = plans.filter((plan) => plan.price > 0);
    const foundPlan = findMexicanPlanByTypeOfClientAndMaxBoostableProperties(
      filteredPlans,
      typeOfClient,
      maxBoostableProperties
    );

    if (!foundPlan) {
      return state.merge({
        [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
          selectedOptions: {
            boostingLimit: maxBoostableProperties,
            planId: null,
            typeOfClient: null,
          },
        },
      });
    }

    return state.merge({
      [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
        selectedOptions: {
          boostingLimit: maxBoostableProperties,
          planId: foundPlan.id,
        },
      },
    });
  },
  [
    state.get({
      plans: PLANS_STATE_REGION,
      typeOfClient: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.typeOfClient`,
      countryCode: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedPublisher.countryCode`,
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHES_IN_THAILAND_PROPERTY_CHANGED,
  (_, { value }) => ({
    ...effects.dispatchMany([
      {
        id: PLANS_PER_COUNTRY_REQUESTED,
        payload: {
          countryCode: COUNTRY_CODE.THAILAND,
          publishInThailandProperty: value,
        },
      },
      {
        id: WASI_PLANS_REQUESTED,
        payload: { countryCode: COUNTRY_CODE.THAILAND },
      },
    ]),
    ...state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.publishesInThailandProperty`]:
        value,
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.planId`]: '',
    }),
  })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_ADDITIONAL_MONTH_CHANGED,
  (_, { value }) => ({
    ...state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.additionalMonth`]:
        value,
    }),
    ...effects.dispatch(
      SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED
    ),
  })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_TYPE_CHANGED,
  (
    {
      state: {
        publisher: {
          subscription: {
            billingCycleInMonths,
            maxBoostableProperties,
            productId,
          },
          superboost: {
            maxBoostableProperties: maxSuperboostableProperties,
          } = {},
        },
      },
    },
    { type }
  ) => ({
    ...state.merge({
      [REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE]: {
        type,
        selectedOptions: {
          billingCycle: billingCycleInMonths,
          boostingLimit: maxBoostableProperties,
          superboostingLimit: maxSuperboostableProperties ?? '',
          planId: productId,
          baseFinalPrice: null,
          baseCustomDiscount: null,
          superboostFinalPrice: null,
          superboostCustomDiscount: null,
        },
      },
    }),
    ...effects.dispatch(
      SUBSCRIPTION_CHANGE_REQUEST_MODAL_BILLING_DETAIL_REQUESTED
    ),
  }),
  [
    state.get({
      publisher: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedPublisher`,
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_MAX_SUPERBOOSTABLE_PROPERTIES_SELECTED,
  (_, { maxSuperboostableProperties }) =>
    state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.superboostingLimit`]:
        maxSuperboostableProperties,
    })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_SELECTED,
  (_, { wasiPlanId }) =>
    state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.wasiPlanId`]:
        wasiPlanId,
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.wasiPlanCustomDiscount`]:
        null,
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.wasiPlanFinalPrice`]:
        null,
    })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_CUSTOM_DISCOUNT_CHANGED,
  (_, { wasiPlanCustomDiscount }) => {
    const customDiscountNullIfEmpty = setNullIfEmpty(wasiPlanCustomDiscount);
    return {
      ...state.set({
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.wasiPlanCustomDiscount`]:
          customDiscountNullIfEmpty,
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.wasiPlanFinalPrice`]:
          null,
      }),
      ...effects.dispatch({
        id: VALIDATE_DISCOUNT_WHEN_SUBSCRIPTION_CHANGE_REQUEST_MODAL_CUSTOM_DISCOUNT_UPDATED,
        payload: {
          stateRegion: 'wasiPlanCustomDiscount',
          discount: customDiscountNullIfEmpty,
        },
      }),
    };
  }
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_PLAN_FINAL_PRICE_CHANGED,
  (_, { finalPrice }) =>
    state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.wasiPlanFinalPrice`]:
        setNullIfEmpty(finalPrice),
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.wasiPlanCustomDiscount`]:
        null,
    })
);

const calculateValue = (extraUsers) => {
  let value = extraUsers;

  if (value > MAX_EXTRA_USERS) {
    value = MAX_EXTRA_USERS;
  }
  if (value < MIN_EXTRA_USERS) {
    value = MIN_EXTRA_USERS;
  }

  return value;
};

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_SELECTED,
  (_, { extraUsers }) => {
    const extraUsersNullIfEmpty = setNullIfEmpty(extraUsers);
    return {
      ...state.set({
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.extraUsersQuantity`]:
          extraUsersNullIfEmpty,
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.extraUsersCustomDiscount`]:
          null,
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.extraUsersFinalPrice`]:
          null,
      }),
      ...effects.dispatch({
        id: VALIDATE_SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_QUANTITY_UPDATED,
        payload: {
          quantity: extraUsersNullIfEmpty,
        },
      }),
    };
  }
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHER_USERS_REQUESTED,
  ({ environment: { apiUrl }, state: { publisherId } }) =>
    http.get({
      url: `${apiUrl}/backoffice/publishers/${publisherId}/users`,
      successEvent: SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHER_USERS_LOADED,
    }),
  [
    environment(),
    state.get({
      publisherId: 'requestSubscriptionChangeModal.selectedPublisher.id',
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_PUBLISHER_USERS_LOADED,
  (_, [{ data }]) =>
    state.set({
      'requestSubscriptionChangeModal.usersForWasiAccount': data,
    })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_SELECTED,
  ({ environment: { apiUrl } }, { user }) => ({
    ...state.set({
      'requestSubscriptionChangeModal.selectedOptions.wasiAccountEmail': user,
    }),
    ...http.get({
      url: `${apiUrl}/admin/wasi-account/${user}`,
      successEvent: SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_EXISTS,
      errorEvent: SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_DOES_NOT_EXIST,
    }),
  }),
  [environment()]
);

registerEventHandler(SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_EXISTS, () =>
  state.set({ 'requestSubscriptionChangeModal.accountError': true })
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_WASI_USER_DOES_NOT_EXIST,
  () => state.set({ 'requestSubscriptionChangeModal.accountError': false })
);

registerEventHandler(
  VALIDATE_SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_QUANTITY_UPDATED,
  (_, { quantity }) =>
    state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.extraUsersQuantity`]:
        calculateValue(quantity),
    }),
  [
    state.get({
      extraUsersQuantity: `${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.extraUsersQuantity`,
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_CUSTOM_DISCOUNT_CHANGED,
  (_, { extraUsersCustomDiscount }) => {
    const customDiscountNullIfEmpty = setNullIfEmpty(extraUsersCustomDiscount);
    return {
      ...state.set({
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.extraUsersCustomDiscount`]:
          customDiscountNullIfEmpty,
        [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.extraUsersFinalPrice`]:
          null,
      }),
      ...effects.dispatch({
        id: VALIDATE_DISCOUNT_WHEN_SUBSCRIPTION_CHANGE_REQUEST_MODAL_CUSTOM_DISCOUNT_UPDATED,
        payload: {
          stateRegion: 'extraUsersCustomDiscount',
          discount: customDiscountNullIfEmpty,
        },
      }),
    };
  }
);

registerEventHandler(
  SUBSCRIPTION_CHANGE_REQUEST_MODAL_EXTRA_USERS_FINAL_PRICE_CHANGED,
  (_, { finalPrice }) =>
    state.set({
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.extraUsersFinalPrice`]:
        setNullIfEmpty(finalPrice),
      [`${REQUEST_SUBSCRIPTION_CHANGE_MODAL_STATE}.selectedOptions.extraUsersCustomDiscount`]:
        null,
    })
);

function getSuperboostProductIdBy(typeOfClient) {
  return typeOfClient === MEXICO_TYPE_OF_CLIENT.REMATES
    ? PRODUCT_REMATES_SUPERBOOST
    : PRODUCT_SUPERBOOST;
}

function getWasiProduct(selectedOptions) {
  return selectedOptions.wasiPlanId
    ? {
        planId: selectedOptions.wasiPlanId,
        customDiscount: selectedOptions.wasiPlanCustomDiscount,
        finalPrice: selectedOptions.wasiPlanFinalPrice,
        extraUsers: selectedOptions.extraUsersQuantity ?? 0,
        extraUsersCustomDiscount: selectedOptions.extraUsersCustomDiscount,
        extraUsersFinalPrice: selectedOptions.extraUsersFinalPrice,
        accountEmail: selectedOptions.wasiAccountEmail,
      }
    : null;
}
