import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import Table, { TABLE_ALIGNMENT } from 'design-system/components/Table';
import SkeletonBone from 'design-system/components/SkeletonBone';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { DataTable, TextColumn } from '../../partials/DataTable';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { publisherIdSelector } from '../../selectors/publisher';
import { useColumn } from '../../partials/DataTable/hooks/useColumn';
import { LOG_WITH_MANAGED_PUBLISHER } from './events';
import { superboostEnabledSelector } from '../../selectors/config';

const PublisherName = styled(Subtitle2)`
  ${typography.weight(FONT_WEIGHT.LIGHT)}
`;

export default function PublishersTable() {
  const loggedPublisherId = useSelector(publisherIdSelector);
  const hasSuperboostEnabled = useSelector(superboostEnabledSelector);
  return (
    <DataTable
      allowPagination
      clickableRows
      loadingComponent={<TableSkeleton />}
    >
      <TextColumn
        id="name"
        source={{ id: 'id', name: 'name' }}
        renderField={({ id, name }) => (
          <>
            <PublisherName>{name}</PublisherName>
            {id === loggedPublisherId && (
              <Subtitle2>
                {' '}
                <FormattedMessageWithValidation id="managed_publishers_table_current_publisher" />
              </Subtitle2>
            )}
          </>
        )}
        title={
          <FormattedMessageWithValidation id="managed_publishers_table_headers_name" />
        }
        fullWidth
      />
      <TextColumn
        id="maxBoostableProperties"
        maxWidth={161}
        title={
          <FormattedMessageWithValidation id="managed_publishers_table_headers_boosting" />
        }
      />
      {hasSuperboostEnabled && (
        <TextColumn
          id="maxSuperboostableProperties"
          maxWidth={161}
          title={
            <FormattedMessageWithValidation id="managed_publishers_table_headers_superboosting" />
          }
        />
      )}
      <ActionsColumn
        id="actions"
        source={{ publisherId: 'id' }}
        maxWidth={110}
        title={
          <FormattedMessageWithValidation id="managed_publishers_table_actions" />
        }
      />
    </DataTable>
  );
}

function ActionsColumn(props) {
  const {
    value: { publisherId },
  } = useColumn(props);
  return (
    <Table.Cell {...props}>
      <FlatButton
        icon={<Icon glyph="login" $size={ICON_SIZE.BASE} />}
        onClick={() =>
          dispatch({
            id: LOG_WITH_MANAGED_PUBLISHER,
            payload: { publisherId },
          })
        }
      />
    </Table.Cell>
  );
}

function TableSkeleton() {
  return (
    <Table.Skeleton
      rows={10}
      columns={[
        {
          id: 'name',
          content: <SkeletonBone $width="138px" $height="16px" />,
        },
        {
          id: 'maxBoostableProperties',
          content: <SkeletonBone $width="105px" $height="15px" />,
        },
        {
          id: 'actions',
          content: <SkeletonBone $width="54px" $height="15px" />,
          alignment: TABLE_ALIGNMENT.RIGHT,
        },
      ]}
    >
      <Table.Row>
        <Table.Cell>
          <SkeletonBone $width="138px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell width={161}>
          <SkeletonBone $width="17px" $height="17px" />
        </Table.Cell>
        <Table.Cell width={110}>
          <SkeletonBone $width="20px" $height="18px" />
        </Table.Cell>
      </Table.Row>
    </Table.Skeleton>
  );
}
