import styled, { css, keyframes } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import Tag, {
  TAG_ICON_POSITION,
  TAG_SIZE,
  TAG_VARIANT,
} from 'design-system/components/Tag';
import Icon from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { useSelector } from 'reffects-store';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  shouldDisplayMarketReferenceErrorMessageSelector,
  shouldDisplayMarketReferenceResultSelector,
  shouldDisplayMarketReferenceThinkerBallSelector,
} from '../../../partials/MarketPrice/selectors';
import { ErrorDisclaimer } from './ErrorDisclaimer';
import PremiumFeedback from '../../../../../partials/PremiumFeedback/PremiumFeedback';
import AnimatedThinkingBall from '../UploadProgress/AnimatedThinkingBall';

const heightTransition = keyframes`
    0%, 100% {
        height: 81px;
    }
    50% {
        height: 11px;
    }
`;

const fadeIn = keyframes`
    0%, 79% {
        opacity: 0;
    }
    80% {
        opacity: 0.5;
    }
    95% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }

`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 81px;
  padding: ${SPACING_SIZE.M}px;
  ${color.text(COLOR_PALETTE.NEUTRAL_A80)};
  ${color.background(COLOR_PALETTE.PRIMARY_A05)};
  ${typography.size(FONT_SIZE.M)};
  font-weight: bold;
  text-align: left;
  border-radius: 8px;
  border: 0;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  transition: height 0.4s ease-out, padding 0s ease-out;

  ${({ $marketReferenceDisplayResult }) =>
    $marketReferenceDisplayResult &&
    css`
      animation: ${heightTransition} 1s forwards;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      $(Tag) {
        display: flex;
        padding: 2px 4px 2px 6px;
        justify-content: center;
        align-items: center;
        gap: ${SPACING_SIZE.XS}px;
        text-transform: uppercase;
      }
    `}

  ${({ $marketReferenceDisplayErrorMessage }) =>
    $marketReferenceDisplayErrorMessage &&
    css`
      height: auto;
      padding: ${SPACING_SIZE.M}px;
    `}
`;

const TagText = styled(Tag)`
  text-transform: uppercase;
  opacity: 0;
  animation: ${fadeIn} 1s forwards;
`;

const Disclaimer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${SPACING_SIZE.S}px;
  flex: 1 0 0;
  padding: 2px 4px 2px 6px;
`;

const DisclaimerText = styled.span`
  color: ${COLOR_PALETTE.PRIMARY_BASE};
  ${typography.font(FONT_FAMILY.PRIMARY)};
  ${typography.size(FONT_SIZE.S)};
  ${typography.weight(FONT_WEIGHT.NORMAL)};
  line-height: normal;
  opacity: 0;
  animation: ${fadeIn} 1s forwards;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

export default function MarketReferenceUploadProgress({ children }) {
  const marketReferenceDisplayThinkerBall = useSelector(
    shouldDisplayMarketReferenceThinkerBallSelector
  );
  const marketReferenceDisplayResult = useSelector(
    shouldDisplayMarketReferenceResultSelector
  );
  const marketReferenceDisplayErrorMessage = useSelector(
    shouldDisplayMarketReferenceErrorMessageSelector
  );

  return (
    <Wrapper>
      <ProgressContainer
        $marketReferenceDisplayResult={marketReferenceDisplayResult}
        $marketReferenceDisplayErrorMessage={marketReferenceDisplayErrorMessage}
      >
        {marketReferenceDisplayThinkerBall && <AnimatedThinkingBall />}
        {marketReferenceDisplayResult && (
          <Disclaimer>
            <TagText
              variant={TAG_VARIANT.PRIMARY_DARK}
              size={TAG_SIZE.SMALL}
              icon={<Icon glyph="spark" />}
              iconPosition={TAG_ICON_POSITION.START}
            >
              <FormattedMessageWithValidation id="premium" />
            </TagText>
            <DisclaimerText>{children}</DisclaimerText>
          </Disclaimer>
        )}

        {marketReferenceDisplayErrorMessage && (
          <Disclaimer>
            <ErrorDisclaimer />
          </Disclaimer>
        )}
      </ProgressContainer>
      {marketReferenceDisplayResult && (
        <PremiumFeedback
          label="premium_feedback_question_market_price"
          section="MarketPrice"
        />
      )}
    </Wrapper>
  );
}
