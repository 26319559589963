import styled from 'styled-components';
import Table, {
  TABLE_ALIGNMENT,
  TABLE_VARIANT,
} from 'design-system/components/Table';
import SkeletonBone from 'design-system/components/SkeletonBone';
import {
  DataTable,
  DateColumn,
  PriceColumn,
  TextColumn,
} from '../../../../../partials/DataTable';
import PropertySnippet from '../../../../../partials/PropertySnippet/PropertySnippet';
import CountryColumn from './Columns/CountryColumn';
import { ActionsColumn } from './Columns/ActionsColumn/ActionsColumn';
import buildBoostingLevelName from '../../../../utils/buildBoostingLevelName';
import { PaymentMethodColumn } from './Columns/PaymentMethodColumn';
import IdColumn from './Columns/IdColumn';
import { BillingEntityColumn } from './Columns/BillingEntityColumn';
import EmailColumn from './Columns/EmailColumn';
import { StatusColumn } from './Columns/StatusColumn';
import { NumberColumn } from '../../../../../partials/DataTable/components/columns/NumberColumn';
import { BankReceiptColumn } from './Columns/BankReceiptColumn';
import { AgencyNameColumn } from './Columns/AgencyNameColumn';
import SalesPersonColumn from './Columns/SalesPersonColumn';

const Wrapper = styled(DataTable)`
  ${Table.mods.variant(TABLE_VARIANT.SMALL)}
`;

function AgenciesTable() {
  return (
    <Wrapper
      noItemsMessage="No items"
      allowPagination
      loadingComponent={<TableSkeleton />}
      fixedColumnsLeft={[{ width: 245 }, { width: 150 }, { width: 112 }]}
      adjustHeightToScreen
    >
      <AgencyNameColumn
        id="name"
        ellipse
        sortable
        title="Agency"
        source={{
          name: 'name',
          subscriptionId: 'subscriptionId',
          numberOfPublishersSharingSubscription:
            'numberOfPublishersSharingSubscription',
          isForTesting: 'isForTesting',
        }}
      />
      <StatusColumn
        sortable
        id="lastActiveBillingDate"
        source={{
          status: 'status',
          hasActiveFirstPaymentCheckout: 'hasActiveFirstPaymentCheckout',
          subscriptionChangeRequests: 'subscriptionChangeRequests',
          isSelfServiced: 'isSelfServiced',
          renewalCancelRequestStatus: 'renewalCancelRequestStatus',
          hasPremiumSubscription: 'hasPremiumSubscription',
        }}
        title="Status"
      />
      <ActionsColumn id="actions" alignment={TABLE_ALIGNMENT.RIGHT} />
      <CountryColumn id="country" sortable title="Country" />
      <IdColumn
        id="id"
        sortable
        title="Publisher ID"
        placeholder="Copy publisher id"
      />
      <IdColumn
        id="subscriptionId"
        sortable
        title="Subscription ID"
        placeholder="Copy subscription id"
      />
      <EmailColumn id="email" sortable title="Email" />
      <BillingEntityColumn
        id="billingEntityName"
        source={{
          name: 'billingEntityName',
          billingEntityExternalId: 'billingEntityExternalId',
          publisherId: 'id',
          country: 'country',
        }}
        sortable
        title="Associated Billing"
      />
      <SalesPersonColumn
        id="salesPersonEmail"
        source={{
          publisherId: 'id',
          salesPersonEmail: 'salesPersonEmail',
          dealId: 'dealId',
        }}
        sortable
        title="Account manager"
      />
      <DateColumn
        id="userCreationDate"
        sortable
        title="Welcome email sent date"
        timeZone="utc"
      />
      <DateColumn
        id="activationDate"
        sortable
        title="Activation date"
        timeZone="utc"
      />
      <TextColumn id="planType" sortable title="Type" />
      <PaymentMethodColumn id="paymentMethod" sortable title="Payment method" />
      <DateColumn
        id="previousBillingDate"
        sortable
        title="Previous billing date"
        timeZone="utc"
      />
      <DateColumn
        id="billingDate"
        sortable
        title="Billing date"
        timeZone="utc"
      />
      <NumberColumn
        id="superBoostingLimit"
        sortable
        title="Max. superboostable properties"
      />
      <NumberColumn
        id="boostingLimit"
        sortable
        title="Max. boosting properties"
      />
      <NumberColumn
        id="boostingLevel"
        sortable
        title="Boosting level"
        renderField={buildBoostingLevelName}
      />
      <TextColumn id="billingCycle" sortable title="Billing cycle" />
      <NumberColumn
        id="publishedListings"
        sortable
        title="Published listings"
      />
      <TextColumn
        id="upfrontDiscount"
        sortable
        title="Upfront discount"
        renderField={(value) => `${value}%`}
      />
      <TextColumn
        id="customDiscount"
        sortable
        title="Subscription custom discount"
        renderField={(value) => `${value}%`}
      />
      <PriceColumn
        id="priceAfterDiscounts"
        sortable
        title="Price after discounts"
        source={{
          amount: 'priceAfterDiscounts.amount',
          currency: 'priceAfterDiscounts.currency',
        }}
      />
      <PriceColumn
        id="endPrice"
        sortable
        title="End price"
        source={{ amount: 'endPrice.amount', currency: 'endPrice.currency' }}
      />
      <BankReceiptColumn
        id="bankReceipt"
        title="Receipt"
        source={{ subscriptionChangeRequests: 'subscriptionChangeRequests' }}
      />
    </Wrapper>
  );
}

function TableSkeleton() {
  return (
    <Table.Skeleton
      rows={10}
      columns={[
        {
          id: 'snippet',
          content: <SkeletonBone $width="88px" $height="16px" />,
        },
        {
          id: 'referenceId',
          content: <SkeletonBone $width="64px" $height="16px" />,
        },
        {
          id: 'operations',
          content: <SkeletonBone $width="53px" $height="16px" />,
        },
        {
          id: 'prices',
          content: <SkeletonBone $width="59px" $height="16px" />,
        },
        {
          id: 'impressions',
          content: <SkeletonBone $width="62px" $height="16px" />,
          alignment: TABLE_ALIGNMENT.CENTER,
        },
        {
          id: 'clicks',
          content: <SkeletonBone $width="62px" $height="16px" />,
          alignment: TABLE_ALIGNMENT.CENTER,
        },
        {
          id: 'enquiries',
          content: <SkeletonBone $width="62px" $height="16px" />,
          alignment: TABLE_ALIGNMENT.CENTER,
        },
        {
          id: 'date',
          content: <SkeletonBone $width="85px" $height="16px" />,
        },
        {
          id: 'boosted',
          content: <SkeletonBone $width="43px" $height="16px" />,
        },
        {
          id: 'published',
          content: <SkeletonBone $width="43px" $height="16px" />,
        },
        {
          id: 'actions',
          content: <SkeletonBone $width="87px" $height="16px" />,
        },
      ]}
    >
      <Table.Row>
        <Table.Cell>
          <PropertySnippet.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.KpiCell>
          <SkeletonBone $width="38px" $height="14px" />
        </Table.KpiCell>
        <Table.KpiCell>
          <SkeletonBone $width="38px" $height="14px" />
        </Table.KpiCell>
        <Table.KpiCell>
          <SkeletonBone $width="38px" $height="14px" />
        </Table.KpiCell>
        <Table.Cell>
          <SkeletonBone $width="60px" $height="14px" randomWidth />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="17px" $height="14px" />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="17px" $height="14px" />
        </Table.Cell>
        <Table.Cell>
          <SkeletonBone $width="17px" $height="14px" />
        </Table.Cell>
      </Table.Row>
    </Table.Skeleton>
  );
}

export default AgenciesTable;
