import styled from 'styled-components';
import React from 'react';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import WithImages from './WithImages';
import WithoutImages from './WithoutImages';

const HelperText = styled(Body2)`
  display: block;
  margin-top: ${spacing.value(SPACING_SIZE.S)};
  ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
`;

const ErrorText = styled(Body2)`
  display: block;
  margin-top: ${spacing.value(SPACING_SIZE.S)};
  ${color.text(COLOR_PALETTE.STRAWBERRY_BASE)}
`;

function ImageUploader(
  {
    id,
    name,
    images,
    helperText,
    errorText,
    isDisabled,
    onImagesUploaded,
    ...rest
  },
  ref
) {
  return (
    <div ref={ref} {...rest}>
      {images.length === 0 ? (
        <WithoutImages id={id} onImagesUploaded={onImagesUploaded} />
      ) : (
        <WithImages
          id={id}
          name={name}
          images={images}
          isDisabled={isDisabled}
          onImagesUploaded={onImagesUploaded}
        />
      )}
      <HelperText>{helperText}</HelperText>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </div>
  );
}

export default React.forwardRef(ImageUploader);
