import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { TABLE_ALIGNMENT } from 'design-system/components/Table';
import { dispatch } from 'reffects';
import { TOOLTIP_MODAL_PLACEMENT } from 'design-system/components/TooltipModal/TooltipModal';
import { DataTable } from '../../../../../partials/DataTable';
import LeadsStats from '../../LeadsStats';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import StageFilters from '../../StageFilters/StageFilters';
import StatusColumn from '../StatusColumn';
import AdFieldsColumn from './partials/AdFieldsColumn';
import PropertyInterestedInSnippet from '../PropertyInterestedInSnippet';
import AccumulatedPropertiesSnippet from '../AccumulatedPropertiesSnippet';
import buildTableRowEventsRedirectingTo from '../../../../../partials/DataTable/utils/buildTableRowEventsRedirectingTo';
import DateFilters from '../../DateFilters/DateFilters';
import ClearFiltersButton from '../../../../../partials/DataTable/components/filters/ClearFiltersButton';
import { LeadsFilter } from '../../LeadsFilter/LeadsFilter';
import { LastActivityColumn } from '../LastActivityColumn';
import LeadsTableSkeleton from './LeadsTableSkeleton';
import { UnreadColumn } from '../UnreadColumn';
import LeadsPropertyFilter from './LeadsPropertyFilter/LeadsPropertyFilter';
import {
  leadsWithHotnessSelector,
  leadsWithLeadPlusSelector,
  leadsWithQualificationSelector,
} from '../selectors';
import { TRACK_CLICK } from '../../../../../partials/Tracking/events';
import HotnessColumn from './partials/HotnessColumn';
import ContactColumn from './partials/ContactColumn';
import { shouldDisplayHotnessColumnSelector } from './selectors';
import { shouldTeasePremiumSubscriptionSelector } from '../../../../../selectors/premium';
import PremiumTitle from './partials/PremiumTitle';
import { isPremiumSubscriptionSelector } from '../../../../../selectors/publisher';

const Filters = styled.div`
  display: flex;
  flex-direction: row;

  ${spacing.stack(SPACING_SIZE.L)}
  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const EnquiriesCount = styled(Body1)`
  line-height: 38px;
`;

function NewTable() {
  const shouldDisplayHotnessColumn = useSelector(
    shouldDisplayHotnessColumnSelector
  );
  const shouldTeasePremium = useSelector(
    shouldTeasePremiumSubscriptionSelector
  );
  const isPremiumSubscription = useSelector(isPremiumSubscriptionSelector);
  const leadsWithLeadPlus = useSelector(leadsWithLeadPlusSelector);
  const leadsWithQualification = useSelector(leadsWithQualificationSelector);
  const leadsWithHotness = useSelector(leadsWithHotnessSelector);
  const handleHeaderClick = (columnName) => {
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: `${columnName}-column`,
        sectionName: 'enquiries-list-ordination-agency',
      },
    });
  };
  return (
    <DataTable
      allowPagination
      clickableRows
      rowEvents={buildTableRowEventsRedirectingTo(
        (itemId) => `/leads/${itemId}`
      )}
      loadingComponent={<LeadsTableSkeleton />}
      noItemsMessage={
        <FormattedMessageWithValidation id="enqpage_table_empty_results" />
      }
    >
      {shouldDisplayHotnessColumn && (
        <HotnessColumn
          id="hotness"
          source={{ id: 'id' }}
          eligibleLeadsForHotness={leadsWithHotness}
          title={
            <PremiumTitle
              shouldTeasePremium={shouldTeasePremium}
              isPremiumSubscription={isPremiumSubscription}
              tooltipFeatureTag="unlock_premium_tooltip_hotness"
            >
              <FormattedMessageWithValidation id="enqpage_table_hotness" />
            </PremiumTitle>
          }
          shouldTeasePremium={shouldTeasePremium}
        />
      )}
      <StatusColumn
        id="status"
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_status" />}
        data-test="lead-status"
        onClick={() => handleHeaderClick('status')}
      />
      <ContactColumn
        id="name"
        source={{
          id: 'id',
          name: 'name',
          email: 'email',
          hasVerifiedEmail: 'hasVerifiedEmail',
          phone: 'phone',
          hasVerifiedPhone: 'hasVerifiedPhone',
        }}
        sortable
        title={
          <PremiumTitle
            shouldTeasePremium={shouldTeasePremium}
            isPremiumSubscription={isPremiumSubscription}
            placement={TOOLTIP_MODAL_PLACEMENT.DOWN}
            tooltipFeatureTag="unlock_premium_tooltip_qualification"
          >
            <FormattedMessageWithValidation id="enqpage_table_contact" />
          </PremiumTitle>
        }
        data-test="lead-contact"
        eligibleLeadsForLeadPlus={leadsWithLeadPlus}
        eligibleLeadsForQualification={leadsWithQualification}
        shouldTeasePremium={shouldTeasePremium}
        onClick={() => handleHeaderClick('name')}
      />
      <AdFieldsColumn
        id="properties"
        title={<FormattedMessageWithValidation id="enqpage_table_property" />}
        renderField={({ operationType, title, address, image }) => (
          <PropertyInterestedInSnippet
            operationType={operationType}
            title={title}
            address={address}
            image={image}
          />
        )}
        renderAccumulatedField={({ total }) => (
          <AccumulatedPropertiesSnippet total={total} />
        )}
      />
      <AdFieldsColumn
        id="enquiryCount"
        title={<FormattedMessageWithValidation id="enqpage_table_enquiry" />}
        renderField={renderEnquiryCount}
        renderAccumulatedField={renderEnquiryCount}
        alignment={TABLE_ALIGNMENT.CENTER}
      />
      <LastActivityColumn
        id="lastActivity"
        source={{
          date: 'lastActivity',
          type: 'lastActivityType',
          callStatus: 'lastActivityCallStatus',
          callDurationInSeconds: 'lastActivityCallDurationInSeconds',
          isFromPersonalShopper: 'isFromPersonalShopper',
        }}
        sortable
        title={<FormattedMessageWithValidation id="enqpage_table_date" />}
        onClick={() => handleHeaderClick('last-activity')}
      />
      <UnreadColumn
        id="unread"
        source={{
          isUnread: 'isUnread',
        }}
      />
    </DataTable>
  );
}

function AgencyLeadsTable() {
  const handleFilterClick = (filterName, payload) => {
    dispatch({
      id: TRACK_CLICK,
      payload: {
        eventName: `filter-enquiries-by-${filterName}`,
        sectionName: 'enquiries-list-filters-agency',
        payload,
      },
    });
  };

  return (
    <>
      <Filters>
        <LeadsFilter
          onSelect={() => {
            handleFilterClick('lead');
          }}
        />
        <LeadsPropertyFilter
          onSelect={() => {
            handleFilterClick('property');
          }}
        />
        <StageFilters
          onSelect={(payload) => {
            handleFilterClick('stage', payload);
          }}
        />
        <DateFilters
          onSelect={(payload) => {
            handleFilterClick('date', payload);
          }}
        />
        <ClearFiltersButton />
      </Filters>
      <LeadsStats />
      <NewTable />
    </>
  );
}

function renderEnquiryCount({ enquiriesCount }) {
  return <EnquiriesCount>{enquiriesCount}</EnquiriesCount>;
}

export default AgencyLeadsTable;
