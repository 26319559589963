import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import qs from 'query-string';
import { ACTIVITY_PERIOD_LAST_3_MONTHS } from './constants';
import { convertToISOStringDate } from '../../../../../../utils/dates';
import { environment } from '../../../../../../coeffects/environment';
import { calendar } from '../../../../../../coeffects/calendar';
import { tracking } from '../../../../../../effects/tracking';

export const REQUEST_LEAD_PLUS_ACTIVITY = 'REQUEST_LEAD_PLUS_ACTIVITY';
export const LEAD_PLUS_ACTIVITY_LOADED = 'LEAD_PLUS_ACTIVITY_LOADED';
export const CHANGE_SELECTED_OPERATION_TYPE = 'CHANGE_SELECTED_OPERATION_TYPE';
export const LEAD_PLUS_VIEWED = 'LEAD_PLUS_VIEWED';
export const LEAD_PLUS_FILTER_ACTIVITY_CLICK =
  'LEAD_PLUS_FILTER_ACTIVITY_CLICK';

function buildQueryString(now, period) {
  const threeMonthsAgo = now;
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  const query = {
    sinceDay:
      period === ACTIVITY_PERIOD_LAST_3_MONTHS
        ? convertToISOStringDate(threeMonthsAgo)
        : null,
  };
  const queryString = qs.stringify(query, { skipNull: true });
  return queryString ? `?${queryString}` : '';
}

registerEventHandler(
  REQUEST_LEAD_PLUS_ACTIVITY,
  (
    { environment: { apiUrl }, calendar: { now }, state: { leadId } },
    { period }
  ) => {
    const queryString = buildQueryString(now, period);
    return {
      ...state.set({ 'lead:activityPeriod': period }),
      ...http.get({
        url: `${apiUrl}/lead-plus/${leadId}${queryString}`,
        successEvent: LEAD_PLUS_ACTIVITY_LOADED,
      }),
    };
  },
  [environment(), calendar(), state.get({ leadId: 'lead.id' })]
);

registerEventHandler(LEAD_PLUS_ACTIVITY_LOADED, (_, [{ data }]) =>
  state.set({ leadPlus: data })
);

registerEventHandler(CHANGE_SELECTED_OPERATION_TYPE, (_, { operationType }) =>
  state.set({ 'leadPlus:selectedOperation': operationType })
);

registerEventHandler(LEAD_PLUS_VIEWED, (_, { sectionName, payload }) =>
  tracking.track('LeadPlusViewed', sectionName, payload)
);

registerEventHandler(
  LEAD_PLUS_FILTER_ACTIVITY_CLICK,
  (_, { sectionName, payload }) =>
    tracking.track('LeadPlusFilterActivity', sectionName, payload)
);
