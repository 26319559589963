import { isMexico } from '../../utils/countries';
import { normalizeTypeOfClient } from './normalizeTypeOfClient';
import { MEXICO_TYPE_OF_CLIENT } from '../pages/CreatePublisher/constants';

export function filterPlansByTypeOfClient(
  countryCode,
  selectedTypeOfClient,
  plans
) {
  if (!isMexico(countryCode)) {
    return plans;
  }

  if (!selectedTypeOfClient) {
    return [];
  }

  return filterMexicanPlansByTypeOfClient(selectedTypeOfClient, plans);
}

export function findMexicanPlanByTypeOfClientAndMaxBoostableProperties(
  plans,
  typeOfClient,
  maxBoostableProperties
) {
  return filterMexicanPlansByTypeOfClient(typeOfClient, plans).find(
    ({ maxBoostableProperties: planMaxBoostableProperties }) =>
      planMaxBoostableProperties === maxBoostableProperties
  );
}

function filterMexicanPlansByTypeOfClient(selectedTypeOfClient, plans) {
  const normalizedSelectedTypeOfClient =
    normalizeTypeOfClient(selectedTypeOfClient);

  const isPremiumClient =
    selectedTypeOfClient === MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM ||
    selectedTypeOfClient === MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM;
  return plans.filter(
    ({ typeOfClient, isPremium }) =>
      typeOfClient === normalizedSelectedTypeOfClient &&
      isPremium === isPremiumClient
  );
}
