import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useWindowSize } from 'react-use';
import getAbsolutePosition from 'design-system/utils/getAbsolutePosition';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import ArrowLeft from 'design-system/components/TooltipModal/ArrowLeft';
import ArrowUp from 'design-system/components/TooltipModal/ArrowUp';
import Portal from '../Portal';
import { bindClassname } from '../../utils';

export const TOOLTIP_MODAL_PLACEMENT = {
  DOWN_RIGHT: Symbol('TOOLTIP_PLACEMENT_DOWN_RIGHT'),
  DOWN: Symbol('TOOLTIP_PLACEMENT_DOWN'),
  RIGHT: Symbol('TOOLTIP_PLACEMENT_RIGHT'),
};
const ARROW_WIDTH = 10;
const ARROW_HEIGHT = 16;
const MARGIN_OFFSET_IN_PX = 4;

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const modPlacementDownRight = () => css`
  top: ${({
    $wrapperPosition: { top },
    $childrenSize: { height: childrenSize },
  }) => top + childrenSize}px;
  left: ${({ $wrapperPosition, $childrenSize }) =>
    verticalPlacementLeftPosition($wrapperPosition, $childrenSize)}px;
`;

const modPlacementDownCentered = () => css`
  top: ${({
    $wrapperPosition: { top },
    $childrenSize: { height: childrenSize },
  }) => top + childrenSize}px;
  left: ${({ $wrapperPosition, $modalSize }) =>
    verticalPlacementLeftPosition($wrapperPosition, $modalSize)}px;
`;

const modPlacementRight = () => css`
  top: ${({ $wrapperPosition, $childrenSize }) =>
    horizontalPlacementTopPosition($wrapperPosition, $childrenSize)}px;
  left: ${({ $wrapperPosition: { right } }) => right}px;
`;

const modPlacement = (placement) =>
  ({
    [TOOLTIP_MODAL_PLACEMENT.RIGHT]: modPlacementRight,
    [TOOLTIP_MODAL_PLACEMENT.DOWN_RIGHT]: modPlacementDownRight,
    [TOOLTIP_MODAL_PLACEMENT.DOWN]: modPlacementDownCentered,
  }[placement]);

const PopoverWrapper = styled.div`
    visibility: ${({ $shown }) => ($shown ? 'visible' : 'hidden')};
    z-index: ${({ $zIndex = 3 }) => $zIndex};
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    max-width: 320px;

    flex-direction: column;
    ${spacing.insetSandwich(SPACING_SIZE.XS)}

    ${({ $placement }) =>
      $placement === TOOLTIP_MODAL_PLACEMENT.RIGHT &&
      css`
        flex-direction: row;
        padding: 0 ${spacing.value(SPACING_SIZE.XS)};
      `}
}

${({ $placement }) => modPlacement($placement)}
`;

const Popover = styled.div`
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${color.background(COLOR_PALETTE.PRIMARY_A120)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
    ${spacing.inset(SPACING_SIZE.M)}
`;

const ArrowLeftWrapper = styled.div`
  margin-top: ${({ $elementSize }) => arrowMarginTop($elementSize)}px;
`;

const ArrowTopWrapper = styled.span`
  margin-left: ${({ $elementSize }) => arrowMarginLeft($elementSize)}px;
  line-height: 0;
`;

function arrowMarginTop({ height }) {
  return height / 2 - ARROW_HEIGHT / 2 + MARGIN_OFFSET_IN_PX;
}

function arrowMarginLeft({ width }) {
  return width / 2 - ARROW_WIDTH / 2;
}

function Arrow({ childrenSize, modalSize, placement }) {
  if (placement === TOOLTIP_MODAL_PLACEMENT.RIGHT) {
    return (
      <ArrowLeftWrapper $elementSize={childrenSize}>
        <ArrowLeft />
      </ArrowLeftWrapper>
    );
  }

  return (
    <ArrowTopWrapper
      $elementSize={
        placement === TOOLTIP_MODAL_PLACEMENT.DOWN ? modalSize : childrenSize
      }
    >
      <ArrowUp />
    </ArrowTopWrapper>
  );
}

function TooltipModal({
  children,
  popoverComponent = <></>,
  $zIndex,
  $placement = TOOLTIP_MODAL_PLACEMENT.RIGHT,
}) {
  const [hovered, setHovered] = useState(false);
  const { width, height } = useWindowSize();
  const modalRef = useRef();
  const wrapperRef = useRef();
  const childrenRef = useRef();
  const wrapperPosition = useWrapperPosition(wrapperRef, hovered);
  const modalSize = useElementSize(modalRef);
  const childrenSize = useElementSize(childrenRef);

  useEffect(() => {
    if (hovered) {
      setHovered(false);
    }
  }, [width, height]);

  return (
    <Wrapper
      ref={wrapperRef}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
    >
      <div ref={childrenRef}>{children}</div>
      <Portal>
        <PopoverWrapper
          $shown={hovered && wrapperPosition != null}
          $wrapperPosition={wrapperPosition ?? {}}
          $zIndex={$zIndex}
          $placement={$placement}
          $modalSize={modalSize}
          $childrenSize={childrenSize}
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
        >
          <Arrow
            placement={$placement}
            modalSize={modalSize}
            childrenSize={childrenSize}
          />
          <Popover>{popoverComponent}</Popover>
        </PopoverWrapper>
      </Portal>
    </Wrapper>
  );
}

function useElementSize(elementRef) {
  const [elementSize, setElementSize] = useState({});
  useEffect(() => {
    if (elementRef.current) {
      setElementSize({
        width: elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      });
    }
  }, [elementRef]);
  return elementSize;
}

function useWrapperPosition(wrapperRef, hovered) {
  const [position, setPosition] = useState();

  useEffect(() => {
    if (wrapperRef.current && hovered) {
      setPosition(getAbsolutePosition(wrapperRef.current));
    }
  }, [hovered]);

  return position;
}

function verticalPlacementLeftPosition(
  { left, width },
  { width: elementWidth }
) {
  return left + width / 2 - elementWidth / 2;
}

function horizontalPlacementTopPosition(
  { top, height },
  { height: elementHeight }
) {
  return top + height / 2 - elementHeight / 2 - MARGIN_OFFSET_IN_PX;
}

export default bindClassname(TooltipModal, Wrapper);
