import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { dispatch } from 'reffects';
import Anchor from 'design-system/components/Anchor';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { OutlineButton } from 'design-system/components/Button/presets';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { SUBSCRIPTION_BENEFITS } from '../../../../constants/routes';
import { OPEN_DIALOG } from '../../../../events/dialogs';
import { SUBSCRIPTION_DETAILS_DIALOG } from '../SubscriptionDetailsModal';
import CustomFormattedDate from '../../../../partials/FormattedDate/CustomFormattedDate';
import { RequestAutoRenewalCancellationModal } from './RequestAutoRenewalCancellationModal';
import { AutoRenewalCancellationRequestedModal } from './AutoRenewalCancellationRequestedModal';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${color.background(COLOR_PALETTE.PRIMARY_A05)}
  ${radius.regular(RADIUS_SIZE.BASE)}
  ${spacing.inset(SPACING_SIZE.M)}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.value(SPACING_SIZE.L)};
  ${Anchor} {
    align-self: baseline;
  }
  ${OutlineButton} {
    align-self: baseline;
    ${color.background(COLOR_PALETTE.NONE)}
  }
`;

const SubscriptionSummary = styled.div`
  display: flex;
  flex-direction: column;
  ${Anchor} {
    text-underline-offset: ${spacing.value(SPACING_SIZE.XS)};
  }
  ${Heading1} {
    ${color.text(COLOR_PALETTE.BLUEBERRY_BASE)}
    &:first-of-type {
      ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
    }

    &:last-of-type {
      ${typography.weight(FONT_WEIGHT.NORMAL)}
    }
  }
`;

const Header = styled.div`
  ${spacing.stack(SPACING_SIZE.S)}
`;

export default function CurrentPlanBanner({
  subscription,
  showCancelAutoRenewal,
}) {
  if (!subscription) return null;
  return (
    <Wrapper>
      <Header>
        <Subtitle3 $color={COLOR_PALETTE.NEUTRAL_A60}>
          <FormattedMessageWithValidation id="subscription_current_plan" />
        </Subtitle3>
      </Header>
      <Content>
        <SubscriptionSummary>
          <Anchor as={Link} to={SUBSCRIPTION_BENEFITS}>
            <Heading1>
              <FormattedMessageWithValidation id={subscription.nameTag} />
            </Heading1>
            <Heading1>
              {' '}
              {subscription.maxBoostableProperties}{' '}
              <FormattedMessageWithValidation id="subscription_boosted_properties" />
            </Heading1>
          </Anchor>
          <Body1 $weight={FONT_WEIGHT.LIGHT}>
            <FormattedMessageWithValidation
              id="subscription_summary_contract_type_value_with_expiration"
              values={{
                duration: subscription.billingCycleInMonths,
                nextBillingDate: (
                  <CustomFormattedDate
                    value={new Date(subscription.nextBillingDate)}
                    timeZone="utc"
                  />
                ),
              }}
            />
          </Body1>
        </SubscriptionSummary>
        <Anchor
          href="#"
          onClick={() =>
            dispatch({
              id: OPEN_DIALOG,
              payload: {
                id: SUBSCRIPTION_DETAILS_DIALOG,
                parameters: {
                  title: 'subscription_current_details',
                  subscription,
                  showCancelAutoRenewal,
                },
              },
            })
          }
        >
          <FormattedMessageWithValidation id="subscription_view_details" />
        </Anchor>
      </Content>
      <RequestAutoRenewalCancellationModal />
      <AutoRenewalCancellationRequestedModal />
    </Wrapper>
  );
}
