const FIELDS_WITH_MAIN_IMAGE = ['propertyImages'];

export function fieldHasMainImage(fieldName) {
  return FIELDS_WITH_MAIN_IMAGE.includes(fieldName);
}

export function fieldMaxNumberOfImages(fieldName) {
  if (fieldName === 'propertyImages') {
    return 200;
  }
  if (fieldName === 'floorPlans') {
    return 10;
  }
  return null;
}
