import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import Price from '../../../Price/Price';
import Offer from '../../partials/Offer';
import OfferContent from '../../partials/OfferContent';
import OfferHeading from '../../partials/OfferHeading';
import OfferPrice from '../../partials/OfferPrice';
import OfferTitle from '../../partials/OfferTitle';
import OfferSubtitle from '../../partials/OfferSubtitle';
import OfferDetails from '../../partials/OfferDetails';

const Prices = styled.div`
  display: flex;
  flex-direction: column;
`;

const OriginalPrice = styled(Body2)`
  ${typography.weight(FONT_WEIGHT.NORMAL)};
  text-decoration: line-through;
`;

const OfferDiscount = styled(Body2)`
  ${color.text(COLOR_PALETTE.PRIMARY_A120)};
  ${typography.weight(FONT_WEIGHT.LIGHT)};
`;

const Action = styled.div`
  padding-top: ${spacing.value(SPACING_SIZE.L)};
`;

export default function OffersFreeToPaidCardDesktop({ offerData, onClick }) {
  const getOfferDuration = () => {
    if (offerData.billingCycleInMonths === 1) {
      return 'subscription_landing_plan_duration_monthly';
    }
    if (offerData.billingCycleInMonths === 3) {
      return 'subscription_landing_plan3_duration';
    }
    return 'subscription_landing_plan_duration_semestral';
  };

  return (
    <Offer>
      <OfferContent>
        <OfferHeading>
          <OfferTitle>
            <FormattedMessageWithValidation id="overview_free_to_paid_modal_offer_title" />
          </OfferTitle>
          <OfferSubtitle>
            {offerData.maxBoostableProperties > 1 && (
              <FormattedMessageWithValidation
                id="overview_free_to_paid_modal_offer_plan_base"
                values={{
                  amount: offerData.maxBoostableProperties,
                }}
              />
            )}
            {offerData.maxBoostableProperties === 1 && (
              <FormattedMessageWithValidation
                id="overview_free_to_paid_modal_offer_plan_base_singular"
                values={{
                  amount: offerData.maxBoostableProperties,
                }}
              />
            )}
            {offerData.maxSuperboostableProperties > 1 && (
              <FormattedMessageWithValidation
                id="overview_free_to_paid_modal_offer_plan_superboost"
                values={{
                  amount: offerData.maxSuperboostableProperties,
                }}
              />
            )}
            {offerData.maxSuperboostableProperties === 1 && (
              <FormattedMessageWithValidation
                id="overview_free_to_paid_modal_offer_plan_superboost_singular"
                values={{
                  amount: offerData.maxSuperboostableProperties,
                }}
              />
            )}
          </OfferSubtitle>
        </OfferHeading>
        <OfferDetails>
          <Prices>
            <OriginalPrice>
              {offerData.totalDiscountPercentage ? (
                <Price
                  amount={offerData.totalPriceVatExcluded}
                  currency={offerData.currency}
                />
              ) : (
                <br />
              )}
            </OriginalPrice>
            <OfferPrice>
              <Price
                amount={offerData.totalDiscountedPriceVatExcluded}
                currency={offerData.currency}
              />
              <span>*</span>
            </OfferPrice>
            <OfferDiscount>
              {offerData.totalDiscountPercentage ? (
                <FormattedMessageWithValidation
                  id="subscription_landing_plan_discount"
                  values={{
                    discount: offerData.totalDiscountPercentage,
                  }}
                />
              ) : (
                <br />
              )}
            </OfferDiscount>
          </Prices>
          <Body1>
            <FormattedMessageWithValidation id={getOfferDuration()} />
          </Body1>
          <Body2>
            <FormattedMessageWithValidation id="subscription_landing_plan_automatic_renewal" />
          </Body2>
          <Body2>
            <FormattedMessageWithValidation id="subscription_landing_plan_vat_notice" />
          </Body2>
        </OfferDetails>
      </OfferContent>
      <Action>
        <PrimaryButton onClick={onClick}>
          <FormattedMessageWithValidation id="subscription_landing_plan_cta" />
        </PrimaryButton>
      </Action>
    </Offer>
  );
}
