import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { border } from 'design-system/styles/border';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import DropZone from '../../../../../../partials/DropZone';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import FileInput from '../../../../../../partials/FileInput';

const DropDownWrapper = styled(DropZone)`
  ${spacing.inset(SPACING_SIZE.L)}
  ${border.outer({ size: 1, color: COLOR_PALETTE.SECONDARY_A80 })}
  border-style: dashed;
  ${radius.regular(RADIUS_SIZE.BASE)}
  cursor: pointer;

  &:hover {
    ${color.background(COLOR_PALETTE.SECONDARY_A05)}
    ${border.outer({ size: 1, color: COLOR_PALETTE.SECONDARY_BASE })}
        border-style: dashed;
  }

  ${Body1} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
    margin-bottom: ${SPACING_SIZE.XS}px;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

const HelperText = styled(Body2)`
  display: block;
  margin-top: ${spacing.value(SPACING_SIZE.S)};
  ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
`;

const ErrorText = styled(Body2)`
  display: block;
  margin-top: ${spacing.value(SPACING_SIZE.S)};
  ${color.text(COLOR_PALETTE.STRAWBERRY_BASE)}
`;

export default function Uploader({
  id,
  isDisabled,
  helperText,
  errorText,
  onImagesUploaded,
}) {
  return (
    <div>
      <DropDownWrapper
        onDrop={(e) => {
          if (!isDisabled) onImagesUploaded(e);
        }}
      >
        <FileInput
          id={id}
          onUpload={onImagesUploaded}
          multiple
          accept=".jpg, .jpeg, .png"
          disabled={isDisabled}
        >
          <Block>
            <Icon
              glyph="photoLibrary"
              $color={COLOR_PALETTE.NEUTRAL_A20}
              $size={ICON_SIZE.XLARGE}
            />
            <Body1>
              <FormattedMessageWithValidation id="newprop_pics_drag_drop" />
            </Body1>
          </Block>
        </FileInput>
      </DropDownWrapper>
      <HelperText>{helperText}</HelperText>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </div>
  );
}
