import styled, { css } from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { dispatch } from 'reffects';
import useTracking from '../../../../../../hooks/useTracking';
import OffersFreeToPaidCardDesktop from '../../../../../../partials/Offers/OffersCard/OffersCardDesktop/OffersFreeToPaidCardDesktop';
import { OFFER_SELECTED_NEW_TRACKING } from '../../../../../Offers/events';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.L)};
`;

const Cards = styled.div`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.L)};
  ${({ hasMoreThanOneOffer }) =>
    !hasMoreThanOneOffer &&
    css`
      margin: auto;
    `}
`;

const Wrapper = styled.div`
  display: flex;
  margin-top: ${spacing.value(SPACING_SIZE.XL)};
`;

export default function FreeToPaidOffersModalContent({
  offers,
  section,
  offerType,
}) {
  const trackImpression = useTracking({
    track: {
      event: 'OffersViewed',
      section,
      payload: { offerType },
    },
  });

  const handleOfferClick = (offerId) =>
    dispatch({
      id: OFFER_SELECTED_NEW_TRACKING,
      payload: {
        offerId,
        trackingSection: section,
        offerType,
      },
    });
  const hasMoreThanOneOffer = offers?.length > 1;

  return (
    <Wrapper>
      <Section>
        <Cards ref={trackImpression} hasMoreThanOneOffer={hasMoreThanOneOffer}>
          {offers?.map((offer) => (
            <OffersFreeToPaidCardDesktop
              key={offer.id}
              offerData={offer}
              onClick={() => handleOfferClick(offer.id)}
            />
          ))}
        </Cards>
      </Section>
    </Wrapper>
  );
}
