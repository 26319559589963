import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { isEqual } from 'lodash';
import { environment } from '../../coeffects/environment';
import { tracking } from '../../effects/tracking';

export const DEFAULT_CONTACT_REQUESTED = 'DEFAULT_CONTACT_REQUESTED';
export const DEFAULT_CONTACT_LOADED = 'DEFAULT_CONTACT_LOADED';
export const USABLE_AREA_CHANGED = 'USABLE_AREA_CHANGED';
export const FLOOR_AREA_CHANGED = 'FLOOR_AREA_CHANGED';
export const PLOT_AREA_CHANGED = 'PLOT_AREA_CHANGED';
export const PLOT_AREA_UNIT_CHANGED = 'PLOT_AREA_UNIT_CHANGED';
export const FIELD_VALUE_CHANGED = 'FIELD_VALUE_CHANGED';
export const PRICE_AMOUNT_CHANGED = 'PRICE_AMOUNT_CHANGED';
export const PRICE_CURRENCY_CHANGED = 'PRICE_CURRENCY_CHANGED';
export const TOGGLE_BANK_PROPERTY = 'TOGGLE_BANK_PROPERTY';
export const REQUEST_MARKET_PRICE = 'REQUEST_MARKET_PRICE';
export const DO_REQUEST_MARKET_PRICE = 'DO_REQUEST_MARKET_PRICE';
export const MARKET_PRICE_LOADED = 'MARKET_PRICE_LOADED';
export const MARKET_PRICE_FAILED = 'MARKET_PRICE_FAILED';
export const TIME_PASSED_TO_RETRY_MARKET_PRICE_REQUEST =
  'TIME_PASSED_TO_RETRY_MARKET_PRICE_REQUEST';

registerEventHandler(
  DEFAULT_CONTACT_REQUESTED,
  ({ environment: { apiUrl } }) => ({
    ...state.set({
      'adForm:isInitialized': false,
    }),
    ...http.get({
      url: `${apiUrl}/users/me/default-contact`,
      successEvent: DEFAULT_CONTACT_LOADED,
    }),
  }),
  [environment()]
);

registerEventHandler(
  DEFAULT_CONTACT_LOADED,
  ({ state: { whatsappCallingCode } }, [{ data }]) =>
    state.set({
      'adForm:isInitialized': true,
      'adForm:defaultContact': {
        contactEmails: data.contactEmails,
        contactPhone: data.contactPhone,
        contactFacebookMessenger: data.contactFacebookMessenger,
        contactLine: data.contactLine,
        contactViber: data.contactViber,
        contactWhatsApp: data.contactWhatsApp
          ? data.contactWhatsApp.replace(whatsappCallingCode, '')
          : undefined,
      },
    }),
  [
    state.get({
      whatsappCallingCode: 'countryConfig.phoneNumber.callingCode',
    }),
  ]
);

registerEventHandler(FIELD_VALUE_CHANGED, (_, { field, value }) => ({
  ...state.set({ [`adForm:ad.${field}`]: value }),
  ...effects.dispatch(REQUEST_MARKET_PRICE),
}));

registerEventHandler(USABLE_AREA_CHANGED, (_, { value, unit }) => ({
  ...state.set({
    'adForm:ad.usableArea': value,
    'adForm:ad.usableAreaUnit': unit,
  }),
  ...effects.dispatch(REQUEST_MARKET_PRICE),
}));

registerEventHandler(FLOOR_AREA_CHANGED, (_, { value, unit }) => ({
  ...state.set({
    'adForm:ad.floorArea': value,
    'adForm:ad.floorAreaUnit': unit,
  }),
  ...effects.dispatch(REQUEST_MARKET_PRICE),
}));

registerEventHandler(PLOT_AREA_CHANGED, (_, { value, unit }) => {
  let mutations = {};
  if (unit === 'sqm') {
    mutations = {
      'adForm:ad.plotAreaUnit': unit,
      'adForm:ad.plotAreaSqm': value,
      'adForm:ad.plotAreaSf': undefined,
      'adForm:ad.plotAreaRai': undefined,
      'adForm:ad.plotAreaNgan': undefined,
      'adForm:ad.plotAreaSqw': undefined,
    };
  } else if (unit === 'sf') {
    mutations = {
      'adForm:ad.plotAreaUnit': unit,
      'adForm:ad.plotAreaSqm': undefined,
      'adForm:ad.plotAreaSf': value,
      'adForm:ad.plotAreaRai': undefined,
      'adForm:ad.plotAreaNgan': undefined,
      'adForm:ad.plotAreaSqw': undefined,
    };
  } else if (unit === 'rai' || unit === 'ngan' || unit === 'sqwa') {
    const stateRegion = {
      rai: 'adForm:ad.plotAreaRai',
      ngan: 'adForm:ad.plotAreaNgan',
      sqwa: 'adForm:ad.plotAreaSqw',
    }[unit];
    mutations = {
      'adForm:ad.plotAreaUnit': 'rai/ngan/sqw',
      'adForm:ad.plotAreaSqm': undefined,
      'adForm:ad.plotAreaSf': undefined,
      [stateRegion]: value,
    };
  }
  return {
    ...state.set(mutations),
    ...effects.dispatch(REQUEST_MARKET_PRICE),
  };
});

registerEventHandler(PLOT_AREA_UNIT_CHANGED, (_, { unit }) => ({
  ...state.set({
    'adForm:ad.plotAreaUnit': unit,
    'adForm:ad.plotAreaSqm': undefined,
    'adForm:ad.plotAreaSf': undefined,
    'adForm:ad.plotAreaRai': undefined,
    'adForm:ad.plotAreaNgan': undefined,
    'adForm:ad.plotAreaSqw': undefined,
  }),
  ...effects.dispatch(REQUEST_MARKET_PRICE),
}));

registerEventHandler(
  PRICE_AMOUNT_CHANGED,
  ({ state: { marketPrice } }, { operationType, amount }) => {
    if (amount == null || amount <= 0) {
      return {
        ...state.set({
          [`adForm:ad.${operationType}Price`]: amount,
          'adForm:marketPrice': undefined,
          'adForm:marketPriceLoadingStatus': undefined,
        }),
      };
    }
    return {
      ...state.set({ [`adForm:ad.${operationType}Price`]: amount }),
      ...(marketPrice
        ? {}
        : effects.dispatch({
            id: REQUEST_MARKET_PRICE,
            payload: { delayed: false },
          })),
    };
  },
  [state.get({ marketPrice: 'adForm:marketPrice' })]
);

registerEventHandler(
  PRICE_CURRENCY_CHANGED,
  (_, { operationType, currency }) => ({
    ...state.set({ [`adForm:ad.${operationType}PriceCurrency`]: currency }),
    ...effects.dispatch({
      id: REQUEST_MARKET_PRICE,
      payload: { delayed: false },
    }),
  })
);

registerEventHandler(TOGGLE_BANK_PROPERTY, (_, { value }) => ({
  ...state.set({ 'adForm:ad.bankProperty': value }),
  ...effects.dispatch(REQUEST_MARKET_PRICE),
}));

registerEventHandler(
  REQUEST_MARKET_PRICE,
  (
    { state: { hasPremiumSubscription, ...adData } },
    { delayed = true } = { delayed: true }
  ) => {
    if (!hasPremiumSubscription) {
      return {};
    }
    const operations = createOperations(adData);
    if (operations == null) {
      return {};
    }
    const request = createBodyForMarketPriceRequest({ ...adData, operations });
    return effects.dispatchLater({
      id: DO_REQUEST_MARKET_PRICE,
      payload: { ...request, missingData: request == null },
      milliseconds: delayed ? 1500 : 0,
    });
  },
  [
    state.get({
      hasPremiumSubscription: 'publisher.subscription.isPremium',
      adId: 'adForm:ad.id',
      propertyType: 'adForm:ad.propertyType',
      operationType: 'adForm:ad.operationType',
      sellPrice: 'adForm:ad.sellPrice',
      sellPriceCurrency: 'adForm:ad.sellPriceCurrency',
      rentPrice: 'adForm:ad.rentPrice',
      rentPriceCurrency: 'adForm:ad.rentPriceCurrency',
      usableArea: 'adForm:ad.usableArea',
      usableAreaUnit: 'adForm:ad.usableAreaUnit',
      floorArea: 'adForm:ad.floorArea',
      floorAreaUnit: 'adForm:ad.floorAreaUnit',
      plotAreaUnit: 'adForm:ad.plotAreaUnit',
      plotAreaSqm: 'adForm:ad.plotAreaSqm',
      plotAreaSf: 'adForm:ad.plotAreaSf',
      plotAreaRai: 'adForm:ad.plotAreaRai',
      plotAreaNgan: 'adForm:ad.plotAreaNgan',
      plotAreaSqw: 'adForm:ad.plotAreaSqw',
      bedrooms: 'adForm:ad.bedrooms',
      bathrooms: 'adForm:ad.bathrooms',
      toilets: 'adForm:ad.toilets',
      furnished: 'adForm:ad.furnished',
      amenities: 'adForm:ad.amenities',
      locationVisibility: 'adForm:ad.locationVisibility',
      coordinates: 'adForm:ad.coordinates',
      geoLevels: 'adForm:ad.geoLevels',
      bankProperty: 'adForm:ad.bankProperty',
    }),
  ]
);

registerEventHandler(
  DO_REQUEST_MARKET_PRICE,
  (
    {
      environment: { apiUrl },
      state: { previousErrorReason, lastRequest, ...adData },
    },
    passedRequest
  ) => {
    const operations = createOperations(adData);
    const currentRequest = createBodyForMarketPriceRequest({
      ...adData,
      operations,
    });
    if (passedRequest?.missingData && previousErrorReason !== 'MissingAdData') {
      return {
        ...state.set({
          'adForm:marketPriceLoadingStatus': 'error',
          'adForm:marketPriceErrorReason': 'MissingAdData',
          'adForm:marketPrice': undefined,
        }),
        ...tracking.track('MarketPriceNotAvailable', adData.section, {
          adId: adData.adId,
          reason: 'MissingAdData',
        }),
      };
    }
    if (passedRequest != null) {
      const { missingData, ...request } = passedRequest;
      if (
        !isEqual(currentRequest, request) ||
        isEqual(currentRequest, lastRequest)
      ) {
        return {};
      }
    }
    return {
      ...http.post({
        url: `${apiUrl}/market-price`,
        body: { adData: currentRequest },
        successEvent: { id: MARKET_PRICE_LOADED, payload: currentRequest },
        errorEvent: {
          id: MARKET_PRICE_FAILED,
          payload: {
            adId: adData.adId,
            section: adData.section,
          },
        },
      }),
      ...state.set({
        'adForm:marketPriceLastRequest': currentRequest,
        'adForm:marketPriceLoadingStatus': 'thinking',
        'adForm:marketPriceErrorReason': undefined,
      }),
    };
  },
  [
    environment(),
    state.get({
      adId: 'adForm:ad.id',
      propertyType: 'adForm:ad.propertyType',
      operationType: 'adForm:ad.operationType',
      sellPrice: 'adForm:ad.sellPrice',
      sellPriceCurrency: 'adForm:ad.sellPriceCurrency',
      rentPrice: 'adForm:ad.rentPrice',
      rentPriceCurrency: 'adForm:ad.rentPriceCurrency',
      usableArea: 'adForm:ad.usableArea',
      usableAreaUnit: 'adForm:ad.usableAreaUnit',
      floorArea: 'adForm:ad.floorArea',
      floorAreaUnit: 'adForm:ad.floorAreaUnit',
      plotAreaUnit: 'adForm:ad.plotAreaUnit',
      plotAreaSqm: 'adForm:ad.plotAreaSqm',
      plotAreaSf: 'adForm:ad.plotAreaSf',
      plotAreaRai: 'adForm:ad.plotAreaRai',
      plotAreaNgan: 'adForm:ad.plotAreaNgan',
      plotAreaSqw: 'adForm:ad.plotAreaSqw',
      bedrooms: 'adForm:ad.bedrooms',
      bathrooms: 'adForm:ad.bathrooms',
      toilets: 'adForm:ad.toilets',
      furnished: 'adForm:ad.furnished',
      amenities: 'adForm:ad.amenities',
      locationVisibility: 'adForm:ad.locationVisibility',
      coordinates: 'adForm:ad.coordinates',
      geoLevels: 'adForm:ad.geoLevels',
      bankProperty: 'adForm:ad.bankProperty',
      section: 'adForm:form',
      previousErrorReason: 'adForm:marketPriceErrorReason',
      lastRequest: 'adForm:marketPriceLastRequest',
    }),
  ]
);

registerEventHandler(
  MARKET_PRICE_LOADED,
  ({ state: { adId, section } }, [{ data }, { operations }]) => ({
    ...state.set({
      'adForm:marketPrice': data,
      'adForm:marketPriceLoadingStatus': 'thinking-finish',
      'adForm:marketPriceErrorRetries': 0,
      'adForm:marketPriceErrorRetryDelay': undefined,
      'adForm:marketPriceErrorReason': undefined,
      'adForm:marketPriceLastRequest': undefined,
    }),
    ...tracking.track('MarketPriceRecommended', section, {
      adId,
      operations: operations.map(({ type, price: inputPrice }) => ({
        type,
        inputPrice,
        recommendedPrice: data.operations[type].suggestedPrice,
      })),
    }),
  }),
  [state.get({ adId: 'adForm:ad.id', section: 'adForm:form' })]
);

registerEventHandler(
  MARKET_PRICE_FAILED,
  ({ state: { retries, seconds } }, [{ status, data }, { adId, section }]) => {
    const RETRY_DELAYS = [10, 20, 40, 60];
    const retryDelay =
      RETRY_DELAYS[Math.min(retries || 0, RETRY_DELAYS.length - 1)];

    if (seconds > 0) {
      return {};
    }

    const errorReason = data?.error;
    let errorTracking = {};
    if (status === 500) {
      errorTracking = tracking.track('MarketPriceNotAvailable', section, {
        adId,
        reason: errorReason,
      });
    }
    const isRetryable = !['MissingAdData', 'DataNotFound'].includes(
      errorReason
    );
    return {
      ...(isRetryable
        ? effects.dispatchLater({
            id: TIME_PASSED_TO_RETRY_MARKET_PRICE_REQUEST,
            milliseconds: 1000,
          })
        : {}),
      ...state.set({
        'adForm:marketPriceLoadingStatus': 'error',
        'adForm:marketPriceErrorReason': errorReason,
        'adForm:marketPrice': undefined,
        'adForm:marketPriceLastRequest': undefined,
        'adForm:marketPriceErrorRetries': isRetryable
          ? (retries || 0) + 1
          : undefined,
        'adForm:marketPriceErrorRetryDelay': isRetryable
          ? retryDelay
          : undefined,
      }),
      ...errorTracking,
    };
  },
  [
    state.get({
      retries: 'adForm:marketPriceErrorRetries',
      seconds: 'adForm:marketPriceErrorRetryDelay',
    }),
  ]
);

registerEventHandler(
  TIME_PASSED_TO_RETRY_MARKET_PRICE_REQUEST,
  ({ state: { seconds } }) =>
    seconds === 0
      ? {}
      : {
          ...state.set({
            'adForm:marketPriceErrorRetryDelay': seconds - 1,
          }),
          ...effects.dispatchLater({
            id: TIME_PASSED_TO_RETRY_MARKET_PRICE_REQUEST,
            milliseconds: 1000,
          }),
        },
  [state.get({ seconds: 'adForm:marketPriceErrorRetryDelay' })]
);

function createBodyForMarketPriceRequest({
  adId,
  propertyType,
  usableArea,
  usableAreaUnit,
  floorArea,
  floorAreaUnit,
  plotAreaUnit,
  plotAreaSqm,
  plotAreaSf,
  plotAreaRai,
  plotAreaNgan,
  plotAreaSqw,
  bedrooms,
  bathrooms,
  toilets,
  furnished,
  amenities,
  locationVisibility,
  coordinates,
  geoLevels,
  bankProperty,
  operations,
}) {
  const floorAreaObject = createArea({ value: floorArea, unit: floorAreaUnit });
  const request = {
    adId,
    propertyType,
    operations,
    locationVisibility,
    coordinates,
    geoLevels,
    isBankProperty: Boolean(bankProperty),
  };
  const plotArea = createPlotArea({
    plotAreaUnit,
    plotAreaSqm,
    plotAreaSf,
    plotAreaRai,
    plotAreaNgan,
    plotAreaSqw,
  });
  if (
    Object.values(request).some((value) => value == null) ||
    (floorAreaObject == null && plotArea.length === 0)
  ) {
    return undefined;
  }
  return {
    ...request,
    usableArea: createArea({ value: usableArea, unit: usableAreaUnit }),
    floorArea: floorAreaObject,
    plotArea,
    bedrooms,
    bathrooms,
    toilets,
    furnished,
    amenities: amenities ?? [],
  };
}

function createOperations({
  operationType,
  sellPrice,
  sellPriceCurrency,
  rentPrice,
  rentPriceCurrency,
}) {
  const createPrice = ({ amount, currency }) =>
    amount != null && amount > 0 && currency != null
      ? { amount, currency }
      : undefined;
  const operations = [];
  if (operationType === 'sell' || operationType === 'sell-and-rent') {
    const price = createPrice({
      amount: sellPrice,
      currency: sellPriceCurrency,
    });
    if (price != null) {
      operations.push({
        type: 'sell',
        price,
      });
    }
  }
  if (operationType === 'rent' || operationType === 'sell-and-rent') {
    const price = createPrice({
      amount: rentPrice,
      currency: rentPriceCurrency,
    });
    if (price != null) {
      operations.push({
        type: 'rent',
        price,
      });
    }
  }
  if (operations.length === 0) {
    return undefined;
  }
  return operations;
}

function createPlotArea({
  plotAreaUnit,
  plotAreaSqm,
  plotAreaSf,
  plotAreaRai,
  plotAreaNgan,
  plotAreaSqw,
}) {
  if (plotAreaUnit === 'sqm') {
    const area = createArea({ value: plotAreaSqm, unit: plotAreaUnit });
    return area == null ? [] : [area];
  }
  if (plotAreaUnit === 'sf') {
    const area = createArea({ value: plotAreaSf, unit: plotAreaUnit });
    return area == null ? [] : [area];
  }
  if (plotAreaUnit === 'rai/ngan/sqw') {
    const areas = [
      createArea({ value: plotAreaRai, unit: 'rai' }),
      createArea({ value: plotAreaNgan, unit: 'ngan' }),
      createArea({ value: plotAreaSqw, unit: 'sqwa' }),
    ];
    return areas.filter(Boolean);
  }
  return [];
}

function createArea({ value, unit }) {
  return value != null && value > 0 && unit != null
    ? { value, unit }
    : undefined;
}
