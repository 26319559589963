import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import Anchor from 'design-system/components/Anchor';
import Layout from 'design-system/components/Layout';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { subscriptionRoute } from '../../../utils/proppitWebRouter';
import { Navigation } from '../../../partials';
import PublisherData from '../PublisherData/PublisherData';
import { retrieveQueryParams } from '../../../coeffects/queryParamsAll';
import { CHECKOUT_TRACK_PAGE_VIEW } from '../CheckoutPageLoader/events';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Illustration} {
    ${spacing.stack(SPACING_SIZE.L, false)}
  }
`;

export default function CheckoutCancelled({ ...props }) {
  const params = useParams();
  const { queryParamsAll } = retrieveQueryParams(window);

  useMount(() => {
    const { pageViewName, pageViewPayloadSelector } = props;
    dispatch({
      id: CHECKOUT_TRACK_PAGE_VIEW,
      payload: {
        pageViewName,
        pageViewPayload: pageViewPayloadSelector({
          ...props,
          ...params,
          ...queryParamsAll,
        }),
      },
    });
  });

  return (
    <>
      <Layout>
        <Layout.Header>
          <Navigation title={<PublisherData />} hideLinks hideAppBanner />
        </Layout.Header>
        <Layout.Content>
          <Layout.Padded>
            <Content>
              <Illustration name="welcome" />
              <Body1>
                <FormattedMessageWithValidation
                  id="checkout_expired_caption_general"
                  values={{
                    my_subscription: (
                      <Anchor
                        $weight={FONT_WEIGHT.BOLD}
                        forwardedAs={Link}
                        to={subscriptionRoute()}
                      >
                        <FormattedMessageWithValidation id="my_subscription" />
                      </Anchor>
                    ),
                  }}
                />
              </Body1>
            </Content>
          </Layout.Padded>
        </Layout.Content>
      </Layout>
    </>
  );
}
