import {
  adDataSelector,
  getImageUploaderStatusSelector,
} from '../../selectors';
import { isPremiumSubscriptionSelector } from '../../../../selectors/publisher';
import { shouldTeasePremiumSubscriptionSelector } from '../../../../selectors/premium';
import { UPLOAD_ANIMATION_STATUS } from '../../AdForm/partials/UploadProgress/constants';
import { fieldMaxNumberOfImages } from './utils';

export function imagesSelector(state, field) {
  return adDataSelector(state)?.[field] ?? [];
}

export function shouldShowSortingSwitchSelector(state) {
  return (
    isPremiumSubscriptionSelector(state) ||
    shouldTeasePremiumSubscriptionSelector(state)
  );
}

export function shouldEnableSortingSwitchSelector(state) {
  const hasPremiumFeature = isPremiumSubscriptionSelector(state);
  return hasPremiumFeature && isInEnabledStatus(state);
}

export function showUploadProgressSelector(state, name) {
  const hasPremiumFeature = isPremiumSubscriptionSelector(state);
  const uploadedImages = imagesSelector(state, name);
  const status = getImageUploaderStatusSelector(state);
  return hasPremiumFeature && uploadedImages.length > 0 && status != null;
}

export function shouldRequestFeedbackSelector(state) {
  return (
    getImageUploaderStatusSelector(state) ===
    UPLOAD_ANIMATION_STATUS.THINKING_FINISH
  );
}

export function shouldEnableDragAndDropSelector(state) {
  return isInEnabledStatus(state);
}

export function isDisabledSelector(state, field) {
  return imagesSelector(state, field).length >= fieldMaxNumberOfImages(field);
}

export function showDeleteImageDialogSelector(state, field) {
  return uploaderState(state, field).imageIdToDelete != null;
}

export function errorSelector(state, field) {
  return uploaderState(state, field).error;
}

function isInEnabledStatus(state) {
  const status = getImageUploaderStatusSelector(state);
  const disabledStates = [
    UPLOAD_ANIMATION_STATUS.LOADING,
    UPLOAD_ANIMATION_STATUS.THINKING,
    UPLOAD_ANIMATION_STATUS.ONLY_THINKING,
  ];
  return !disabledStates.includes(status);
}

function uploaderState(state, field) {
  return state.imageUploader?.[field] ?? {};
}

export function showThinkingSelector(state) {
  return state['adform:ad']?.showThinking ?? false;
}
