import styled from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { Body1 } from 'design-system/components/Typography/presets/Body1';

function CheckboxField({
  id,
  name,
  disabled,
  children,
  defaultValue,
  onChange,
  ...rest
}) {
  const { register, control } = useFormContext();
  const {
    field: { onChange: reactHookFormsOnChange },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <Checkbox.Label {...rest}>
      <Checkbox
        id={id}
        disabled={disabled}
        {...register(name)}
        onChange={(e) => {
          reactHookFormsOnChange(e);
          onChange?.(e);
        }}
      />
      <Body1>{children}</Body1>
    </Checkbox.Label>
  );
}

export default styled(CheckboxField)``;
