import { subscribe, useSelector } from 'reffects-store';
import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import Section from 'design-system/components/Section';
import InputRow from 'design-system/components/InputRow';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { TOOLTIP_MODAL_PLACEMENT } from 'design-system/components/TooltipModal/TooltipModal';
import { debounce } from 'lodash';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { currencySelector } from '../../../selectors/country';
import SelectField from '../../../partials/fields/SelectField';
import NumberField, {
  useCurrencyPosition,
} from '../../../partials/fields/NumberField';
import {
  OPERATION_TYPE_SELL,
  ownershipTranslationTags,
} from '../../../constants/ad';
import CheckboxField from '../../../partials/fields/CheckboxField';
import { SUITABLE_FOR_CREDIT_NAME } from '../constants';
import HiddenField from '../../../partials/fields/HiddenField';
import {
  selectedOperationTypeSelector,
  selectedPropertyTypeSelector,
  shouldShowOwnershipSelector,
  shouldShowTenureSelector,
  showSuitableForCreditSelector,
} from '../selectors';
import { PRICE_AMOUNT_CHANGED, PRICE_CURRENCY_CHANGED } from '../events';
import MarketPrice from './MarketPrice/MarketPrice';
import UnlockPremium from '../../../partials/UnlockPremium';
import { shouldTeasePremiumSubscriptionSelector } from '../../../selectors/premium';

const PriceSection = styled(Section)`
  ${spacing.stack(SPACING_SIZE.S)};
`;

const DEBOUNCE_INPUT_CHANGE_MILIS = 1500;

function OperationSection({ currencies }) {
  const intl = useIntl();
  const { watch, clearErrors } = useFormContext();
  const shouldTeasePremium = useSelector(
    shouldTeasePremiumSubscriptionSelector
  );
  const operationType = useSelector(selectedOperationTypeSelector);
  const propertyType = useSelector(selectedPropertyTypeSelector);
  const [rentPriceCurrency, sellPriceCurrency] = watch([
    'rentPriceCurrency',
    'sellPriceCurrency',
  ]);

  useEffect(() => {
    if (sellPriceCurrency == null && operationType === OPERATION_TYPE_SELL) {
      dispatch({
        id: PRICE_CURRENCY_CHANGED,
        payload: {
          operationType,
          currency: Object.keys(currencies)[0],
        },
      });
    }
    if (rentPriceCurrency == null && operationType === 'rent') {
      dispatch({
        id: PRICE_CURRENCY_CHANGED,
        payload: {
          operationType,
          currency: Object.keys(currencies)[0],
        },
      });
    }
  }, [currencies, operationType]);

  const showSuitableForCredit = useSelector(showSuitableForCreditSelector);
  const showOwnership = useSelector(shouldShowOwnershipSelector);
  const showTenure = useSelector(shouldShowTenureSelector);

  useEffect(clearErrors, [propertyType]);

  const hasMultipleCurrencies = Object.keys(currencies).length > 1;

  const debounceSellPriceChanged = useCallback(
    debounce(
      (value) =>
        dispatch({
          id: PRICE_AMOUNT_CHANGED,
          payload: { operationType: 'sell', amount: value },
        }),
      DEBOUNCE_INPUT_CHANGE_MILIS
    ),
    []
  );
  const debounceRentPriceChanged = useCallback(
    debounce(
      (value) =>
        dispatch({
          id: PRICE_AMOUNT_CHANGED,
          payload: { operationType: 'rent', amount: value },
        }),
      DEBOUNCE_INPUT_CHANGE_MILIS
    ),
    []
  );
  const premiumTip = shouldTeasePremium && (
    <UnlockPremium
      labelTag="unlock_premium_market_price"
      tooltipTag="unlock_premium_tooltip_market_price"
      tooltipPlacement={TOOLTIP_MODAL_PLACEMENT.DOWN_RIGHT}
    />
  );
  return (
    <>
      {(operationType === 'sell' || operationType === 'sell-and-rent') && (
        <PriceSection
          title={
            <FormattedMessageWithValidation id="ad_form_section_price_sell" />
          }
        >
          <InputRow
            arrangement={
              !showOwnership && hasMultipleCurrencies ? [2, 4] : undefined
            }
          >
            {hasMultipleCurrencies && (
              <SelectField
                label={
                  <FormattedMessageWithValidation id="newprop_currency_emptystate" />
                }
                name="sellPriceCurrency"
                choices={Object.entries(currencies).map(([code, symbol]) => ({
                  value: code,
                  label: symbol,
                }))}
                data-tag="operation_sellPriceCurrency"
                onSelect={({ value }) =>
                  dispatch({
                    id: PRICE_CURRENCY_CHANGED,
                    payload: { operationType: 'sell', currency: value },
                  })
                }
              />
            )}
            <NumberField
              label={
                <FormattedMessageWithValidation id="newprop_price_emptystate" />
              }
              name="sellPrice"
              unit={currencies[sellPriceCurrency]}
              unitPosition={useCurrencyPosition(sellPriceCurrency)}
              data-tag="operation_sellPrice"
              data-test="operation_sellPrice"
              onChange={debounceSellPriceChanged}
              tip={premiumTip}
            />
            {showOwnership && (
              <SelectField
                name="ownership"
                label={
                  <FormattedMessageWithValidation id="newprop_ownership_header" />
                }
                placeholder={intl.formatMessage({
                  id: 'newprop_furnished_emptystate',
                })}
                choices={[
                  {
                    value: 'freehold',
                    label: intl.formatMessage({
                      id: ownershipTranslationTags.get('freehold'),
                    }),
                  },
                  {
                    value: 'leasehold',
                    label: intl.formatMessage({
                      id: ownershipTranslationTags.get('leasehold'),
                    }),
                  },
                ]}
              />
            )}
          </InputRow>
        </PriceSection>
      )}
      {(operationType === 'rent' || operationType === 'sell-and-rent') && (
        <PriceSection
          title={
            <FormattedMessageWithValidation id="ad_form_section_price_rent" />
          }
        >
          <InputRow
            arrangement={
              !showOwnership && hasMultipleCurrencies ? [2, 4] : undefined
            }
          >
            {hasMultipleCurrencies && (
              <SelectField
                label={
                  <FormattedMessageWithValidation id="newprop_currency_emptystate" />
                }
                name="rentPriceCurrency"
                choices={Object.entries(currencies).map(([code, symbol]) => ({
                  value: code,
                  label: symbol,
                }))}
                data-tag="operation_rentPriceCurrency"
                onSelect={({ value }) =>
                  dispatch({
                    id: PRICE_CURRENCY_CHANGED,
                    payload: { operationType: 'rent', currency: value },
                  })
                }
              />
            )}
            <NumberField
              label={
                <FormattedMessageWithValidation id="newprop_price_emptystate" />
              }
              name="rentPrice"
              unit={currencies[rentPriceCurrency]}
              unitPosition={useCurrencyPosition(rentPriceCurrency)}
              data-tag="operation_rentPrice"
              onChange={debounceRentPriceChanged}
              tip={premiumTip}
            />
            {showTenure && (
              <SelectField
                name="tenureInYears"
                label={
                  <FormattedMessageWithValidation id="newprop_duration_header" />
                }
                placeholder={intl.formatMessage({
                  id: 'newprop_furnished_emptystate',
                })}
                choices={[
                  {
                    value: 1,
                    label: intl.formatMessage({
                      id: 'newprop_duration_1',
                    }),
                  },
                  {
                    value: 3,
                    label: intl.formatMessage({
                      id: 'newprop_duration_3',
                    }),
                  },
                  {
                    value: 6,
                    label: intl.formatMessage({
                      id: 'newprop_duration_6',
                    }),
                  },
                  {
                    value: 9,
                    label: intl.formatMessage({
                      id: 'newprop_duration_9',
                    }),
                  },
                  {
                    value: 12,
                    label: intl.formatMessage({
                      id: 'newprop_duration_12',
                    }),
                  },
                ]}
              />
            )}
          </InputRow>
        </PriceSection>
      )}
      <MarketPrice />
      {showSuitableForCredit && operationType === OPERATION_TYPE_SELL && (
        <Section
          title={
            <FormattedMessageWithValidation id="ad_form_section_financing" />
          }
        >
          <InputRow>
            <CheckboxField
              id={SUITABLE_FOR_CREDIT_NAME}
              name={SUITABLE_FOR_CREDIT_NAME}
            >
              <FormattedMessageWithValidation id="adform_field_apto_credito" />
            </CheckboxField>
          </InputRow>
        </Section>
      )}
      <HiddenField name="sellPriceCurrency" />
      <HiddenField name="rentPriceCurrency" />
    </>
  );
}

export default subscribe(OperationSection, (state) => {
  const currencies = currencySelector(state).reduce(
    (acc, { code, symbol }) => ({
      ...acc,
      [code]: symbol,
    }),
    {}
  );
  return {
    currencies,
  };
});
