import styled, { css } from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Icon from 'design-system/components/Icon';
import Tag, {
  TAG_ICON_POSITION,
  TAG_VARIANT,
} from 'design-system/components/Tag';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { TOOLTIP_MODAL_PLACEMENT } from 'design-system/components/TooltipModal/TooltipModal';
import { useSelector } from 'reffects-store';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import UnlockPremium from '../../../../../partials/UnlockPremium';
import { isPremiumSubscriptionSelector } from '../../../../../selectors/publisher';
import { showRestoreSortButtonSelector } from '../../../selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Switch = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing.value(SPACING_SIZE.XS)};
  width: fit-content;
`;

const SwitchContainer = styled.div`
  display: flex;
  ${spacing.inset(SPACING_SIZE.XS)};
  align-items: flex-start;
  gap: ${spacing.value(SPACING_SIZE.XS)};
  ${radius.regular(RADIUS_SIZE.LARGE)};
  background: ${COLOR_PALETTE.NEUTRAL_A05};
  width: fit-content;
`;

const Option = styled(Tag)`
  cursor: pointer;
  transition: background 0.3s;
  box-shadow: ${({ active }) =>
    active ? '0 0 2px 0 rgba(0, 0, 0, 0.25)' : 'none'};
  font-weight: ${({ active }) =>
    active
      ? typography.weight(FONT_WEIGHT.SEMIBOLD)
      : typography.weight(FONT_WEIGHT.NORMAL)};

  &[data-disabled] {
    cursor: not-allowed;
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
  }
`;

const RestoreButton = styled(Tag)`
  display: flex;
  ${spacing.inset(SPACING_SIZE.S)};
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  background: none;
  cursor: pointer;
`;

const TagText = styled.div`
  ${typography.weight(FONT_WEIGHT.NORMAL)};
  ${({ active }) =>
    active &&
    css`
      ${typography.weight(FONT_WEIGHT.BOLD)};
    `}
`;

const PremiumText = styled.div`
  display: flex;
  align-items: flex-start;
  ${typography.size(FONT_SIZE.XS)}
  ${color.text(COLOR_PALETTE.PRIMARY_BASE)}
    ${typography.font(FONT_FAMILY.PRIMARY)};
  ${typography.weight(FONT_WEIGHT.NORMAL)};
  ${typography.lineHeight(FONT_SIZE.L)};
  text-align: center;
  text-transform: uppercase;
`;

export default function SortingSwitch({
  usingAISorting,
  disabled,
  onToggleSortingMode,
  onRestoreSorting,
}) {
  const isPremiumSubscription = useSelector(isPremiumSubscriptionSelector);
  const showRestoreSortButton = useSelector(showRestoreSortButtonSelector);

  return (
    <Wrapper>
      <Switch>
        <SwitchContainer>
          <Option
            active={usingAISorting && !disabled}
            onClick={() => !disabled && onToggleSortingMode(true)}
            icon={<Icon glyph="spark" />}
            iconPosition={TAG_ICON_POSITION.START}
            variant={usingAISorting ? TAG_VARIANT.NEUTRAL : TAG_VARIANT.DEFAULT}
            disabled={disabled}
            data-disabled={disabled || undefined}
          >
            <TagText active={usingAISorting}>
              <FormattedMessageWithValidation id="switch_image_sorting_original_ai" />
            </TagText>
          </Option>
          <Option
            active={!usingAISorting || disabled}
            onClick={() => onToggleSortingMode(false)}
            variant={usingAISorting ? TAG_VARIANT.DEFAULT : TAG_VARIANT.NEUTRAL}
            disabled={disabled}
            data-disabled={disabled || undefined}
          >
            <TagText active={!usingAISorting}>
              <FormattedMessageWithValidation id="switch_image_sorting_original" />
            </TagText>
          </Option>
        </SwitchContainer>
        {!isPremiumSubscription ? (
          <UnlockPremium
            labelTag="unlock_premium_sorting"
            tooltipTag="unlock_premium_tooltip_ai_images_sorting"
            tooltipPlacement={TOOLTIP_MODAL_PLACEMENT.DOWN_RIGHT}
          />
        ) : (
          <PremiumText>
            <FormattedMessageWithValidation id="premium_feature" />
          </PremiumText>
        )}
      </Switch>
      {showRestoreSortButton && (
        <Switch>
          <RestoreButton
            onClick={onRestoreSorting}
            icon={<Icon glyph="resetImageUndo" />}
            iconPosition={TAG_ICON_POSITION.START}
            variant={TAG_VARIANT.DEFAULT}
          >
            <TagText>
              <FormattedMessageWithValidation id="switch_image_sorting_restore" />
            </TagText>
          </RestoreButton>
        </Switch>
      )}
    </Wrapper>
  );
}
