import styled from 'styled-components';
import { Day, DayPicker } from 'react-day-picker';
import {
  endOfDay,
  format,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  startOfDay,
} from 'date-fns';
import { useState } from 'react';
import 'react-day-picker/dist/style.css';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import Button, {
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'design-system/components/Button';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import Typography, {
  TYPOGRAPHY_VARIANT,
} from 'design-system/components/Typography/Typography';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { fromUTCDate, toUTCDate } from '../../../utils/dates';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  width: 629px;
  .rdp {
    --rdp-cell-size: 38px;
  }
  .rdp-multiple_months {
    margin: 0;
  }
  .rdp-caption {
    height: 34px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    ${spacing.stack(SPACING_SIZE.S, false)};
    .rdp-nav_button:hover {
      background-color: initial;
    }
    .rdp-caption_label {
      ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_2)}
      ${typography.font(FONT_FAMILY.PRIMARY)}
    }
  }
  .rdp-month {
    margin: 0;
    ${spacing.inset(SPACING_SIZE.L)}
    &:first-child {
      ${divider.right(DIVIDER_SIZE.THIN)}
    }
  }
  .rdp-head_cell {
    ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_2)}
    ${typography.font(FONT_FAMILY.PRIMARY)}
        ${typography.weight(FONT_WEIGHT.MEDIUM)}
        text-transform: none;
  }

  .rdp-row {
    .rdp-cell:first-child,
    .first-day-of-month {
      .rdp-day_range_middle {
        border-radius: 99999px 0 0 99999px;
      }
    }
    .rdp-cell:last-child,
    .last-day-of-month {
      .rdp-day_range_middle {
        border-radius: 0 99999px 99999px 0;
      }
    }
  }
  .rdp-cell {
    padding-bottom: 2px;
    .rdp-button {
      ${typography.font(FONT_FAMILY.PRIMARY)}
      ${Typography.mods.variant(TYPOGRAPHY_VARIANT.BODY_1)}
            &:hover:not(.rdp-day_selected) {
        ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
      }
    }
  }

  .rdp-day_selected {
    ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  }

  .rdp-day_today {
    font-weight: initial;
  }

  .rdp-day_range_start:not(.rdp-day_range_end) {
    ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
        border-top-right-radius: 100% !important;
    border-bottom-right-radius: 100% !important;
  }

  .rdp-cell:has(.rdp-day_range_start:not(.rdp-day_range_end)) {
    border-radius: 99999px 0 0 99999px;
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  }

  .rdp-day_range_end:not(.rdp-day_range_start) {
    ${color.background(COLOR_PALETTE.SECONDARY_BASE)}
    ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
        border-top-left-radius: 100% !important;
    border-bottom-left-radius: 100% !important;
  }

  .rdp-cell:has(.rdp-day_range_end:not(.rdp-day_range_start)) {
    border-radius: 0 99999px 99999px 0;
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  }

  .rdp-day_range_middle {
    ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${spacing.inset(SPACING_SIZE.L)}
`;

const CancelButton = styled(Button)`
  ${Button.mods.variant(BUTTON_VARIANT.FLAT)}
  ${Button.mods.size(BUTTON_SIZE.SMALL)}
`;

const ConfirmButton = styled(Button)`
  ${Button.mods.variant(BUTTON_VARIANT.SECONDARY)}
  ${Button.mods.size(BUTTON_SIZE.SMALL)}
`;

const IconResized = styled(Icon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  ${color.text(COLOR_PALETTE.PUMPKIN_BASE)};
`;
const Label = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing.value(SPACING_SIZE.S)} ${spacing.value(SPACING_SIZE.L)};
  gap: ${spacing.value(SPACING_SIZE.S)};
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)};
  ${typography.size(FONT_SIZE.M)} ${typography.weight(FONT_WEIGHT.NORMAL)};
  ${typography.lineHeight(20)} ${typography.font(FONT_FAMILY.PRIMARY)};
  align-items: center;
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)}
  margin-bottom: -8px;
`;

function CustomDay({ date, displayMonth }) {
  const day = <Day date={date} displayMonth={displayMonth} />;
  if (isFirstDayOfMonth(date)) {
    return <div className="first-day-of-month">{day}</div>;
  }
  if (isLastDayOfMonth(date)) {
    return <div className="last-day-of-month">{day}</div>;
  }
  return day;
}

export default function Datepicker({
  onConfirm,
  onCancel,
  start,
  end,
  minDate,
  maxDate,
  formatWeekday,
  formatMonth,
  submitButtonText,
  cancelButtonText,
  showLastYearWarning,
  ...rest
}) {
  const [range, setRange] = useState({
    from: fromUTCDate(start),
    to: fromUTCDate(end),
  });
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  return (
    <Wrapper {...rest}>
      <DayPicker
        ISOWeek
        numberOfMonths={2}
        mode="range"
        defaultMonth={range?.from}
        selected={range}
        onSelect={setRange}
        components={{
          IconLeft: () => <Icon $size={ICON_SIZE.SMALL} glyph="chevronLeft" />,
          IconRight: () => (
            <Icon $size={ICON_SIZE.SMALL} glyph="chevronRight" />
          ),
          Day: (props) => <CustomDay {...props} />,
        }}
        fromDate={fromUTCDate(minDate)}
        toDate={fromUTCDate(maxDate)}
        formatters={{
          formatCaption: (date) => formatMonth(date),
          formatDay: (date, options) => format(date, 'dd', options),
          formatWeekdayName: (date) => formatWeekday(date),
        }}
      />
      {showLastYearWarning && new Date(range?.from) < oneYearAgo && (
        <Label>
          <IconResized glyph="info" />
          <FormattedMessageWithValidation id="custom_date_datepicker_only_last_year" />
        </Label>
      )}
      <Controls>
        <div>
          <CancelButton onClick={onCancel}>{cancelButtonText}</CancelButton>
          <ConfirmButton
            onClick={() =>
              onConfirm({
                startDate: toUTCDate(startOfDay(range?.from)),
                endDate: toUTCDate(endOfDay(range?.to)),
              })
            }
            disabled={!range?.from || !range?.to}
          >
            {submitButtonText}
          </ConfirmButton>
        </div>
      </Controls>
    </Wrapper>
  );
}
