import styled, { keyframes } from 'styled-components';
import AIThinkerBall from '../AIThinkerBall/AIThinkerBall';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const dots = keyframes`
    0% {
        content: '';
    }
    25% {
        content: '.';
    }
    50% {
        content: '..';
    }
    75% {
        content: '...';
    }
    100% {
        content: '....';
    }
`;
const TextWithDots = styled.div`
  margin-left: 0;
  ::after {
    content: '';
    animation: ${dots} 1.8s steps(1, end) infinite;
  }
`;
export default function AnimatedThinkingBall() {
  return (
    <>
      <AIThinkerBall />
      <TextWithDots>
        <FormattedMessageWithValidation id="upload_progress_bar_uploading_images_thinking" />
      </TextWithDots>
    </>
  );
}
