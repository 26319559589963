import { effects, effects as reffects, registerEventHandler } from 'reffects';
import { state as stateEffect, state } from 'reffects-store';
import { http } from 'reffects-batteries';
import query from 'query-string';
import { environment } from '../../../../coeffects/environment';
import { PROPERTY_LOCATION_VISIBILITY } from '../../../../constants/ad';
import { PROJECT_INPUT_NAME } from '../../constants';
import { REQUEST_MARKET_PRICE } from '../../events';

export const ADDRESS_SUGGESTIONS_SELECTED = 'ADDRESS_SUGGESTIONS_SELECTED';
export const PROPERTY_GEOLOCATION_OBTAINED = 'PROPERTY_GEOLOCATION_OBTAINED';
export const PROPERTY_GEOLOCATION_REQUEST_FAILED = 'GEO_LEVELS_REQUEST_FAILED';
export const ADDRESS_TEXT_INTRODUCED = 'ADDRESS_TEXT_INTRODUCED';
export const ADDRESS_SEARCH_SUGGESTIONS = 'ADDRESS_SEARCH_SUGGESTIONS';
export const ADDRESS_SUGGESTIONS_OBTAINED = 'ADDRESS_SUGGESTIONS_OBTAINED';
export const ADDRESS_SUGGESTIONS_FAILED = 'ADDRESS_SUGGESTIONS_FAILED';
export const ADDRESS_AUTOCOMPLETE_CLEARED = 'ADDRESS_AUTOCOMPLETE_CLEARED';
export const LOCATION_VISIBILITY_CHANGED = 'LOCATION_VISIBILITY_CHANGED';
export const MAP_MARKER_DRAGGED = 'MAP_MARKER_DRAGGED';
export const MARKER_GEOLOCATION_OBTAINED = 'MARKER_GEOLOCATION_OBTAINED';
export const PROJECT_NAME_TEXT_INTRODUCED = 'PROJECT_NAME_TEXT_INTRODUCED';
export const PROJECT_NAME_SEARCH_SUGGESTIONS =
  'PROJECT_NAME_SEARCH_SUGGESTIONS';
export const PROJECT_NAME_SUGGESTIONS_OBTAINED =
  'PROJECT_NAME_SUGGESTIONS_OBTAINED';
export const PROJECT_NAME_SUGGESTIONS_RETRIEVAL_FAILED =
  'PROJECT_NAME_SUGGESTIONS_RETRIEVAL_FAILED';
export const PROJECT_NAME_SUGGESTIONS_SELECTED =
  'PROJECT_NAME_SUGGESTIONS_SELECTED';
export const PROJECT_NAME_AUTOCOMPLETE_CLEARED =
  'PROJECT_NAME_AUTOCOMPLETE_CLEARED';

export const MINIMUM_LENGTH_TO_RETRIEVE_SUGGESTIONS = 2;
const MAX_SUGGESTIONS_NUMBER = 5;

registerEventHandler(ADDRESS_TEXT_INTRODUCED, (_, { addressText }) => ({
  ...state.set({
    'adForm:displayableAddress': addressText,
    'adForm:addressSuggestionLoading': true,
  }),
}));

registerEventHandler(
  ADDRESS_SEARCH_SUGGESTIONS,
  ({ environment: { apiUrl } }, { addressText, locale }) => {
    if (addressText == null) {
      return {};
    }

    if (addressText.length < MINIMUM_LENGTH_TO_RETRIEVE_SUGGESTIONS) {
      return {};
    }
    const queryString = query.stringify(
      {
        query: addressText,
        locale,
      },
      { skipNull: true }
    );

    return {
      ...state.set({
        'adForm:addressSuggestionLoading': true,
      }),
      ...http.get({
        url: `${apiUrl}/address-suggestions?${queryString}`,
        successEvent: ADDRESS_SUGGESTIONS_OBTAINED,
        errorEvent: ADDRESS_SUGGESTIONS_FAILED,
      }),
    };
  },
  [environment()]
);

registerEventHandler(ADDRESS_SUGGESTIONS_OBTAINED, (_, payload) => {
  const [{ predictions: suggestions }] = payload;
  const parsedSuggestions = suggestions.map((suggestion) => ({
    address: suggestion.description,
    place_id: suggestion.place_id,
  }));

  return state.set({
    'adForm:addressSuggestions': parsedSuggestions,
    'adForm:addressSuggestionLoading': false,
  });
});

registerEventHandler(ADDRESS_SUGGESTIONS_FAILED, () =>
  state.set({
    'adForm:addressSuggestionLoading': false,
  })
);

registerEventHandler(
  ADDRESS_SUGGESTIONS_SELECTED,
  ({ environment: { apiUrl } }, selectedSuggestion) => ({
    ...state.set({
      'adForm:addressSuggestionLoading': false,
      'adForm:ad.address': selectedSuggestion.address,
      'adForm:ad.locationVisibility': PROPERTY_LOCATION_VISIBILITY.ACCURATE,
      'adForm:displayableAddress': selectedSuggestion.address,
    }),
    ...http.get({
      url: `${apiUrl}/property-geolocations/address?address=${encodeURIComponent(
        selectedSuggestion.address
      )}&place_id=${encodeURIComponent(selectedSuggestion.place_id)}`,
      successEvent: PROPERTY_GEOLOCATION_OBTAINED,
      errorEvent: PROPERTY_GEOLOCATION_REQUEST_FAILED,
    }),
  }),
  [environment()]
);

registerEventHandler(ADDRESS_AUTOCOMPLETE_CLEARED, () =>
  state.set({
    'adForm:displayableAddress': undefined,
    'adForm:addressSuggestions': undefined,
    'adForm:ad.geoLevels': [],
    'adForm:ad.address': undefined,
    'adForm:ad.coordinates': undefined,
    'adForm:marketPrice': undefined,
  })
);

registerEventHandler(
  PROPERTY_GEOLOCATION_OBTAINED,
  (
    _,
    [
      {
        data: { geoLevels, latitude, longitude },
      },
    ]
  ) => ({
    ...state.set({
      'adForm:ad.geoLevels': geoLevels,
      'adForm:ad.coordinates': {
        latitude,
        longitude,
      },
    }),
    ...effects.dispatch(REQUEST_MARKET_PRICE),
  })
);

registerEventHandler(PROPERTY_GEOLOCATION_REQUEST_FAILED, () =>
  state.set({
    'adForm:ad.geoLevels': [],
    'adForm:marketPrice': undefined,
  })
);

registerEventHandler(LOCATION_VISIBILITY_CHANGED, (_, locationVisibility) => ({
  ...stateEffect.set({ 'adForm:ad.locationVisibility': locationVisibility }),
  ...effects.dispatch(REQUEST_MARKET_PRICE),
}));

registerEventHandler(
  MAP_MARKER_DRAGGED,
  ({ environment: { apiUrl } }, { latitude, longitude }) =>
    http.get({
      url: `${apiUrl}/property-geolocations/coordinates?latitude=${latitude}&longitude=${longitude}`,
      successEvent: MARKER_GEOLOCATION_OBTAINED,
    }),
  [environment()]
);

registerEventHandler(
  MARKER_GEOLOCATION_OBTAINED,
  (
    _,
    [
      {
        data: { address, latitude, longitude, geoLevels },
      },
    ]
  ) => ({
    ...state.set({
      'adForm:ad.geoLevels': geoLevels,
      'adForm:ad.address': address,
      'adForm:displayableAddress': address,
      'adForm:ad.coordinates.latitude': parseFloat(latitude),
      'adForm:ad.coordinates.longitude': parseFloat(longitude),
      'adForm:addressSuggestions': null,
    }),
    ...effects.dispatch(REQUEST_MARKET_PRICE),
  })
);

registerEventHandler(PROJECT_NAME_TEXT_INTRODUCED, (_, projectName) =>
  state.set({
    'adForm:displayableProjectName': projectName,
    'adForm:projectNameSuggestionLoading': true,
  })
);

registerEventHandler(
  PROJECT_NAME_SEARCH_SUGGESTIONS,
  ({ environment: { apiUrl } }, projectName) => {
    if (projectName?.length < MINIMUM_LENGTH_TO_RETRIEVE_SUGGESTIONS) {
      return {};
    }
    const encodedProjectName = encodeURIComponent(projectName);

    return {
      ...state.set({
        'adForm:projectNameSuggestionLoading': true,
      }),
      ...http.get({
        url: `${apiUrl}/project-suggestions?query=${encodedProjectName}`,
        successEvent: PROJECT_NAME_SUGGESTIONS_OBTAINED,
        errorEvent: PROJECT_NAME_SUGGESTIONS_RETRIEVAL_FAILED,
      }),
    };
  },
  [environment()]
);

registerEventHandler(PROJECT_NAME_SUGGESTIONS_OBTAINED, (_, [{ data }]) =>
  state.set({
    'adForm:projectNameSuggestions': data.slice(0, MAX_SUGGESTIONS_NUMBER),
    'adForm:projectNameSuggestionLoading': false,
  })
);

registerEventHandler(PROJECT_NAME_SUGGESTIONS_RETRIEVAL_FAILED, () =>
  state.set({
    'adForm:projectNameSuggestionLoading': false,
  })
);

registerEventHandler(
  PROJECT_NAME_SUGGESTIONS_SELECTED,
  (_, { id, name, latitude, longitude, amenities, address, geoLevels }) => ({
    ...state.set({
      [`adForm:ad.${PROJECT_INPUT_NAME}`]: { id, name },
      'adForm:ad.locationVisibility': PROPERTY_LOCATION_VISIBILITY.ACCURATE,
      'adForm:projectAmenities': amenities,
      'adForm:ad.geoLevels': geoLevels,
      'adForm:ad.address': address,
      'adForm:displayableProjectName': name,
      'adForm:displayableAddress': address,
      'adForm:ad.coordinates.latitude': parseFloat(latitude),
      'adForm:ad.coordinates.longitude': parseFloat(longitude),
      'adForm:addressSuggestions': null,
    }),
    ...effects.dispatch(REQUEST_MARKET_PRICE),
  })
);

registerEventHandler(PROJECT_NAME_AUTOCOMPLETE_CLEARED, () => ({
  ...state.set({
    'adForm:displayableProjectName': undefined,
    'adForm:projectNameSuggestions': undefined,
    [`adForm:ad.${PROJECT_INPUT_NAME}`]: undefined,
    'adForm:ad.amenities': [],
    'adForm:projectAmenities': undefined,
  }),
  ...reffects.dispatch(ADDRESS_AUTOCOMPLETE_CLEARED),
}));
