import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../coeffects/environment';

export const LOAD_LEADS_WITH_LEAD_PLUS = 'LOAD_LEADS_WITH_LEAD_PLUS';
export const LEADS_WITH_LEAD_PLUS_LOADED = 'LEADS_WITH_LEAD_PLUS_LOADED';
export const LOAD_LEADS_WITH_QUALIFIED_ENQUIRIES =
  'LOAD_LEADS_WITH_QUALIFIED_ENQUIRIES';
export const LEADS_WITH_QUALIFIED_ENQUIRIES_LOADED =
  'LEADS_WITH_QUALIFIED_ENQUIRIES_LOADED';
export const LOAD_LEAD_QUALIFICATIONS = 'LOAD_LEAD_QUALIFICATIONS';
export const LEAD_QUALIFICATIONS_LOADED = 'LEAD_QUALIFICATIONS_LOADED';

registerEventHandler(
  LOAD_LEADS_WITH_LEAD_PLUS,
  ({ environment: { apiUrl }, state: { isDeveloper } }, { leads }) =>
    isDeveloper
      ? {}
      : http.post({
          url: `${apiUrl}/eligible-lead-plus`,
          body: {
            leads: leads.map(({ id, email, phone }) => ({
              id,
              contactEmail: email,
              contactPhone: phone,
            })),
          },
          successEvent: LEADS_WITH_LEAD_PLUS_LOADED,
        }),
  [environment(), state.get({ isDeveloper: 'publisher.isDeveloper' })]
);

registerEventHandler(LEADS_WITH_LEAD_PLUS_LOADED, (_, [{ data }]) =>
  state.set({ leadsWithLeadPlus: data })
);

registerEventHandler(
  LOAD_LEADS_WITH_QUALIFIED_ENQUIRIES,
  ({ environment: { apiUrl } }, { leads }) =>
    http.post({
      url: `${apiUrl}/leads-with-qualified-enquiries`,
      body: {
        leads: leads.map(({ id }) => id),
      },
      successEvent: LEADS_WITH_QUALIFIED_ENQUIRIES_LOADED,
    }),
  [environment()]
);

registerEventHandler(LEADS_WITH_QUALIFIED_ENQUIRIES_LOADED, (_, [{ data }]) =>
  state.set({ leadsWithQualifiedEnquiries: data })
);

registerEventHandler(
  LOAD_LEAD_QUALIFICATIONS,
  ({ environment: { apiUrl } }, { leads }) =>
    http.post({
      url: `${apiUrl}/lead-qualifications`,
      body: {
        leads: leads.map(({ id }) => id),
      },
      successEvent: LEAD_QUALIFICATIONS_LOADED,
    }),
  [environment()]
);

registerEventHandler(LEAD_QUALIFICATIONS_LOADED, (_, [{ data }]) =>
  state.set({ leadQualifications: data })
);
