import { useSelector } from 'reffects-store';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedNumber } from 'react-intl';
import { useCallback, useState } from 'react';
import Grid from 'design-system/components/Grid';
import Icon from 'design-system/components/Icon';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE } from 'design-system/styles/color';
import { Heading2 } from 'design-system/components/Typography/presets/Heading2';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import ActionCard from '../../../../partials/ActionCard';

import PropertiesCard from './PropertiesCard';

import NoPropertiesCreated from './NoPropertiesCreated';

import { showImportStatsSelector } from './selectors';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { newPropertyForm } from '../../../../utils/proppitWebRouter';
import CustomFormattedDate from '../../../../partials/FormattedDate/CustomFormattedDate';
import { IMPORT_STATS, PROPERTIES } from '../../../../constants/routes';

import useTracking from '../../../../hooks/useTracking';
import {
  isPublisherFromLATAMSelector,
  isPublisherFromMxSelector,
  publisherSubscriptionPlanIsFreeSelector,
  publisherSubscriptionPlanIsPaidSelector,
} from '../../../../selectors/publisher';
import { importStatsSummarySelector } from '../../../../selectors/xmlStats';
import WasiCard from './WasiCard';
import { publisherHasAnyCreatedAdSelector } from '../../selectors';

import {
  maxBoostablePropertiesSelector,
  maxSuperboostablePropertiesSelector,
  propertiesWithBoostingSelector,
  propertiesWithoutBoostingSelector,
  propertiesWithSuperboostingSelector,
  publishedPropertiesSelector,
} from '../../../../selectors/propertiesSummary';
import { superboostEnabledSelector } from '../../../../selectors/config';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  align-items: end;
  justify-content: space-between;
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

const ImportStatsSummaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.L)};
`;

function PropertiesSummaryForManualPublisher({
  publishedProperties,
  boostedProperties,
  maxBoostableProperties,
  nonBoostedProperties,
  superboostedProperties,
  maxSuperboostableProperties,
  isMXPublisher,
}) {
  const hasSuperboostEnabled = useSelector(superboostEnabledSelector);
  const isLatamPublisher = useSelector(isPublisherFromLATAMSelector);
  const [newPropertyLinkElement, setNewPropertyLinkElement] = useState(null);
  const newPropertyRef = useCallback((node) => {
    if (node != null && newPropertyLinkElement !== node)
      setNewPropertyLinkElement(node);
  }, []);
  useTracking({
    ref: newPropertyLinkElement,
    trackClick: {
      eventName: 'create-new-listing',
    },
    trackImpression: {
      elementName: 'create-new-listing-btn',
    },
    sectionName: 'overview-listings-counter-right-new-listing-button',
  });
  if (isMXPublisher) {
    return (
      <Grid
        areas={({ repeat }) => `
          "${repeat(4, 'boosted')} ${repeat(4, 'superboosted')} ${repeat(
          4,
          'cta'
        )}"
        `}
      >
        <Grid.Area $id="boosted">
          <PropertiesCard
            icon={<Icon glyph="eye" $color={COLOR_PALETTE.PRIMARY_BASE} />}
            message={
              <FormattedMessageWithValidation id="overview_published_properties_card_title_published" />
            }
            amount={
              <Heading2>
                <FormattedNumber value={publishedProperties} />
              </Heading2>
            }
            linkTo={`${PROPERTIES}`}
            elementName="navigate-to-boosted-listings-btn"
            eventName="navigate-to-boosted-listings"
            sectionName="overview-listings-counter-right-nav-to-listings-button"
          />
        </Grid.Area>
        <Grid.Area $id="superboosted">
          <PropertiesCard
            icon={
              <Icon glyph="superboost" $color={COLOR_PALETTE.PRIMARY_BASE} />
            }
            message={
              <FormattedMessageWithValidation id="overview_superboosted_properties_card_title" />
            }
            amount={
              <>
                <Heading2>
                  <FormattedNumber value={superboostedProperties} />
                </Heading2>
                <Heading2 $color={COLOR_PALETTE.NEUTRAL_A40}>
                  {' '}
                  / <FormattedNumber value={maxSuperboostableProperties} />
                </Heading2>
              </>
            }
            linkTo={
              superboostedProperties > 0 &&
              `${PROPERTIES}?status=published-superboosted`
            }
            elementName="navigate-to-superboosted-listings-btn"
            eventName="navigate-to-superboosted-listings"
            sectionName="overview-listings-counter-right-nav-to-listings-button"
          />
        </Grid.Area>
        <Grid.Area $id="cta">
          <WasiCard />
        </Grid.Area>
      </Grid>
    );
  }
  if (hasSuperboostEnabled) {
    return (
      <Grid
        areas={({ repeat }) => `"${repeat(8, 'kpis')} ${repeat(4, 'cta')}"`}
      >
        <Grid.Area $id="kpis">
          <FullHeightGrid
            areas={({ repeat }) =>
              `"${repeat(4, 'boosted')} ${repeat(4, 'superboosted')} ${repeat(
                4,
                'boosted-not-published'
              )}"`
            }
          >
            <Grid.Area $id="boosted">
              <PropertiesCard
                icon={<Icon glyph="bolt" $color={COLOR_PALETTE.PRIMARY_BASE} />}
                message={
                  <FormattedMessageWithValidation id="overview_published_properties_card_title_boosted" />
                }
                amount={
                  <>
                    <Heading2>
                      <FormattedNumber value={boostedProperties} />
                    </Heading2>
                    {boostedProperties > 0 && (
                      <Heading2 $color={COLOR_PALETTE.NEUTRAL_A40}>
                        {' '}
                        / <FormattedNumber value={maxBoostableProperties} />
                      </Heading2>
                    )}
                  </>
                }
                linkTo={
                  boostedProperties > 0
                    ? `${PROPERTIES}?status=published-boosted`
                    : undefined
                }
                elementName="navigate-to-boosted-listings-btn"
                eventName="navigate-to-boosted-listings"
                sectionName="overview-listings-counter-right-nav-to-listings-button"
              />
            </Grid.Area>
            <Grid.Area $id="superboosted">
              <PropertiesCard
                icon={
                  <Icon
                    glyph="superboost"
                    $color={COLOR_PALETTE.PRIMARY_BASE}
                  />
                }
                message={
                  <FormattedMessageWithValidation id="overview_superboosted_properties_card_title" />
                }
                amount={
                  <>
                    <Heading2>
                      <FormattedNumber value={superboostedProperties} />
                    </Heading2>
                    <Heading2 $color={COLOR_PALETTE.NEUTRAL_A40}>
                      {' '}
                      / <FormattedNumber value={maxSuperboostableProperties} />
                    </Heading2>
                  </>
                }
                linkTo={
                  superboostedProperties > 0
                    ? `${PROPERTIES}?status=published-superboosted`
                    : undefined
                }
                elementName="navigate-to-superboosted-listings-btn"
                eventName="navigate-to-superboosted-listings"
                sectionName="overview-listings-counter-right-nav-to-listings-button"
              />
            </Grid.Area>
            <Grid.Area $id="boosted-not-published">
              <PropertiesCard
                icon={
                  <Icon
                    glyph="boltCrossed"
                    $color={COLOR_PALETTE.NEUTRAL_A40}
                  />
                }
                message={
                  <FormattedMessageWithValidation id="overview_published_properties_card_title_not_boosted" />
                }
                amount={
                  <Heading2>
                    <FormattedNumber value={nonBoostedProperties} />
                  </Heading2>
                }
                linkTo={
                  nonBoostedProperties > 0 &&
                  `${PROPERTIES}?status=published-not-boosted`
                }
                elementName="navigate-to-published-not-boosted-listings-btn"
                eventName="navigate-to-published-not-boosted-listings"
                sectionName="overview-listings-counter-right-nav-to-listings-button"
              />
            </Grid.Area>
          </FullHeightGrid>
        </Grid.Area>
        <Grid.Area $id="cta">
          {isLatamPublisher ? (
            <WasiCard />
          ) : (
            <ActionCard
              illustration={<Illustration name="newProperty" />}
              title={
                <FormattedMessageWithValidation id="overview_published_properties_cta_title" />
              }
              cta={
                <PrimaryButton
                  $size={BUTTON_SIZE.SMALL}
                  forwardedAs={Link}
                  to={newPropertyForm()}
                  ref={newPropertyRef}
                >
                  <FormattedMessageWithValidation id="overview_properties_new_cta" />
                </PrimaryButton>
              }
            />
          )}
        </Grid.Area>
      </Grid>
    );
  }
  return (
    <Grid
      areas={({ repeat }) => `
          "${repeat(4, 'boosted')} ${repeat(4, 'unboosted')} ${repeat(
        4,
        'cta'
      )}"
        `}
    >
      <Grid.Area $id="boosted">
        <PropertiesCard
          icon={<Icon glyph="bolt" $color={COLOR_PALETTE.PRIMARY_BASE} />}
          message={
            <FormattedMessageWithValidation id="overview_published_properties_card_title_boosted" />
          }
          amount={
            <>
              <Heading2>
                <FormattedNumber value={boostedProperties} />
              </Heading2>
              {boostedProperties > 0 && (
                <Heading2 $color={COLOR_PALETTE.NEUTRAL_A40}>
                  {' '}
                  / <FormattedNumber value={maxBoostableProperties} />
                </Heading2>
              )}
            </>
          }
          linkTo={
            boostedProperties > 0 && `${PROPERTIES}?status=published-boosted`
          }
          elementName="navigate-to-boosted-listings-btn"
          eventName="navigate-to-boosted-listings"
          sectionName="overview-listings-counter-right-nav-to-listings-button"
        />
      </Grid.Area>
      <Grid.Area $id="unboosted">
        <PropertiesCard
          icon={<Icon glyph="boltCrossed" $color={COLOR_PALETTE.NEUTRAL_A40} />}
          message={
            <FormattedMessageWithValidation id="overview_published_properties_card_title_not_boosted" />
          }
          amount={
            <Heading2>
              <FormattedNumber value={nonBoostedProperties} />
            </Heading2>
          }
          linkTo={
            nonBoostedProperties > 0 &&
            `${PROPERTIES}?status=published-not-boosted`
          }
          elementName="navigate-to-published-not-boosted-listings-btn"
          eventName="navigate-to-published-not-boosted-listings"
          sectionName="overview-listings-counter-right-nav-to-listings-button"
        />
      </Grid.Area>
      <Grid.Area $id="cta">
        {isLatamPublisher ? (
          <WasiCard />
        ) : (
          <ActionCard
            illustration={<Illustration name="newProperty" />}
            title={
              <FormattedMessageWithValidation id="overview_published_properties_cta_title" />
            }
            cta={
              <PrimaryButton
                $size={BUTTON_SIZE.SMALL}
                forwardedAs={Link}
                to={newPropertyForm()}
                ref={newPropertyRef}
              >
                <FormattedMessageWithValidation id="overview_properties_new_cta" />
              </PrimaryButton>
            }
          />
        )}
      </Grid.Area>
    </Grid>
  );
}

function PropertiesSummaryForAutomaticPublisher({
  publishedProperties,
  total,
  boostedProperties,
  maxBoostableProperties,
  nonBoostedProperties,
  withErrors,
  duplicated,
  date,
  superboostedProperties,
  maxSuperboostableProperties,
  isMXPublisher,
}) {
  const hasSuperboostEnabled = useSelector(superboostEnabledSelector);
  const areas = ({ repeat }) => {
    if (isMXPublisher) {
      return `
        "${repeat(2, 'total')} ${repeat(2, 'published')} ${repeat(
        2,
        'superboosted'
      )} ${repeat(2, 'errors')} ${repeat(2, 'duplicated')} ${repeat(2, 'date')}"
      `;
    }
    if (hasSuperboostEnabled) {
      return `
        " ${repeat(2, 'date')} ${repeat(2, 'unboosted')}  ${repeat(
        2,
        'boosted'
      )}  ${repeat(2, 'superboosted')} ${repeat(2, 'errors')} ${repeat(
        2,
        'duplicated'
      )}"
      `;
    }
    return `
        "${repeat(2, 'total')} ${repeat(2, 'boosted')} ${repeat(
      2,
      'unboosted'
    )} ${repeat(2, 'errors')} ${repeat(2, 'duplicated')} ${repeat(2, 'date')}"
      `;
  };
  return (
    <Grid areas={areas}>
      {isMXPublisher && (
        <>
          <Grid.Area $id="total">
            <PropertiesCard
              icon={<Icon glyph="import" $color={COLOR_PALETTE.NEUTRAL_A80} />}
              message={
                <FormattedMessageWithValidation id="overview_published_properties_card_title_total" />
              }
              amount={
                <Heading2>
                  <FormattedNumber value={total} />
                </Heading2>
              }
            />
          </Grid.Area>
          <Grid.Area $id="published">
            <PropertiesCard
              icon={<Icon glyph="eye" $color={COLOR_PALETTE.PRIMARY_BASE} />}
              message={
                <FormattedMessageWithValidation id="overview_published_properties_card_title_published" />
              }
              amount={
                <Heading2>
                  <FormattedNumber value={publishedProperties} />
                </Heading2>
              }
              linkTo={
                publishedProperties > 0 && `${PROPERTIES}?status=published`
              }
              elementName="navigate-to-published-listings-btn"
              eventName="navigate-to-published-listings"
              sectionName="overview-listings-counter-right-nav-to-listings-button"
            />
          </Grid.Area>
          <Grid.Area $id="superboosted">
            <PropertiesCard
              icon={
                <Icon glyph="superboost" $color={COLOR_PALETTE.PRIMARY_BASE} />
              }
              message={
                <FormattedMessageWithValidation id="overview_superboosted_properties_card_title" />
              }
              amount={
                <>
                  <Heading2>
                    <FormattedNumber value={superboostedProperties} />
                  </Heading2>
                  <Heading2 $color={COLOR_PALETTE.NEUTRAL_A40}>
                    {' '}
                    / <FormattedNumber value={maxSuperboostableProperties} />
                  </Heading2>
                </>
              }
              linkTo={
                boostedProperties > 0 &&
                `${PROPERTIES}?status=published-superboosted`
              }
              elementName="navigate-to-superboosted-listings-btn"
              eventName="navigate-to-superboosted-listings"
              sectionName="overview-listings-counter-right-nav-to-listings-button"
            />
          </Grid.Area>
        </>
      )}
      {!isMXPublisher && (
        <>
          {hasSuperboostEnabled && (
            <Grid.Area $id="date">
              <ImportStatsSummaryColumn>
                <PropertiesCard
                  message={
                    <FormattedMessageWithValidation id="overview_published_properties_card_title_execution_date" />
                  }
                  amount={
                    <Heading2>
                      {date ? (
                        <CustomFormattedDate value={new Date(date)} />
                      ) : null}
                    </Heading2>
                  }
                />
                <PropertiesCard
                  message={
                    <FormattedMessageWithValidation id="overview_published_properties_card_title_total" />
                  }
                  amount={
                    <Heading2>
                      <FormattedNumber value={total} />
                    </Heading2>
                  }
                />
              </ImportStatsSummaryColumn>
            </Grid.Area>
          )}
          {!hasSuperboostEnabled && (
            <Grid.Area $id="total">
              <PropertiesCard
                icon={
                  <Icon glyph="import" $color={COLOR_PALETTE.NEUTRAL_A80} />
                }
                message={
                  <FormattedMessageWithValidation id="overview_published_properties_card_title_total" />
                }
                amount={
                  <Heading2>
                    <FormattedNumber value={total} />
                  </Heading2>
                }
              />
            </Grid.Area>
          )}
          {hasSuperboostEnabled && (
            <Grid.Area $id="superboosted">
              <PropertiesCard
                icon={
                  <Icon
                    glyph="superboost"
                    $color={COLOR_PALETTE.PRIMARY_BASE}
                  />
                }
                message={
                  <FormattedMessageWithValidation id="overview_superboosted_properties_card_title" />
                }
                amount={
                  <>
                    <Heading2>
                      <FormattedNumber value={superboostedProperties} />
                    </Heading2>
                    <Heading2 $color={COLOR_PALETTE.NEUTRAL_A40}>
                      {' '}
                      / <FormattedNumber value={maxSuperboostableProperties} />
                    </Heading2>
                  </>
                }
                linkTo={
                  boostedProperties > 0 &&
                  `${PROPERTIES}?status=published-superboosted`
                }
                elementName="navigate-to-superboosted-listings-btn"
                eventName="navigate-to-superboosted-listings"
                sectionName="overview-listings-counter-right-nav-to-listings-button"
              />
            </Grid.Area>
          )}
          <Grid.Area $id="boosted">
            <PropertiesCard
              icon={<Icon glyph="bolt" $color={COLOR_PALETTE.PRIMARY_BASE} />}
              message={
                <FormattedMessageWithValidation id="overview_published_properties_card_title_boosted" />
              }
              amount={
                <>
                  <Heading2>
                    <FormattedNumber value={boostedProperties} />
                  </Heading2>
                  {boostedProperties > 0 && (
                    <Heading2 $color={COLOR_PALETTE.NEUTRAL_A40}>
                      {' '}
                      / <FormattedNumber value={maxBoostableProperties} />
                    </Heading2>
                  )}
                </>
              }
              linkTo={
                boostedProperties > 0 &&
                `${PROPERTIES}?status=published-boosted`
              }
              elementName="navigate-to-boosted-listings-btn"
              eventName="navigate-to-boosted-listings"
              sectionName="overview-listings-counter-right-nav-to-listings-button"
            />
          </Grid.Area>
          <Grid.Area $id="unboosted">
            <PropertiesCard
              icon={
                <Icon glyph="boltCrossed" $color={COLOR_PALETTE.NEUTRAL_A40} />
              }
              message={
                <FormattedMessageWithValidation id="overview_published_properties_card_title_not_boosted" />
              }
              amount={
                <Heading2>
                  <FormattedNumber value={nonBoostedProperties} />
                </Heading2>
              }
              linkTo={
                nonBoostedProperties > 0 &&
                `${PROPERTIES}?status=published-not-boosted`
              }
              elementName="navigate-to-published-not-boosted-listings-btn"
              eventName="navigate-to-published-not-boosted-listings"
              sectionName="overview-listings-counter-right-nav-to-listings-button"
            />
          </Grid.Area>
        </>
      )}
      <Grid.Area $id="errors">
        <PropertiesCard
          icon={<Icon glyph="error" $color={COLOR_PALETTE.STRAWBERRY_BASE} />}
          message={
            <FormattedMessageWithValidation id="overview_published_properties_card_title_with_error" />
          }
          amount={
            <Heading2>
              <FormattedNumber value={withErrors} />
            </Heading2>
          }
          linkTo={IMPORT_STATS}
          elementName="navigate-to-import-stats-btn"
          eventName="navigate-to-import-stats"
          sectionName="overview-listings-counter-right-nav-to-import-stats-button"
        />
      </Grid.Area>
      <Grid.Area $id="duplicated">
        <PropertiesCard
          icon={<Icon glyph="duplicate" $color={COLOR_PALETTE.NEUTRAL_A80} />}
          message={
            <FormattedMessageWithValidation id="overview_published_properties_card_title_duplicated" />
          }
          amount={
            <Heading2>
              <FormattedNumber value={duplicated} />
            </Heading2>
          }
        />
      </Grid.Area>
      {(!hasSuperboostEnabled || isMXPublisher) && (
        <Grid.Area $id="date">
          <PropertiesCard
            icon={
              <Icon glyph="execution" $color={COLOR_PALETTE.NEUTRAL_BASE} />
            }
            message={
              <FormattedMessageWithValidation id="overview_published_properties_card_title_execution_date" />
            }
            amount={
              <Heading2>
                {date ? <CustomFormattedDate value={new Date(date)} /> : null}
              </Heading2>
            }
          />
        </Grid.Area>
      )}
    </Grid>
  );
}

function PublishedProperties() {
  const publishedProperties = useSelector(publishedPropertiesSelector);
  const boostedProperties = useSelector(propertiesWithBoostingSelector);
  const superboostedProperties = useSelector(
    propertiesWithSuperboostingSelector
  );
  const isMXPublisher = useSelector(isPublisherFromMxSelector);
  const maxSuperboostableProperties = useSelector(
    maxSuperboostablePropertiesSelector
  );
  const maxBoostableProperties = useSelector(maxBoostablePropertiesSelector);
  const nonBoostedProperties = useSelector(propertiesWithoutBoostingSelector);
  const importStatsSummary = useSelector(importStatsSummarySelector);
  const showImportStats = useSelector(showImportStatsSelector);
  const isFreePublisher = useSelector(publisherSubscriptionPlanIsFreeSelector);
  const isPaidPublisher = useSelector(publisherSubscriptionPlanIsPaidSelector);
  const hasAnyPropertyCreated = useSelector(publisherHasAnyCreatedAdSelector);
  const showEmptyState = isFreePublisher && hasAnyPropertyCreated === false;
  const showPropertyCards =
    isPaidPublisher || (isFreePublisher && hasAnyPropertyCreated);

  return (
    <Wrapper>
      <Header>
        <Overline>
          {showImportStats ? (
            <FormattedMessageWithValidation id="overview_published_properties_section_stats_title" />
          ) : (
            <FormattedMessageWithValidation id="overview_published_properties_section_title" />
          )}
        </Overline>
      </Header>

      {showEmptyState && <NoPropertiesCreated />}

      {!showImportStats && showPropertyCards && (
        <PropertiesSummaryForManualPublisher
          publishedProperties={publishedProperties}
          boostedProperties={boostedProperties}
          maxBoostableProperties={maxBoostableProperties}
          nonBoostedProperties={nonBoostedProperties}
          superboostedProperties={superboostedProperties}
          maxSuperboostableProperties={maxSuperboostableProperties}
          isMXPublisher={isMXPublisher}
        />
      )}

      {showImportStats && showPropertyCards && (
        <PropertiesSummaryForAutomaticPublisher
          publishedProperties={publishedProperties}
          boostedProperties={boostedProperties}
          maxBoostableProperties={maxBoostableProperties}
          nonBoostedProperties={nonBoostedProperties}
          superboostedProperties={superboostedProperties}
          maxSuperboostableProperties={maxSuperboostableProperties}
          isMXPublisher={isMXPublisher}
          {...importStatsSummary}
        />
      )}
    </Wrapper>
  );
}

export default PublishedProperties;
