import { useSelector } from 'reffects-store';
import { isAllowedSelector } from '../selectors/permissions';

export function ProtectedComponent({ action, control, forced, children }) {
  const show = useSelector(isAllowedSelector(action));
  if (control !== undefined && !control) {
    return null;
  }

  if (!forced && !show) {
    return null;
  }

  return children;
}
