import Dialog, {
  DIALOG_THEME,
  DIALOG_WIDTH,
  FOOTER_CONTENT_POSITION,
} from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  OutlinePrimaryButton,
  PrimaryButton,
} from 'design-system/components/Button/presets';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { dispatch } from 'reffects';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import Typography from 'design-system/components/Typography/Typography';
import { useMatch } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import { useDialog } from '../../../hooks/useDialog';
import { FREE_PUBLISHER_OFFERS_MODAL } from './constants';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import PublisherSites from '../../PublisherSites';
import useTracking from '../../../hooks/useTracking';
import { GO_TO_MY_SUBSCRIPTION_PAGE } from './events';
import { useFeatureFlagForPublisherCountrySelector } from '../../../utils/featuresFlags/selectors';
import { isXSPublisherSelector } from '../../../selectors/publisher';
import { offersForUpsellNowModalSelector } from '../../Offers/selectors';
import { OFFERS_MOUNTED } from '../../../pages/Offers/events';
import FreeToPaidOffersModalContent from '../../../pages/Subscription/partials/UpsellOffers/partials/FreeToPaidOffersModalContent/FreeToPaidOffersModalContent';

const Wrapper = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.L)};
  ${PublisherSites} {
    display: flex;
    flex-direction: row;
    gap: ${spacing.value(SPACING_SIZE.M)};
  }
`;

const Intro = styled(Typography)`
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;
const Closing = styled(Typography)`
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

const BulletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.S)};
  ${spacing.inset(SPACING_SIZE.S)};
  ${Typography} {
    display: flex;
    flex-direction: column;
    gap: ${spacing.value(SPACING_SIZE.XS)};
  }
`;

const Bullets = styled.div`
  display: flex;
  flex-direction: column;
  ${BulletWrapper}:not(:last-child) {
    ${divider.bottom(DIVIDER_SIZE.THIN)}
  }
  ${Typography} {
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
  }
`;

function Bullet({ tag, children }) {
  return (
    <BulletWrapper>
      <Icon glyph="check" $size={ICON_SIZE.SMALL} />
      <Typography>
        <FormattedMessageWithValidation id={tag} />
        {children}
      </Typography>
    </BulletWrapper>
  );
}

function DialogContent() {
  const ref = useTracking({
    trackImpression: {
      elementName: 'free-publisher-offers-modal',
    },
    sectionName: 'free-publisher-offers-modal',
  });
  return (
    <Wrapper ref={ref}>
      <Intro>
        <FormattedMessageWithValidation id="subscription_landing_modal_body_intro" />
      </Intro>
      <Bullets>
        <Bullet tag="subscription_landing_modal_body_bullet_1" />
        <Bullet tag="subscription_landing_modal_body_bullet_2" />
        <Bullet tag="subscription_landing_modal_body_bullet_3">
          <PublisherSites />
        </Bullet>
      </Bullets>
      <Closing>
        <FormattedMessageWithValidation id="subscription_landing_modal_body_closing" />
      </Closing>
    </Wrapper>
  );
}

function freePublisherModal(open, closeDialog) {
  return (
    <Dialog open={open} theme={DIALOG_THEME.DARK}>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id="subscription_landing_modal_header" />
      </Dialog.Header>
      {open && <DialogContent />}
      <Dialog.Footer alignment={FOOTER_CONTENT_POSITION.START}>
        <PrimaryButton onClick={() => dispatch(GO_TO_MY_SUBSCRIPTION_PAGE)}>
          <FormattedMessageWithValidation id="subscription_landing_modal_cta" />
        </PrimaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

export default function FreePublisherOffersModal() {
  const UPSELL_PLAN_MODAL_HOME_FREE_4194 =
    useFeatureFlagForPublisherCountrySelector(
      'UPSELL_PLAN_MODAL_HOME_FREE_4194'
    );
  const offers = useSelector(offersForUpsellNowModalSelector);
  const isXSPublisher = useSelector(isXSPublisherSelector);
  const { open, closeDialog } = useDialog(FREE_PUBLISHER_OFFERS_MODAL);
  const isInSubscriptionPage = useMatch('/subscription*');
  const showOffers = offers.length > 0;
  useMount(() => {
    dispatch(OFFERS_MOUNTED);
  });
  if (isInSubscriptionPage) {
    return null;
  }

  if (UPSELL_PLAN_MODAL_HOME_FREE_4194 && isXSPublisher && showOffers) {
    return ContractDialogContent(open, closeDialog, offers);
  }
  if (!UPSELL_PLAN_MODAL_HOME_FREE_4194) {
    return freePublisherModal(open, closeDialog);
  }
  return null;
}

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.L)};
`;

function ContractDialogContent(open, closeDialog, offers) {
  let dialogWidth = DIALOG_WIDTH.LARGE;
  if (offers.length < 3) {
    dialogWidth = DIALOG_WIDTH.PRIMARY;
  }
  return (
    <Dialog open={open} width={dialogWidth}>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id="subscription_landing_modal_header" />
      </Dialog.Header>
      {open && (
        <Centered>
          <Dialog.Content>
            <Dialog.Text>
              <FormattedMessageWithValidation id="subscription_landing_modal_body_intro" />
            </Dialog.Text>
            <FreeToPaidOffersModalContent
              offers={offers}
              section="overview_free_to_paid_publisher_offer_modal"
              offerType="NewContract"
            />
          </Dialog.Content>
          <OutlinePrimaryButton
            onClick={() => dispatch(GO_TO_MY_SUBSCRIPTION_PAGE)}
          >
            <FormattedMessageWithValidation id="overview_free_to_paid_modal_subscription_page_cta" />
          </OutlinePrimaryButton>
        </Centered>
      )}
      <Dialog.Footer alignment={FOOTER_CONTENT_POSITION.END}>
        <FlatButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="modal_button_close" />
        </FlatButton>
      </Dialog.Footer>
    </Dialog>
  );
}
