import { isPremiumSubscriptionSelector } from '../../../../../selectors/publisher';
import { getFeatureFlagForPublisherCountrySelector } from '../../../../../utils/featuresFlags/selectors';

export function leadsPropertiesSelector(state) {
  return state.leadsProperties ?? [];
}

export function shouldDisplayHotnessColumnSelector(state) {
  const TEASE_PREMIUM_4271 = getFeatureFlagForPublisherCountrySelector(
    state,
    'TEASE_PREMIUM_4271'
  );
  return TEASE_PREMIUM_4271 || isPremiumSubscriptionSelector(state);
}
