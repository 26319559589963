import styled from 'styled-components';
import ConfirmDeleteImageDialog from '../../ConfirmDeleteImageDialog';
import SortableContainer from '../../SortableContainer/SortableContainer';

const Wrapper = styled.div``;

export default function Gallery({
  images,
  onImagesSorted,
  onDelete,
  showDeleteImageDialog,
  onConfirmDeleteImage,
  onCancelDeleteImage,
  disabledDragAndDrop,
}) {
  if (images.length === 0) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <SortableContainer
          images={images}
          onDelete={onDelete}
          onSort={onImagesSorted}
          disabledDragAndDrop={disabledDragAndDrop}
        />
      </Wrapper>
      <ConfirmDeleteImageDialog
        open={showDeleteImageDialog}
        onConfirm={onConfirmDeleteImage}
        onCancel={onCancelDeleteImage}
      />
    </>
  );
}
