import { useSelector } from 'reffects-store';
import InputRow from 'design-system/components/InputRow';
import { dispatch } from 'reffects';
import SingleChoiceField from '../../../partials/fields/SingleChoiceField';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { propertyTypesWithTranslationTagsSelector } from '../../../selectors/country';
import { FIELD_VALUE_CHANGED } from '../events';

export default function PropertyTypeSection({ choiceLabel }) {
  const propertyTypesOptions = useSelector(
    propertyTypesWithTranslationTagsSelector
  );

  return (
    <InputRow>
      <SingleChoiceField
        required
        label={choiceLabel}
        name="propertyType"
        choices={Object.entries(propertyTypesOptions).map(([name, tag]) => ({
          label: <FormattedMessageWithValidation id={tag} />,
          value: name,
        }))}
        onChange={({ target }) =>
          dispatch({
            id: FIELD_VALUE_CHANGED,
            payload: { field: 'propertyType', value: target.value },
          })
        }
      />
    </InputRow>
  );
}
