import { Link, useMatch } from 'react-router-dom';
import styled from 'styled-components';
import NavigationBar from 'design-system/components/NavigationBar/NavigationBar';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import useTracking from '../../hooks/useTracking';
import UnreadCount from '../UnreadCount/UnreadCount';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${NavigationBar.TabLink} {
    ${({ $hasUnreadNotifications }) =>
      $hasUnreadNotifications && spacing.inline(SPACING_SIZE.NONE)};
  }
`;

function TabLink({
  to,
  children,
  deep,
  eventName,
  elementName,
  sectionName,
  unreadNotifications,
  ...props
}) {
  const activePathMatch = deep ? `${to}/*`.replace('//', '/') : to;
  const isCurrent = useMatch(activePathMatch);
  const trackingRef = useTracking({
    trackClick: {
      eventName,
    },
    sectionName,
  });
  const hasUnreadNotifications = Boolean(unreadNotifications);

  return (
    <Wrapper
      ref={trackingRef}
      $hasUnreadNotifications={hasUnreadNotifications}
      {...props}
    >
      <NavigationBar.TabLink as={Link} to={to} $isCurrent={isCurrent}>
        {children}
      </NavigationBar.TabLink>
      {hasUnreadNotifications && (
        <UnreadCount numberOfUnreadElements={unreadNotifications} />
      )}
    </Wrapper>
  );
}

export default styled(TabLink)``;
