import {
  QUALIFICATION_PREFERRABLE_CHANNELS_EXTRA_DATA,
  QUALIFICATION_QUESTIONS,
  QUALIFICATIONS_WITH_MULTIPLE_ANSWERS,
} from './constants';

export function isLoadingEnquiryQualificationSelector(state) {
  return state.enquiryQualification == null;
}

export function isLoadingLeadQualificationSelector(state) {
  return state.leadQualification == null;
}

export function leadQualificationSelector(state) {
  if (isLoadingLeadQualificationSelector(state)) {
    return {};
  }

  return state.leadQualification;
}

export function enquiryQualificationSelector(state) {
  if (isLoadingEnquiryQualificationSelector(state)) {
    return {};
  }

  const { enquiryQualification } = state;
  return buildQuestions(enquiryQualification);
}

function buildQuestions(enquiryQualification) {
  const finalQuestions = {};
  QUALIFICATION_QUESTIONS.forEach(({ key, value }) => {
    const question = buildQuestion(key, value, enquiryQualification);
    if (question) {
      finalQuestions[key] = question;
    }
  });

  return finalQuestions;
}

function buildQuestion(questionKey, questionTag, enquiryQualification) {
  switch (questionKey) {
    case 'budget':
      return buildBudgetQuestion(
        questionKey,
        enquiryQualification,
        questionTag
      );
    case 'region':
      if (
        isNotValidValue(enquiryQualification.region) ||
        !enquiryQualification.minRooms
      ) {
        return null;
      }
      return {
        question: questionTag,
        answers: [
          {
            label: QUALIFICATIONS_WITH_MULTIPLE_ANSWERS[questionKey][0],
            value: {
              region: enquiryQualification.region,
              minRooms: enquiryQualification.minRooms,
              ...(enquiryQualification.maxRooms && {
                maxRooms: enquiryQualification.maxRooms,
              }),
            },
          },
        ],
      };
    case 'infoRequired':
      return buildInfoRequiredQuestion(
        questionKey,
        enquiryQualification,
        questionTag
      );
    case 'preferredChannel':
      return buildPreferredChannelQuestion(
        questionKey,
        enquiryQualification,
        questionTag
      );
    case 'funding':
      return buildFundingQuestion(enquiryQualification, questionTag);
    default:
      if (isNotValidValue(enquiryQualification[questionKey])) {
        return null;
      }
      return {
        question: questionTag,
        answers: Array.isArray(enquiryQualification[questionKey])
          ? enquiryQualification[questionKey]
          : [enquiryQualification[questionKey]],
      };
  }
}

function buildBudgetQuestion(key, enquiryQualification, questionTag) {
  const budgetQuestions = [];
  if (enquiryQualification.minBudget) {
    budgetQuestions.push({
      label: QUALIFICATIONS_WITH_MULTIPLE_ANSWERS[key][0],
      value: enquiryQualification.minBudget,
    });
  }
  if (enquiryQualification.maxBudget) {
    budgetQuestions.push({
      label: QUALIFICATIONS_WITH_MULTIPLE_ANSWERS[key][1],
      value: enquiryQualification.maxBudget,
    });
  }

  if (budgetQuestions.length === 0) {
    return null;
  }

  return {
    question: questionTag,
    answers: budgetQuestions,
  };
}

function buildInfoRequiredQuestion(key, enquiryQualification, questionTag) {
  const infoRequired = enquiryQualification[key];
  if (!infoRequired || infoRequired.length === 0) {
    return null;
  }

  const valuesTranslations = QUALIFICATIONS_WITH_MULTIPLE_ANSWERS[key];
  const infoRequiredQuestions = infoRequired.map((info) => ({
    label: valuesTranslations.find((value) => value.key === info).value,
    value: info,
  }));

  return {
    question: questionTag,
    answers: infoRequiredQuestions,
  };
}

function buildPreferredChannelQuestion(key, enquiryQualification, questionTag) {
  const preferredChannel = enquiryQualification[key];

  if (isNotValidValue(preferredChannel)) {
    return null;
  }

  const finalPreferredChannel = preferredChannel.map((channel) => {
    const channelData = QUALIFICATION_PREFERRABLE_CHANNELS_EXTRA_DATA[channel];
    return {
      label: channelData.label,
      icon: channelData.icon,
      value: channel,
    };
  });

  return {
    question: questionTag,
    answers: finalPreferredChannel,
  };
}

function buildFundingQuestion(enquiryQualification, questionTag) {
  const { funding } = enquiryQualification;
  if (isNotValidValue(funding)) {
    return null;
  }

  const valuesTranslations = QUALIFICATIONS_WITH_MULTIPLE_ANSWERS.funding;
  const infoRequiredQuestions = funding.map((fundingValues) => ({
    label: valuesTranslations.find((value) => value.key === fundingValues)
      .value,
    value: fundingValues,
  }));

  return {
    question: questionTag,
    answers: infoRequiredQuestions,
  };
}

function isNotValidValue(value) {
  return !value || (Array.isArray(value) && value.length === 0);
}
