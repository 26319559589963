import { COUNTRY_CODE } from '../../../../../../../constants/country';
import { isMexico } from '../../../../../../../utils/countries';
import { plansSelector } from '../../../../selectors';
import {
  oneOf,
  userIsFinanceManagerSelector,
  userIsFinanceSelector,
  userIsSalesManagerSelector,
  userIsSuperadminSelector,
} from '../../../../../../../selectors/user';
import { MEXICO_TYPE_OF_CLIENT } from '../../../../../CreatePublisher/constants';
import { isAllowedSelector } from '../../../../../../../selectors/permissions';
import { filterPlansByTypeOfClient } from '../../../../../../utils/plansFinder';

export function showPublishesInThailandPropertyCheckboxSelector(state) {
  return selectedPublisherCountryCodeSelector(state) === COUNTRY_CODE.THAILAND;
}

export function showTypeOfClientSelector(state) {
  return isMexico(selectedPublisherCountryCodeSelector(state));
}

export function plansByMaxBoostablePropertiesSelector(state) {
  const maxBoostablePropsSelected = selectedBoostingLimitSelector(state);
  const plans = availablePlansToChangeSubscriptionSelector(state).filter(
    ({ maxBoostableProperties }) =>
      maxBoostableProperties === maxBoostablePropsSelected
  );

  return plans
    .filter((plan) => plan.active)
    .filter((plan) => plan.price > 0)
    .sort((plan, anotherPlan) => plan.price - anotherPlan.price);
}

export function selectedPublishesInThailandPropertySelector(state) {
  return selectedOptionsSelector(state).publishesInThailandProperty ?? false;
}

export function selectedPlanIdSelector(state) {
  return selectedOptionsSelector(state).planId ?? '';
}

export function selectedBillingCycleSelector(state) {
  return selectedOptionsSelector(state).billingCycle ?? '';
}

export function selectedBoostingLimitSelector(state) {
  return selectedOptionsSelector(state).boostingLimit ?? '';
}

export function selectedSuperboostingLimitSelector(state) {
  return selectedOptionsSelector(state).superboostingLimit ?? '';
}

export function selectedTypeOfClientSelector(state) {
  const plans = availablePlansToChangeSubscriptionSelector(state);
  const selectedPlanId = selectedPlanIdSelector(state);
  const foundPlan = plans.find((plan) => plan.id === selectedPlanId);
  if (foundPlan?.isPremium) {
    return foundPlan.typeOfClient === MEXICO_TYPE_OF_CLIENT.AGENCIA
      ? MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM
      : MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM;
  }

  return selectedOptionsSelector(state)?.typeOfClient;
}

export function availableTypeOfClientOptionsSelector(state) {
  const typeOfClients = [
    MEXICO_TYPE_OF_CLIENT.AGENCIA,
    MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM,
    MEXICO_TYPE_OF_CLIENT.REMATES,
    MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM,
  ];
  if (canModifyPremium(state)) {
    return typeOfClients;
  }
  const subscription = selectedSubscriptionSelector(state);
  if (subscription.isPremium) {
    return [
      MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM,
      MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM,
    ];
  }
  return [MEXICO_TYPE_OF_CLIENT.AGENCIA, MEXICO_TYPE_OF_CLIENT.REMATES];
}

export function selectedStartDateSelector(state) {
  return selectedOptionsSelector(state).startDate ?? '';
}

export function selectedNextBillingDateSelector(state) {
  return selectedOptionsSelector(state).nextBillingDate ?? '';
}

export function selectedBaseSubscriptionFinalPriceSelector(state) {
  return selectedOptionsSelector(state).baseSubscriptionFinalPrice ?? '';
}

export function selectedSuperboostFinalPriceSelector(state) {
  return selectedOptionsSelector(state).superboostFinalPrice ?? '';
}

export function reasonSelector(state) {
  return selectedOptionsSelector(state).reason ?? '';
}

export function superboostErrorMessageSelector(state) {
  const selectedSuperboostingLimit = selectedSuperboostingLimitSelector(state);
  const selectedBoostingLimit = selectedBoostingLimitSelector(state);
  if (!selectedBoostingLimit) {
    return undefined;
  }
  if (selectedSuperboostingLimit > selectedBoostingLimit) {
    return `The amount can't be greater than boostable properties`;
  }
  const minValue = minValueForSuperboostingLimitSelector(state);
  return selectedSuperboostingLimit >= minValue
    ? undefined
    : 'Superboost can not be set to 0';
}

function noFieldHasChanged({ changeSubscriptionModal }) {
  const { selectedOptions, selectedPublisher } = changeSubscriptionModal;

  const selectedSuperboostingLimit = Number(selectedOptions.superboostingLimit);

  return (
    selectedOptions.billingCycle ===
      selectedPublisher.subscription.billingCycleInMonths &&
    selectedOptions.boostingLimit ===
      selectedPublisher.subscription.maxBoostableProperties &&
    selectedSuperboostingLimit ===
      selectedPublisher.superboost.maxBoostableProperties &&
    selectedOptions.planId === selectedPublisher.subscription.productId &&
    selectedOptions.typeOfClient === selectedPublisher.typeOfClient &&
    selectedOptions.startDate === selectedPublisher.subscription.startDate &&
    selectedOptions.nextBillingDate ===
      selectedPublisher.subscription.nextBillingDate &&
    selectedOptions.baseSubscriptionFinalPrice ===
      selectedPublisher.subscription.totalPrice.amount &&
    selectedOptions.superboostFinalPrice ===
      selectedPublisher.superboost.totalPrice.amount &&
    selectedOptions.publishesInThailandProperty ===
      selectedPublisher.isThailandPropertyClient
  );
}

export function canEditBillingCycleSelector(state) {
  return userIsSuperadminSelector(state);
}

export function canEditStartDateSelector(state) {
  return userIsSuperadminSelector(state);
}

export function canEditNextBillingDateSelector(state) {
  return oneOf(
    userIsSuperadminSelector,
    userIsSalesManagerSelector,
    userIsFinanceManagerSelector,
    userIsFinanceSelector
  )(state);
}

export function minNextBillingDateSelector(state) {
  if (
    oneOf(
      userIsFinanceSelector,
      userIsFinanceManagerSelector,
      userIsSalesManagerSelector
    )(state)
  ) {
    return selectedPublisherSelector(state)?.subscription?.nextBillingDate;
  }
  return undefined;
}

export function canEditSubscriptionPriceSelector(state) {
  return isAllowedSelector('publisher/modify-subscription/price')(state);
}

export function canSubmitFormSelector(state) {
  const selectedSuperboostingLimit = selectedSuperboostingLimitSelector(state);
  const selectedBoostingLimit = selectedBoostingLimitSelector(state);
  const isInProgress = changeSubscriptionIsInProgressSelector(state);

  if (isInProgress) {
    return false;
  }
  if (!selectedBoostingLimit) {
    return false;
  }
  if (noFieldHasChanged(state)) {
    return false;
  }
  const minValue = minValueForSuperboostingLimitSelector(state);
  if (selectedSuperboostingLimit > selectedBoostingLimit) {
    return false;
  }
  return selectedSuperboostingLimit >= minValue;
}

function canModifyPremium(state) {
  return isAllowedSelector('publisher/modify-subscription/premium')(state);
}

function minValueForSuperboostingLimitSelector(state) {
  return selectedPublisherSelector(state).superboost?.maxBoostableProperties > 0
    ? 1
    : 0;
}

export function changeSubscriptionIsInProgressSelector(state) {
  return modalSelector(state).inProgress;
}

export function maxBoostablePropertiesOptionsSelector(state) {
  const plans = filterPlansByTypeOfClient(
    selectedPublisherCountryCodeSelector(state),
    selectedTypeOfClientSelector(state),
    availablePlansToChangeSubscriptionSelector(state)
  );
  const { productId } = selectedSubscriptionSelector(state);
  return Array.from(
    new Set(
      plans
        .filter(({ active, id }) => active || id === productId)
        .filter((plan) => plan.price > 0)
        .map(({ maxBoostableProperties: maxProp }) => maxProp)
        .sort((maxProp, anotherMaxProp) => maxProp - anotherMaxProp)
    )
  );
}

export function selectedPublisherCountryCodeSelector(state) {
  return selectedPublisherSelector(state).countryCode;
}

function availablePlansToChangeSubscriptionSelector(state) {
  if (canModifyPremium(state)) {
    return plansSelector(state);
  }
  const hasPremium = selectedSubscriptionSelector(state).isPremium ?? false;
  return plansSelector(state).filter(
    ({ isPremium }) => isPremium === hasPremium
  );
}

function selectedSubscriptionSelector(state) {
  return selectedPublisherSelector(state).subscription ?? {};
}

function selectedPublisherSelector(state) {
  return modalSelector(state).selectedPublisher ?? {};
}

function selectedOptionsSelector(state) {
  return modalSelector(state).selectedOptions ?? {};
}

function modalSelector(state) {
  return state.changeSubscriptionModal ?? {};
}
