import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { isEmpty } from 'lodash';
import { toast } from '../../../effects/toast';
import { isValidUUID } from '../../../utils/strings';
import { addArray, addObject } from '../../../utils/formDataUtils';
import { uuid } from '../../../coeffects/uuid';
import { environment } from '../../../coeffects/environment';
import { optimizeImages } from '../../../effects/optimizeImages';
import { PUBLISHER_TYPE } from '../../../constants/publisher';
import {
  CREATE_PUBLISHER_PAGE,
  CREATION_STEP,
  INVALID_UUID_ERROR,
  LOADING_ADS_MODE,
  MANDATORY_PUBLISHER_MAPPINGS_ERROR,
  MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE,
} from './constants';
import { navigateTo } from '../../../effects/routing';
import { composeSearchParams } from '../../../utils/url';
import { TABLE } from '../Developers/constants';
import { location } from '../../../coeffects/location';
import { combineStateSetEffects } from '../../../partials/DataTable/utils/combineStateSetEffects';
import { PATHS } from '../../constants/routes';
import { isMexico } from '../../../utils/countries';
import { OPEN_DIALOG } from '../../../events/dialogs';

import { GENERIC_CONFIRMATION_MODAL } from '../../../partials/modals/generic/constants';
import { PLANS_PER_COUNTRY_REQUESTED } from '../events';
import { PLANS_STATE_REGION } from '../constants';
import { findMexicanPlanByTypeOfClientAndMaxBoostableProperties } from '../../utils/plansFinder';

export const FORM_SUBMISSION_PUBLISHER_FINISHED =
  'FORM_SUBMISSION_PUBLISHER_FINISHED';
export const CREATE_PUBLISHER_FORM_VALUE_CHANGED =
  'CREATE_PUBLISHER_FORM_VALUE_CHANGED';
export const CREATE_PUBLISHER_FORM_LOADED = 'CREATE_PUBLISHER_FORM_LOADED';
export const CREATE_PUBLISHER_FORM_PLAN_OPTION_SELECTED =
  'CREATE_PUBLISHER_FORM_PLAN_OPTION_SELECTED';
export const CREATE_PUBLISHER_FORM_TYPE_OF_CLIENT_OPTION_SELECTED =
  'CREATE_PUBLISHER_FORM_TYPE_OF_CLIENT_OPTION_SELECTED';
export const CREATE_PUBLISHER_SUBMITTED = 'CREATE_PUBLISHER_SUBMITTED';
export const NEW_CLIENT_CHECKBOX_CHANGED = 'NEW_CLIENT_CHECKBOX_CHANGED';
export const CREATE_PUBLISHER_ATTEMPTED = 'CREATE_PUBLISHER_ATTEMPTED';
export const CREATE_PUBLISHER_IMAGE_UPLOADED =
  'CREATE_PUBLISHER_IMAGE_UPLOADED';
export const CREATE_PUBLISHER_IMAGE_OPTIMIZED =
  'CREATE_PUBLISHER_IMAGE_OPTIMIZED';
export const CREATE_PUBLISHER_IMAGE_DELETE_REQUESTED =
  'CREATE_PUBLISHER_IMAGE_DELETE_REQUESTED';
export const CREATE_PUBLISHER_IMAGE_DELETE_CONFIRMED =
  'CREATE_PUBLISHER_IMAGE_DELETE_CONFIRMED';
export const CREATE_PUBLISHER_FORM_MAX_BOOSTABLE_PROPERTIES_SELECTED =
  'CREATE_PUBLISHER_FORM_MAX_BOOSTABLE_PROPERTIES_SELECTED';
export const CREATE_PUBLISHER_FORM_COUNTRY_SELECTED =
  'CREATE_PUBLISHER_FORM_COUNTRY_SELECTED';
export const CREATE_PUBLISHER_FORM_THAILAND_PROPERTY_SELECTED =
  'CREATE_PUBLISHER_FORM_THAILAND_PROPERTY_SELECTED';
export const CREATE_PUBLISHER_IMAGES_OPTIMIZATION_FAILED =
  'CREATE_PUBLISHER_IMAGES_OPTIMIZATION_FAILED';
export const PUBLISHER_TYPE_SELECTED = 'PUBLISHER_TYPE_SELECTED';
export const PUBLISHER_MAPPING_VALUE_CHANGED =
  'PUBLISHER_MAPPING_VALUE_CHANGED';
export const PUBLISHER_MAPPING_DELETED = 'PUBLISHER_MAPPING_DELETED';
export const BILLING_ENTITY_SELECTED = 'BILLING_ENTITY_SELECTED';
export const MULTI_ACCOUNT_CLIENT_INFORMATION_CLEARED =
  'MULTI_ACCOUNT_CLIENT_INFORMATION_CLEARED';
export const PLANS_PER_COUNTRY_FOR_CREATION_REQUESTED =
  'PLANS_PER_COUNTRY_FOR_CREATION_REQUESTED';
export const FORM_SUBMISSION_FAILED = 'FORM_SUBMISSION_FAILED';

export const CREATE_DEVELOPER_SUBMITTED = 'CREATE_DEVELOPER_SUBMITTED';
export const CREATE_DEVELOPER_SUCCEEDED = 'CREATE_DEVELOPER_SUCCEEDED';
export const MULTIPLE_ACCOUNT_TYPE_CHANGED = 'MULTIPLE_ACCOUNT_TYPE_CHANGED';
export const CREATE_PUBLISHER_FORM_BILLING_CYCLE_CHANGED =
  'CREATE_PUBLISHER_FORM_BILLING_CYCLE_CHANGED';

export const LOAD_AVAILABLE_PAYMENT_METHODS = 'LOAD_AVAILABLE_PAYMENT_METHODS';
export const AVAILABLE_PAYMENT_METHODS_LOADED =
  'AVAILABLE_PAYMENT_METHODS_LOADED';
export const AVAILABLE_PAYMENT_METHODS_FAILED =
  'AVAILABLE_PAYMENT_METHODS_FAILED';

registerEventHandler(CREATE_PUBLISHER_FORM_LOADED, () =>
  state.merge({
    [CREATE_PUBLISHER_PAGE]: {
      id: '',
      name: '',
      type: 'agency',
      plan: '',
      billingDate: '',
      paymentMethod: '',
      availablePaymentMethods: [],
      maxBoostablePropertiesSelected: '',
      superboostableProperties: 0,
      country: '',
      isNewPublisher: false,
      billingCycle: '',
      image: '',
      isTestPublisher: false,
      loadingAdsMode: '',
      publishInThailandProperty: false,
      step: 'creation-form',
      selectedProjects: {},
      publisherMappings: [],
      selectedBillingEntity: null,
      selectedSalesPerson: null,
      selectedDeal: null,
      displayableDeal: '',
    },
  })
);

registerEventHandler(
  FORM_SUBMISSION_PUBLISHER_FINISHED,
  ({ state: { id, country, type }, location: { href } }) => {
    const params = {
      [`filter-${TABLE.id}-adminFinanceCountry`]: country,
      [`filter-${TABLE.id}-searchValue`]: id,
    };

    const path = composeSearchParams(href, params);
    return {
      ...state.set({
        [`${CREATE_PUBLISHER_PAGE}.submittingForm`]: false,
      }),
      ...navigateTo(
        type === PUBLISHER_TYPE.DEVELOPER
          ? `${PATHS.DEVELOPERS}${path}`
          : `${PATHS.AGENCIES}${composeSearchParams(href, {
              country,
              q: id,
            })}`
      ),
      ...toast.show({
        text: 'Publisher created successfully',
      }),
    };
  },
  [
    state.get({
      id: `${CREATE_PUBLISHER_PAGE}.id`,
      type: `${CREATE_PUBLISHER_PAGE}.type`,
      country: `${CREATE_PUBLISHER_PAGE}.country`,
    }),
    location(),
  ]
);

registerEventHandler(FORM_SUBMISSION_FAILED, () => ({
  ...state.set({
    [`${CREATE_PUBLISHER_PAGE}.submittingForm`]: false,
  }),
  ...toast.show({
    text: 'Cant create publisher. Check that name or id are unique',
  }),
}));

registerEventHandler(
  PLANS_PER_COUNTRY_FOR_CREATION_REQUESTED,
  (_, payload) => ({
    ...state.set({
      [`${CREATE_PUBLISHER_PAGE}.plan`]: '',
    }),
    ...effects.dispatch({ id: PLANS_PER_COUNTRY_REQUESTED, payload }),
  })
);

registerEventHandler(
  CREATE_PUBLISHER_FORM_VALUE_CHANGED,
  (_, { name, value }) =>
    state.set({
      [`${CREATE_PUBLISHER_PAGE}.${name}`]: value,
    })
);

registerEventHandler(
  CREATE_PUBLISHER_FORM_PLAN_OPTION_SELECTED,
  (_, { plan }) => {
    let newStateValues = {};

    if (plan === 'free') {
      newStateValues = {
        paymentMethod: '',
        billingDate: null,
        billingCycle: 1,
        superboostableProperties: 0,
      };
    }

    return {
      ...state.merge({
        [CREATE_PUBLISHER_PAGE]: { ...newStateValues, plan },
      }),
      ...effects.dispatch(LOAD_AVAILABLE_PAYMENT_METHODS),
    };
  }
);

const FREE_PLAN_ID = 'free';
registerEventHandler(
  CREATE_PUBLISHER_FORM_TYPE_OF_CLIENT_OPTION_SELECTED,
  (_, { typeOfClient }) => {
    let newStateValues = {};

    if (typeOfClient === 'free') {
      newStateValues = {
        paymentMethod: '',
        billingDate: null,
        billingCycle: 1,
        superboostableProperties: 0,
        plan: FREE_PLAN_ID,
      };
    }

    return {
      ...state.merge({
        [CREATE_PUBLISHER_PAGE]: {
          ...newStateValues,
          typeOfClient,
          maxBoostablePropertiesSelected: null,
        },
      }),
      ...effects.dispatch(LOAD_AVAILABLE_PAYMENT_METHODS),
    };
  },
  []
);

registerEventHandler(
  CREATE_PUBLISHER_SUBMITTED,
  (
    {
      state: {
        image,
        multipleAccountClient,
        publisherSharingSubscriptionWithId,
        ...publisher
      },
      environment: { apiUrl },
    },
    { successEvent }
  ) => {
    if (
      multipleAccountClient === MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.SHARED
    ) {
      publisher = { ...publisher, publisherSharingSubscriptionWithId };
    }

    const formData = addArray([image], 'images', addObject({ publisher }));

    return http.post({
      url: `${apiUrl}/backoffice/publishers/agencies`,
      body: formData,
      successEvent,
      errorEvent: FORM_SUBMISSION_FAILED,
    });
  },
  [
    state.get({
      id: `${CREATE_PUBLISHER_PAGE}.id`,
      name: `${CREATE_PUBLISHER_PAGE}.name`,
      type: `${CREATE_PUBLISHER_PAGE}.type`,
      source: `${CREATE_PUBLISHER_PAGE}.source`,
      plan: `${CREATE_PUBLISHER_PAGE}.plan`,
      billingDate: `${CREATE_PUBLISHER_PAGE}.billingDate`,
      paymentMethod: `${CREATE_PUBLISHER_PAGE}.paymentMethod`,
      country: `${CREATE_PUBLISHER_PAGE}.country`,
      isNewPublisher: `${CREATE_PUBLISHER_PAGE}.isNewPublisher`,
      billingCycle: `${CREATE_PUBLISHER_PAGE}.billingCycle`,
      image: `${CREATE_PUBLISHER_PAGE}.image`,
      isTestPublisher: `${CREATE_PUBLISHER_PAGE}.isTestPublisher`,
      loadingAdsMode: `${CREATE_PUBLISHER_PAGE}.loadingAdsMode`,
      publishInThailandProperty: `${CREATE_PUBLISHER_PAGE}.publishInThailandProperty`,
      mappings: `${CREATE_PUBLISHER_PAGE}.publisherMappings`,
      billingEntityId: `${CREATE_PUBLISHER_PAGE}.selectedBillingEntity.id`,
      billingContactEmail: `${CREATE_PUBLISHER_PAGE}.billingContactEmail`,
      salesPersonId: `${CREATE_PUBLISHER_PAGE}.selectedSalesPerson.id`,
      dealId: `${CREATE_PUBLISHER_PAGE}.selectedDeal.id`,
      publisherSharingSubscriptionWithId: `${CREATE_PUBLISHER_PAGE}.selectedSiblingPublisher.id`,
      multipleAccountClient: `${CREATE_PUBLISHER_PAGE}.multipleAccountClient`,
      superboostableProperties: `${CREATE_PUBLISHER_PAGE}.superboostableProperties`,
      maxBoostableProperties: `${CREATE_PUBLISHER_PAGE}.maxBoostablePropertiesSelected`,
      additionalMonth: `${CREATE_PUBLISHER_PAGE}.additionalMonth`,
    }),
    environment(),
  ]
);

registerEventHandler(
  CREATE_DEVELOPER_SUBMITTED,
  ({ state: { image, ...publisher }, environment: { apiUrl } }) => {
    const formData = addArray(
      [image],
      'images',
      addObject({
        publisher,
      })
    );

    return {
      ...state.set({
        [`${CREATE_PUBLISHER_PAGE}.submittingForm`]: true,
      }),
      ...http.post({
        url: `${apiUrl}/backoffice/publishers/developers`,
        body: formData,
        successEvent: CREATE_DEVELOPER_SUCCEEDED,
        errorEvent: FORM_SUBMISSION_FAILED,
      }),
    };
  },
  [
    state.get({
      id: `${CREATE_PUBLISHER_PAGE}.id`,
      name: `${CREATE_PUBLISHER_PAGE}.name`,
      country: `${CREATE_PUBLISHER_PAGE}.country`,
      loadingAdsMode: `${CREATE_PUBLISHER_PAGE}.loadingAdsMode`,
      publishInThailandProperty: `${CREATE_PUBLISHER_PAGE}.publishInThailandProperty`,
      image: `${CREATE_PUBLISHER_PAGE}.image`,
      isTestPublisher: `${CREATE_PUBLISHER_PAGE}.isTestPublisher`,
      billingEntityId: `${CREATE_PUBLISHER_PAGE}.selectedBillingEntity.id`,
      billingContactEmail: `${CREATE_PUBLISHER_PAGE}.billingContactEmail`,
      mappings: `${CREATE_PUBLISHER_PAGE}.publisherMappings`,
      projects: `${CREATE_PUBLISHER_PAGE}.selectedProjects`,
      salesPersonId: `${CREATE_PUBLISHER_PAGE}.selectedSalesPerson.id`,
      dealId: `${CREATE_PUBLISHER_PAGE}.selectedDeal.id`,
    }),
    environment(),
  ]
);

registerEventHandler(CREATE_DEVELOPER_SUCCEEDED, () => ({
  ...state.set({
    [`${CREATE_PUBLISHER_PAGE}.submittingForm`]: false,
  }),
  ...navigateTo(`${PATHS.DEVELOPERS}?page=1`),
  ...toast.show({
    text: 'Developer created successfully',
  }),
}));

registerEventHandler(
  NEW_CLIENT_CHECKBOX_CHANGED,
  ({ uuid: { newUuid } }, { isNewPublisher }) => ({
    ...state.set({
      [`${CREATE_PUBLISHER_PAGE}.id`]: isNewPublisher ? newUuid : '',
      [`${CREATE_PUBLISHER_PAGE}.isNewPublisher`]: isNewPublisher,
      [`${CREATE_PUBLISHER_PAGE}.billingDate`]: null,
    }),
    ...effects.dispatch(MULTI_ACCOUNT_CLIENT_INFORMATION_CLEARED),
  }),
  [uuid()]
);

registerEventHandler(
  CREATE_PUBLISHER_ATTEMPTED,
  ({ state: { publisherId, isNewPublisher, publisherMappings, type } }) => {
    const errorEffects = [];

    if (!isNewPublisher && !isValidUUID(publisherId))
      errorEffects.push(setInvalidUuidErrorEffect());

    if (!isNewPublisher && (!publisherMappings || isEmpty(publisherMappings)))
      errorEffects.push(setMandatoryPublisherMappingsErrorEffect());

    if (!isEmpty(errorEffects)) return combineStateSetEffects(...errorEffects);

    if (type === PUBLISHER_TYPE.DEVELOPER)
      return displayProjectsStepsAndResetErrorsEffect();

    return {
      ...combineStateSetEffects(
        resetErrorsEffect(),
        state.set({
          [`${CREATE_PUBLISHER_PAGE}.submittingForm`]: true,
        })
      ),
      ...effects.dispatch({
        id: CREATE_PUBLISHER_SUBMITTED,
        payload: {
          successEvent: FORM_SUBMISSION_PUBLISHER_FINISHED,
        },
      }),
    };
  },
  [
    state.get({
      publisherId: `${CREATE_PUBLISHER_PAGE}.id`,
      isNewPublisher: `${CREATE_PUBLISHER_PAGE}.isNewPublisher`,
      publisherMappings: `${CREATE_PUBLISHER_PAGE}.publisherMappings`,
      type: `${CREATE_PUBLISHER_PAGE}.type`,
    }),
  ]
);

registerEventHandler(CREATE_PUBLISHER_IMAGE_UPLOADED, (_, { file }) => ({
  ...optimizeImages({
    images: [file],
    successEvent: CREATE_PUBLISHER_IMAGE_OPTIMIZED,
    errorEvent: CREATE_PUBLISHER_IMAGES_OPTIMIZATION_FAILED,
    options: {
      maxWidth: 200,
      maxHeight: 200,
      mimeType: 'image/jpeg',
    },
  }),
  ...state.set({
    [`${CREATE_PUBLISHER_PAGE}.imagesError`]: null,
  }),
}));

registerEventHandler(
  CREATE_PUBLISHER_IMAGE_OPTIMIZED,
  (_, [[optimizedImage]]) =>
    state.set({
      [`${CREATE_PUBLISHER_PAGE}.image`]: optimizedImage,
    })
);

registerEventHandler(
  CREATE_PUBLISHER_IMAGES_OPTIMIZATION_FAILED,
  (_, [{ error }]) =>
    state.set({
      [`${CREATE_PUBLISHER_PAGE}.imagesError`]: error.message,
    })
);

registerEventHandler(CREATE_PUBLISHER_IMAGE_DELETE_REQUESTED, () =>
  effects.dispatch({
    id: OPEN_DIALOG,
    payload: {
      id: GENERIC_CONFIRMATION_MODAL,
      parameters: {
        messages: {
          title: { id: 'newprop_pics_remove_header' },
          body: { id: 'newprop_pics_remove_body' },
          confirmButton: { id: 'newprop_pics_remove_yes' },
          cancelButton: { id: 'newprop_pics_remove_no' },
        },
        actions: {
          confirm: CREATE_PUBLISHER_IMAGE_DELETE_CONFIRMED,
        },
      },
    },
  })
);

registerEventHandler(CREATE_PUBLISHER_IMAGE_DELETE_CONFIRMED, () =>
  state.set({
    [`${CREATE_PUBLISHER_PAGE}.image`]: null,
  })
);

registerEventHandler(
  CREATE_PUBLISHER_FORM_MAX_BOOSTABLE_PROPERTIES_SELECTED,
  (
    { state: { plans, typeOfClient, countryCode } },
    { maxBoostableProperties }
  ) => {
    if (!isMexico(countryCode)) {
      return {
        ...state.set({
          [`${CREATE_PUBLISHER_PAGE}.maxBoostablePropertiesSelected`]:
            maxBoostableProperties,
          [`${CREATE_PUBLISHER_PAGE}.plan`]: '',
          [`${CREATE_PUBLISHER_PAGE}.paymentMethod`]: '',
        }),
        ...effects.dispatch(LOAD_AVAILABLE_PAYMENT_METHODS),
      };
    }

    const filteredPlans = plans.filter((plan) => plan.price > 0);
    const foundPlan = findMexicanPlanByTypeOfClientAndMaxBoostableProperties(
      filteredPlans,
      typeOfClient,
      maxBoostableProperties
    );

    if (!foundPlan) {
      return {
        ...state.set({
          [`${CREATE_PUBLISHER_PAGE}.maxBoostablePropertiesSelected`]:
            maxBoostableProperties,
          [`${CREATE_PUBLISHER_PAGE}.plan`]: null,
          [`${CREATE_PUBLISHER_PAGE}.paymentMethod`]: '',
          [`${CREATE_PUBLISHER_PAGE}.typeOfClient`]: null,
        }),
        ...effects.dispatch(LOAD_AVAILABLE_PAYMENT_METHODS),
      };
    }

    return {
      ...state.set({
        [`${CREATE_PUBLISHER_PAGE}.maxBoostablePropertiesSelected`]:
          maxBoostableProperties,
        [`${CREATE_PUBLISHER_PAGE}.plan`]: foundPlan.id,
        [`${CREATE_PUBLISHER_PAGE}.paymentMethod`]: '',
      }),
      ...effects.dispatch(LOAD_AVAILABLE_PAYMENT_METHODS),
    };
  },
  [
    state.get({
      plans: PLANS_STATE_REGION,
      typeOfClient: `${CREATE_PUBLISHER_PAGE}.typeOfClient`,
      countryCode: `${CREATE_PUBLISHER_PAGE}.country`,
    }),
  ]
);

registerEventHandler(
  CREATE_PUBLISHER_FORM_COUNTRY_SELECTED,
  ({ state: { publishInThailandProperty, publisherType } }, countryCode) => ({
    ...effects.dispatch({
      id: PLANS_PER_COUNTRY_FOR_CREATION_REQUESTED,
      payload: {
        countryCode,
        publishInThailandProperty,
        isDeveloper: publisherType === 'developer',
      },
    }),
    ...state.merge({
      [CREATE_PUBLISHER_PAGE]: {
        country: countryCode,
        publishInThailandProperty: false,
        paymentMethod: '',
        availablePaymentMethods: [],
        selectedBillingEntity: null,
        billingContactEmail: null,
        selectedSiblingPublisher: null,
        multipleAccountClient: MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.NO,
        typeOfClient: null,
      },
    }),
  }),
  [
    state.get({
      publishInThailandProperty: `${CREATE_PUBLISHER_PAGE}.publishInThailandProperty`,
      publisherType: `${CREATE_PUBLISHER_PAGE}.type`,
    }),
  ]
);

registerEventHandler(
  CREATE_PUBLISHER_FORM_THAILAND_PROPERTY_SELECTED,
  ({ state: { countryCode, publisherType } }, { publishInThailandProperty }) =>
    effects.dispatchMany([
      {
        id: PLANS_PER_COUNTRY_FOR_CREATION_REQUESTED,
        payload: {
          countryCode,
          publishInThailandProperty,
          isDeveloper: publisherType === 'developer',
        },
      },
      {
        id: CREATE_PUBLISHER_FORM_VALUE_CHANGED,
        payload: {
          name: 'publishInThailandProperty',
          value: publishInThailandProperty,
        },
      },
      {
        id: CREATE_PUBLISHER_FORM_VALUE_CHANGED,
        payload: {
          name: 'paymentMethod',
          value: '',
        },
      },
      LOAD_AVAILABLE_PAYMENT_METHODS,
    ]),
  [
    state.get({
      countryCode: `${CREATE_PUBLISHER_PAGE}.country`,
      publisherType: `${CREATE_PUBLISHER_PAGE}.type`,
    }),
  ]
);

registerEventHandler(
  PUBLISHER_TYPE_SELECTED,
  ({ state: { countryCode, publishInThailandProperty } }, type) => ({
    ...effects.dispatch({
      id: PLANS_PER_COUNTRY_FOR_CREATION_REQUESTED,
      payload: {
        countryCode,
        publishInThailandProperty,
        isDeveloper: type === 'developer',
      },
    }),
    ...state.merge({
      [CREATE_PUBLISHER_PAGE]: {
        type,
        plan: '',
        billingDate: '',
        paymentMethod: '',
        maxBoostablePropertiesSelected: '',
        billingCycle: '',
        loadingAdsMode: LOADING_ADS_MODE.MANUAL,
      },
    }),
  }),
  [
    state.get({
      countryCode: `${CREATE_PUBLISHER_PAGE}.country`,
      publishInThailandProperty: `${CREATE_PUBLISHER_PAGE}.publishInThailandProperty`,
    }),
  ]
);

registerEventHandler(
  PUBLISHER_MAPPING_VALUE_CHANGED,
  ({ state: { mappings } }, { brandId, newValue }) =>
    combineStateSetEffects(
      state.set({
        [`${CREATE_PUBLISHER_PAGE}.publisherMappings`]: updateMappings(
          mappings,
          brandId,
          newValue
        ),
      }),
      isEmpty(mappings) &&
        state.set({
          [MANDATORY_PUBLISHER_MAPPINGS_ERROR]: false,
        })
    ),
  [state.get({ mappings: `${CREATE_PUBLISHER_PAGE}.publisherMappings` })]
);

function updateMappings(mappings, brandId, newValue) {
  const existingMapping = mappings.find((m) => m.brandId === brandId);
  if (existingMapping) {
    const newMapping = { ...existingMapping, ...newValue };
    return mappings.map((m) => (m.brandId === brandId ? newMapping : m));
  }
  return [
    ...mappings,
    {
      brandId: undefined,
      id: undefined,
      clientSince: undefined,
      ...newValue,
    },
  ];
}

registerEventHandler(
  PUBLISHER_MAPPING_DELETED,
  ({ state: { mappings } }, { brandId }) => {
    const updatedMappings = mappings.filter(
      (mapping) => mapping.brandId !== brandId
    );
    return combineStateSetEffects(
      state.set({
        [`${CREATE_PUBLISHER_PAGE}.publisherMappings`]: updatedMappings,
      }),
      isEmpty(updatedMappings) &&
        state.set({
          [MANDATORY_PUBLISHER_MAPPINGS_ERROR]: true,
        })
    );
  },
  [state.get({ mappings: `${CREATE_PUBLISHER_PAGE}.publisherMappings` })]
);

registerEventHandler(BILLING_ENTITY_SELECTED, (_, payload) =>
  state.set({
    [`${CREATE_PUBLISHER_PAGE}.selectedBillingEntity`]: payload,
  })
);

registerEventHandler(MULTI_ACCOUNT_CLIENT_INFORMATION_CLEARED, () =>
  state.merge({
    [`${CREATE_PUBLISHER_PAGE}`]: {
      selectedBillingEntity: null,
      billingContactEmail: null,
      selectedSiblingPublisher: null,
      plan: '',
      billingDate: '',
      paymentMethod: '',
      maxBoostablePropertiesSelected: '',
      billingCycle: '',
    },
  })
);

registerEventHandler(MULTIPLE_ACCOUNT_TYPE_CHANGED, (_, payload) =>
  state.merge({
    [CREATE_PUBLISHER_PAGE]: {
      ...payload,
      selectedBillingEntity: null,
      billingContactEmail: null,
      selectedSiblingPublisher: null,
      plan: '',
      billingDate: '',
      paymentMethod: '',
      maxBoostablePropertiesSelected: '',
      billingCycle: '',
      selectedDeal: null,
      selectedSalesPerson: null,
      displayableDeal: '',
    },
  })
);

registerEventHandler(
  CREATE_PUBLISHER_FORM_BILLING_CYCLE_CHANGED,
  (_, { billingCycle }) => ({
    ...state.set({
      [`${CREATE_PUBLISHER_PAGE}.billingCycle`]: billingCycle,
      [`${CREATE_PUBLISHER_PAGE}.additionalMonth`]: false,
    }),
    ...effects.dispatch(LOAD_AVAILABLE_PAYMENT_METHODS),
  })
);

registerEventHandler(
  LOAD_AVAILABLE_PAYMENT_METHODS,
  ({
    environment: { apiUrl },
    state: {
      planId,
      countryCode,
      publishesInThailandProperty,
      billingCycleInMonths,
    },
  }) => {
    if (!planId || !countryCode || !billingCycleInMonths) {
      return state.set({
        [`${CREATE_PUBLISHER_PAGE}.paymentMethod`]: '',
        [`${CREATE_PUBLISHER_PAGE}.availablePaymentMethods`]: [],
      });
    }
    return http.get({
      url: `${apiUrl}/available-payment-methods?planId=${planId}&countryCode=${countryCode}&publishesInThailandProperty=${publishesInThailandProperty}&billingCycleInMonths=${billingCycleInMonths}`,
      successEvent: AVAILABLE_PAYMENT_METHODS_LOADED,
      errorEvent: AVAILABLE_PAYMENT_METHODS_FAILED,
    });
  },
  [
    environment(),
    state.get({
      planId: `${CREATE_PUBLISHER_PAGE}.plan`,
      countryCode: `${CREATE_PUBLISHER_PAGE}.country`,
      publishesInThailandProperty: `${CREATE_PUBLISHER_PAGE}.publishInThailandProperty`,
      billingCycleInMonths: `${CREATE_PUBLISHER_PAGE}.billingCycle`,
    }),
  ]
);

registerEventHandler(AVAILABLE_PAYMENT_METHODS_LOADED, (_, [{ data }]) =>
  state.set({ [`${CREATE_PUBLISHER_PAGE}.availablePaymentMethods`]: data })
);

registerEventHandler(AVAILABLE_PAYMENT_METHODS_FAILED, () => ({}));

function setInvalidUuidErrorEffect() {
  return state.set({
    [INVALID_UUID_ERROR]: true,
  });
}

function setMandatoryPublisherMappingsErrorEffect() {
  return state.set({
    [MANDATORY_PUBLISHER_MAPPINGS_ERROR]: true,
  });
}

function resetErrorsEffect() {
  return state.set({
    [INVALID_UUID_ERROR]: false,
    [MANDATORY_PUBLISHER_MAPPINGS_ERROR]: false,
  });
}

function displayProjectsStepsAndResetErrorsEffect() {
  return combineStateSetEffects(
    resetErrorsEffect(),
    state.set({
      [`${CREATE_PUBLISHER_PAGE}.step`]: CREATION_STEP.PROJECTS,
    })
  );
}
