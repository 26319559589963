import { effects, registerEventHandler } from 'reffects';
import { CHECKOUT_BILLING_SUMMARY_REQUESTED } from '../events';
import { CHECKOUT_TRACK_PAGE_VIEW } from '../CheckoutPageLoader/events';

export const CHECKOUT_BILLING_PAGE_MOUNTED = 'CHECKOUT_BILLING_PAGE_MOUNTED';

registerEventHandler(
  CHECKOUT_BILLING_PAGE_MOUNTED,
  (_, { subscriptionChangeRequestId, pageViewName, pageViewPayload }) => ({
    ...effects.dispatchMany([
      {
        id: CHECKOUT_TRACK_PAGE_VIEW,
        payload: { pageViewName, pageViewPayload },
      },
      {
        id: CHECKOUT_BILLING_SUMMARY_REQUESTED,
        payload: { subscriptionChangeRequestId },
      },
    ]),
  }),
  []
);
