import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useCallback, useRef } from 'react';
import Grid from 'design-system/components/Grid';
import Layout from 'design-system/components/Layout';
import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useParams } from 'react-router-dom';
import { Navigation } from '../../../partials';
import Sidebar from '../partials/Sidebar';
import { CHECKOUT_STEPS } from '../partials/CheckoutStepper/CheckoutStepper';
import Form from './partials/Form/Form';
import Summary from '../partials/Summary/Summary';
import {
  billingEntityFileDisableSubmitSelector,
  formSubmittedSelector,
  isFormLoadedSelector,
} from './partials/Form/selectors';
import {
  BILLING_INFORMATION_FORM_ERROR,
  BILLING_INFORMATION_SUBMITTED,
} from './partials/Form/events';
import { checkoutPageSCRIdSelector } from '../selectors';
import PublisherData from '../PublisherData/PublisherData';
import CheckoutSummaryMobile from '../partials/Summary/CheckoutSummaryMobile';
import { CHECKOUT_BILLING_PAGE_MOUNTED } from './events';
import { retrieveQueryParams } from '../../../coeffects/queryParamsAll';

const MobileSummaryWrapper = styled(Layout.Padded)`
  display: none;
  ${(props) =>
    props.theme?.isMobile &&
    css`
      display: block;
    `}
  position: sticky;
  bottom: 0;
  z-index: 2;
  margin-top: auto;
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  padding: ${spacing.value(SPACING_SIZE.M)} ${spacing.value(
    SPACING_SIZE.L
  )} ${spacing.value(SPACING_SIZE.XXL)} ${spacing.value(SPACING_SIZE.L)};
`;

const BillingContent = styled(Layout.Content)`
  ${(props) =>
    props.theme?.isMobile &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

const DesktopSummary = styled(Summary)`
  ${(props) =>
    props.theme?.isMobile &&
    css`
      display: none;
    `}
`;

export default function BillingInformation({ ...props }) {
  const billingInformationRef = useRef();
  const formSubmitted = useSelector(formSubmittedSelector);
  const submitIsDisabled = useSelector(billingEntityFileDisableSubmitSelector);
  const subscriptionChangeRequestId = useSelector(checkoutPageSCRIdSelector);
  const { queryParamsAll } = retrieveQueryParams(window);
  const params = useParams();

  useMount(() => {
    const { pageViewName, pageViewPayloadSelector } = props;
    dispatch({
      id: CHECKOUT_BILLING_PAGE_MOUNTED,
      payload: {
        subscriptionChangeRequestId,
        pageViewName,
        pageViewPayload: pageViewPayloadSelector({
          ...props,
          ...params,
          ...queryParamsAll,
        }),
      },
    });
  });

  const formLoaded = useSelector(isFormLoadedSelector);

  const handleSubmit = useCallback(
    (data) => {
      dispatch({
        id: BILLING_INFORMATION_SUBMITTED,
        payload: {
          subscriptionChangeRequestId,
          ...data,
        },
      });
    },
    [subscriptionChangeRequestId]
  );

  const handleError = useCallback(() => {
    dispatch({
      id: BILLING_INFORMATION_FORM_ERROR,
      payload: [undefined, subscriptionChangeRequestId],
    });
  }, []);

  return (
    <Layout independentScroll>
      <Layout.Header>
        <Navigation title={<PublisherData />} hideLinks hideAppBanner />
      </Layout.Header>
      <Layout.LeftSidebar>
        <Sidebar currentStep={CHECKOUT_STEPS.BILLING} />
      </Layout.LeftSidebar>
      <BillingContent>
        <Layout.Padded>
          <Grid
            areas={({ repeat }) => `
            "${repeat(5, 'form')} ${repeat(3, 'summary')} ."
          `}
            mobileAreas={({ repeat }) => `
            "${repeat(9, 'form')}"
            "${repeat(9, 'summary')}"
          `}
          >
            <Grid.Area $id="form">
              {formLoaded && (
                <Form
                  onSubmit={handleSubmit}
                  onError={handleError}
                  subscriptionChangeRequestId={subscriptionChangeRequestId}
                  hiddenButtonRef={billingInformationRef}
                />
              )}
            </Grid.Area>
            <Grid.Area $id="summary">
              <DesktopSummary />
            </Grid.Area>
          </Grid>
        </Layout.Padded>
        <MobileSummaryWrapper>
          <CheckoutSummaryMobile
            onClick={() => billingInformationRef.current.click()}
            translateId="checkout_billing_contact_info_continue"
            disabledButton={formSubmitted || submitIsDisabled}
          />
        </MobileSummaryWrapper>
      </BillingContent>
    </Layout>
  );
}
