import { userIsPublisherCollaboratorSelector } from '../../selectors/user';
import { publisherCountrySelector } from '../../selectors/publisher';
import { COUNTRY_CODE } from '../../constants/country';

export function newContractOffersSelector(state) {
  if (userIsPublisherCollaboratorSelector(state)) {
    return [];
  }
  return state.offers ?? [];
}

export function upsellOffersSelector(state) {
  if (userIsPublisherCollaboratorSelector(state)) {
    return [];
  }
  return state.upsellOffers ?? [];
}

export function offersForUpsellNowModalSelector(state) {
  if (userIsPublisherCollaboratorSelector(state)) {
    return [];
  }
  const offers = state.offers ?? [];

  const publisherCountry = publisherCountrySelector(state);

  if (publisherCountry === COUNTRY_CODE.ARGENTINA) {
    return offers.filter(
      (offer) =>
        (offer.maxBoostableProperties === 10 &&
          offer.billingCycleInMonths === 1) ||
        (offer.maxBoostableProperties === 25 &&
          offer.billingCycleInMonths === 1) ||
        (offer.maxBoostableProperties === 50 &&
          offer.billingCycleInMonths === 1)
    );
  }

  return offers.filter(
    (offer) =>
      (offer.maxBoostableProperties === 1 &&
        offer.billingCycleInMonths === 1) ||
      (offer.maxBoostableProperties === 10 &&
        offer.billingCycleInMonths === 1) ||
      (offer.maxBoostableProperties === 10 && offer.billingCycleInMonths === 3)
  );
}
