import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../coeffects/environment';
import { uuid } from '../../../coeffects/uuid';
import { calendar } from '../../../coeffects/calendar';
import { postMultipart } from '../../../effects/http';
import { navigateTo } from '../../../effects/routing';
import { SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION } from '../../../partials/modals/ad/events';
import { analytics } from '../../../effects/analytics';
import { PROPERTIES } from '../../../constants/routes';
import { prepareDataToSubmit } from '../prepareDataToSubmit';
import { prepareDataFromResponse } from '../prepareDataFromResponse';
import { tracking } from '../../../effects/tracking';
import { TOGGLE_SORTING_MODE } from '../partials/ImageUploader/events';

export const CREATE_AD_FORM_MOUNTED = 'CREATE_AD_FORM_MOUNTED';
export const AD_SUBMITTED = 'AD_SUBMITTED';
export const AD_FORM_SAVED = 'AD_FORM_SAVED';
export const AD_FORM_FAILED = 'AD_FORM_FAILED';
export const EDIT_AD_DATA_REQUESTED = 'EDIT_AD_DATA_REQUESTED';
export const EDIT_AD_FORM_LOADED = 'EDIT_AD_FORM_LOADED';
export const EDIT_AD_FORM_LOAD_FAILED = 'EDIT_AD_FORM_LOAD_FAILED';
export const RESET_AD_FORM_VALUES = 'RESET_AD_FORM_VALUES';

registerEventHandler(
  CREATE_AD_FORM_MOUNTED,
  ({ calendar: { timestamp }, uuid: { newUuid }, state: { typeOfClient } }) =>
    state.set({
      'adForm:ad': {
        id: newUuid,
        bankProperty:
          typeOfClient == null ? undefined : typeOfClient === 'remates',
      },
      'adForm:form': 'NewProperty',
      'adForm:marketPrice': undefined,
      'adForm:marketPriceLoadingStatus': undefined,
      'adForm:marketPriceErrorReason': undefined,
      'adForm:marketPriceErrorRetries': 0,
      'adForm:marketPriceErrorRetryDelay': 0,
      imageUploader: undefined,
      'adForm:displayableAddress': undefined,
      'adForm:addressSuggestionLoading': undefined,
      'adForm:addressSuggestions': undefined,
      'adForm:displayableProjectName': undefined,
      'adForm:projectNameSuggestionLoading': undefined,
      'adForm:projectNameSuggestions': undefined,
      'adForm:projectAmenities': undefined,
      'adForm:begunAt': timestamp,
      'adForm:showRestoreSortButton': false,
      'adForm:images': undefined,
    }),
  [calendar(), uuid(), state.get({ typeOfClient: 'publisher.typeOfClient' })]
);

registerEventHandler(
  AD_SUBMITTED,
  (
    {
      calendar: { now, timestamp },
      state: {
        begunAt,
        sortingWithAi,
        showRestoreSortButton,
        hasPremiumSubscription,
        section,
      },
      environment: { apiUrl },
    },
    { published = true, ...formData }
  ) => {
    const { ad, imagesToUpload, defaultContact } = prepareDataToSubmit({
      now,
      formData: {
        ...formData,
        propertyImagesSortedWithAi: showRestoreSortButton
          ? false
          : sortingWithAi,
      },
      published,
      sortingWithAi,
    });

    return {
      ...postMultipart({
        url: `${apiUrl}/properties/${ad.id}`,
        body: {
          ad,
        },
        files: imagesToUpload,
        successEvent: {
          id: AD_FORM_SAVED,
          payload: { published, defaultContact },
        },
        errorEvent: [AD_FORM_FAILED],
      }),
      ...state.set({ 'adForm:submitting': true }),
      ...trackSubmitFormClick({ now: timestamp, begunAt, published }),
      ...(hasPremiumSubscription
        ? trackSorting(section, ad, sortingWithAi, showRestoreSortButton)
        : {}),
    };
  },
  [
    calendar(),
    state.get({
      begunAt: 'adForm:begunAt',
      sortingWithAi: 'adForm:ad.propertyImagesSortedWithAi',
      showRestoreSortButton: 'adForm:showRestoreSortButton',
      hasPremiumSubscription: 'publisher.subscription.isPremium',
      section: 'adForm:form',
    }),
    environment(),
  ]
);

registerEventHandler(
  AD_FORM_SAVED,
  ({ environment: { apiUrl } }, [response, eventPayload]) => ({
    ...state.set({
      'adForm:submitting': false,
    }),
    ...http.put({
      url: `${apiUrl}/users/me/default-contact`,
      body: eventPayload.defaultContact,
    }),
    ...effects.dispatchMany([
      {
        id: SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION,
        payload: {
          userAction: eventPayload.published
            ? 'publish_and_boost'
            : 'save_as_draft',
          section: eventPayload.published
            ? 'listing-form-save-bottom-button'
            : 'listing-form-draft-bottom-button',
          ...response.data,
        },
      },
      RESET_AD_FORM_VALUES,
    ]),
    ...navigateTo(PROPERTIES),
  }),
  [environment()]
);

registerEventHandler(AD_FORM_FAILED, () =>
  state.set({ 'adForm:submitting': false })
);

registerEventHandler(
  EDIT_AD_DATA_REQUESTED,
  ({ environment: { apiUrl } }, { adId }) => ({
    ...state.set({
      'adForm:form': 'EditProperty',
      'adForm:marketPrice': undefined,
      'adForm:marketPriceLoadingStatus': undefined,
      'adForm:marketPriceErrorReason': undefined,
      'adForm:marketPriceErrorRetries': 0,
      'adForm:marketPriceErrorRetryDelay': 0,
      'adForm:isInitialized': false,
    }),
    ...http.get({
      url: `${apiUrl}/properties/${adId}`,
      successEvent: [EDIT_AD_FORM_LOADED],
      errorEvent: [EDIT_AD_FORM_LOAD_FAILED],
    }),
  }),
  [environment()]
);

registerEventHandler(
  EDIT_AD_FORM_LOADED,
  (
    {
      state: { whatsappCallingCode, hasPremiumSubscription },
      calendar: { timestamp },
    },
    [{ data }]
  ) => {
    const adData = prepareDataFromResponse({
      data,
      whatsappCallingCode,
    });

    const stateMutations = state.set({
      'adForm:isInitialized': true,
      'adForm:ad': adData,
      'adForm:images.uploaded': adData.propertyImages,
      'adForm:displayableAddress': data.address,
      'adForm:displayableProjectName': data.project?.name,
      imageUploader: undefined,
      'adForm:begunAt': timestamp,
    });
    if (hasPremiumSubscription && adData.propertyImagesSortedWithAi) {
      return {
        ...stateMutations,
        ...effects.dispatch({
          id: TOGGLE_SORTING_MODE,
          payload: { usingAI: true },
        }),
      };
    }
    return stateMutations;
  },
  [
    state.get({
      whatsappCallingCode: 'countryConfig.phoneNumber.callingCode',
      hasPremiumSubscription: 'publisher.subscription.isPremium',
    }),
    calendar(),
  ]
);

registerEventHandler(EDIT_AD_FORM_LOAD_FAILED, () => navigateTo('/'));

registerEventHandler(RESET_AD_FORM_VALUES, () =>
  state.set({
    'adForm:isInitialized': false,
    'adForm:ad': undefined,
    'adForm:marketPrice': undefined,
    'adForm:marketPriceLoadingStatus': undefined,
    'adForm:marketPriceErrorReason': undefined,
    'adForm:marketPriceErrorRetries': 0,
    'adForm:marketPriceErrorRetryDelay': 0,
    'adForm:displayableAddress': undefined,
    'adForm:addressSuggestionLoading': undefined,
    'adForm:addressSuggestions': undefined,
    'adForm:displayableProjectName': undefined,
    'adForm:projectNameSuggestionLoading': undefined,
    'adForm:projectNameSuggestions': undefined,
    'adForm:projectAmenities': undefined,
    imageUploader: undefined,
    'adForm:begunAt': undefined,
    'adForm:showRestoreSortButton': false,
    'adForm:images': undefined,
    imageUploaderStatus: undefined,
  })
);

function trackSubmitFormClick({ now, begunAt, published }) {
  const timeToComplete = calculateElapsedTime(now, begunAt);

  return analytics.trackClick(
    published ? 'save-listing-publishing' : 'save-listing-as-draft',
    published
      ? 'listing-form-save-bottom-button'
      : 'listing-form-draft-bottom-button',
    { timeToComplete }
  );
}

function trackSorting(section, ad, sortingWithAi, showRestoreSortButton) {
  const aiSortingMode = showRestoreSortButton ? 'AIModifiedManually' : 'AI';
  const sortingMode = sortingWithAi ? aiSortingMode : 'Manual';
  return tracking.track('PropertyImagesSaved', section, {
    adId: ad.id,
    sortingMode,
  });
}

function calculateElapsedTime(timestamp, begunAt) {
  return (timestamp - begunAt) / 1000;
}
