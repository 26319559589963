import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import ContactInterest from 'design-system/components/ContactInterest/ContactInterest';
import { TOOLTIP_MODAL_PLACEMENT } from 'design-system/components/TooltipModal/TooltipModal';
import FormattedMessageWithValidation from '../../../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import UnlockPremium from '../../../../../../../../partials/UnlockPremium';

const QualificationBlock = styled.div`
  display: flex;
  ${spacing.insetSandwich(SPACING_SIZE.XL)};
  flex-direction: column;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  align-self: stretch;
`;

const QualificationTitle = styled.div`
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)};
  ${typography.font(FONT_FAMILY.PRIMARY)};
  ${typography.weight(FONT_WEIGHT.MEDIUM)};
  ${typography.size(FONT_SIZE.S)};
  padding-left: ${spacing.value(SPACING_SIZE.XL)};
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
`;

const Wrapper = styled(ContactInterest)`
  width: 100%;
  padding: ${spacing.value(SPACING_SIZE.M)} ${spacing.value(SPACING_SIZE.XL)};

  > * {
    padding: 0;
  }
`;

export default function NonPremiumLeadQualification() {
  return (
    <QualificationBlock>
      <QualificationTitle>
        <FormattedMessageWithValidation id="lead_detail_qualification_title" />
      </QualificationTitle>
      <Wrapper
        title={
          <FormattedMessageWithValidation id="lead_detail_qualification_financial_data" />
        }
      >
        <UnlockPremium
          labelTag="unlock_premium_data"
          tooltipTag="unlock_premium_tooltip_qualification"
          tooltipPlacement={TOOLTIP_MODAL_PLACEMENT.DOWN_RIGHT}
        />
      </Wrapper>
      <Wrapper
        title={
          <FormattedMessageWithValidation id="lead_detail_qualification_hotness" />
        }
      >
        <UnlockPremium
          labelTag="unlock_premium_data"
          tooltipTag="unlock_premium_tooltip_qualification"
          tooltipPlacement={TOOLTIP_MODAL_PLACEMENT.DOWN_RIGHT}
        />
      </Wrapper>
    </QualificationBlock>
  );
}
