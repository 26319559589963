import { useLifecycles } from 'react-use';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import Grid from 'design-system/components/Grid';
import Layout from 'design-system/components/Layout';
import styled, { css } from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Navigation } from '../../../partials';
import Sidebar from '../partials/Sidebar';
import { CHECKOUT_STEPS } from '../partials/CheckoutStepper/CheckoutStepper';
import Form from './partials/Form/Form';
import Summary from './partials/Summary';
import {
  PAYMENT_METHOD_PAGE_MOUNTED,
  PAYMENT_METHOD_PAGE_UNMOUNTED,
} from './events';
import { isFormLoadedSelector } from '../BillingInformation/partials/Form/selectors';
import { checkoutPageSCRIdSelector } from '../selectors';
import PublisherData from '../PublisherData/PublisherData';
import CheckoutSummaryMobile from '../partials/Summary/CheckoutSummaryMobile';
import { retrieveQueryParams } from '../../../coeffects/queryParamsAll';

const MobileSummaryWrapper = styled(Layout.Padded)`
  display: none;
  ${(props) =>
    props.theme?.isMobile &&
    css`
      display: block;
    `}
  position: sticky;
  bottom: 0;
  z-index: 2;
  margin-top: auto;
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  padding: ${spacing.value(SPACING_SIZE.M)} 
  ${spacing.value(SPACING_SIZE.L)} 
  ${spacing.value(SPACING_SIZE.XXL)}
  ${spacing.value(SPACING_SIZE.L)};
`;

const DesktopSummary = styled(Summary)`
  ${(props) =>
    props.theme?.isMobile &&
    css`
      display: none;
    `}
`;
const PaymenMethodContent = styled(Layout.Content)`
  ${(props) =>
    props.theme?.isMobile &&
    css`
      display: flex;
      flex-direction: column;
      ${Layout.Padded} {
        ${spacing.inset(SPACING_SIZE.L)}
      }
      ${Grid} {
        display: flex;
        column-gap: normal;
      }
    `}
`;

export default function PaymentMethod({ ...props }) {
  const paymentMethodRef = useRef();
  const subscriptionChangeRequestId = useSelector(checkoutPageSCRIdSelector);
  const params = useParams();
  const { queryParamsAll } = retrieveQueryParams(window);

  useLifecycles(
    () => {
      const { pageViewName, pageViewPayloadSelector } = props;
      dispatch({
        id: PAYMENT_METHOD_PAGE_MOUNTED,
        payload: {
          subscriptionChangeRequestId,
          pageViewName,
          pageViewPayload: pageViewPayloadSelector({
            ...props,
            ...params,
            ...queryParamsAll,
          }),
        },
      });
    },
    () => dispatch(PAYMENT_METHOD_PAGE_UNMOUNTED)
  );

  const formLoaded = useSelector(isFormLoadedSelector);

  return (
    <Layout independentScroll>
      <Layout.Header>
        <Navigation title={<PublisherData />} hideLinks hideAppBanner />
      </Layout.Header>
      <Layout.LeftSidebar>
        <Sidebar currentStep={CHECKOUT_STEPS.PAYMENT} />
      </Layout.LeftSidebar>
      <PaymenMethodContent>
        <Layout.Padded>
          <Grid
            areas={({ repeat }) => `
            "${repeat(5, 'form')} ${repeat(3, 'summary')} ."
          `}
          >
            <Grid.Area $id="form">
              {formLoaded && (
                <Form
                  subscriptionChangeRequestId={subscriptionChangeRequestId}
                  hiddenButtonRef={paymentMethodRef}
                />
              )}
            </Grid.Area>
            <Grid.Area $id="summary">
              <DesktopSummary
                subscriptionChangeRequestId={subscriptionChangeRequestId}
              />
            </Grid.Area>
          </Grid>
        </Layout.Padded>
        <MobileSummaryWrapper>
          <CheckoutSummaryMobile hiddeButton />
        </MobileSummaryWrapper>
      </PaymenMethodContent>
    </Layout>
  );
}
