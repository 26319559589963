import { countryConfigSelector } from '../../../../../selectors/config';
import {
  publisherCountrySelector,
  publisherSelector,
  publisherTaxDiscountsSelector,
} from '../../../../../selectors/publisher';
import { isMexico } from '../../../../../utils/countries';
import {
  BILLING_ENTITY_FILE,
  BILLING_ENTITY_FILE_DATA,
  BILLING_ENTITY_FILE_DISABLE_SUBMIT,
  BILLING_ENTITY_FILE_IS_LOADED,
  BILLING_ENTITY_FILE_LOADING_ERROR,
} from '../../constants';

export function isFormLoadedSelector(state) {
  const loading = state?.form?.loading;
  if (loading == null) {
    return false;
  }

  return !loading;
}

export function formSubmittedSelector(state) {
  return state.billingInformationFormSubmitted ?? false;
}

export function configFieldValidationsSelector(state) {
  return countryConfigSelector(state).billingDataValidation ?? {};
}

export function billingEntityDataSelector(state) {
  const publisher = publisherSelector(state);
  const { retefuenteCO, reteIcaCO, reteIvaCO } =
    publisherTaxDiscountsSelector(state) ?? {};
  const billingContactEmail = publisher?.billingContactEmail;
  const id = publisher?.billingEntityId;
  const billingEntity = publisher?.billingEntity;

  return {
    id: id ?? '',
    address: billingEntity?.address ?? '',
    city: billingEntity?.city ?? '',
    country: billingEntity?.country ?? publisherCountrySelector(state) ?? '',
    email: billingEntity?.email ?? billingContactEmail ?? '',
    legalName: billingEntity?.legalName ?? '',
    phone: billingEntity?.phone ?? '',
    postalCode: billingEntity?.postalCode ?? '',
    province: billingEntity?.province ?? '',
    taxId: billingEntity?.taxId ?? '',
    typeOfPerson: billingEntity?.typeOfPerson ?? '',
    billRequired: billingEntity?.billRequired ?? true,
    reteIva: reteIvaCO,
    retefuente: retefuenteCO,
    reteIca: reteIcaCO,
  };
}

export function currentBillingEntityRequiresBillSelector(state) {
  return billingEntityDataSelector(state)?.billRequired;
}

export function shouldDisableFormSelector(state) {
  const { sharesBillingEntity, isGenericBillingEntity } =
    publisherSelector(state) ?? {};
  return sharesBillingEntity && !isGenericBillingEntity;
}

export function shouldShowFormSelector(state) {
  if (!publisherIsFromMexicoSelector(state)) {
    return true;
  }

  return billRequiredSelector(state) && hasHaciendaRequestFailedSelector(state);
}

export function shouldShowUploadCedulaFiscalSelector(state) {
  if (!publisherIsFromMexicoSelector(state)) {
    return false;
  }
  return (
    billRequiredSelector(state) &&
    !hasHaciendaRequestFailedSelector(state) &&
    !hasBillingEntityIdSelector(state)
  );
}

export function billRequiredSelector(state) {
  return state?.billingInformationFormBillRequired ?? true;
}

export function billingEntityFileSelector(state) {
  return state?.[BILLING_ENTITY_FILE];
}

export function billingEntityFileDataSelector(state) {
  return state?.[BILLING_ENTITY_FILE_DATA];
}

export function billingEntityFileLoadingSelector(state) {
  return state?.[BILLING_ENTITY_FILE_IS_LOADED];
}

export function billingEntityFileErrorLoadingSelector(state) {
  return state[BILLING_ENTITY_FILE_LOADING_ERROR] ?? false;
}

export function billingEntityFileDisableSubmitSelector(state) {
  if (!isMexico(publisherCountrySelector(state))) {
    return false;
  }
  if (hasHaciendaRequestFailedSelector(state) || !billRequiredSelector(state)) {
    return false;
  }

  return (
    (state?.[BILLING_ENTITY_FILE_DISABLE_SUBMIT] ?? true) &&
    !hasBillingEntityIdSelector(state)
  );
}

export function shouldShowBillRequiredSwitchSelector(state) {
  return publisherIsFromMexicoSelector(state);
}

export function hasHaciendaRequestFailedSelector(state) {
  return state.hasHaciendaRequestFailed ?? false;
}

export function taxIdSelector(state) {
  const countryConfig = countryConfigSelector(state);
  const { taxId } = countryConfig;

  return taxId ?? '';
}

export function publisherIsFromMexicoSelector(state) {
  return isMexico(publisherCountrySelector(state));
}

export function shouldShowEditButtonSelector(state) {
  const hasBillingEntity = hasBillingEntityIdSelector(state);
  if (publisherIsFromMexicoSelector(state)) {
    const isFileLoading = billingEntityFileLoadingSelector(state);
    const fileData = billingEntityFileDataSelector(state);
    return Boolean(fileData?.id && !isFileLoading) || hasBillingEntity;
  }
  return (
    !hasBeenClickedBillingEntityEditButtonSelector(state) && hasBillingEntity
  );
}

export function hasBillingEntityIdSelector(state) {
  return Boolean(state?.publisher?.billingEntityId);
}

export function hasBeenClickedBillingEntityEditButtonSelector(state) {
  return state?.checkoutPage?.billingEntityEditButtonHasBeenClicked;
}
