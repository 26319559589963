import styled from 'styled-components';
import { useRef } from 'react';
import Dialog from 'design-system/components/Dialog/Dialog';
import { useDialog } from '../../../../../../hooks/useDialog';
import { QR_MODAL } from './constants';
import ContactQr from '../../../ContactQr';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useTrack } from '../../../../../../hooks/useTracking';

const Content = styled(Dialog.Content)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function QrModal() {
  const { open, parameters, closeDialog } = useDialog(QR_MODAL);
  const refToUse = useRef(null);
  useTrack(refToUse, {
    track: {
      event: 'LeadDetailContactQrViewed',
      section: 'lead-detail-contact',
    },
  });

  return (
    <Dialog open={open} fitContent>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id="enquiry_detail_lead_contact_scan_qr" />
      </Dialog.Header>
      <Content>
        <ContactQr ref={refToUse} contact={parameters} />
      </Content>
      <Dialog.Footer />
    </Dialog>
  );
}
