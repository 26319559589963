import { useController, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import ImageUploader from './ImageUploader';
import { useRegisterFieldErrors } from '../FieldGroup';
import { IMAGES_SELECTED } from './events';
import { errorSelector, imagesSelector, isDisabledSelector } from './selectors';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import AdImageUploader from './AdImageUploader';
import { fieldHasMainImage } from './utils';

export function ImageUploaderField({ id, name, helperText, ...rest }) {
  const { control } = useFormContext();
  const {
    fieldState: { error },
    field: { value: formValue, onChange, ref },
  } = useController({
    name,
    control,
    defaultValue: [],
  });
  const value = useSelector((state) => imagesSelector(state, name));
  const isDisabled = useSelector((state) => isDisabledSelector(state, name));
  const errorText = useSelector((state) => errorSelector(state, name));

  useEffect(() => {
    onChange(value);
  }, [value]);

  useRegisterFieldErrors({ name, errors: error });

  const onImagesUploaded = (newImages) =>
    dispatch({
      id: IMAGES_SELECTED,
      payload: {
        field: name,
        images: formValue,
        newImages,
      },
    });

  if (fieldHasMainImage(name)) {
    return (
      <AdImageUploader
        id={id}
        name={name}
        images={formValue}
        helperText={helperText}
        errorText={
          error?.message ??
          (errorText && <FormattedMessageWithValidation {...errorText} />)
        }
        isDisabled={isDisabled}
        ref={ref}
        onImagesUploaded={onImagesUploaded}
        {...rest}
      />
    );
  }

  return (
    <ImageUploader
      id={id}
      name={name}
      images={formValue}
      helperText={helperText}
      errorText={
        error?.message ??
        (errorText && <FormattedMessageWithValidation {...errorText} />)
      }
      isDisabled={isDisabled}
      ref={ref}
      onImagesUploaded={onImagesUploaded}
      {...rest}
    />
  );
}
