export default function ArrowUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
    >
      <path d="M5.99902 0L-0.000976562 6H11.999L5.99902 0Z" fill="#08A36B" />
    </svg>
  );
}
