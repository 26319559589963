import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../../coeffects/environment';
import { CHECKOUT_BILLING_SUMMARY_REQUESTED } from '../events';
import { loadCountryConfig } from '../../../effects/loadCountryConfig';
import { BACK_TO_BILLING_INFO } from './partials/Form/events';
import { history } from '../../../coeffects/history';
import { navigateTo } from '../../../effects/routing';
import {
  checkoutAlreadyActiveRoute,
  checkoutPaymentCancelledRoute,
  checkoutPaymentForbiddenRoute,
} from '../../../utils/proppitWebRouter';
import { isBackofficeRole } from '../../../constants/user';
import {
  CHECKOUT_COUNTRY_CONFIG_RETRIEVED,
  CHECKOUT_PUBLISHER_DATA_RETRIEVAL_FAILED,
  CHECKOUT_TRACK_PAGE_VIEW,
} from '../CheckoutPageLoader/events';

export const PAYMENT_METHOD_PAGE_MOUNTED = `PAYMENT_METHOD_PAGE_MOUNTED`;
export const PAYMENT_METHOD_PAGE_UNMOUNTED = `PAYMENT_METHOD_PAGE_UNMOUNTED`;
export const LOAD_PUBLISHER = `LOAD_PUBLISHER`;
export const CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS =
  'CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS';
export const CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS_LOADED =
  'CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS_LOADED';
export const CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS_FAILED =
  'CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS_FAILED';
export const CHECKOUT_PUBLISHER_DATA_RETRIEVED_WITH_BILLING_ENTITY_CHECK = `CHECKOUT_PUBLISHER_DATA_RETRIEVED_WITH_BILLING_ENTITY_CHECK`;
export const PROFORMA_INVOICE_REQUESTED = `PROFORMA_INVOICE_REQUESTED`;
export const PROFORMA_INVOICE_LOADED = `PROFORMA_INVOICE_LOADED`;
export const PROFORMA_INVOICE_REQUEST_RETRIED = `PROFORMA_INVOICE_REQUEST_RETRIED`;
export const CHECK_SCR_STATUS_HAS_CHANGED = 'CHECK_SCR_STATUS_HAS_CHANGED';

registerEventHandler(
  PAYMENT_METHOD_PAGE_MOUNTED,
  (
    { state: { countryConfig }, historyLength },
    { subscriptionChangeRequestId, pageViewName, pageViewPayload }
  ) => ({
    ...state.set({
      form: {
        loading: countryConfig == null,
        submitting: false,
      },
      showBackButton: historyLength > 0,
      isInPaymentMethodPage: true,
    }),
    ...effects.dispatchMany([
      {
        id: CHECKOUT_TRACK_PAGE_VIEW,
        payload: { pageViewName, pageViewPayload },
      },
      {
        id: LOAD_PUBLISHER,
        payload: { subscriptionChangeRequestId },
      },
      {
        id: CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS,
        payload: { subscriptionChangeRequestId },
      },
      {
        id: CHECKOUT_BILLING_SUMMARY_REQUESTED,
        payload: { subscriptionChangeRequestId },
      },
    ]),
  }),
  [state.get({ countryConfig: 'countryConfig' }), history.length()]
);

registerEventHandler(PAYMENT_METHOD_PAGE_UNMOUNTED, () =>
  state.set({
    isInPaymentMethodPage: undefined,
    'checkoutPage.availablePaymentMethods': [],
  })
);

registerEventHandler(
  LOAD_PUBLISHER,
  (
    { environment: { apiUrl }, state: { isInPaymentMethodPage } },
    {
      subscriptionChangeRequestId,
      successEvent = CHECKOUT_PUBLISHER_DATA_RETRIEVED_WITH_BILLING_ENTITY_CHECK,
    }
  ) =>
    !isInPaymentMethodPage
      ? {}
      : http.get({
          url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/publisher`,
          successEvent: {
            id: successEvent,
            payload: { subscriptionChangeRequestId },
          },
          errorEvent: CHECKOUT_PUBLISHER_DATA_RETRIEVAL_FAILED,
        }),
  [
    environment(),
    state.get({
      isInPaymentMethodPage: 'isInPaymentMethodPage',
    }),
  ]
);

registerEventHandler(
  CHECKOUT_PUBLISHER_DATA_RETRIEVED_WITH_BILLING_ENTITY_CHECK,
  (
    {
      environment: { apiUrl },
      state: { subRoute, hasHaciendaRequestFailed, role, subscriptionId },
    },
    [{ data: publisher }, payload]
  ) => {
    if (!publisher.billingEntityId) {
      if (!hasHaciendaRequestFailed) {
        return effects.dispatch({ id: BACK_TO_BILLING_INFO, payload });
      }
    }
    const doNavigate = doNavigateIfSCRChangedStatus(
      publisher,
      payload.subscriptionChangeRequestId,
      subRoute
    );
    if (doNavigate) {
      return doNavigate;
    }
    if (
      subscriptionId &&
      subscriptionId !== publisher.subscriptionId &&
      !isBackofficeRole(role)
    ) {
      return navigateTo(
        checkoutPaymentForbiddenRoute({
          subRoute,
          subscriptionChangeRequestId: payload.subscriptionChangeRequestId,
        })
      );
    }
    return {
      ...state.set({ publisher }),
      ...loadCountryConfig({
        apiUrl,
        country: publisher.countryCode,
        successEvent: CHECKOUT_COUNTRY_CONFIG_RETRIEVED,
      }),
      ...effects.dispatchLater({
        id: LOAD_PUBLISHER,
        milliseconds: 5000,
        payload: {
          ...payload,
          successEvent: CHECK_SCR_STATUS_HAS_CHANGED,
        },
      }),
      ...(publisher.hasGenericBillingEntity
        ? {}
        : effects.dispatch({
            id: PROFORMA_INVOICE_REQUESTED,
            payload: {
              subscriptionChangeRequestId: payload.subscriptionChangeRequestId,
            },
          })),
    };
  },
  [
    environment(),
    state.get({
      subRoute: 'checkoutConfig.subRoute',
      hasHaciendaRequestFailed: 'hasHaciendaRequestFailed',
      subscriptionId: 'publisher.subscriptionId',
      role: 'user.role',
    }),
  ]
);

registerEventHandler(
  CHECK_SCR_STATUS_HAS_CHANGED,
  ({ state: { subRoute } }, [{ data: publisher }, payload]) => {
    const doNavigate = doNavigateIfSCRChangedStatus(
      publisher,
      payload.subscriptionChangeRequestId,
      subRoute
    );

    return (
      doNavigate ?? {
        ...effects.dispatchLater({
          id: LOAD_PUBLISHER,
          milliseconds: 5000,
          payload: {
            subscriptionChangeRequestId: payload.subscriptionChangeRequestId,
            successEvent: CHECK_SCR_STATUS_HAS_CHANGED,
          },
        }),
      }
    );
  },
  [
    state.get({
      subRoute: 'checkoutConfig.subRoute',
    }),
  ]
);

function doNavigateIfSCRChangedStatus(
  publisher,
  subscriptionChangeRequestId,
  subRoute
) {
  if (
    publisher.subscriptionChangeRequestActivated === true ||
    publisher.subscriptionChangeRequestPaid === true
  ) {
    return navigateTo(
      checkoutAlreadyActiveRoute({
        subRoute,
        subscriptionChangeRequestId,
      })
    );
  }
  if (publisher.subscriptionChangeRequestCancelled === true) {
    return navigateTo(
      checkoutPaymentCancelledRoute({
        subRoute,
        subscriptionChangeRequestId,
      })
    );
  }

  return null;
}

registerEventHandler(
  PROFORMA_INVOICE_REQUESTED,
  ({ environment: { apiUrl } }, { subscriptionChangeRequestId }) =>
    http.get({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/proforma`,
      successEvent: PROFORMA_INVOICE_LOADED,
      errorEvent: {
        id: PROFORMA_INVOICE_REQUEST_RETRIED,
        payload: { subscriptionChangeRequestId },
      },
    }),
  [environment()]
);

registerEventHandler(PROFORMA_INVOICE_LOADED, (_, [{ data }]) =>
  state.set({ proformaLink: data.url })
);

registerEventHandler(
  PROFORMA_INVOICE_REQUEST_RETRIED,
  ({ state: { isInPaymentMethodPage } }, [__, payload]) =>
    !isInPaymentMethodPage
      ? {}
      : effects.dispatchLater({
          id: PROFORMA_INVOICE_REQUESTED,
          milliseconds: 3000,
          payload,
        }),
  [state.get({ isInPaymentMethodPage: 'isInPaymentMethodPage' })]
);

registerEventHandler(
  CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS,
  ({ environment: { apiUrl } }, { subscriptionChangeRequestId }) =>
    http.get({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/payment-methods`,
      successEvent: CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS_LOADED,
      errorEvent: CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS_FAILED,
    }),
  [environment()]
);

registerEventHandler(
  CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS_LOADED,
  (_, [{ data }]) => state.set({ 'checkoutPage.availablePaymentMethods': data })
);

registerEventHandler(
  CHECKOUT_LOAD_AVAILABLE_PAYMENT_METHODS_FAILED,
  () => ({})
);
