import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../coeffects/environment';
import {
  PROPERTY_TYPE_APARTMENT,
  PROPERTY_TYPE_BEACH_HOUSE,
  PROPERTY_TYPE_BUILDING,
  PROPERTY_TYPE_BUNGALOW,
  PROPERTY_TYPE_CAR_PARK,
  PROPERTY_TYPE_COMMERCIAL,
  PROPERTY_TYPE_COMMERCIAL_IN_MALL,
  PROPERTY_TYPE_COMMERCIAL_LAND,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_DOCTOR_OFFICE,
  PROPERTY_TYPE_DUPLEX,
  PROPERTY_TYPE_FIRST_FLOOR,
  PROPERTY_TYPE_FLAT,
  PROPERTY_TYPE_GATED_COMMUNITY_HOUSE,
  PROPERTY_TYPE_HORIZONTAL_CONDO,
  PROPERTY_TYPE_HOTEL,
  PROPERTY_TYPE_HOUSE,
  PROPERTY_TYPE_INDUSTRIAL_UNIT,
  PROPERTY_TYPE_LAND,
  PROPERTY_TYPE_LOFT,
  PROPERTY_TYPE_OFFICE,
  PROPERTY_TYPE_PENTHOUSE,
  PROPERTY_TYPE_QUINTA,
  PROPERTY_TYPE_RANCH,
  PROPERTY_TYPE_ROOM,
  PROPERTY_TYPE_RUSTIC_HOUSE,
  PROPERTY_TYPE_SERVICED_APARTMENT,
  PROPERTY_TYPE_STORAGE_ROOM,
  PROPERTY_TYPE_STUDIO,
  PROPERTY_TYPE_SUITE,
  PROPERTY_TYPE_TOWNHOUSE,
  PROPERTY_TYPE_VILLA,
} from '../../../../constants/ad';
import { isSEA } from '../../../../utils/countries';
import { translations } from '../../../../coeffects/translations';
import { downloadCsv, downloadXls } from '../../../../effects/downloadFile';
import { analytics } from '../../../../effects/analytics';
import { fromUTCDate, toUTCDate } from '../../../../utils/dates';
import { LEAD_FILTERS } from '../../constants';
import { featureFlagsByCountry } from '../../../../coeffects/featureFlags';

export const DOWNLOAD_ENQUIRIES_EXCEL_REQUESTED =
  'DOWNLOAD_ENQUIRIES_EXCEL_REQUESTED';
export const DOWNLOAD_ENQUIRIES_CSV_REQUESTED =
  'DOWNLOAD_ENQUIRIES_CSV_REQUESTED';
export const LEADS_DOWNLOAD_REQUESTED = 'LEADS_DOWNLOAD_REQUESTED';
export const LEADS_DATA_DOWNLOAD_SUCCESSFULLY =
  'LEADS_DATA_DOWNLOAD_SUCCESSFULLY';
export const LEADS_DOWNLOAD_FILE_GENERATED = 'LEADS_DOWNLOAD_FILE_GENERATED';
const FORMAT_XLS = 'xls';
const FORMAT_CSV = 'csv';

registerEventHandler(DOWNLOAD_ENQUIRIES_EXCEL_REQUESTED, (_, payload) => ({
  ...state.set({
    'enquiries:exportFormat': FORMAT_XLS,
  }),
  ...analytics.trackClick('export-leads-data-to-excel', 'leads-table'),
  ...effects.dispatch({ id: LEADS_DOWNLOAD_REQUESTED, payload }),
}));

registerEventHandler(DOWNLOAD_ENQUIRIES_CSV_REQUESTED, (_, payload) => ({
  ...state.set({
    'enquiries:exportFormat': FORMAT_CSV,
  }),
  ...analytics.trackClick('export-leads-data-to-csv', 'leads-table'),
  ...effects.dispatch({ id: LEADS_DOWNLOAD_REQUESTED, payload }),
}));

registerEventHandler(
  LEADS_DOWNLOAD_REQUESTED,
  (
    { environment: { apiUrl } },
    {
      filters: {
        [LEAD_FILTERS.LAST_ACTIVITY_DATE_RANGE]: { startDate, endDate } = {},
      },
    }
  ) => {
    let url = `${apiUrl}/leads-to-export`;
    const toString = (date) =>
      fromUTCDate(
        typeof date === 'string' ? new Date(date) : date
      ).toISOString();
    if (startDate) {
      url += `?from=${toString(startDate)}&to=${toString(endDate)}`;
    }

    return http.get({
      url,
      successEvent: LEADS_DATA_DOWNLOAD_SUCCESSFULLY,
    });
  },
  [environment()]
);

registerEventHandler(
  LEADS_DATA_DOWNLOAD_SUCCESSFULLY,
  (
    /* eslint-disable camelcase */
    {
      state: { sitesToPublish, country, isDeveloper, hasPremiumSubscription },
      featuresByCountry: { PREMIUM_DOWNLOAD_CSV_4285 },
      translations: {
        enquiries_unknokwn_value,
        excel_cells_date,
        excel_cells_lead_type,
        excel_cells_contact_name,
        excel_cells_email,
        excel_cells_telephone,
        excel_cells_message,
        excel_cells_lead_status,
        excel_cells_reference_ID,
        excel_cells_agency_contact_email,
        excel_cells_property_title,
        excel_cells_property_type,
        excel_cells_price,
        excel_cells_city,
        excel_cells_bedrooms,
        excel_cells_project_name,
        excel_cells_operation_type,
        excel_cells_floor_area,
        newprop_proptype1,
        newprop_proptype2,
        newprop_proptype,
        newprop_proptype4,
        newprop_proptype7,
        newprop_proptype11,
        newprop_proptype5,
        newprop_proptype6,
        newprop_proptype8,
        newprop_proptype9,
        newprop_proptype10,
        newprop_proptype13,
        newprop_proptype14,
        newprop_proptype15,
        newprop_proptype16,
        newprop_proptype17,
        newprop_proptype18,
        newprop_proptype19,
        newprop_proptype20,
        newprop_proptype21,
        ad_form_property_type_hotel,
        ad_form_property_type_suite,
        ad_form_property_type_flat,
        ad_form_property_type_penthouse,
        ad_form_property_type_bungalow,
        ad_form_property_type_duplex,
        ad_form_property_type_rustic_house,
        ad_form_property_type_first_floor,
        ad_form_property_type_building,
        ad_form_property_type_storage_room,
        ad_form_property_type_ranch,
        ad_form_property_type_quinta,
        excel_cells_operation_type_sale,
        excel_cells_operation_type_rent,
        excel_cells_lead_type_mail,
        excel_cells_lead_type_phone,
        excel_cells_contact_method_call,
        excel_cells_lead_type_chat,
        excel_cells_contact_method_whatsapp,
        excel_cells_contact_method_line,
        excel_cells_contact_method_viber,
        excel_cells_contact_method_facebook_msn,
        enqpage_table_status2,
        enqpage_table_status1,
        status_enquiries_visit,
        status_enquiries_proposal,
        status_enquiries_won,
        status_enquiries_lost,
        adform_field_apto_credito,
        subscription_plans_no,
        overview_subscription_block_webs_yes,
        excel_cells_lead_qualification_visit_interest,
        excel_cells_lead_qualification_budget,
        excel_cells_lead_qualification_information,
        excel_cells_lead_qualification_other_interests,
        excel_cells_lead_qualification_preferred_channel,
        generic_yes,
        lead_qualification_information_payment,
        lead_qualification_information_photos_and_details,
        lead_qualification_information_delivery_date,
        lead_qualification_rooms,
        lead_qualification_preferred_channel_whatsapp,
        lead_qualification_preferred_channel_email,
        lead_qualification_preferred_channel_call,
        lead_qualification_preferred_channel_other,
        enquiry_new_email_hotness_hot,
        enquiry_new_email_hotness_super_hot,
        enquiry_new_email_qualification_time_to_move_as_soon_as_possible,
        enquiry_new_email_qualification_time_to_move_in_3_months,
        enquiry_new_email_qualification_financing_need_requires_credit,
        enquiry_new_email_qualification_financing_need_already_has_credit,
        enquiry_new_email_qualification_financing_need_does_not_require_credit,
        enquiry_new_email_qualification_guarantee_already_has_guarantee,
        enquiry_new_email_qualification_guarantee_can_have_guarantee,
        enquiry_new_email_qualification_guarantee_cannot_have_guarantee,
        excel_cells_call_duration,
        excel_cells_lead_qualification,
        excel_cells_hotness,
        excel_cells_only_premium_function,
        enqpage_table_activity_personal_shopper,
      },
    },
    [response]
  ) => {
    function getHotnessColumn(lead) {
      if (!hasPremiumSubscription) {
        return excel_cells_only_premium_function;
      }
      if (lead.leadQualificationHot) {
        return enquiry_new_email_hotness_hot;
      }

      if (lead.leadQualificationSuperHot) {
        return enquiry_new_email_hotness_super_hot;
      }
      return '';
    }

    function getQualificationColumn(lead) {
      if (!hasPremiumSubscription) {
        return excel_cells_only_premium_function;
      }
      const financingNeedTags = {
        requires_credit:
          enquiry_new_email_qualification_financing_need_requires_credit,
        already_has_credit:
          enquiry_new_email_qualification_financing_need_already_has_credit,
        does_not_require_credit:
          enquiry_new_email_qualification_financing_need_does_not_require_credit,
      };
      const guaranteeTags = {
        already_has_guarantee:
          enquiry_new_email_qualification_guarantee_already_has_guarantee,
        can_have_guarantee:
          enquiry_new_email_qualification_guarantee_can_have_guarantee,
        cannot_have_guarantee:
          enquiry_new_email_qualification_guarantee_cannot_have_guarantee,
      };
      const timeToMoveTags = {
        as_soon_as_possible:
          enquiry_new_email_qualification_time_to_move_as_soon_as_possible,
        in_3_months: enquiry_new_email_qualification_time_to_move_in_3_months,
      };

      // Join all the tags available divided by a | character
      return [
        financingNeedTags[lead.leadQualificationFinancingNeed],
        guaranteeTags[lead.leadQualificationGuarantee],
        timeToMoveTags[lead.leadQualificationTimeToMove],
      ]
        .filter((tag) => tag)
        .join(' | ');
    }

    function obtainOperationTypeTag(operationType) {
      const tagsByOperationType = {
        sell: excel_cells_operation_type_sale,
        rent: excel_cells_operation_type_rent,
        unknown: enquiries_unknokwn_value,
      };
      return tagsByOperationType[operationType];
    }

    function obtainPropertyTypeTag(propertyType) {
      const tagsByPropertyType = {
        [PROPERTY_TYPE_APARTMENT]: newprop_proptype1,
        [PROPERTY_TYPE_HOUSE]: newprop_proptype2,
        [PROPERTY_TYPE_CONDO]: newprop_proptype,
        [PROPERTY_TYPE_VILLA]: newprop_proptype4,
        [PROPERTY_TYPE_COMMERCIAL]: newprop_proptype7,
        [PROPERTY_TYPE_LAND]: newprop_proptype11,
        [PROPERTY_TYPE_TOWNHOUSE]: newprop_proptype5,
        [PROPERTY_TYPE_SERVICED_APARTMENT]: newprop_proptype6,
        [PROPERTY_TYPE_OFFICE]: newprop_proptype8,
        [PROPERTY_TYPE_INDUSTRIAL_UNIT]: newprop_proptype9,
        [PROPERTY_TYPE_CAR_PARK]: newprop_proptype10,
        [PROPERTY_TYPE_STUDIO]: newprop_proptype18,
        [PROPERTY_TYPE_ROOM]: newprop_proptype14,
        [PROPERTY_TYPE_BEACH_HOUSE]: newprop_proptype13,
        [PROPERTY_TYPE_HOTEL]: ad_form_property_type_hotel,
        [PROPERTY_TYPE_SUITE]: ad_form_property_type_suite,
        [PROPERTY_TYPE_FLAT]: ad_form_property_type_flat,
        [PROPERTY_TYPE_PENTHOUSE]: ad_form_property_type_penthouse,
        [PROPERTY_TYPE_BUNGALOW]: ad_form_property_type_bungalow,
        [PROPERTY_TYPE_DUPLEX]: ad_form_property_type_duplex,
        [PROPERTY_TYPE_RUSTIC_HOUSE]: ad_form_property_type_rustic_house,
        [PROPERTY_TYPE_FIRST_FLOOR]: ad_form_property_type_first_floor,
        [PROPERTY_TYPE_BUILDING]: ad_form_property_type_building,
        [PROPERTY_TYPE_STORAGE_ROOM]: ad_form_property_type_storage_room,
        [PROPERTY_TYPE_RANCH]: ad_form_property_type_ranch,
        [PROPERTY_TYPE_QUINTA]: ad_form_property_type_quinta,
        [PROPERTY_TYPE_GATED_COMMUNITY_HOUSE]: newprop_proptype15,
        [PROPERTY_TYPE_HORIZONTAL_CONDO]: newprop_proptype16,
        [PROPERTY_TYPE_LOFT]: newprop_proptype17,
        [PROPERTY_TYPE_COMMERCIAL_IN_MALL]: newprop_proptype19,
        [PROPERTY_TYPE_COMMERCIAL_LAND]: newprop_proptype20,
        [PROPERTY_TYPE_DOCTOR_OFFICE]: newprop_proptype21,
      };
      return tagsByPropertyType[propertyType];
    }

    function obtainStatusTag(status) {
      const tagsByStatus = {
        'new lead': enqpage_table_status2,
        contacted: enqpage_table_status1,
        visit: status_enquiries_visit,
        proposal: status_enquiries_proposal,
        won: status_enquiries_won,
        lost: status_enquiries_lost,
      };

      return tagsByStatus[status];
    }

    function obtainLeadTypeTag(leadType, isFromPersonalShopper) {
      const tagsByLeadType = {
        chat: excel_cells_lead_type_chat,
        email: excel_cells_lead_type_mail,
        phone: excel_cells_lead_type_phone,
        call: excel_cells_contact_method_call,
        whatsapp: excel_cells_contact_method_whatsapp,
        line: excel_cells_contact_method_line,
        viber: excel_cells_contact_method_viber,
        messenger: excel_cells_contact_method_facebook_msn,
      };

      if (
        PREMIUM_DOWNLOAD_CSV_4285 &&
        hasPremiumSubscription &&
        isFromPersonalShopper
      ) {
        return enqpage_table_activity_personal_shopper;
      }

      return tagsByLeadType[leadType];
    }

    function obtainBooleanAsText(boolean) {
      const tagsBooleanAsText = {
        true: overview_subscription_block_webs_yes,
        false: subscription_plans_no,
        undefined: '',
      };
      return tagsBooleanAsText[boolean];
    }

    function obtainQualificationInfoRequiredTag(infoRequired) {
      const tagsByQualificationInfoRequired = {
        payment_conditions: lead_qualification_information_payment,
        photos_and_details: lead_qualification_information_photos_and_details,
        delivery_date: lead_qualification_information_delivery_date,
      };

      return infoRequired
        .map((info) => tagsByQualificationInfoRequired[info])
        .join(', ');
    }

    function obtainQualificationPreferredChannelTag(channels) {
      const tagsByQualificationPreferredChannel = {
        whatsapp: lead_qualification_preferred_channel_whatsapp,
        email: lead_qualification_preferred_channel_email,
        call: lead_qualification_preferred_channel_call,
        other: lead_qualification_preferred_channel_other,
      };

      return channels
        .map((channel) => tagsByQualificationPreferredChannel[channel])
        .join(', ');
    }

    function buildProjectQualification(lead) {
      if (!isDeveloper) {
        return [];
      }

      return [
        {
          header: excel_cells_lead_qualification_visit_interest,
          value: lead.qualificationVisitInterest ? generic_yes : '',
        },
        {
          header: excel_cells_lead_qualification_information,
          value: lead.qualificationInfoRequired
            ? obtainQualificationInfoRequiredTag(lead.qualificationInfoRequired)
            : '',
        },
        {
          header: excel_cells_lead_qualification_budget,
          value: createQualificationBudget(lead),
        },
        {
          header: excel_cells_lead_qualification_other_interests,
          value: createQualificationOtherInterests(
            lead,
            lead_qualification_rooms
          ),
        },
        {
          header: excel_cells_lead_qualification_preferred_channel,
          value: lead.qualificationPreferredChannel
            ? obtainQualificationPreferredChannelTag(
                lead.qualificationPreferredChannel
              )
            : '',
        },
      ];
    }

    function subtractProjectName(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== excel_cells_project_name)
      );
    }

    function subtractSuitableForCredit(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== adform_field_apto_credito)
      );
    }

    function buildLeadQualification(lead) {
      if (!PREMIUM_DOWNLOAD_CSV_4285) {
        return [];
      }

      return [
        { header: excel_cells_hotness, value: getHotnessColumn(lead) },
        {
          header: excel_cells_lead_qualification,
          value: getQualificationColumn(lead),
        },
      ];
    }

    function buildCallDuration(lead) {
      if (!PREMIUM_DOWNLOAD_CSV_4285) {
        return [];
      }
      if (!hasPremiumSubscription) {
        return [
          {
            header: excel_cells_call_duration,
            value: excel_cells_only_premium_function,
          },
        ];
      }
      const minutes = Math.floor(lead.callDuration / 60);
      const seconds = lead.callDuration % 60;
      const transformedCallDuration = `${minutes}:${seconds}`;

      return [
        { header: excel_cells_call_duration, value: transformedCallDuration },
      ];
    }

    let translatedContent = response.data.map((lead) => [
      {
        header: excel_cells_date,
        value: toUTCDate(new Date(lead.date)).toISOString(),
      },
      {
        header: excel_cells_lead_type,
        value: obtainLeadTypeTag(lead.leadType, lead.isFromPersonalShopper),
      },
      { header: excel_cells_contact_name, value: lead.fullName },
      { header: excel_cells_telephone, value: lead.phone },
      { header: excel_cells_email, value: lead.email },
      ...buildLeadQualification(lead),
      { header: excel_cells_message, value: lead.message },
      ...buildCallDuration(lead),
      ...createSitesToPublish(lead.adId, lead.projectId, sitesToPublish),
      { header: excel_cells_reference_ID, value: lead.referenceId },
      {
        header: excel_cells_agency_contact_email,
        value: lead.contactEmails.join(', '),
      },
      { header: excel_cells_property_title, value: lead.title },
      {
        header: excel_cells_property_type,
        value: obtainPropertyTypeTag(lead.propertyType),
      },
      {
        header: excel_cells_price,
        value: formatPrice(lead.price.amount, lead.price.currency),
      },
      {
        header: adform_field_apto_credito,
        value: obtainBooleanAsText(lead.suitableForCredit),
      },
      { header: excel_cells_city, value: lead.address },
      { header: excel_cells_bedrooms, value: lead.bedrooms },
      { header: excel_cells_project_name, value: lead.projectName },
      {
        header: excel_cells_operation_type,
        value: obtainOperationTypeTag(lead.operationType),
      },
      { header: excel_cells_floor_area, value: lead.floorArea },
      {
        header: excel_cells_lead_status,
        value: obtainStatusTag(lead.status),
      },
      ...buildProjectQualification(lead),
    ]);

    if (!isSEA(country)) {
      translatedContent = subtractProjectName(translatedContent);
    }

    if (country !== 'AR') {
      translatedContent = subtractSuitableForCredit(translatedContent);
    }

    return effects.dispatch({
      id: LEADS_DOWNLOAD_FILE_GENERATED,
      payload: translatedContent,
    });
  },
  [
    state.get({
      sitesToPublish: 'countryConfig.sitesToPublish',
      country: 'publisher.countryCode',
      isDeveloper: 'publisher.isDeveloper',
      hasPremiumSubscription: 'publisher.subscription.isPremium',
    }),
    featureFlagsByCountry('PREMIUM_DOWNLOAD_CSV_4285'),
    translations([
      'enquiries_unknokwn_value',
      'excel_cells_date',
      'excel_cells_lead_type',
      'excel_cells_contact_name',
      'excel_cells_email',
      'excel_cells_telephone',
      'excel_cells_message',
      'excel_cells_lead_status',
      'excel_cells_reference_ID',
      'excel_cells_agency_contact_email',
      'excel_cells_property_title',
      'excel_cells_property_type',
      'excel_cells_price',
      'excel_cells_city',
      'excel_cells_bedrooms',
      'excel_cells_project_name',
      'excel_cells_operation_type',
      'excel_cells_floor_area',
      'newprop_proptype',
      'newprop_proptype1',
      'newprop_proptype2',
      'newprop_proptype4',
      'newprop_proptype5',
      'newprop_proptype6',
      'newprop_proptype7',
      'newprop_proptype8',
      'newprop_proptype9',
      'newprop_proptype10',
      'newprop_proptype11',
      'newprop_proptype12',
      'newprop_proptype13',
      'newprop_proptype14',
      'newprop_proptype15',
      'newprop_proptype16',
      'newprop_proptype17',
      'newprop_proptype18',
      'newprop_proptype19',
      'newprop_proptype20',
      'newprop_proptype21',
      'ad_form_property_type_hotel',
      'ad_form_property_type_suite',
      'ad_form_property_type_flat',
      'ad_form_property_type_penthouse',
      'ad_form_property_type_bungalow',
      'ad_form_property_type_duplex',
      'ad_form_property_type_rustic_house',
      'ad_form_property_type_first_floor',
      'ad_form_property_type_building',
      'ad_form_property_type_storage_room',
      'ad_form_property_type_ranch',
      'ad_form_property_type_quinta',
      'excel_cells_operation_type_sale',
      'excel_cells_operation_type_rent',
      'excel_cells_lead_type_chat',
      'excel_cells_lead_type_mail',
      'excel_cells_lead_type_phone',
      'excel_cells_contact_method_call',
      'excel_cells_contact_method_whatsapp',
      'excel_cells_contact_method_line',
      'excel_cells_contact_method_viber',
      'excel_cells_contact_method_facebook_msn',
      'enqpage_table_status2',
      'enqpage_table_status1',
      'status_enquiries_visit',
      'status_enquiries_proposal',
      'status_enquiries_won',
      'status_enquiries_lost',
      'adform_field_apto_credito',
      'subscription_plans_no',
      'overview_subscription_block_webs_yes',
      'excel_cells_lead_qualification_visit_interest',
      'excel_cells_lead_qualification_budget',
      'excel_cells_lead_qualification_information',
      'excel_cells_lead_qualification_other_interests',
      'excel_cells_lead_qualification_preferred_channel',
      'generic_yes',
      'lead_qualification_information_payment',
      'lead_qualification_information_photos_and_details',
      'lead_qualification_information_delivery_date',
      'lead_qualification_rooms',
      'lead_qualification_preferred_channel_whatsapp',
      'lead_qualification_preferred_channel_email',
      'lead_qualification_preferred_channel_call',
      'lead_qualification_preferred_channel_other',
      'enquiry_new_email_hotness_hot',
      'enquiry_new_email_hotness_super_hot',
      'enquiry_new_email_qualification_time_to_move_as_soon_as_possible',
      'enquiry_new_email_qualification_time_to_move_in_3_months',
      'enquiry_new_email_qualification_financing_need_requires_credit',
      'enquiry_new_email_qualification_financing_need_already_has_credit',
      'enquiry_new_email_qualification_financing_need_does_not_require_credit',
      'enquiry_new_email_qualification_guarantee_already_has_guarantee',
      'enquiry_new_email_qualification_guarantee_can_have_guarantee',
      'enquiry_new_email_qualification_guarantee_cannot_have_guarantee',
      'excel_cells_hotness',
      'excel_cells_lead_qualification',
      'excel_cells_call_duration',
      'excel_cells_only_premium_function',
      'enqpage_table_activity_personal_shopper',
    ]),
  ]
);

registerEventHandler(
  LEADS_DOWNLOAD_FILE_GENERATED,
  ({ state: { format } }, payload) => {
    if (format === FORMAT_XLS) {
      return downloadXls({ filename: 'leads.xls', content: payload });
    }

    return downloadCsv({ filename: 'leads.csv', content: payload });
  },
  [state.get({ format: 'enquiries:exportFormat' })]
);

function formatPrice(amount, currency) {
  if (!amount || !currency) {
    return '';
  }
  const symbolsByCurrency = {
    USD: '$',
    CLF: 'UF',
    CLP: '$',
    COP: '$',
    MXN: 'MEX$',
    PHP: '₱',
    THB: '฿',
    VND: '₫',
    ARS: '$',
    PAB: 'B/.',
    PEN: 'S/',
  };
  return `${amount}${symbolsByCurrency[currency]}`;
}

function createSitesToPublish(adId, projectId, sitesToPublish) {
  return sitesToPublish
    .filter(({ domain }) => domain)
    .map(({ site, domain, projectDetailPage }) => ({
      header: `Link ${site}`,
      value: adId ? `${domain}${adId}` : `${projectDetailPage}${projectId}`,
    }));
}

function createQualificationBudget(lead) {
  const minBudget = lead.qualificationMinBudget;
  const maxBudget = lead.qualificationMaxBudget;

  if (minBudget && maxBudget) {
    return `${minBudget.amount} - ${maxBudget.amount} ${maxBudget.currency}`;
  }
  if (minBudget) {
    return `${minBudget.amount} ${minBudget.currency}`;
  }
  if (maxBudget) {
    return `${maxBudget.amount} ${maxBudget.currency}`;
  }

  return '';
}

function createQualificationOtherInterests(lead, roomTranslation) {
  const region = lead.qualificationRegion;
  const minRooms = lead.qualificationMinRooms;
  const maxRooms = lead.qualificationMaxRooms;

  if (!region) {
    return '';
  }

  if (minRooms && maxRooms) {
    return `${minRooms} - ${maxRooms} ${roomTranslation} / ${region}`;
  }
  if (minRooms) {
    return `${minRooms} ${roomTranslation} / ${region}`;
  }
  if (maxRooms) {
    return `${maxRooms} ${roomTranslation} / ${region}`;
  }

  return '';
}
