import React from 'react';
import BreakdownTable from 'design-system/components/BreakdownTable/BreakdownTable';
import Dialog from 'design-system/components/Dialog/Dialog';
import { dispatch } from 'reffects';
import { OutlineButton } from 'design-system/components/Button/presets';
import { useDialog } from '../../../hooks/useDialog';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Discounts from './PlanSummary/Discounts';
import Taxes from './PlanSummary/Taxes';
import TaxDiscounts from './PlanSummary/TaxDiscounts';
import { DISCOUNT_NAMES } from '../../../constants/subscription';
import Price from '../../../partials/Price/Price';
import { OPEN_REQUEST_CANCELLATION_MODAL } from './CurrentPlanBanner/events';

export const SUBSCRIPTION_DETAILS_DIALOG = 'SUBSCRIPTION_DETAILS_DIALOG';

export default function SubscriptionDetailsModal() {
  const { open, closeDialog, parameters } = useDialog(
    SUBSCRIPTION_DETAILS_DIALOG
  );
  if (
    !parameters ||
    !parameters.subscription ||
    !parameters.subscription.products
  ) {
    return null;
  }
  const { subscription } = parameters;
  const { products } = subscription;

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>
        <FormattedMessageWithValidation id={parameters.title} />
      </Dialog.Header>
      <Dialog.Content>
        <BreakdownTable>
          {products.map(
            ({
              boostableProperties,
              nameTag,
              monthlyPrice,
              basePrice,
              type,
              discounts,
              billingDetail: { proportionalPriceForRemainingDays } = {},
              unusedPreviousSubscriptionDays,
            }) => (
              <React.Fragment key={type}>
                {!proportionalPriceForRemainingDays && (
                  <BreakdownTable.Row
                    title={
                      <>
                        <FormattedMessageWithValidation id={nameTag} />
                        &nbsp;
                        <Price
                          amount={monthlyPrice.amount}
                          currency={monthlyPrice.currency}
                        />
                        &nbsp;x&nbsp;
                        <FormattedMessageWithValidation
                          id="subscription_summary_contract_type_value"
                          values={{
                            duration: subscription.billingCycleInMonths,
                          }}
                        />
                        &nbsp;-&nbsp;{boostableProperties}{' '}
                        <FormattedMessageWithValidation id="subscription_boosted_properties" />
                      </>
                    }
                    price={
                      <Price
                        amount={basePrice.amount}
                        currency={basePrice.currency}
                      />
                    }
                  />
                )}
                {proportionalPriceForRemainingDays && (
                  <BreakdownTable.Row
                    title={
                      <FormattedMessageWithValidation
                        id="checkout_summary_proportional_price_for_remaining_days"
                        values={{
                          boostableProperties,
                          days: unusedPreviousSubscriptionDays,
                        }}
                      />
                    }
                    price={
                      <Price
                        amount={proportionalPriceForRemainingDays.amount}
                        currency={proportionalPriceForRemainingDays.currency}
                      />
                    }
                  />
                )}
                <Discounts
                  subscription={{
                    ...subscription,
                    discounts: Object.values(discounts).filter(
                      ({ name }) =>
                        !proportionalPriceForRemainingDays &&
                        name === DISCOUNT_NAMES.PROPORTIONAL_SUPERBOOST
                    ),
                  }}
                />
              </React.Fragment>
            )
          )}
          <Discounts
            subscription={{
              ...subscription,
              discounts: Object.values(subscription.discounts).filter(
                ({ name }) =>
                  name !== DISCOUNT_NAMES.CUSTOM &&
                  name !== DISCOUNT_NAMES.PROPORTIONAL_SUPERBOOST
              ),
            }}
          />
          <Discounts
            subscription={{
              ...subscription,
              discounts: Object.values(subscription.discounts).filter(
                ({ name }) => name === DISCOUNT_NAMES.CUSTOM
              ),
            }}
          />
          <BreakdownTable.Row
            title={
              <FormattedMessageWithValidation id="checkout_summary_subtotal" />
            }
            price={
              <Price
                amount={subscription.totalBasePriceWithDiscount.amount}
                currency={subscription.totalBasePriceWithDiscount.currency}
              />
            }
          />
          <Taxes taxes={subscription.taxApplied} />
          <TaxDiscounts taxDiscounts={subscription.taxDiscounts} />
          <BreakdownTable.TotalRow
            title={
              <FormattedMessageWithValidation id="subscription_summary_total" />
            }
            price={
              <Price
                amount={subscription.totalPrice.amount}
                currency={subscription.totalPrice.currency}
              />
            }
          />
        </BreakdownTable>
      </Dialog.Content>
      <Dialog.Footer>
        {parameters?.showCancelAutoRenewal && (
          <OutlineButton
            onClick={() => dispatch(OPEN_REQUEST_CANCELLATION_MODAL)}
          >
            <FormattedMessageWithValidation id="subscription_request_cancellation_cta" />
          </OutlineButton>
        )}
      </Dialog.Footer>
    </Dialog>
  );
}
