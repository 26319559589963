import { COLOR_PALETTE } from 'design-system/styles/color';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { useSelector } from 'reffects-store';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  differencePercentageSelector,
  hasMarketReferenceStatusSelector,
  priceIsAboveMarketPriceSelector,
  priceIsBelowMarketPriceSelector,
  priceIsSameAsMarketPriceSelector,
  pricePerSquareMeterSelector,
  suggestedPriceSelector,
} from './selectors';
import Price from '../../../../partials/Price/Price';
import MarketReferenceUploadProgress from '../../AdForm/partials/MarketReferenceUploadProgress/MarketReferenceUploadProgress';

export default function MarketPrice() {
  const aboveMarketPrice = useSelector(priceIsAboveMarketPriceSelector);
  const exactMarketPrice = useSelector(priceIsSameAsMarketPriceSelector);
  const belowMarketPrice = useSelector(priceIsBelowMarketPriceSelector);
  const pricePerSquareMeter = useSelector(pricePerSquareMeterSelector);
  const differencePercentage = useSelector(differencePercentageSelector);
  const suggestedPrice = useSelector(suggestedPriceSelector);
  const hasMarketReferenceStatus = useSelector(
    hasMarketReferenceStatusSelector
  );
  const formatPrice = ({ amount, currency }) => (
    <Price amount={amount} currency={currency} />
  );

  if (!hasMarketReferenceStatus) {
    return null;
  }

  return (
    <MarketReferenceUploadProgress>
      <Body2 $weight={FONT_WEIGHT.NORMAL} $color={COLOR_PALETTE.PRIMARY_A120}>
        {aboveMarketPrice && (
          <FormattedMessageWithValidation
            id="ad_form_section_market_price_above"
            values={{
              pricePerSquareMeter: formatPrice(pricePerSquareMeter),
              percentage: differencePercentage,
              suggestedPrice: formatPrice(suggestedPrice),
            }}
          />
        )}
        {exactMarketPrice && (
          <FormattedMessageWithValidation
            id="ad_form_section_market_price_exact"
            values={{
              pricePerSquareMeter: formatPrice(pricePerSquareMeter),
            }}
          />
        )}
        {belowMarketPrice && (
          <FormattedMessageWithValidation
            id="ad_form_section_market_price_below"
            values={{
              pricePerSquareMeter: formatPrice(pricePerSquareMeter),
              percentage: differencePercentage,
              suggestedPrice: formatPrice(suggestedPrice),
            }}
          />
        )}
      </Body2>
    </MarketReferenceUploadProgress>
  );
}
