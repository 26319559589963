import { useMatch } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useEffect } from 'react';
import { BoostingLimitReachedModal } from '../ad/variants/BoostingLimitReachedModal';
import {
  hasSuperboostLimitReachedModalShowedSelector,
  showBoostedPropertiesLimitReachedModalSelector,
  showSuperboostingLimitReachedModalSelector,
} from '../ad/selectors';
import {
  OPEN_BOOSTING_LIMIT_REACHED_MODAL_BY_COUNTRY,
  OPEN_SUPERBOOSTING_LIMIT_REACHED_MODAL,
} from '../ad/events';
import { UpsellSuperBoostingLimitReachedModal } from '../ad/variants/UpsellSuperboostingLimitReachedModal';

export default function PublisherLimitReachedOffersModal() {
  const isInSubscriptionPage = useMatch('/subscription*');
  const isInPropertiesPage = useMatch('/properties*');
  const showSuperboostModal = useSelector(
    showSuperboostingLimitReachedModalSelector
  );
  const showBoostingModal = useSelector(
    showBoostedPropertiesLimitReachedModalSelector
  );
  const hasSuperboostLimitReachedModalShowed = useSelector(
    hasSuperboostLimitReachedModalShowedSelector
  );
  useEffect(() => {
    if (isInPropertiesPage || isInSubscriptionPage) {
      return;
    }
    if (showBoostingModal) {
      console.log('Boosting Limit Reached Modal');
      dispatch({
        id: OPEN_BOOSTING_LIMIT_REACHED_MODAL_BY_COUNTRY,
        payload: {
          section: 'boosting-limit-reached-modal',
        },
      });
    }
    if (showSuperboostModal && !hasSuperboostLimitReachedModalShowed) {
      dispatch({
        id: OPEN_SUPERBOOSTING_LIMIT_REACHED_MODAL,
        payload: {
          section: 'superboosting-limit-reached-modal',
        },
      });
    }
  }, [
    showSuperboostModal,
    isInPropertiesPage,
    isInSubscriptionPage,
    hasSuperboostLimitReachedModalShowed,
    showBoostingModal,
  ]);

  return (
    <>
      <BoostingLimitReachedModal />
      <UpsellSuperBoostingLimitReachedModal />
    </>
  );
}
