import { get } from 'lodash';
import { plansSelector } from './Agencies/selectors';

function filterPlansByMaxBoostablePropertiesSelector(
  state,
  maxBoostablePropertiesSelected
) {
  return plansSelector(state)
    .filter(
      ({ maxBoostableProperties }) =>
        maxBoostableProperties === maxBoostablePropertiesSelected
    )
    .filter(({ price }) => price > 0)
    .reduce((acc, plan) => {
      if (!acc.some((p) => p.id === plan.id)) {
        acc.push(plan);
      }
      return acc;
    }, [])
    .sort((plan, anotherPlan) => plan.price - anotherPlan.price);
}

export function publishersSelector(state) {
  return state['admin:publishers'] ?? [];
}

export function orderAlphabetically(publisher, anotherPublisher) {
  return publisher.name.localeCompare(anotherPublisher.name);
}

export function createPlansByMaxBoostablePropertiesSelector(
  numberOfMaxBoostablePropertiesKey
) {
  return (state) =>
    filterPlansByMaxBoostablePropertiesSelector(
      state,
      get(state, numberOfMaxBoostablePropertiesKey)
    );
}
