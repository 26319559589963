import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { Link } from 'react-router-dom';
import Avatar from 'design-system/components/Avatar/Avatar';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Menu from 'design-system/components/Menu/Menu';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import {
  isPremiumSubscriptionSelector,
  publisherLogoSelector,
} from '../../selectors/publisher';

import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';

import { LOGOUT_USER_REQUESTED } from '../../App/events';
import {
  changePasswordRoute,
  managedPublishersRoute,
} from '../../utils/proppitWebRouter';
import { legalLinksSelector } from '../../selectors/config';
import { userManagesMultiplePublishersSelector } from '../../selectors/user';

const Wrapper = styled.div`
  ${spacing.value(SPACING_SIZE.L)}
`;

const AvatarButton = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: 0;
`;

const BodyItem = styled(Body1)`
  line-height: 24px;
`;

function UserMenu() {
  const hasPremiumSubscription = useSelector(isPremiumSubscriptionSelector);
  const managesMultiplePublishers = useSelector(
    userManagesMultiplePublishersSelector
  );
  const publisherLogoSource = useSelector(publisherLogoSelector);
  const { termsAndConditions, privacyPolicy, cookiesPolicy } =
    useSelector(legalLinksSelector);

  const handleMenuItemConfirmed = ({ value }) => value && dispatch(value);
  return (
    <Wrapper>
      <DropdownMenu
        button={
          <AvatarButton>
            <Avatar
              source={publisherLogoSource}
              hasPremiumSubscription={hasPremiumSubscription}
            />
          </AvatarButton>
        }
        onConfirm={handleMenuItemConfirmed}
      >
        <Menu>
          {managesMultiplePublishers && (
            <>
              <Menu.Item
                id="user_menu_managed_publishers"
                label={
                  <BodyItem $noWrap>
                    <FormattedMessageWithValidation id="user_menu_managed_publishers" />
                  </BodyItem>
                }
                forwardedAs={Link}
                to={managedPublishersRoute()}
              />
              <Menu.Divider />
            </>
          )}
          <Menu.Item
            id="user_menu_change_password"
            label={
              <BodyItem $noWrap>
                <FormattedMessageWithValidation id="user_menu_changepwd" />
              </BodyItem>
            }
            forwardedAs={Link}
            to={changePasswordRoute()}
          />
          <Menu.Item
            id="user_menu_logout"
            label={
              <BodyItem $noWrap>
                <FormattedMessageWithValidation id="user_menu_logout" />
              </BodyItem>
            }
            value={LOGOUT_USER_REQUESTED}
            tabIndex={0}
            role="button"
          />
          <Menu.Divider />
          <Menu.Item
            id="user_menu_terms_and_conditions"
            label={
              <BodyItem $noWrap>
                <FormattedMessageWithValidation id="termsandconds_footer_tandco" />
              </BodyItem>
            }
            forwardedAs={Link}
            to={termsAndConditions}
          />
          <Menu.Item
            id="user_menu_privacy_policy"
            label={
              <BodyItem $noWrap>
                <FormattedMessageWithValidation id="termsandconds_footer_privacy" />
              </BodyItem>
            }
            forwardedAs={Link}
            to={privacyPolicy}
          />
          <Menu.Item
            id="user_menu_cookies_policy"
            label={
              <BodyItem $noWrap>
                <FormattedMessageWithValidation id="termsandconds_footer_cookies" />
              </BodyItem>
            }
            forwardedAs={Link}
            to={cookiesPolicy}
          />
        </Menu>
      </DropdownMenu>
    </Wrapper>
  );
}

export default UserMenu;
