import { css } from 'styled-components';
import { color as colorTokens } from '../tokens/color';

export const COLOR_PALETTE = {
  NONE: 'transparent',

  PRIMARY_A05: colorTokens.palette.primary.a05,
  PRIMARY_A10: colorTokens.palette.primary.a10,
  PRIMARY_A20: colorTokens.palette.primary.a20,
  PRIMARY_A40: colorTokens.palette.primary.a40,
  PRIMARY_A60: colorTokens.palette.primary.a60,
  PRIMARY_A80: colorTokens.palette.primary.a80,
  PRIMARY_BASE: colorTokens.palette.primary.base,
  PRIMARY_A120: colorTokens.palette.primary.a120,
  PRIMARY_A140: colorTokens.palette.primary.a140,
  PRIMARY_A160: colorTokens.palette.primary.a160,

  SECONDARY_A05: colorTokens.palette.secondary.a05,
  SECONDARY_A10: colorTokens.palette.secondary.a10,
  SECONDARY_A20: colorTokens.palette.secondary.a20,
  SECONDARY_A40: colorTokens.palette.secondary.a40,
  SECONDARY_A60: colorTokens.palette.secondary.a60,
  SECONDARY_A80: colorTokens.palette.secondary.a80,
  SECONDARY_BASE: colorTokens.palette.secondary.base,

  PUMPKIN_A05: colorTokens.palette.pumpkin.a05,
  PUMPKIN_A10: colorTokens.palette.pumpkin.a10,
  PUMPKIN_A20: colorTokens.palette.pumpkin.a20,
  PUMPKIN_A40: colorTokens.palette.pumpkin.a40,
  PUMPKIN_A60: colorTokens.palette.pumpkin.a60,
  PUMPKIN_A80: colorTokens.palette.pumpkin.a80,
  PUMPKIN_BASE: colorTokens.palette.pumpkin.base,
  PUMPKIN_A110: colorTokens.palette.pumpkin.a110,

  GOLD_BASE: colorTokens.palette.gold.base,
  GOLD_A120: colorTokens.palette.gold.a120,

  EGGPLANT_A05: colorTokens.palette.eggplant.a05,
  EGGPLANT_A10: colorTokens.palette.eggplant.a10,
  EGGPLANT_A20: colorTokens.palette.eggplant.a20,
  EGGPLANT_A40: colorTokens.palette.eggplant.a40,
  EGGPLANT_A60: colorTokens.palette.eggplant.a60,
  EGGPLANT_A80: colorTokens.palette.eggplant.a80,
  EGGPLANT_BASE: colorTokens.palette.eggplant.base,

  BLUEBERRY_A05: colorTokens.palette.blueberry.a05,
  BLUEBERRY_A10: colorTokens.palette.blueberry.a10,
  BLUEBERRY_A20: colorTokens.palette.blueberry.a20,
  BLUEBERRY_A40: colorTokens.palette.blueberry.a40,
  BLUEBERRY_A60: colorTokens.palette.blueberry.a60,
  BLUEBERRY_A80: colorTokens.palette.blueberry.a80,
  BLUEBERRY_BASE: colorTokens.palette.blueberry.base,

  STRAWBERRY_A05: colorTokens.palette.strawberry.a05,
  STRAWBERRY_A10: colorTokens.palette.strawberry.a10,
  STRAWBERRY_A20: colorTokens.palette.strawberry.a20,
  STRAWBERRY_A40: colorTokens.palette.strawberry.a40,
  STRAWBERRY_A60: colorTokens.palette.strawberry.a60,
  STRAWBERRY_A80: colorTokens.palette.strawberry.a80,
  STRAWBERRY_BASE: colorTokens.palette.strawberry.base,

  NEUTRAL_A00: colorTokens.palette.neutral.a00,
  NEUTRAL_A03: colorTokens.palette.neutral.a03,
  NEUTRAL_A05: colorTokens.palette.neutral.a05,
  NEUTRAL_A10: colorTokens.palette.neutral.a10,
  NEUTRAL_A20: colorTokens.palette.neutral.a20,
  NEUTRAL_A40: colorTokens.palette.neutral.a40,
  NEUTRAL_A60: colorTokens.palette.neutral.a60,
  NEUTRAL_A80: colorTokens.palette.neutral.a80,
  NEUTRAL_BASE: colorTokens.palette.neutral.base,
};

export const COLOR_SEMANTIC = {
  PAPER: colorTokens.semantic.paper,
  DIVIDER: colorTokens.semantic.divider,

  STATUS_ERROR: colorTokens.semantic.status.error,
};

const background = (colorValue) => css`
  background-color: ${colorValue};
`;

const text = (colorValue) => css`
  color: ${colorValue};
`;

export const color = {
  background,
  text,
};
