import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../../../coeffects/environment';

export const REQUEST_ENQUIRY_QUALIFICATION = 'REQUEST_ENQUIRY_QUALIFICATION';
export const ENQUIRY_QUALIFICATION_LOADED = 'ENQUIRY_QUALIFICATION_LOADED';
export const REQUEST_LEAD_QUALIFICATION = 'REQUEST_LEAD_QUALIFICATION';
export const REQUEST_LEAD_QUALIFICATION_LOADED =
  'REQUEST_LEAD_QUALIFICATION_LOADED';

registerEventHandler(
  REQUEST_ENQUIRY_QUALIFICATION,
  ({ environment: { apiUrl }, state: { leadId } }) =>
    http.get({
      url: `${apiUrl}/enquiry-qualification/${leadId}`,
      successEvent: ENQUIRY_QUALIFICATION_LOADED,
    }),
  [environment(), state.get({ leadId: 'lead.id' })]
);

registerEventHandler(ENQUIRY_QUALIFICATION_LOADED, (_, [{ data }]) =>
  state.set({ enquiryQualification: data })
);

registerEventHandler(
  REQUEST_LEAD_QUALIFICATION,
  ({ environment: { apiUrl }, state: { leadId } }) =>
    http.get({
      url: `${apiUrl}/lead-qualification/${leadId}`,
      successEvent: REQUEST_LEAD_QUALIFICATION_LOADED,
    }),
  [environment(), state.get({ leadId: 'lead.id' })]
);

registerEventHandler(REQUEST_LEAD_QUALIFICATION_LOADED, (_, [{ data }]) =>
  state.set({ leadQualification: data })
);
