import { useSelector } from 'reffects-store';
import { DropdownFilter } from '../../../partials/DataTable';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { isPublisherFromMxSelector } from '../../../selectors/publisher';
import { superboostEnabledSelector } from '../../../selectors/config';

function NestedText({ id }) {
  return (
    <span>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <FormattedMessageWithValidation id={id} />
    </span>
  );
}

const STATUS_OPTIONS = [
  {
    id: 'all',
    value: null,
    label: <FormattedMessageWithValidation id="filter_properties_status_all" />,
  },
  {
    id: 'published',
    value: 'published',
    label: (
      <FormattedMessageWithValidation id="filter_properties_status_published" />
    ),
  },
  {
    id: 'published-superboosted',
    value: 'published-superboosted',
    label: <NestedText id="filter_properties_status_published_superboosted" />,
  },
  {
    id: 'published-boosted',
    value: 'published-boosted',
    label: <NestedText id="filter_properties_status_published_boosted" />,
  },
  {
    id: 'published-not-boosted',
    value: 'published-not-boosted',
    label: <NestedText id="filter_properties_status_published_not_boosted" />,
  },
  {
    id: 'unpublished',
    value: 'unpublished',
    label: (
      <FormattedMessageWithValidation id="filter_properties_status_not_listed" />
    ),
  },
];
const STATUS_OPTIONS_FOR_MX = [
  {
    id: 'all',
    value: null,
    label: <FormattedMessageWithValidation id="filter_properties_status_all" />,
  },
  {
    id: 'published',
    value: 'published',
    label: (
      <FormattedMessageWithValidation id="filter_properties_status_published" />
    ),
  },
  {
    id: 'published-superboosted',
    value: 'published-superboosted',
    label: <NestedText id="filter_properties_status_published_superboosted" />,
  },
  {
    id: 'unpublished',
    value: 'unpublished',
    label: (
      <FormattedMessageWithValidation id="filter_properties_status_not_listed" />
    ),
  },
];

export function StatusFilter() {
  const hasSuperboostEnabled = useSelector(superboostEnabledSelector);
  const isMXPublisher = useSelector(isPublisherFromMxSelector);

  const statusOptions = isMXPublisher ? STATUS_OPTIONS_FOR_MX : STATUS_OPTIONS;
  return (
    <DropdownFilter
      label={<FormattedMessageWithValidation id="propspage_table_status" />}
      source="status"
      options={statusOptions.filter(
        ({ id }) => hasSuperboostEnabled || id !== 'published-superboosted'
      )}
    />
  );
}
