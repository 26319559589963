import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { isEmpty } from 'lodash';
import { environment } from '../../coeffects/environment';

import { navigateTo } from '../../effects/routing';
import { enquiriesRoute } from '../../utils/proppitWebRouter';
import { UNREAD_LEADS_COUNT_REQUESTED } from '../../events';
import { REQUEST_LEAD_PLUS_ACTIVITY } from './partials/LeadInformation/partials/LeadPlus/events';
import { ACTIVITY_PERIOD_LAST_3_MONTHS } from './partials/LeadInformation/partials/LeadPlus/constants';
import {
  REQUEST_ENQUIRY_QUALIFICATION,
  REQUEST_LEAD_QUALIFICATION,
} from './partials/LeadInformation/partials/Qualification/events';
import { tracking } from '../../effects/tracking';

export const LEAD_DETAIL_PAGE_MOUNTED = 'LEAD_DETAIL_PAGE_MOUNTED';
export const LEAD_DETAIL_PAGE_UNMOUNTED = 'LEAD_DETAIL_PAGE_UNMOUNTED';
export const LEAD_DATA_LOADED = 'LEAD_DATA_LOADED';
export const GO_BACK_TO_LEADS = 'GO_BACK_TO_LEADS';
export const LEAD_DETAIL_FEEDBACK_SUBMITTED = 'LEAD_DETAIL_FEEDBACK_SUBMITTED';

registerEventHandler(
  LEAD_DETAIL_PAGE_MOUNTED,
  ({ environment: { apiUrl } }, { leadId }) => ({
    ...state.set({
      'leadDetail.leadId': leadId,
      'lead:editingNoteId': undefined,
      selectedPropertyId: undefined,
      leadQualification: undefined,
    }),
    ...http.get({
      url: `${apiUrl}/leads/${leadId}`,
      successEvent: LEAD_DATA_LOADED,
    }),
  }),
  [environment()]
);

registerEventHandler(
  LEAD_DATA_LOADED,
  ({ environment: { apiUrl } }, [{ data }]) => {
    const lead = data;
    const eventsDispatched = [];
    if (lead.hasLeadPlus) {
      eventsDispatched.push({
        id: REQUEST_LEAD_PLUS_ACTIVITY,
        payload: { period: ACTIVITY_PERIOD_LAST_3_MONTHS },
      });
    }
    if (lead.hasEnquiryQualification) {
      eventsDispatched.push({ id: REQUEST_ENQUIRY_QUALIFICATION });
    }
    if (lead.hasLeadQualification) {
      eventsDispatched.push({ id: REQUEST_LEAD_QUALIFICATION });
    }
    return {
      ...state.set({ lead }),
      ...http.put({
        url: `${apiUrl}/leads/${lead.id}/read`,
        successEvent: UNREAD_LEADS_COUNT_REQUESTED,
      }),
      ...(!isEmpty(eventsDispatched)
        ? effects.dispatchMany(eventsDispatched)
        : {}),
    };
  },
  [environment()]
);

registerEventHandler(LEAD_DETAIL_PAGE_UNMOUNTED, () =>
  state.set({
    lead: undefined,
    leadPlus: undefined,
    activityTimeline: undefined,
    leadEnquiredProperties: undefined,
    'leadPlus:selectedOperation': undefined,
    leadQualification: undefined,
  })
);

registerEventHandler(
  GO_BACK_TO_LEADS,
  ({ state: { previousLeadsPageFilter = '' } }) => ({
    ...state.set({ previousLeadsPageFilter: undefined }),
    ...navigateTo(enquiriesRoute(previousLeadsPageFilter)),
  }),
  [state.get({ previousLeadsPageFilter: 'previousLeadsPageFilter' })]
);

registerEventHandler(
  LEAD_DETAIL_FEEDBACK_SUBMITTED,
  (_, { sectionName, payload }) => ({
    ...tracking.track('LeadFeedbackClicked', sectionName, payload),
  })
);
