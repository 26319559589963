import {
  maxBoostablePropertiesSelector,
  maxSuperboostablePropertiesSelector,
  propertiesWithBoostingSelector,
  propertiesWithSuperboostingSelector,
} from '../../../selectors/propertiesSummary';

export function getLastErrorAdId(state) {
  return state.tryToPublishErrorAdId ?? {};
}

export function showBoostedPropertiesLimitReachedModalSelector(state) {
  const maxBoostableProperties = maxBoostablePropertiesSelector(state);
  const boostedProperties = propertiesWithBoostingSelector(state);
  if (!maxBoostableProperties) {
    return false;
  }
  if (!boostedProperties) {
    return false;
  }
  return boostedProperties >= maxBoostableProperties;
}

export function superboostedPropertiesLimitReachedSelector(state) {
  const maxSuperboostableProperties =
    maxSuperboostablePropertiesSelector(state);
  const superboostingProperties = propertiesWithSuperboostingSelector(state);
  if (!maxSuperboostableProperties) {
    return false;
  }
  if (!superboostingProperties) {
    return false;
  }

  return superboostingProperties >= maxSuperboostableProperties;
}

export function showSuperboostingLimitReachedModalSelector(state) {
  const maxSuperboostableProperties =
    maxSuperboostablePropertiesSelector(state);
  const maxBoostableProperties = maxBoostablePropertiesSelector(state);
  const boostingLimitReached =
    showBoostedPropertiesLimitReachedModalSelector(state);
  const superboostingLimitReached =
    superboostedPropertiesLimitReachedSelector(state);

  if (boostingLimitReached) {
    return false;
  }

  return (
    superboostingLimitReached &&
    maxBoostableProperties > maxSuperboostableProperties
  );
}

export function hasSuperboostLimitReachedModalShowedSelector(state) {
  return state.superboostLimitReachedModalShowed ?? false;
}
