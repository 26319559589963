import { withoutEventPropagation } from 'design-system/utils';
import Menu from 'design-system/components/Menu/Menu';
import { dispatch } from 'reffects';
import { OPEN_PUBLISHER_HISTORY } from './events';

export function PublisherHistoryAction({ publisher: { id } }) {
  const showHistory = () =>
    dispatch({ id: OPEN_PUBLISHER_HISTORY, payload: { publisherId: id } });
  return (
    <Menu.Item
      label="Publisher History"
      onClick={withoutEventPropagation(showHistory)}
    />
  );
}
