import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ImagePreview from '../ImagePreview/ImagePreview';

export default function SortableImage({
  id,
  file,
  isMain,
  isBadQuality,
  onDelete,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ImagePreview
      ref={setNodeRef}
      style={style}
      id={id}
      file={file}
      isMain={isMain}
      displayDeleteIcon={!isDragging}
      isBadQuality={isBadQuality}
      onDelete={onDelete}
      {...attributes}
      {...listeners}
    />
  );
}
