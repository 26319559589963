import { useSelector } from 'reffects-store';
import GlobalizaLogo from 'design-system/components/GlobalizaLogo';
import LogoDotPropertyGroup from 'design-system/components/LogoDotPropertyGroup';
import LogoLamudi from 'design-system/components/LogoLamudi';
import Logo from 'design-system/components/Logo/Logo';
import styled from 'styled-components';
import Tag, { TAG_SIZE, TAG_VARIANT } from 'design-system/components/Tag';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  isPremiumSubscriptionSelector,
  showByDotPropertyLogoSelector,
  showByLamudiLogoSelector,
} from '../selectors/publisher';
import {
  isCountryConfigLoadedSelector,
  showGlobalizaLogoSelector,
} from '../App/selectors';
import FormattedMessageWithValidation from './FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.S)};
  ${Tag} {
    align-self: center;
    text-transform: uppercase;
  }
`;

export function BrandLogo() {
  const isCountryLoaded = useSelector(isCountryConfigLoadedSelector);
  const showDotPropertyLogo = useSelector(showByDotPropertyLogoSelector);
  const showLamudiLogo = useSelector(showByLamudiLogoSelector);
  const showGlobalizaLogo = useSelector(showGlobalizaLogoSelector);
  const hasPremiumSubscription = useSelector(isPremiumSubscriptionSelector);
  if (!isCountryLoaded) return null;

  const premiumTag = hasPremiumSubscription && (
    <Tag variant={TAG_VARIANT.PRIMARY} size={TAG_SIZE.SMALL}>
      <FormattedMessageWithValidation id="premium" />
    </Tag>
  );
  if (showDotPropertyLogo) {
    return (
      <Wrapper>
        <LogoDotPropertyGroup />
        {premiumTag}
      </Wrapper>
    );
  }

  if (showLamudiLogo) {
    return (
      <Wrapper>
        <LogoLamudi />
        {premiumTag}
      </Wrapper>
    );
  }

  if (showGlobalizaLogo) {
    return (
      <Wrapper>
        <GlobalizaLogo />
        {premiumTag}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Logo />
      {premiumTag}
    </Wrapper>
  );
}

export function BrandLogoForAnonymousUsers({ ...rest }) {
  const showDotPropertyLogo = useSelector(showByDotPropertyLogoSelector);
  const showLamudiLogo = useSelector(showByLamudiLogoSelector);
  const showGlobalizaLogo = useSelector(showGlobalizaLogoSelector);
  if (showDotPropertyLogo) {
    return <LogoDotPropertyGroup {...rest} />;
  }

  if (showLamudiLogo) {
    return <LogoLamudi {...rest} />;
  }

  if (showGlobalizaLogo) {
    return <GlobalizaLogo {...rest} />;
  }

  return <Logo {...rest} />;
}
