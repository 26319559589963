import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import styled from 'styled-components';
import ImagePreview from '../partials/ImagePreview/ImagePreview';
import SortableImage from '../partials/SortableImage/SortableImage';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 5em);
  grid-template-rows: repeat(3, minmax(auto, 5em));
  column-gap: ${spacing.value(SPACING_SIZE.M)};
  row-gap: ${spacing.value(SPACING_SIZE.M)};
`;

export default function SortableContainer({
  images,
  onSort,
  onDelete,
  disabledDragAndDrop,
}) {
  const [items, setItems] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  useEffect(() => {
    setItems(images);
  }, [images]);

  const handleDragStart = ({ active }) => {
    setActiveItem(items.find((item) => item.id === active.id));
  };

  const handleDragEnd = ({ active, over }) => {
    if (!active || !over) return;
    if (active.id !== over.id) {
      onSort({
        draggedImageId: active.id,
        destinationImageId: over.id,
      });
      setItems((prevItems) => {
        const oldIndex = prevItems.findIndex((item) => item.id === active.id);
        const newIndex = prevItems.findIndex((item) => item.id === over.id);

        const sortedArray = arrayMove(prevItems, oldIndex, newIndex);

        return sortedArray.map((item, index) => {
          if (index > 0) {
            return {
              ...item,
              isMain: false,
            };
          }
          return {
            ...item,
            isMain: true,
          };
        });
      });
    }

    setActiveItem(null);
  };

  const handleDragCancel = () => {
    setActiveItem(null);
  };

  return !disabledDragAndDrop ? (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Wrapper>
          {items.map((item) => (
            <SortableImage
              key={item.id}
              id={item.id}
              activeId={activeItem?.id}
              file={item.file}
              isMain={item.isMain}
              isBadQuality={item.isBadQuality}
              onDelete={onDelete}
            />
          ))}
        </Wrapper>
      </SortableContext>
      <DragOverlay adjustScale>
        {activeItem ? (
          <ImagePreview
            id={activeItem.id}
            file={activeItem.file}
            isMain={activeItem.isMain}
            isBadQuality={activeItem.isBadQuality}
            displayDeleteIcon={false}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  ) : (
    <Wrapper>
      {items.map((item) => (
        <SortableImage
          key={item.id}
          id={item.id}
          activeId={activeItem?.id}
          file={item.file}
          isMain={item.isMain}
          isBadQuality={item.isBadQuality}
          onDelete={onDelete}
          displayDeleteIcon={false}
        />
      ))}
    </Wrapper>
  );
}
