import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import ContactInterest from 'design-system/components/ContactInterest/ContactInterest';
import Icon from 'design-system/components/Icon';
import { useSelector } from 'reffects-store';
import {
  isLoadingLeadQualificationSelector,
  leadQualificationSelector,
} from '../../selectors';
import FormattedMessageWithValidation from '../../../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import PremiumFeedback from '../../../../../../../../partials/PremiumFeedback/PremiumFeedback';

const Wrapper = styled.div`
  display: flex;
  ${spacing.insetSandwich(SPACING_SIZE.XL)};
  flex-direction: column;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

const QualificationBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  align-self: stretch;
`;

const QualificationTitle = styled.div`
  ${color.text(COLOR_PALETTE.NEUTRAL_A60)};
  ${typography.font(FONT_FAMILY.PRIMARY)};
  ${typography.weight(FONT_WEIGHT.MEDIUM)};
  ${typography.size(FONT_SIZE.S)};
  padding-left: ${spacing.value(SPACING_SIZE.XL)};
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
`;

const CustomContactInterest = styled(ContactInterest)`
  width: 100%;
  padding: ${spacing.value(SPACING_SIZE.M)} ${spacing.value(SPACING_SIZE.XL)};

  > * {
    padding: 0;
  }
`;

const SpacedDiv = styled.div`
  width: 100%;
  padding: 0 ${spacing.value(SPACING_SIZE.L)};
`;

export default function PremiumLeadQualification() {
  const isLoading = useSelector(isLoadingLeadQualificationSelector);
  const {
    isSuperHot,
    isHot,
    hasFinancingNeed,
    hasGuarantee,
    timeToMove,
    financingNeed,
    guarantee,
  } = useSelector(leadQualificationSelector);

  if (isLoading) {
    return null;
  }

  return (
    <Wrapper>
      <QualificationBlock>
        <QualificationTitle>
          <FormattedMessageWithValidation id="lead_detail_qualification_title" />
        </QualificationTitle>
        {(hasFinancingNeed || hasGuarantee) && (
          <CustomContactInterest
            title={
              <FormattedMessageWithValidation id="lead_detail_qualification_financial_data" />
            }
          >
            {financingNeed && (
              <ContactInterest.Feature icon={<Icon glyph="doneCircle" />}>
                <FormattedMessageWithValidation
                  id={`enquiry_new_email_qualification_financing_need_${financingNeed}`}
                />
              </ContactInterest.Feature>
            )}
            {guarantee && (
              <ContactInterest.Feature icon={<Icon glyph="doneCircle" />}>
                <FormattedMessageWithValidation
                  id={`enquiry_new_email_qualification_guarantee_${guarantee}`}
                />
              </ContactInterest.Feature>
            )}
          </CustomContactInterest>
        )}

        {(isHot || isSuperHot) && (
          <CustomContactInterest
            title={
              <FormattedMessageWithValidation id="lead_detail_qualification_hotness" />
            }
          >
            <ContactInterest.Feature
              icon={
                isSuperHot ? <Icon glyph="superHot" /> : <Icon glyph="hot" />
              }
            >
              <FormattedMessageWithValidation
                id={`leadpage_activity_bubble_${timeToMove}`}
              />
            </ContactInterest.Feature>
          </CustomContactInterest>
        )}
      </QualificationBlock>
      <SpacedDiv>
        <PremiumFeedback
          label="premium_feedback_question_qualification"
          section="Qualification"
        />
      </SpacedDiv>
    </Wrapper>
  );
}
