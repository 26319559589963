import { createSelector } from 'reselect';
import { countryConfigSelector } from '../../selectors/config';
import {
  developerActivePromotedProjectsSelector,
  isDeveloperSelector,
  isPremiumSubscriptionSelector,
  publisherCountrySelector,
} from '../../selectors/publisher';
import { OPERATION_TYPE_SELL, operationTypeCaptions } from '../../constants/ad';
import { PROJECT_ID_INPUT_NAME, PROJECT_INPUT_NAME } from './constants';
import { operationTypesByCountrySelector } from '../../selectors/country';

const COUNTRIES_WITH_CONTACT_LINE = ['TH'];
const COUNTRIES_WITH_CONTACT_VIBER = ['PH'];
const COUNTRIES_WITH_CONTACT_FACEBOOK_MESSENGER = ['TH', 'VN', 'PH'];
const COUNTRIES_WITH_OWNERSHIP = ['TH', 'VN', 'PH'];
const COUNTRIES_WITH_TENURE = ['TH', 'VN', 'PH'];

const propertyTypeParameter = (_, propertyType) => propertyType;

function pruneFalsyValues(values) {
  return Object.fromEntries(
    Object.entries(values).filter(([, val]) => Boolean(val))
  );
}

export function selectedOperationTypeSelector(state) {
  return adDataSelector(state)?.operationType ?? undefined;
}

export function selectedPropertyTypeSelector(state) {
  return adDataSelector(state)?.propertyType ?? undefined;
}

export function sellPriceSelector(state) {
  const { sellPrice, sellPriceCurrency } = adDataSelector(state) ?? {};
  return createPrice(sellPrice, sellPriceCurrency);
}

export function rentPriceSelector(state) {
  const { rentPrice, rentPriceCurrency } = adDataSelector(state) ?? {};
  return createPrice(rentPrice, rentPriceCurrency);
}

function createPrice(amount, currency) {
  return amount == null || currency == null ? undefined : { amount, currency };
}

export function floorAreaSelector(state) {
  const { floorArea, floorAreaUnit } = adDataSelector(state) ?? {};
  return createArea(floorArea, floorAreaUnit);
}

function createArea(value, unit) {
  return value == null || unit == null ? undefined : { value, unit };
}

export function formIsBeingSubmittedSelector(state) {
  return state['adForm:submitting'] ?? false;
}

export function adDataSelector(state) {
  if (!state['adForm:ad']) return undefined;

  return pruneFalsyValues(state['adForm:ad']);
}

export const projectSelector = createSelector(
  adDataSelector,
  (adData) => adData?.[PROJECT_INPUT_NAME]
);

export const projectIdSelector = createSelector(
  adDataSelector,
  (adData) => adData?.[PROJECT_ID_INPUT_NAME]
);

const projectAmenitiesSelector = (state) =>
  state['adForm:projectAmenities'] ?? [];

export const detailsOfPropertyTypeSelector = createSelector(
  countryConfigSelector,
  propertyTypeParameter,
  (countryConfig, propertyType) =>
    countryConfig.adFormRules[propertyType]?.details ?? []
);

export const amenitiesOfPropertyTypeSelector = createSelector(
  countryConfigSelector,
  propertyTypeParameter,
  (countryConfig, propertyType) =>
    countryConfig.adFormRules[propertyType]?.amenities ?? []
);

export const projectAmenitiesOfPropertyTypeSelector = createSelector(
  projectAmenitiesSelector,
  amenitiesOfPropertyTypeSelector,
  (projectAmenities, amenities) =>
    projectAmenities.filter((amenity) => amenities.includes(amenity))
);

export const nearbyLocationsOfPropertyTypeSelector = createSelector(
  countryConfigSelector,
  propertyTypeParameter,
  (countryConfig, propertyType) =>
    countryConfig.adFormRules[propertyType]?.nearbyLocations ?? []
);
export const rulesOfPropertyTypeSelector = createSelector(
  countryConfigSelector,
  propertyTypeParameter,
  (_, x, operationType) => operationType,
  (countryConfig, propertyType, operationType) =>
    operationType === OPERATION_TYPE_SELL
      ? []
      : countryConfig.adFormRules[propertyType]?.rules ?? []
);

export function floorAreaUnitChoicesSelector(state) {
  const countryConfig = countryConfigSelector(state);
  return countryConfig.areas.floorAreaUnitChoices;
}

export function floorAreaUnitsSelector(state) {
  const floorAreaUnitChoices = floorAreaUnitChoicesSelector(state);
  return reduceAreaChoices(floorAreaUnitChoices);
}

export function usableAreaUnitChoicesSelector(state) {
  const countryConfig = countryConfigSelector(state);
  return countryConfig.areas.usableAreaUnitChoices;
}

export function usableAreaUnitsSelector(state) {
  const usableAreaUnitChoices = usableAreaUnitChoicesSelector(state);
  return reduceAreaChoices(usableAreaUnitChoices);
}

export function plotAreaUnitChoicesSelector(state) {
  const countryConfig = countryConfigSelector(state);
  return countryConfig.areas.plotAreaUnitChoices;
}

export function plotAreaUnitsSelector(state) {
  const plotAreaUnitChoices = plotAreaUnitChoicesSelector(state);
  return reduceAreaChoices(plotAreaUnitChoices);
}

export function defaultCommunityFeesSelector(state) {
  const countryConfig = countryConfigSelector(state);
  return countryConfig.currencies.defaultCommunityFees;
}

export function callingCodeSelector(state) {
  const countryConfig = countryConfigSelector(state);
  return countryConfig.phoneNumber.callingCode;
}

export function defaultAreaUnitSelector(state) {
  const countryConfig = countryConfigSelector(state);
  return countryConfig.areas?.defaultAreaUnit ?? null;
}

export function defaultPriceCurrencySelector(state) {
  const countryConfig = countryConfigSelector(state);
  return countryConfig.currencies?.defaultPropertyPrice ?? null;
}

export function defaultContactDetailsSelector(state) {
  return state['adForm:defaultContact'] ?? undefined;
}

export function shouldShowContactLineSelector(state) {
  const country = publisherCountrySelector(state);
  return COUNTRIES_WITH_CONTACT_LINE.includes(country);
}

export function shouldShowContactViberSelector(state) {
  const country = publisherCountrySelector(state);
  return COUNTRIES_WITH_CONTACT_VIBER.includes(country);
}

export function shouldShowContactFacebookMessengerSelector(state) {
  const country = publisherCountrySelector(state);
  return COUNTRIES_WITH_CONTACT_FACEBOOK_MESSENGER.includes(country);
}

export function shouldShowOwnershipSelector(state) {
  const country = publisherCountrySelector(state);
  return COUNTRIES_WITH_OWNERSHIP.includes(country);
}

export function shouldShowTenureSelector(state) {
  const country = publisherCountrySelector(state);
  return COUNTRIES_WITH_TENURE.includes(country);
}

export function showSuitableForCreditSelector(state) {
  return publisherCountrySelector(state) === 'AR';
}

function reduceAreaChoices(areas) {
  return areas.reduce(
    (acc, { name, label }) => ({
      ...acc,
      [name]: label,
    }),
    {}
  );
}

export function operationTypeOptionsSelector(state) {
  const operationTypes = operationTypesByCountrySelector(state);

  return operationTypes.map((operationType) => ({
    operationType,
    caption: operationTypeCaptions.get(operationType),
  }));
}

export function isPublicationEnabledSelector(state) {
  if (isDeveloperSelector(state)) {
    return isPublicationEnabledForProjectSelector(state)({
      projectId: projectIdSelector(state),
    });
  }
  return true;
}

export function isPublicationEnabledForProjectSelector(state) {
  const activePromotedProjects = developerActivePromotedProjectsSelector(state);
  return ({ projectId }) => activePromotedProjects.includes(projectId);
}

export function getImageUploaderStatusSelector(state) {
  return state.imageUploaderStatus;
}

export function propertyImagesSortedWithAiSelector(state) {
  if (!isPremiumSubscriptionSelector(state)) {
    return false;
  }
  return state['adForm:ad']?.propertyImagesSortedWithAi ?? true;
}

export function showRestoreSortButtonSelector(state) {
  return state['adForm:showRestoreSortButton'] ?? false;
}

export function sortingErrorRetryAtSelector(state) {
  return state['adForm:sortingErrorRetryDelay'] ?? false;
}
