const palette = {
  primary: {
    a05: '#F3FCF9',
    a10: '#E7FAF3',
    a20: '#CEF5E7',
    a40: '#9DEBCF',
    a60: '#6CE0B6',
    a80: '#3BD69E',
    base: '#0ACC86',
    a120: '#08A36B',
    a140: '#067A50',
    a160: '#08A36B',
  },
  secondary: {
    a05: '#F3F4F5',
    a10: '#E7E8EC',
    a20: '#CFD1D8',
    a40: '#A0A4B1',
    a60: '#70768A',
    a80: '#414963',
    base: '#111B3C',
  },
  pumpkin: {
    a05: '#FFFAF2',
    a10: '#FFF6E6',
    a20: '#FFEDCC',
    a40: '#FFDB99',
    a60: '#FFC866',
    a80: '#FFB633',
    base: '#FFA400',
    a110: '#E59400',
  },
  gold: {
    a120: '#A67C00',
    base: '#FFDC73',
  },
  eggplant: {
    a05: '#F7F2FA',
    a10: '#F0E6F5',
    a20: '#E1CCEB',
    a40: '#C399D6',
    a60: '#A566C2',
    a80: '#8633AD',
    base: '#680099',
  },
  blueberry: {
    a05: '#F2FAFF',
    a10: '#E6F4FF',
    a20: '#CCEAFF',
    a40: '#99D5FF',
    a60: '#66BFFF',
    a80: '#33AAFF',
    base: '#1A73E8',
  },
  strawberry: {
    a05: '#FDF5F2',
    a10: '#FBEBE6',
    a20: '#F7D7CC',
    a40: '#F0B099',
    a60: '#E88866',
    a80: '#D84A27',
    base: '#D93900',
  },
  neutral: {
    a00: '#FFFFFF',
    a03: '#F7F7F7',
    a05: '#F2F2F2',
    a10: '#E6E6E6',
    a20: '#CCCCCC',
    a40: '#999999',
    a60: '#666666',
    a80: '#333333',
    base: '#000000',
  },
};

const semantic = {
  paper: palette.neutral.a00,
  divider: palette.neutral.a10,
  status: {
    error: palette.strawberry.base,
  },
};

export const color = {
  palette,
  semantic,
};
