import { MEXICO_TYPE_OF_CLIENT } from '../../CreatePublisher/constants';
import { TYPE_OF_CLIENT_FREE_VALUE } from './constants';

const defaultChoices = [
  {
    id: TYPE_OF_CLIENT_FREE_VALUE,
    label: 'Free',
    value: TYPE_OF_CLIENT_FREE_VALUE,
  },
  {
    id: MEXICO_TYPE_OF_CLIENT.AGENCIA,
    label: 'Agencia',
    value: MEXICO_TYPE_OF_CLIENT.AGENCIA,
  },
  {
    id: MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM,
    label: 'Agencia Premium',
    value: MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM,
  },
  {
    id: MEXICO_TYPE_OF_CLIENT.REMATES,
    label: 'Remates',
    value: MEXICO_TYPE_OF_CLIENT.REMATES,
  },
  {
    id: MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM,
    label: 'Remates Premium',
    value: MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM,
  },
];

export default function filterChoices(availableTypes) {
  if (availableTypes == null) {
    return defaultChoices;
  }

  return defaultChoices.filter(({ id }) =>
    availableTypes.some((availableType) => availableType === id)
  );
}
