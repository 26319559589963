import styled from 'styled-components';
import Layout from 'design-system/components/Layout';
import Illustration from 'design-system/components/Illustration/Illustration';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { Navigation } from '../../../partials';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import PublisherData from '../PublisherData/PublisherData';
import { retrieveQueryParams } from '../../../coeffects/queryParamsAll';
import { CHECKOUT_TRACK_PAGE_VIEW } from '../CheckoutPageLoader/events';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Subtitle1} {
    ${spacing.stack(SPACING_SIZE.M, false)}
  }

  ${Illustration} {
    ${spacing.stack(SPACING_SIZE.L, false)}
  }
`;

export default function SubscriptionAlreadyActive({ ...props }) {
  const params = useParams();
  const { queryParamsAll } = retrieveQueryParams(window);

  useMount(() => {
    const { pageViewName, pageViewPayloadSelector } = props;
    dispatch({
      id: CHECKOUT_TRACK_PAGE_VIEW,
      payload: {
        pageViewName,
        pageViewPayload: pageViewPayloadSelector({
          ...props,
          ...params,
          ...queryParamsAll,
        }),
      },
    });
  });

  return (
    <Layout>
      <Layout.Header>
        <Navigation title={<PublisherData />} hideLinks hideAppBanner />
      </Layout.Header>
      <Layout.Content>
        <Layout.Padded>
          <Content>
            <Subtitle1>
              <FormattedMessageWithValidation id="checkout_forbidden_title" />
            </Subtitle1>
            <Illustration name="welcome" />
            <Body1>
              <FormattedMessageWithValidation id="checkout_forbidden_body" />
            </Body1>
          </Content>
        </Layout.Padded>
      </Layout.Content>
    </Layout>
  );
}
