import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../../../../coeffects/environment';
import { toast } from '../../../../../../effects/toast';
import { CLOSE_DIALOG } from '../../../../../../events/dialogs';
import { openNewTab } from '../../../../../../effects/routing';

export const RESEND_FIRST_PAYMENT_CHECKOUT_EMAIL =
  'RESEND_FIRST_PAYMENT_CHECKOUT_EMAIL';
export const MARK_PUBLISHER_AS_TEST = 'MARK_PUBLISHER_AS_TEST';
export const MARK_PUBLISHER_AS_TEST_SUCCESS = 'MARK_PUBLISHER_AS_TEST_SUCCESS';
export const MARK_PUBLISHER_AS_TEST_ERROR = 'MARK_PUBLISHER_AS_TEST_ERROR';
export const OPEN_PUBLISHER_HISTORY = 'OPEN_PUBLISHER_HISTORY';

registerEventHandler(
  RESEND_FIRST_PAYMENT_CHECKOUT_EMAIL,
  ({ environment: { apiUrl } }, { publisherId }) => ({
    ...toast.show({ text: 'First Payment Email has been sent successfully!' }),
    ...http.post({
      url: `${apiUrl}/admin/publishers/${publisherId}/resend-first-payment-email`,
    }),
  }),
  [environment()]
);

registerEventHandler(
  MARK_PUBLISHER_AS_TEST,
  ({ environment: { apiUrl } }, { publisherId, onSuccessEvent }) =>
    http.post({
      url: `${apiUrl}/backoffice/publishers/${publisherId}/test`,
      successEvent: {
        id: MARK_PUBLISHER_AS_TEST_SUCCESS,
        payload: { onSuccessEvent },
      },
      errorEvent: MARK_PUBLISHER_AS_TEST_ERROR,
    }),
  [environment()]
);

registerEventHandler(
  MARK_PUBLISHER_AS_TEST_SUCCESS,
  (_, [__, { onSuccessEvent }]) => ({
    ...toast.show({ text: 'Publisher marked as test' }),
    ...effects.dispatchMany([CLOSE_DIALOG, onSuccessEvent]),
  })
);

registerEventHandler(MARK_PUBLISHER_AS_TEST_ERROR, () =>
  toast.show({ text: 'Error marking publisher as test' })
);

registerEventHandler(
  OPEN_PUBLISHER_HISTORY,
  ({ environment: { apiUrl } }, { publisherId }) =>
    openNewTab(`${apiUrl}/dev-tools/agency/timeline/${publisherId}`, '_blank'),
  [environment()]
);
